import * as React from "react";
const SvgResetViewDesktop = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "Ebene_2", width: 26, height: 26, "data-name": "Ebene 2", ...props }, /* @__PURE__ */ React.createElement("path", { d: "m18.396 18.67.336.328zm-4.517 2.27-.063-.465zm-4.958-.985.237-.406zm-2.868-4.001a.47.47 0 0 0-.873.35zm14.068-2.194c-.183 1.636-.936 3.429-2.061 4.58l.673.658c1.295-1.327 2.12-3.326 2.322-5.133zm-2.061 4.58a7.3 7.3 0 0 1-4.244 2.135l.127.932a8.25 8.25 0 0 0 4.79-2.41zm-4.244 2.135a7.3 7.3 0 0 1-4.658-.926l-.474.812a8.24 8.24 0 0 0 5.259 1.046zm-4.658-.926a7.3 7.3 0 0 1-3.105-3.595l-.873.35a8.24 8.24 0 0 0 3.504 4.057z", style: {
  fill: "#333"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.38 11.502q.111-.548.296-1.077", style: {
  fill: "none",
  stroke: "#333",
  strokeDasharray: "0 0 1.98 1.98",
  strokeLinecap: "square",
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m3.432 8.972 1.612 2.796a.52.52 0 0 0 .705.193l2.776-1.57", style: {
  fill: "none",
  stroke: "#333",
  strokeLinecap: "round",
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m20.484 16.09-.886-.317.158-.443a7.31 7.31 0 0 0 .43-2.465c0-.425-.038-.854-.115-1.276l-.082-.463.925-.166.084.462a8.253 8.253 0 0 1-.356 4.224zM5.823 11.515l-.91-.234.116-.455c.231-.9.611-1.746 1.13-2.516l.262-.39.78.525-.262.39a7.2 7.2 0 0 0-1 2.225zM19.47 9.704l-.232-.408a7.3 7.3 0 0 0-1.548-1.888l-.355-.309.616-.71.355.309a8.2 8.2 0 0 1 1.748 2.132l.233.409zM8.247 7.351l-.562-.754.378-.28a8.2 8.2 0 0 1 2.456-1.253l.45-.14.279.897-.45.14a7.3 7.3 0 0 0-2.174 1.11zm7.798-1.037-.438-.172a7.3 7.3 0 0 0-2.39-.5l-.47-.02.037-.939.47.019q.495.02.998.1c.583.094 1.154.25 1.7.464l.436.173z", style: {
  fill: "#333"
} }));
export default SvgResetViewDesktop;

import { BffConfiguratorConfigurationAttributes } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { ButtonTile, Panel } from '@components/configurator';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';

interface LayerConfigureAttributeProps {
    currentSku: string;
    configuration: BffConfiguratorConfigurationAttributes;
    disabledBackButton?: boolean;
    onChangeOption: (sku: string) => void;
    onClose: () => void;
}

export const LayerConfigureAttribute = ({
    configuration: { title, values },
    currentSku,
    onChangeOption,
    disabledBackButton,
    onClose
}: LayerConfigureAttributeProps) => {
    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    const renderOptions = () => {
        const options = values?.map(({ sku, title, image, subtitle }) => {
            return (
                <ButtonTile.Item direction={tileDirection} key={sku}>
                    <ButtonTile.Surface
                        direction={tileDirection}
                        onClick={() => {
                            onChangeOption(sku);
                        }}
                        selected={currentSku === sku}
                    >
                        <ButtonTile.Image
                            size={thumbnailSize}
                            direction={tileDirection}
                            image={image as ButtonTileImageImage}
                        />
                        <ButtonTile.Text
                            direction={tileDirection}
                            title={title}
                            size={md ? 'large' : 'default'}
                            subtitle={md && subtitle}
                        />
                    </ButtonTile.Surface>
                </ButtonTile.Item>
            );
        });

        return <ButtonTile.List direction={listDirection}>{options}</ButtonTile.List>;
    };

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" isDisabled={disabledBackButton} onPress={onClose} />
                <Panel.ScrollArea>
                    <Panel.Title>{title}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation
                kind="back"
                onPress={onClose}
                isDisabled={disabledBackButton}
                appearance="mobile"
                title={title as string}
            />
            <Panel.ScrollArea direction={'horizontal'}>{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

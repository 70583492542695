export const colors = {
    white: '#fff',
    black: '#101010',
    grey: '#A9A9A9',

    coral1: '#fcc9c9',
    coral2: '#d43e42',
    coral3: '#ef6b6b',

    // Todo: Consider moving this to a tokens object once we have more colors indentified
    text1: '#333333'
    // text2: '...'
    // background1: '...'
    // background2: '...'
    // border1: '...'

    // Todo: Document colors for shadows and overlays
    // black1: 'rgb(0 0 0 / 7%)',
    // black2: 'rgb(16 16 16 / 10%)',
    // black3: 'rgb(239 107 107 / 12%)'

    // Todo: Document and order gray values
    // gray1: '#f5f5f5',
    // gray2: '#e6e6e6',
    // gray3: '#d9d9d9',
    // gray4: '#c4c4c4',
    // gray5: '#a9a9a9',
};

export const colorFamilies = {
    purple_pink: '#9C586E',
    red_orange: '#C23D19',
    brown: '#603012',
    yellow: '#CAB713',
    green: '#517E35',
    blue: '#2E4F88',
    black_dark_grey: '#282828',
    white_light_grey: '#D9D9D9'
};

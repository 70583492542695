import { PanelCounter } from './panel-counter';
import { PanelFooter } from './panel-footer';
import { PanelHint } from './panel-hint';
import { PanelNavigation } from './panel-navigation';
import { PanelOrderSamples } from './panel-order-samples';
import { PanelScrollArea } from './panel-scroll-area';
import { PanelSticky } from './panel-sticky';
import { PanelSurface } from './panel-surface';
import { PanelTabList } from './panel-tab-list';
import { PanelContent, PanelTabPanel } from './panel-tab-panel';
import { PanelTitle } from './panel-title';

export const Panel = {
    Sticky: PanelSticky,
    Title: PanelTitle,
    Footer: PanelFooter,
    Counter: PanelCounter,
    ScrollArea: PanelScrollArea,
    Surface: PanelSurface,
    Navigation: PanelNavigation,
    Hint: PanelHint,
    TabList: PanelTabList,
    TabPanel: PanelTabPanel,
    Content: PanelContent,
    OrderSamples: PanelOrderSamples
};

import { Spinner } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { useState } from 'react';
import { useFocusRing } from 'react-aria';
import { Button as DefaultButton, ButtonProps as DefaultButtonProps, PressEvent } from 'react-aria-components';

const styles = {
    reset: css`
        all: unset;
    `,
    tileImage: css`
        border-radius: 5px;
        font-size: 0;
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 140px;
        height: 102px;
        background: linear-gradient(0deg, rgba(0 0 0 / 0%) 0%, rgba(0 0 0 / 5%) 60%), #f2f2f2;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    `,
    tileImageDragDisabled: css`
        img {
            -webkit-touch-callout: none;
            user-select: none;
            pointer-events: none;
            -webkit-user-drag: none;
        }
    `,
    tileImageFocusVisible: css`
        outline: 1.5px solid ${colors.coral2};
        outline-offset: 3px;
    `,
    tileImageEmpty: css`
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #e8e8e8;
        position: relative;
    `,
    tileImageSpinner: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `,
    tileImageCircle: css`
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        margin: 5px;
    `,
    tileDirectionRow: css`
        margin-right: 18px;
    `,
    tileDirectionColumn: css`
        flex-direction: column;
        max-width: 140px;
    `,
    tileImagetint: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        mix-blend-mode: multiply;
    `
};

export type ButtonTileImageImage = {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
};

interface ButtonTileImageProps extends DefaultButtonProps {
    className?: string;
    image?: ButtonTileImageImage | null; // Pass null to show placeholder
    direction: 'row' | 'column';
    shape?: 'default' | 'circle';
    onClick?: (event: PressEvent) => void;
    size: [number | string, number | string];
    children?: React.ReactNode;
    disableDrag?: boolean;
    tint?: boolean;
    showLoadingIndicator?: boolean;
}

export const ButtonTileImage = ({
    className,
    image,
    direction,
    children,
    shape = 'default',
    size = [80, 80],
    disableDrag,
    tint,
    showLoadingIndicator = false,
    onClick
}: ButtonTileImageProps) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    const Element = onClick ? DefaultButton : 'span';

    const [isLoading, setIsLoading] = useState(showLoadingIndicator);

    const handleOnLoad = () => {
        setIsLoading(false);
    };

    return (
        <Element
            className={cx(
                'tile-image',
                `tile-image-${shape}`,
                `tile-image-${size.join('-')}`,
                image === null && 'tile-image-empty',
                styles.reset,
                styles.tileImage,
                isFocusVisible && styles.tileImageFocusVisible,
                direction === 'column' && styles.tileDirectionColumn,
                direction === 'row' && styles.tileDirectionRow,
                shape === 'circle' && styles.tileImageCircle,
                disableDrag && styles.tileImageDragDisabled,
                className
            )}
            style={{
                width: size[0],
                height: size[1],
                minWidth: size[0]
            }}
            {...(onClick && { ...(focusProps as any) })}
            {...(onClick && { onPress: onClick })}
        >
            {image === null && (
                <div className={styles.tileImageEmpty}>
                    <svg style={{ width: '100%', height: '100%' }} preserveAspectRatio="none">
                        <line x1="0" y1="100%" x2="100%" y2="0" stroke="#dcdcdc" strokeWidth="3" />
                    </svg>
                </div>
            )}

            {image !== null && image && (
                <img loading="lazy" alt={image.alt ?? ''} src={image.src} onLoad={handleOnLoad} />
            )}

            {showLoadingIndicator && isLoading && <Spinner className={styles.tileImageSpinner} />}

            {tint && <div className={styles.tileImagetint} />}

            {children}
        </Element>
    );
};

import { useMedia } from '@common/hooks/use-media';
import { media } from '@common/styles/media';
import { SearchInput } from '@components/configurator';
import { Panel } from '@components/configurator/components/panel';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { css, cx } from '@linaria/core';
import { ModalOverlay } from 'react-aria-components';

const styles = {
    searchInputContainer: css`
        width: 100%;
        padding: 9px 17px 10px;

        ${media.md} {
            padding: 0;
        }
    `,
    counterContainer: css`
        display: flex;
        justify-content: center;
        padding: 29px 0;
    `,
    mobileConfigureSearchOverlay: css`
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        height: var(--visual-viewport-height);
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};

        > * {
            box-shadow: 0 0 10px 0 rgba(0 0 0 / 10%);
            margin-top: auto;
            padding-top: 20px;
            background: white;
        }
    `
};

export const LayerConfigureColorSearch = ({
    searchText,
    onClose,
    onClear,
    onSearchInputChange,
    children,
    counter
}: {
    searchText: string;
    onClose: () => void;
    onClear: () => void;
    onSearchInputChange: (value: string) => void;
    children: React.ReactNode;
    counter: React.ReactNode;
}) => {
    const { md } = useMedia();

    const bodyElement = (
        <>
            <Panel.Sticky>
                <div className={styles.searchInputContainer}>
                    <SearchInput
                        value={searchText || ''}
                        onChange={onSearchInputChange}
                        onClear={onClear}
                        onClose={onClose}
                    />
                </div>
            </Panel.Sticky>
            {counter ? <div className={styles.counterContainer}>{counter}</div> : null}
            {children}
        </>
    );

    if (md) return <Panel.ScrollArea>{bodyElement}</Panel.ScrollArea>;

    return (
        <ModalOverlay
            isOpen={true}
            className={cx('layer-configure-color-search-overlay', styles.mobileConfigureSearchOverlay)}
        >
            <div>{bodyElement}</div>
        </ModalOverlay>
    );
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { Fragment } from 'react';

const styles = {
    searchInputFieldLabelProposal: css`
        @keyframes blink {
            0% {
                border-right-color: transparent;
            }

            50% {
                border-right-color: ${colors.grey};
            }

            100% {
                border-right-color: transparent;
            }
        }

        @keyframes reveal {
            0% {
                max-width: 0;
                opacity: 1;
            }

            16.666% {
                max-width: 100%;
                opacity: 1;
            }

            33.333% {
                max-width: 0;
            }

            33.3336% {
                opacity: 0;
            }
        }

        &.word-index-0 {
            --index: 0;
        }

        &.word-index-1 {
            --index: 1;
        }

        &.word-index-2 {
            --index: 2;
        }

        max-width: 0;
        opacity: 0;
        transition:
            max-width 1s,
            opacity 1s;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 4px;
        color: ${colors.coral2};
        animation: reveal 9s forwards;
        animation-delay: calc(3s * var(--index));

        &.touched {
            animation: none;
        }

        &::after {
            content: '';
            padding-left: 4px;
            border-right: 1px solid ${colors.grey};
            animation: blink 1s infinite;
        }
    `
};

export const SearchInputHint = () => {
    return (
        <Fragment>
            <span className={cx(styles.searchInputFieldLabelProposal, 'word-index-0')}>
                <Trans>red</Trans>
            </span>
            <span className={cx(styles.searchInputFieldLabelProposal, 'word-index-1')}>
                <Trans>twill</Trans>
            </span>
            <span className={cx(styles.searchInputFieldLabelProposal, 'word-index-2')}>
                <Trans>jade grey</Trans>
            </span>
        </Fragment>
    );
};

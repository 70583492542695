import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { forwardRef, RefObject } from 'react';
import {
    Button as DefaultButton,
    ButtonProps as DefaultButtonProps,
    ToggleButton as DefaultToggleButton,
    ToggleButtonProps as DefaultToggleButtonProps
} from 'react-aria-components';

import { Spinner } from '../spinner';

export const styles = {
    reset: css`
        all: unset;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;

        &[data-focus-visible] {
            outline-width: 1.5px;
            outline-offset: 3px;
            outline-style: solid;
        }

        &[data-pressed] {
            transform: scale(0.97);
            transition: transform 0.1s;
        }
    `,
    button: css`
        padding: 11px 22px 12px;
        border-radius: 5px;
        gap: 6px;
        font-size: 13px;
        font-weight: 400;
        line-height: 122%;
        align-items: center;
    `,
    buttonIcon: css`
        font-size: 0;
        display: flex;
    `,
    basic: css`
        background: #fff;
        color: ${colors.coral2};
        outline-color: ${colors.coral2};
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

        &[data-hovered] {
            box-shadow: 0 1.867px 3.734px 0 rgb(0 0 0 / 24%);
        }
    `,
    primary: css`
        background: #333;
        color: #fff;
        outline-color: #333;
        font-size: 15px;

        &[data-hovered] {
            background-color: #101010;
        }

        &[data-selected] {
            background-color: ${colors.coral2};
            color: #fff;

            &[data-focus-visible] {
                outline-color: ${colors.coral2};
            }
        }
    `,
    secondary: css`
        background: #fff;
        color: #101010;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        outline-color: ${colors.coral2};

        &[data-hovered] {
            box-shadow: 0 1.867px 3.734px 0 rgb(0 0 0 / 24%);
        }
    `,
    coral: css`
        background: ${colors.coral2};
        color: ${colors.white};
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        outline-color: ${colors.coral2};

        svg {
            stroke: ${colors.white};
        }

        &[data-hovered] {
            box-shadow: 0 1.867px 3.734px 0 rgb(0 0 0 / 24%);
        }
    `,
    tertiary: css`
        background: #fff;
        color: #101010;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        outline-color: ${colors.coral2};

        &[data-hovered] {
            box-shadow: 0 1.867px 3.734px 0 rgb(0 0 0 / 24%);
            color: ${colors.coral2};
        }

        &[data-selected] {
            color: ${colors.coral2};
        }
    `,
    rounded: css`
        border-radius: 90px;
        padding: 11px 18px;
    `,
    spinner: css`
        width: 16px;
        height: 16px;

        span {
            opacity: 1 !important;
            max-width: 7rem !important;
        }
    `
};

export interface ButtonProps {
    ref?: RefObject<HTMLButtonElement>;
    icon?: React.ReactNode;
    variant?: 'basic' | 'primary' | 'secondary' | 'tertiary' | 'coral';
    rounded?: boolean;
    children: React.ReactNode;
    className?: string;
    isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps & DefaultButtonProps>((props, ref) => {
    const { children, icon, rounded = false, variant = 'basic', className, isLoading, ...rest } = props;

    return (
        <DefaultButton
            ref={ref}
            className={cx(styles.reset, styles.button, styles[variant], rounded && styles.rounded, className)}
            {...rest}
        >
            {isLoading && <Spinner className={cx(styles.spinner)} />}
            {icon && (
                <span aria-hidden="true" className={styles.buttonIcon}>
                    {icon}
                </span>
            )}
            {children}
        </DefaultButton>
    );
});
Button.displayName = 'Button';

export const ToggleButton = (props: ButtonProps & DefaultToggleButtonProps) => {
    const { children, icon, rounded = false, variant = 'basic', ...rest } = props;

    return (
        <DefaultToggleButton
            className={cx(styles.reset, styles.button, styles[variant], rounded && styles.rounded)}
            {...rest}
        >
            {icon && <span aria-hidden="true">{icon}</span>}
            {children}
        </DefaultToggleButton>
    );
};

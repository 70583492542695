import {
    BffConfiguratorConfiguration,
    BffConfiguratorConfigurationAttributesValue,
    BffConfiguratorConfigurationColorItem
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { ButtonTile, Panel } from '@components/configurator';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useState } from 'react';

const styles = {
    layerTitleCounter: css`
        margin-left: 8px;
        color: #a2a2a2;
        font-weight: 400;
        opacity: 0;
    `,
    layerTitleCounterVisible: css`
        opacity: 1;
    `
};

type LayerOverviewRecommendedOption = {
    screenshot?: string;
    values?: BffConfiguratorConfigurationAttributesValue[];
    colors?: BffConfiguratorConfigurationColorItem[];
} & BffConfiguratorConfiguration;

const LayerOverviewRecommendedOption = (props: LayerOverviewRecommendedOption) => {
    const updateViewer = useViewer((state) => state.updateViewer);
    const onToggleById = useConfigurator((state) => state.onToggleById);
    const onSetTab = useConfigurator((state) => state.onSetTab);
    const presets = useConfigurator((state) => state.presets);

    const { id, screenshot, selected, title, type } = props;
    const [isHovered, setIsHovered] = useState(false);
    const { md } = useMedia();

    const imageSize = md ? [80, 80] : [102, 102];
    const direction = md ? 'row' : 'column';

    let count = 0;

    if (type === 'values') {
        count = props.values?.length ?? 0;
    }

    if (type === 'colors') {
        count = props.colors?.length ?? 0;
    }

    const titleElement = md ? (
        <div>
            {title}
            <span className={cx(styles.layerTitleCounter, isHovered && styles.layerTitleCounterVisible)}>
                <Trans>{count} options</Trans>
            </span>
        </div>
    ) : (
        title
    );

    const subTitleElement = md ? selected : null;

    const handleClick = async () => {
        await updateViewer(presets.currentRecommendationId!);
        onSetTab('builder');
        onToggleById(id);
    };

    return (
        <ButtonTile.Item direction={direction}>
            <ButtonTile.Surface direction={direction} onClick={handleClick} onHoverChange={setIsHovered}>
                <ButtonTile.Image
                    direction={direction}
                    size={imageSize as [number, number]}
                    image={
                        screenshot
                            ? {
                                  src: screenshot
                              }
                            : null
                    }
                />
                <ButtonTile.Text direction={direction} title={titleElement} subtitle={subTitleElement} />
                {md && <ButtonTile.Chevron />}
            </ButtonTile.Surface>
        </ButtonTile.Item>
    );
};

export const LayerOverviewRecommended = () => {
    const { md } = useMedia();

    const presets = useConfigurator((state) => state.presets);
    const screenshotsCache = useViewer((state) => state.screenshotsCache);
    const screenshotFallback = useViewer((state) => state.screenshotFallback);

    const direction = md ? 'column' : 'row';
    const currentId = presets?.currentRecommendationId;

    if (!presets?.recommendations) {
        return null;
    }

    const getScreenshotForViewpoint = (sku: string, viewpoint: string): string | undefined => {
        if (!screenshotsCache.small[sku as any]) {
            return screenshotFallback;
        }
        const screenshot = screenshotsCache.small[sku as any].find((screenshot) => {
            const hasViewpoint = screenshot.viewpoint?.indexOf(viewpoint || '') !== -1;
            return hasViewpoint;
        });
        let screenshotSrc = screenshot?.screenshot;
        if (!screenshotSrc) {
            screenshotSrc = screenshotFallback;
        }
        return screenshotSrc;
    };

    const renderConfigurationOptions = () => {
        const items = presets?.recommendations
            ?.find((r: any) => r.id === currentId)
            ?.configurations?.map((option: any) => {
                const screenshotSrc = getScreenshotForViewpoint(currentId as string, option.viewpoint as string);

                // // WE SET THIS AS LONG AS WE DONT GET THE SCREENSHOTS FROM OTHER CPONFIGRUATIONS
                // if (currentId !== history.at(-1)) {
                //     screenshotSrc = screenshotFallback;
                // }

                return (
                    <LayerOverviewRecommendedOption
                        key={option.id}
                        screenshot={screenshotSrc}
                        {...(option as LayerOverviewRecommendedOption)}
                    />
                );
            });

        return <ButtonTile.List direction={direction}>{items}</ButtonTile.List>;
    };

    if (md) {
        return renderConfigurationOptions();
    }

    return <Panel.ScrollArea direction="horizontal">{renderConfigurationOptions()}</Panel.ScrollArea>;
};

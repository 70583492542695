import { useConfig } from '@common/hooks/use-config';
import { media } from '@common/styles/media';
import { Viewer } from '@components/configurator';
import { PANEL_WIDTH, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { ToolbarDesktop } from '@components/configurator/components/toolbar';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { useEffect } from 'react';

const PANEL_HEIGHT = 235;

const styles = {
    viewer: css`
        height: calc(100dvh - ${PANEL_HEIGHT - TAB_HEIGHT_MOBILE}px);
        width: 100dvw;
        position: absolute;
        z-index: 0;
        will-change: transform;

        .viewer-progress {
            bottom: ${PANEL_HEIGHT}px;

            ${media.md} {
                bottom: calc(24px + ${PANEL_HEIGHT}px);
            }
        }

        ${media.md} {
            transform: none;
            width: calc(100% - ${PANEL_WIDTH.BASE}px);
            height: 100%;

            .viewer-progress {
                bottom: 51px;
            }
        }
    `,
    toolbar: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 31px;
        display: flex;
        z-index: 2;
    `
};

export const ConfiguratorStandardViewer = () => {
    const shareUrl = useConfig('shareUrl', '');
    const loadViewer = useViewer((state) => state.loadViewer);
    const updateViewer = useViewer((state) => state.updateViewer);
    const destroyViewer = useViewer((state) => state.destroyViewer);
    const viewer = useViewer((state) => state.viewer);
    const loaded = useViewer((state) => state.loaded);
    const setZoomProportion = useViewer((state) => state.setZoomProportion);
    const data = useConfigurator((state) => state.data);
    const createShareLink = useConfigurator((state) => state.createShareLink);
    const { image, sku } = data || {};

    useEffect(() => {
        if (viewer && sku) {
            updateViewer(sku);
            return;
        }

        if (sku) {
            loadViewer(
                {
                    onEndCameraAnimation: () => {
                        setZoomProportion();
                    },
                    onEndCameraInteraction: () => {
                        setZoomProportion();
                    }
                },
                sku
            );
        }
    }, [sku, viewer]);

    useEffect(() => {
        // only call this on unmount
        return () => {
            destroyViewer();
        };
    }, []);

    if (!data) {
        return null;
    }

    return (
        <div className={styles.viewer}>
            <Viewer placeholderUrl={loaded ? undefined : image?.src}>
                <ToolbarDesktop
                    className={styles.toolbar}
                    hasResetView={true}
                    hasFullscreen={true}
                    hasDimensions={true}
                    onShare={() => createShareLink(shareUrl)}
                />
            </Viewer>
        </div>
    );
};

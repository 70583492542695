import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    title: css`
        padding: 75px 0;
        text-align: center;
        font-size: 26px;
        line-height: 28.34px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 + * {
            margin-top: 8px;
        }
    `
};

interface PanelTitleProps {
    title?: string;
    className?: string;
    children?: React.ReactNode;
}

export const PanelTitle = (props: PanelTitleProps) => {
    const { className, children, title } = props;

    return (
        <div className={cx('panel-title', styles.title, className)}>
            {title && <h2>{title}</h2>}
            {children}
        </div>
    );
};

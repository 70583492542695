import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';

const styles = {
    tileImageCount: css`
        font-size: 12px;
        font-weight: 600;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: ${colors.white};
        position: absolute;
        z-index: 2;
        right: 4px;
        top: 4px;
    `
};

interface ButtonTileImageCountProps {
    className?: string;
    children: React.ReactNode;
}

export const ButtonTileImageCount = (props: ButtonTileImageCountProps) => {
    return <span className={cx('tile-image-count', styles.tileImageCount, props.className)}>{props.children}</span>;
};

import { Back, Close } from '@common/icons';
import { Spinner } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';

import { ButtonIcon } from '../button-icon/button-icon';

const styles = {
    navigationDesktop: css`
        position: absolute;
        z-index: 4;
        padding: 14px;
    `,
    navigationMobile: css`
        padding: 14px;
        display: flex;
        justify-content: center;
    `,
    navigationMobileTitle: css`
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        font-size: 20px;
    `,
    navigationMobileButton: css`
        margin-right: -40px;
        position: relative;
        z-index: 3;
    `,
    spinner: css`
        width: 15px;
    `
};

interface PanelNavigationProps {
    className?: string;
    kind?: 'close' | 'back';
    appearance?: 'desktop' | 'mobile';
    title?: string;
    onPress?: () => void;
    isDisabled?: boolean;
    children?: React.ReactNode;
}

export const PanelNavigation = (props: PanelNavigationProps) => {
    const { className, kind = 'close', appearance = 'desktop', title, isDisabled } = props;

    return (
        <div
            className={cx(
                'panel-navigation',
                appearance == 'desktop' && styles.navigationDesktop,
                appearance == 'mobile' && styles.navigationMobile,
                className
            )}
        >
            {appearance === 'desktop' && (
                <div className={cx('panel-navigation-icon')}>
                    <>
                        {kind === 'close' && (
                            <ButtonIcon
                                isDisabled={isDisabled}
                                onPress={props.onPress}
                                aria-label={t`Close`}
                                icon={isDisabled ? <Close color={colors.grey} /> : <Close />}
                            />
                        )}
                        {kind === 'back' && (
                            <ButtonIcon
                                isDisabled={isDisabled}
                                onPress={props.onPress}
                                aria-label={t`Back`}
                                icon={isDisabled ? <Back color={colors.grey} /> : <Back />}
                            />
                        )}
                    </>
                </div>
            )}
            {appearance === 'mobile' && (
                <>
                    <div className={cx('panel-navigation-icon', styles.navigationMobileButton)}>
                        <>
                            {kind === 'close' && (
                                <ButtonIcon
                                    onPress={props.onPress}
                                    isDisabled={isDisabled}
                                    aria-label={t`Close`}
                                    variant="ghost"
                                    icon={isDisabled ? <Spinner className={styles.spinner} /> : <Close />}
                                />
                            )}
                            {kind === 'back' && (
                                <ButtonIcon
                                    variant="ghost"
                                    isDisabled={isDisabled}
                                    onPress={props.onPress}
                                    aria-label={t`Back`}
                                    icon={isDisabled ? <Spinner className={styles.spinner} /> : <Back />}
                                />
                            )}
                        </>
                    </div>
                    <div className={cx('panel-navigation-title', styles.navigationMobileTitle)}>{title}</div>
                </>
            )}
        </div>
    );
};

import { css, cx } from '@linaria/core';
import React, { forwardRef, LegacyRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { PADDING_HORIZONTAL } from './constants';

const styles = {
    scrollArea: css`
        display: inline-flex;
        flex-direction: column;
        flex: 1 1 100%;
        position: relative;
        padding: 0 ${PADDING_HORIZONTAL};
        padding-top: 100px;
        scroll-behavior: smooth;

        &:has(.panel-title),
        &:has(.panel-tab-list),
        &:has(.panel-sticky:first-child) {
            padding-top: 0;
        }

        &:has(.panel-sticky:first-child + .tile-list) {
            .tile-list {
                margin-top: 80px;
            }
        }
    `,
    scrollAreaHorizontal: css`
        overflow-x: auto;
        flex-direction: row;
        width: 100%;
        padding-bottom: 18px;
        padding-top: 0;
    `,
    scrollAreaVertical: css`
        touch-action: pan-y;
        overflow-y: auto;
        flex-direction: column;
        width: 100%;
    `
};

interface PanelScrollAreaProps {
    className?: string;
    children: React.ReactNode;
    direction?: 'horizontal' | 'vertical';
}

type ScrollContextType = {
    scrollLeft: number | null;
    scrollTop: number | null;
    scrollWrapperWidth: number | null;
    scrollWrapperHeight: number | null;
    scrollWrapperScrollHeight: number | null;
};

interface PanelScrollAreaRef {
    isScrolled: boolean;
    scrollTo: (options: ScrollToOptions) => void;
    disableScroll: () => void;
    enableScroll: () => void;
}

export type { PanelScrollAreaRef };

export const ScrollContext = React.createContext<ScrollContextType>({
    scrollLeft: null,
    scrollTop: null,
    scrollWrapperWidth: null,
    scrollWrapperHeight: null,
    scrollWrapperScrollHeight: null
});

export const PanelScrollArea = forwardRef((props: PanelScrollAreaProps, ref) => {
    const { className, children, direction = 'vertical' } = props;
    const innerRef = useRef<Element>(null);

    const [scrollLeft, setScrollLeft] = React.useState<number | null>(null);
    const [scrollTop, setScrollTop] = React.useState<number | null>(null);
    const [scrollWrapperWidth, setScrollWrapperWidth] = React.useState<number | null>(null);
    const [scrollWrapperHeight, setScrollWrapperHeight] = React.useState<number | null>(null);
    const [scrollWrapperScrollHeight, setScrollWrapperScrollHeight] = React.useState<number | null>(null);
    const blockScroll = useRef(false);

    useImperativeHandle(ref, () => ({
        get isScrolled() {
            return scrollTop !== 0 || scrollLeft !== 0;
        },
        scrollTo: (options: ScrollToOptions) => {
            if (innerRef.current) {
                innerRef.current.scrollTo(options);
            }
        },
        disableScroll: () => {
            blockScroll.current = true;
        },
        enableScroll: () => {
            blockScroll.current = false;
        }
    }));

    const handleScroll = useCallback(
        (e: any) => {
            if (blockScroll.current) {
                return e.preventDefault();
            }

            const c = innerRef.current;
            if (c) {
                setScrollTop(c.scrollTop);
                setScrollLeft(c.scrollLeft);
                setScrollWrapperWidth(c.clientWidth);
                setScrollWrapperHeight(c.clientHeight);
                setScrollWrapperScrollHeight(c.scrollHeight);
            }
        },
        [blockScroll.current]
    );

    useEffect(() => {
        const c = innerRef.current;
        if (c) {
            c.addEventListener('scroll', handleScroll, { passive: false });
            c.addEventListener('touchmove', handleScroll, { passive: false });
        }
        return () => {
            if (c) {
                c.removeEventListener('scroll', handleScroll);
                c.removeEventListener('touchmove', handleScroll);
            }
        };
    }, []);

    const contextValue = useMemo(
        () => ({
            scrollLeft,
            scrollTop,
            scrollWrapperWidth,
            scrollWrapperHeight,
            scrollWrapperScrollHeight
        }),
        [scrollLeft, scrollTop, scrollWrapperWidth, scrollWrapperHeight, scrollWrapperScrollHeight]
    );

    return (
        <ScrollContext.Provider value={contextValue}>
            <div
                className={cx(
                    'panel-scroll-area',
                    styles.scrollArea,
                    direction === 'vertical' && styles.scrollAreaVertical,
                    direction === 'horizontal' && styles.scrollAreaHorizontal,
                    className
                )}
                ref={innerRef as LegacyRef<HTMLDivElement>}
            >
                {children}
            </div>
        </ScrollContext.Provider>
    );
});

PanelScrollArea.displayName = 'PanelScrollArea';

import { TabList } from '@common/primitives';
import { media } from '@common/styles/media';
import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    tabList: css`
        padding: 18px 0;
        width: 100%;
        align-self: flex-end;

        ${media.md} {
            padding-bottom: 58px;
        }
    `
};

interface PanelTabListProps {
    className?: string;
    children: React.ReactNode;
}

export const PanelTabList = (props: PanelTabListProps) => {
    const { className, children } = props;

    return <TabList className={cx('panel-tab-list', styles.tabList, className)}>{children}</TabList>;
};

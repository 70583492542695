import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    sticky: css`
        z-index: 3;
        position: sticky;
        top: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 26px;
    `,
    stickyFooter: css`
        margin-bottom: 0;
        bottom: 14px;
        top: auto;
    `
};

interface PanelStickyProps {
    className?: string;
    children: React.ReactNode;
    position?: 'top' | 'footer';
}

export const PanelSticky = (props: PanelStickyProps) => {
    const { className, children, position = 'top' } = props;
    return (
        <div className={cx('panel-sticky', styles.sticky, position === 'footer' && styles.stickyFooter, className)}>
            {children}
        </div>
    );
};

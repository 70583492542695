import useElementWidth from '@common/hooks/use-element-width';
import { useMedia } from '@common/hooks/use-media';
import { Close, Edit, Save } from '@common/icons';
import { Button, ButtonIcon } from '@common/primitives';
import { Spinner } from '@common/primitives/spinner/spinner';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { formatPrice } from '@common/utils/format-price';
import { AddToCartButton, ButtonTile, Panel } from '@components/configurator';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { TAB_HEIGHT_DESKTOP } from '@components/configurator/components/panel/constants';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useModularConfigurator, useShoppingList } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';

const styles = {
    container: css`
        width: 100%;
        height: 100%;
        background-color: ${colors.white};
        padding-top: 30px;
    `,
    tileContainer: css`
        display: flex;
        padding-bottom: 34px;

        ${media.md} {
            padding-bottom: 8px;
        }
    `,
    tileBody: css`
        align-self: center;
        flex: 1;
        justify-content: space-between;
        display: flex;
        width: 100%;
    `,
    tileTextContainer: css`
        flex: 1;
    `,
    tileTextPriceDelivery: css`
        display: flex;
        align-items: center;

        ${media.md} {
            margin-bottom: 12px;
        }
    `,
    tileTextTitle: css`
        text-align: left;
        margin-top: 6px;

        ${media.md} {
            margin-top: 0;
        }
    `,
    tileTextSeparator: css`
        display: inline-flex;
        margin-left: 8px;
        margin-right: 8px;
        height: 17px;
        width: 1px;
        background-color: #d9d9d9;
    `,
    tileFooter: css`
        display: flex;
        justify-content: space-between;
    `,
    tileFooterDelete: css`
        margin-left: auto;
        align-self: center;
    `,
    emptyState: css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    `,
    emptyStateDesktop: css`
        margin-top: -${TAB_HEIGHT_DESKTOP / 2}px;
    `,
    emptyStateTitle: css`
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 116%;
        margin-bottom: 28px;
    `,
    emptyStateBodyText: css`
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%;
        margin-top: 11px;
    `,
    footerContainer: css`
        background-color: ${colors.white};
        bottom: 0;
    `,
    footer: css`
        width: 100%;
        padding: 9px 3px 3px;
        box-shadow: 0 0 10px 0 rgba(0 0 0 / 10%);
    `,
    modal: css`
        height: 100dvh;
        overflow-y: auto;
    `,
    modalOverlay: css`
        position: fixed;
        inset: 0;
        background-color: ${colors.white};
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};
    `,
    deleteButtonDesktop: css`
        aspect-ratio: 1;
        height: 44px;
    `
};

interface LayerOverviewModularShoppingListProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    onEdit: (sku: string) => void;
    onUpdate: (sku: string) => void;
}

export const LayerOverviewModularShoppingList = (props: LayerOverviewModularShoppingListProps) => {
    const { i18n } = useLingui();

    const { header, footer, onEdit, onUpdate } = props;

    const { shoppingList, deleteShoppingListLineItem, initShoppingList, status, clearShoppingListNotification } =
        useShoppingList((state) => state);

    const modularAddToShoppingList = useModularConfigurator((state) => state.modularAddToShoppingList);
    const hasProducts = useModularConfigurator((state) => state.hasProducts)();
    const articleNo = useModularConfigurator((state) => state.articleNo);
    const onSetTab = useModularConfigurator((state) => state.onSetTab);

    const lineItems = shoppingList?.lineItems || [];

    const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null);

    const { width, setElementRef } = useElementWidth();
    const { md } = useMedia();

    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize = md ? 180 : width;

    useEffect(() => {
        if (status === 'idle') {
            initShoppingList([articleNo]);
        }
        clearShoppingListNotification();
    }, [status]);

    const handleDelete = (lineItemsId: string) => {
        deleteShoppingListLineItem(lineItemsId, [articleNo]);
    };

    const handleMobileDelete = (lineItemId: string) => {
        if (deleteConfirmation === lineItemId) {
            handleDelete(lineItemId);
            setDeleteConfirmation(null);
        } else {
            setDeleteConfirmation(lineItemId);
        }
    };

    const handleEdit = async (sku: string) => {
        onEdit(sku);
    };

    const handleUpdate = (sku: string) => {
        onUpdate(sku);
    };

    const renderItems = () => {
        return lineItems?.map((item) => {
            const formattedPrice = item.price && formatPrice(item?.price, i18n);
            return (
                <motion.div
                    key={item.sku}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.25 }}
                    className={styles.tileContainer}
                >
                    <ButtonTile.Item key={item.sku} direction={tileDirection}>
                        <ButtonTile.Surface direction={tileDirection}>
                            <ButtonTile.Image
                                image={item.image as ButtonTileImageImage}
                                direction={tileDirection}
                                size={[thumbnailSize, thumbnailSize]}
                                showLoadingIndicator={true}
                                onClick={() => (md ? handleUpdate(item.sku) : handleEdit(item.sku))}
                                tint
                            />

                            <ButtonTile.Info size={[thumbnailSize, thumbnailSize]}>
                                <ButtonIcon
                                    onPress={() => handleEdit(item.sku)}
                                    aria-label={t`Edit`}
                                    size="sm"
                                    icon={<Edit />}
                                />
                            </ButtonTile.Info>

                            <div className={styles.tileBody}>
                                <div className={styles.tileTextContainer}>
                                    <ButtonTile.Text
                                        direction={tileDirection}
                                        title={<div className={styles.tileTextTitle}>{item.name}</div>}
                                        subtitle={
                                            <div className={styles.tileTextPriceDelivery}>
                                                {formattedPrice}
                                                {item.price && item.delivery?.deliveryTime && (
                                                    <span className={styles.tileTextSeparator} />
                                                )}
                                                {item?.delivery?.deliveryTime}
                                            </div>
                                        }
                                    />
                                    <div className={styles.tileFooter}>
                                        {item.isSellable && (
                                            <AddToCartButton
                                                sku={item.sku}
                                                checkoutText={t`Checkout`}
                                                rounded
                                                variant="basic"
                                                color={colors.black}
                                            />
                                        )}
                                    </div>
                                </div>

                                {md ? (
                                    <ButtonIcon
                                        aria-label={t`Delete`}
                                        className={styles.deleteButtonDesktop}
                                        variant="ghost"
                                        icon={<Close />}
                                        onPress={() => handleDelete(item.id)}
                                    />
                                ) : (
                                    <div className={styles.tileFooterDelete}>
                                        {deleteConfirmation === item.id ? (
                                            <Button
                                                rounded
                                                variant="secondary"
                                                onPress={() => handleMobileDelete(item.id)}
                                            >
                                                <Close />
                                                <Trans>Yes, I want to delete</Trans>
                                            </Button>
                                        ) : (
                                            <ButtonIcon
                                                aria-label={t`Delete`}
                                                variant="ghost"
                                                icon={<Close />}
                                                onPress={() => handleMobileDelete(item.id)}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                </motion.div>
            );
        });
    };

    const renderSuccessEmptyState = () => {
        return (
            status === 'success' &&
            lineItems.length === 0 && (
                <div className={cx(styles.emptyState, md && styles.emptyStateDesktop)}>
                    <div className={styles.emptyStateTitle}>
                        <Trans>Nothing here yet</Trans>
                    </div>
                    <ButtonIcon
                        aria-label={t`Save`}
                        onPress={async () => {
                            if (hasProducts) {
                                await modularAddToShoppingList();
                            } else {
                                onSetTab('builder');
                            }
                        }}
                        icon={<Save fill={colors.coral2} />}
                    />
                    <div className={styles.emptyStateBodyText}>
                        <Trans>Start saving</Trans>
                    </div>
                </div>
            )
        );
    };

    const renderLoadingEmptyState = () => {
        return (
            status === 'loading' && (
                <div className={cx(styles.emptyState, md && styles.emptyStateDesktop)}>
                    <Spinner />
                </div>
            )
        );
    };

    const renderItemsList = () => {
        return (
            <ButtonTile.List direction={'column'}>
                <div ref={setElementRef}>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {renderItems()}
                    </AnimatePresence>
                </div>
            </ButtonTile.List>
        );
    };

    const body = renderSuccessEmptyState() || renderLoadingEmptyState() || renderItemsList();

    if (md) return body;

    return (
        <ModalOverlay isOpen className={styles.modalOverlay}>
            <Modal isOpen className={styles.modal}>
                {header && <Panel.Sticky position="top">{header}</Panel.Sticky>}
                <Panel.ScrollArea direction="vertical" className={styles.container}>
                    {body}
                </Panel.ScrollArea>
                <Panel.Sticky position="footer" className={styles.footerContainer}>
                    <div className={styles.footer}>{footer}</div>
                </Panel.Sticky>
            </Modal>
        </ModalOverlay>
    );
};

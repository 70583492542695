import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Meter as DefaultMeter } from 'react-aria-components';

const styles = {
    meter: css`
        height: 8px;
        background-color: #f5f5f5;
        position: relative;
        flex: 1;
        box-shadow: inset 1px 1px 1px rgba(0 0 0 / 17%);

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    `,
    meterContainer: css`
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 3px;
    `,
    meterFilled: css`
        background-color: ${colors.coral2};
    `
};

interface MeterProps {
    value: number;
    labelId: string;
}

export const Meter = ({ value = 0, labelId }: MeterProps) => {
    const minValue = 0;
    const maxValue = 5;

    const renderMeterItems = () =>
        Array.from({ length: maxValue }, (_, i) => (
            <div key={i} className={cx(styles.meter, i < value && styles.meterFilled)} />
        ));

    return (
        <DefaultMeter
            aria-labelledby={labelId}
            className={cx('-meter', styles.meterContainer)}
            value={value}
            minValue={minValue}
            maxValue={maxValue}
        >
            {() => renderMeterItems()}
        </DefaultMeter>
    );
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { VisuallyHidden } from 'react-aria';
import {
    ProgressBar as DefaultProgressBar,
    Label,
    type ProgressBarProps as DefaultProgressBarProps
} from 'react-aria-components';

const styles = {
    container: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    `,
    bar: css`
        width: 180px;
        height: 6px;
        left: 506px;
        top: 384px;
        background: #e6e6e6;
        box-shadow: inset 0 0.5px 3px rgba(0 0 0 / 16%);
        border-radius: 4px;
    `,
    value: css`
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: ${colors.text1};
        text-align: center;
    `,
    valuePercentSymbol: css`
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 1px;
    `,
    fill: css`
        height: 6px;
        background: linear-gradient(180deg, rgba(255 255 255 / 10%) 0%, rgba(255 255 255 / 0%) 100%), ${colors.coral3};
        border-radius: 4px;
    `
};

interface ProgressBarProps extends DefaultProgressBarProps {
    label: string;
}

export const ProgressBar = ({ label, ...props }: ProgressBarProps) => {
    return (
        <DefaultProgressBar
            {...props}
            formatOptions={{
                maximumFractionDigits: 0
            }}
        >
            {({ percentage, valueText }) => (
                <>
                    <VisuallyHidden>
                        <Label>{label}</Label>
                    </VisuallyHidden>
                    <div className={cx('progress-bar', styles.container)}>
                        <div className={styles.bar}>
                            <div className={styles.fill} style={{ width: percentage + '%' }} />
                        </div>
                        <span className={styles.value}>
                            {valueText}
                            <span className={styles.valuePercentSymbol}>%</span>
                        </span>
                    </div>
                </>
            )}
        </DefaultProgressBar>
    );
};

import { Check, CloseSmall } from '@common/icons';
import { styles as checkboxStyles } from '@common/primitives/checkbox/checkbox';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { css, cx } from '@linaria/core';
import { useRef } from 'react';
import { AriaCheckboxProps, useCheckbox, VisuallyHidden } from 'react-aria';
import { Button as DefaultButton } from 'react-aria-components';
import { useToggleState } from 'react-stately';

const styles = {
    wrapper: css`
        all: unset;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        position: relative;

        ${media.md} {
            max-width: 166px;
        }
    `,
    tableImage: css`
        width: 100%;
        height: auto;
        aspect-ratio: 166 / 108;
        object-fit: cover;
        border-radius: 3px;
    `,
    tableImageTitle: css`
        color: ${colors.black};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%; /* 17.08px */
        padding: 12px 0;
        word-break: break-all;

        &::first-letter {
            text-transform: uppercase;
        }
    `,
    tableImageCloseButton: css`
        all: unset;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 9px;
        right: 9px;
        background-color: ${colors.white};
        border-radius: 100%;
        cursor: pointer;

        svg {
            height: 10px;
            width: 10px;
        }

        &[data-focus-visible] {
            outline: none;
            background-color: rgb(0 0 0 / 7%);
        }
    `,
    tableImageCheckboxContainer: css`
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 0;
    `,
    tableImageCheckboxSelected: css`
        background-color: ${colors.coral2};

        svg {
            color: ${colors.white};
        }
    `,
    tableImageCheckboxDisabled: css`
        opacity: 0.5;
        cursor: not-allowed;
    `
};

interface TableImageProps {
    shape?: 'square' | 'rectangle';
    title: string;
    src: string;
    className?: string;
    isRemoveButtonShown?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    onRemove?: () => void;
    onChange?: () => void;
}

const Checkbox = (
    props: {
        children: React.ReactNode;
    } & AriaCheckboxProps
) => {
    const { children, isSelected, isDisabled } = props;
    const state = useToggleState(props);
    const ref = useRef(null);
    const { inputProps } = useCheckbox(props, state, ref);

    return (
        <label className={cx('table-image', styles.wrapper)}>
            <VisuallyHidden>
                <input {...inputProps} ref={ref} />
            </VisuallyHidden>
            {children}

            <div className={styles.tableImageCheckboxContainer}>
                <div
                    className={cx(
                        checkboxStyles.checkbox,
                        isSelected && styles.tableImageCheckboxSelected,
                        isDisabled && styles.tableImageCheckboxDisabled
                    )}
                >
                    <Check className={checkboxStyles.svg} />
                </div>
            </div>
        </label>
    );
};

export const TableImage = (props: TableImageProps) => {
    const {
        className,
        title,
        src,
        onRemove,
        isRemoveButtonShown = true,
        isSelectable,
        isSelected,
        isDisabled,
        onChange
    } = props;

    const body = (
        <>
            <div>
                <img alt={title} role="presentation" src={src} className={styles.tableImage} />
                {isRemoveButtonShown && (
                    <DefaultButton aria-label="Close" className={styles.tableImageCloseButton} onPress={onRemove}>
                        <CloseSmall />
                    </DefaultButton>
                )}
            </div>
            <div className={cx(styles.tableImageTitle)}>{title}</div>
        </>
    );

    if (isSelectable) {
        return (
            <Checkbox aria-label={title} isDisabled={isDisabled} isSelected={isSelected || false} onChange={onChange}>
                {body}
            </Checkbox>
        );
    }

    return <div className={cx('table-image', styles.wrapper, className)}>{body}</div>;
};

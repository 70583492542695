import { Spinner } from '@common/primitives/spinner';
import { media } from '@common/styles/media';
import { Recommendations } from '@components/configurator';
import { PANEL_WIDTH, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { ConfiguratorRecommendation } from '@components/configurator/components/recommendations/recommendations';
import { useModularConfigurator, useViewer } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { t } from '@lingui/macro';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

const styles = {
    presets: css`
        position: absolute;
        height: calc(100dvh - ${TAB_HEIGHT_MOBILE}px);
        width: 100%;
        top: 0;
        background-color: #f5f5f5;
        z-index: 1;
        display: flex;

        ${media.md} {
            height: 100%;
            padding-right: ${PANEL_WIDTH.BASE}px;
            z-index: 2;
            right: 0;
            background: #f5f5f5;
        }
    `,
    spinner: css`
        align-self: center;
        margin: 0 auto;
    `
};

export const ConfiguratorModularPresets = () => {
    const onSetTab = useModularConfigurator((state) => state.onSetTab);
    const tab = useModularConfigurator((state) => state.ui.tab);

    const setPresetEmersyaId = useModularConfigurator((state) => state.setPresetEmersyaId);
    const presets = useModularConfigurator((state) => state.presets);

    const modularLoadSharedConfiguration = useViewer((state) => state.modularLoadSharedConfiguration);
    const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(false);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const togglePresetLoadingError = useModularConfigurator((state) => state.togglePresetLoadingError);

    // We need to watch if the viewer is loading and ready.
    // We MUST prevent the user to load a recommendation if the viewer is loading otherwise emersya complains
    const isLoading = useModularConfigurator((state) => state.ui.isLoading);

    // We must reset the preset if the recommendation tab is mounted
    // otherwise the panel on the right would show the wrong
    // products if switching between the tabs
    useEffect(() => {
        if (tab === 'presets' && presets.recommendations && presets.recommendations.length > 0) {
            setPresetEmersyaId(presets.recommendations[0].id);
        }
    }, [tab]);

    const handleEdit = useCallback(
        async (recommendation: ConfiguratorRecommendation) => {
            try {
                setIsBlocked(true);
                setIsLoadingConfiguration(true);
                await modularLoadSharedConfiguration(recommendation.id);
                onSetTab('builder');
            } catch {
                togglePresetLoadingError(true, t`Looks like there has been an error`);
            } finally {
                setIsLoadingConfiguration(false);
                setIsBlocked(false);
            }
        },
        [modularLoadSharedConfiguration, onSetTab, setIsBlocked]
    );

    const handleSelect = useCallback(
        (recommendation: ConfiguratorRecommendation) => {
            setPresetEmersyaId(recommendation.id);
        },
        [setPresetEmersyaId]
    );

    return (
        <AnimatePresence>
            {tab === 'presets' && (
                <div className={styles.presets}>
                    {(presets.state === 'loading' || isLoading) && <Spinner className={styles.spinner} />}
                    {presets.state === 'loaded' && !isLoading && (
                        <Recommendations
                            isLoadingSharedConfiguration={isLoadingConfiguration}
                            onEdit={handleEdit}
                            onSelect={handleSelect}
                            recommendations={presets.recommendations || []}
                        />
                    )}
                </div>
            )}
        </AnimatePresence>
    );
};

import { useClickoutSide } from '@common/hooks/use-click-outside';
import { useIsTouchDevice } from '@common/hooks/use-is-touch-device';
import { Delete, Rotate } from '@common/icons';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { useModularConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { styles as defaultButtonStyles } from '@primitives/button/button';
import { LegacyRef, useEffect, useState } from 'react';
import { Button as DefaultButton } from 'react-aria-components';
import { useEventListener } from 'usehooks-ts';

import { EmersyaPayloadNode } from '../viewer/viewer-types';

const styles = {
    wrapper: css`
        display: inline-flex;
        padding: 0 10px;
        flex-direction: row;
        gap: 2px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        background: ${colors.white};
        border-radius: 90px;
        position: absolute;
        z-index: 10;
        transform: translateY(calc(-50% - 40px)) translateX(calc(-50% + 40px));

        ${media.md} {
            transform: translateY(-50%) translateX(-50%);
        }
    `,
    menuButton: css`
        svg {
            fill: ${colors.black};
        }

        &[data-hovered] {
            svg {
                fill: ${colors.coral2};
            }
        }

        &[data-disabled] {
            svg {
                opacity: 0.2;
            }
        }
    `,

    buttonIcon: css`
        width: 40px;
        height: 40px;
    `
};

interface ContextMenuProps {
    viewerRef: any;
    className?: string;
    canRotate?: boolean;
    canDelete?: boolean;
    coords?: [number, number];
}

type ContextMenuForProductNodeProps = ContextMenuProps & {
    localId: number;
};

// Utility function which helps to ignore the (invisible) rectangular base node
const getRootLocalId = (node: EmersyaPayloadNode) => {
    if (node.localIds.length >= 2) {
        return node.localIds[1];
    }
    return node.localId;
};

export const ContextMenuForProductNode = (props: ContextMenuForProductNodeProps) => {
    const { viewer } = useViewer();
    const { className, localId, viewerRef } = props;
    const isTouchDevice = useIsTouchDevice();

    const [coords, setCoords] = useState<[number, number] | null>(props.coords ?? null);
    const [canRotate, setCanRotate] = useState(props.canRotate || false);
    const [canDelete, setCanDelete] = useState(props.canDelete || false);
    const [showContextMenu, setShowContextMenu] = useState(false);

    const { nodes, selectedProduct, setSelectedProductSlot, trackedProductNodes } = useModularConfigurator((state) => ({
        nodes: state.nodes,
        selectedProduct: state.selectedProduct,
        setSelectedProductSlot: state.setSelectedProductSlot,
        trackedProductNodes: state.trackedProductNodes
    }));

    const {
        removeModularConfiguratorProduct,
        isModularConfiguratorProductRotatable,
        toggleProductNodeInstanceTracking,
        rotateModularConfiguratorProduct
    } = useViewer((state) => ({
        removeModularConfiguratorProduct: state.removeModularConfiguratorProduct,
        isModularConfiguratorProductRotatable: state.isModularConfiguratorProductRotatable,
        toggleProductNodeInstanceTracking: state.toggleProductNodeInstanceTracking,
        rotateModularConfiguratorProduct: state.rotateModularConfiguratorProduct
    }));

    // On mobile only: Add click eventlistener to the ref viewerRef to wake up the context menu again when the user clicks on the viewer
    useEffect(() => {
        if (!viewerRef || !isTouchDevice) return;

        const handleTouchStart = () => {
            if (!showContextMenu && selectedProduct && getRootLocalId(selectedProduct) === localId) {
                setShowContextMenu(true);
            }
        };

        const currentViewerRef = viewerRef.current;
        currentViewerRef.addEventListener('touchstart', handleTouchStart);
        return () => currentViewerRef.removeEventListener('touchstart', handleTouchStart);
    }, [viewerRef, isTouchDevice, showContextMenu, selectedProduct, localId]);

    // On mobile only: Hide the context menu when the user clicks outside of the viewer
    const ref = useClickoutSide(
        () => {
            if (showContextMenu) {
                setShowContextMenu(false);
            }
        },
        { active: isTouchDevice },
        [],
        [viewerRef]
    ) as LegacyRef<HTMLDivElement> | undefined;

    useEffect(() => {
        if (!viewer || !selectedProduct) {
            setCoords(null);
            return;
        }

        const isCurrentProduct = getRootLocalId(selectedProduct) === localId;
        setShowContextMenu(isCurrentProduct);

        if (isCurrentProduct) {
            isModularConfiguratorProductRotatable({ localId: selectedProduct.localId }).then((data: any) => {
                setCanRotate(data.rotatable);
                setCanDelete(selectedProduct.children.filter((child) => child.fromRelation).length === 0);
            });

            toggleProductNodeInstanceTracking({ localId: getRootLocalId(selectedProduct), value: true });
        }
    }, [selectedProduct, nodes, viewer, localId]);

    useEffect(() => {
        if (!viewer || !selectedProduct?.localIds || !trackedProductNodes || !showContextMenu) return;

        const nodeMeta = trackedProductNodes.find((el) => el.localId === getRootLocalId(selectedProduct));
        if (nodeMeta) {
            setCoords([nodeMeta.x, nodeMeta.y]);
        }
    }, [trackedProductNodes, selectedProduct, showContextMenu, viewer]);

    const handleRotate = () => {
        if (selectedProduct) rotateModularConfiguratorProduct({ localId: selectedProduct.localId });
    };

    const handleDelete = () => {
        if (selectedProduct) {
            removeModularConfiguratorProduct({ localId: selectedProduct.localId });
            setSelectedProductSlot(null);
        }
    };

    useEventListener('keydown', (event: KeyboardEvent) => {
        if ((event.key === 'Delete' || event.key === 'Backspace') && canDelete) {
            handleDelete();
        }
    });

    if (!coords) return null;

    return (
        <div
            ref={ref}
            className={cx('context-menu', styles.wrapper, className)}
            style={{ left: coords[0], top: coords[1], display: showContextMenu ? 'flex' : 'none' }}
            aria-hidden={!showContextMenu}
        >
            <DefaultButton
                isDisabled={!canDelete}
                className={cx('menu-button', defaultButtonStyles.reset, styles.menuButton)}
                onPress={handleDelete}
            >
                <Delete className={cx('button-icon button-icon-delete', styles.buttonIcon)} />
            </DefaultButton>
            <DefaultButton
                isDisabled={!canRotate}
                className={cx('menu-button', defaultButtonStyles.reset, styles.menuButton)}
                onPress={handleRotate}
            >
                <Rotate className={cx('button-icon button-icon-rotate', styles.buttonIcon)} />
            </DefaultButton>
        </div>
    );
};

export const ContextMenu = (props: ContextMenuProps) => {
    const toggleProductNodeInstanceTracking = useViewer((state) => state.toggleProductNodeInstanceTracking);
    const trackedProductNodes = useModularConfigurator((state) => state.trackedProductNodes);
    const selectedProduct = useModularConfigurator((state) => state.selectedProduct);

    useEffect(() => {
        if (!selectedProduct) {
            return;
        }
        toggleProductNodeInstanceTracking({ localId: getRootLocalId(selectedProduct), value: true });
    }, [selectedProduct]);

    return trackedProductNodes?.map((node) => {
        return <ContextMenuForProductNode key={node.localId} localId={node.localId} {...props} />;
    });
};

// *****************************************
// AUTOGENERATED DO NOT EDIT
// To refresh this file run: `npm run dev:graphql`
// (@see codegen.yml)
// *****************************************
/* eslint-disable unicorn/template-indent,@typescript-eslint/no-unused-vars */

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    JSON: { input: any; output: any };
    Sku: { input: any; output: any };
};

export type BffCartAddress = {
    __typename?: 'BffCartAddress';
    additionalAddressInfo?: Maybe<Scalars['String']['output']>;
    apartment?: Maybe<Scalars['String']['output']>;
    billing?: Maybe<Scalars['Boolean']['output']>;
    buildingName?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    defaultShippingAddress?: Maybe<Scalars['Boolean']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    locality?: Maybe<Scalars['String']['output']>;
    mobile?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    region?: Maybe<Scalars['String']['output']>;
    salutation?: Maybe<Scalars['String']['output']>;
    shipping?: Maybe<Scalars['Boolean']['output']>;
    streetName?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type BffCartAddressUpdate = {
    additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
    additionalStreetInfo?: InputMaybe<Scalars['String']['input']>;
    apartment?: InputMaybe<Scalars['String']['input']>;
    billing?: InputMaybe<Scalars['Boolean']['input']>;
    buildingName?: InputMaybe<Scalars['String']['input']>;
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
    department?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    fax?: InputMaybe<Scalars['String']['input']>;
    firstName: Scalars['String']['input'];
    id?: InputMaybe<Scalars['String']['input']>;
    lastName: Scalars['String']['input'];
    locality?: InputMaybe<Scalars['String']['input']>;
    mobile?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    poBox?: InputMaybe<Scalars['String']['input']>;
    postalCode: Scalars['String']['input'];
    region?: InputMaybe<Scalars['String']['input']>;
    salutation: Scalars['String']['input'];
    shipping?: InputMaybe<Scalars['Boolean']['input']>;
    state?: InputMaybe<Scalars['String']['input']>;
    streetName: Scalars['String']['input'];
    streetNumber: Scalars['String']['input'];
    taxNumber?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type BffCartPaymentValue = {
    __typename?: 'BffCartPaymentValue';
    currency: Scalars['String']['output'];
    value: Scalars['Int']['output'];
};

export type BffCartResult = {
    __typename?: 'BffCartResult';
    anayltics?: Maybe<Scalars['JSON']['output']>;
    billingAddress?: Maybe<BffCartAddress>;
    canSplitOrder: Scalars['Boolean']['output'];
    cartItems: Array<BffCartResultCartItem>;
    count: Scalars['Int']['output'];
    custom?: Maybe<Scalars['JSON']['output']>;
    discountCode?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isBlocked: Scalars['Boolean']['output'];
    isOrderSplit: Scalars['Boolean']['output'];
    messages: Array<BffCartResultMessage>;
    payment?: Maybe<Scalars['JSON']['output']>;
    paymentAmount?: Maybe<BffCartPaymentValue>;
    shippingAddress?: Maybe<BffCartAddress>;
    showReturnNote: Scalars['Boolean']['output'];
    skipPayment: Scalars['Boolean']['output'];
    totals: Array<BffCartResultTotalPrice>;
    version?: Maybe<Scalars['String']['output']>;
};

export type BffCartResultPaymentArgs = {
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
};

export type BffCartResultCartItem = {
    __typename?: 'BffCartResultCartItem';
    delivery?: Maybe<BffCartResultCartItemDelivery>;
    id: Scalars['String']['output'];
    lineItems?: Maybe<Array<BffCartResultItem>>;
    shippingCost?: Maybe<BffCartResultShippingCost>;
    shippingOption?: Maybe<BffCartResultShippingOption>;
};

export type BffCartResultCartItemDelivery = {
    __typename?: 'BffCartResultCartItemDelivery';
    calendarEndDate: Scalars['String']['output'];
    calendarStartDate: Scalars['String']['output'];
    deliveryEndDate: Scalars['String']['output'];
    deliveryStartDate: Scalars['String']['output'];
    desiredDeliveryDate: Scalars['String']['output'];
    weekRange: Scalars['String']['output'];
};

export type BffCartResultDelivery = {
    __typename?: 'BffCartResultDelivery';
    calendarEndDate?: Maybe<Scalars['String']['output']>;
    calendarStartDate?: Maybe<Scalars['String']['output']>;
    deliveryEndDate?: Maybe<Scalars['String']['output']>;
    deliveryStartDate?: Maybe<Scalars['String']['output']>;
    desiredDeliveryDate?: Maybe<Scalars['String']['output']>;
    weekRange?: Maybe<Scalars['String']['output']>;
};

export type BffCartResultItem = {
    __typename?: 'BffCartResultItem';
    articleNo: Scalars['String']['output'];
    delivery?: Maybe<BffCartResultDelivery>;
    id: Scalars['String']['output'];
    image: BffCartResultItemImage;
    isConfigurable: Scalars['Boolean']['output'];
    messages: Array<BffCartResultMessage>;
    name: Scalars['String']['output'];
    orderLimit: Scalars['Int']['output'];
    quantity: Scalars['Int']['output'];
    showQuantity: Scalars['Boolean']['output'];
    showReturnNote: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
    totalPrice: BffProductPrice;
    type: Scalars['String']['output'];
};

export type BffCartResultItemImage = {
    __typename?: 'BffCartResultItemImage';
    height: Scalars['Int']['output'];
    src: Scalars['String']['output'];
    width: Scalars['Int']['output'];
};

export type BffCartResultMessage = {
    __typename?: 'BffCartResultMessage';
    message: Scalars['String']['output'];
    sku?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
};

export type BffCartResultShippingCost = {
    __typename?: 'BffCartResultShippingCost';
    id?: Maybe<Scalars['String']['output']>;
    price: BffCartResultShippingCostPrice;
    shippingType: Scalars['String']['output'];
};

export type BffCartResultShippingCostPrice = {
    __typename?: 'BffCartResultShippingCostPrice';
    currencyCode?: Maybe<Scalars['String']['output']>;
    currencySymbol?: Maybe<Scalars['String']['output']>;
    formatted: Scalars['String']['output'];
    raw?: Maybe<Scalars['Float']['output']>;
};

export type BffCartResultShippingOption = {
    __typename?: 'BffCartResultShippingOption';
    id?: Maybe<Scalars['String']['output']>;
    items: Array<BffCartResultShippingOptionItem>;
};

export type BffCartResultShippingOptionItem = {
    __typename?: 'BffCartResultShippingOptionItem';
    description: Scalars['String']['output'];
    key: Scalars['String']['output'];
    price: BffCartResultShippingCostPrice;
    selected: Scalars['Boolean']['output'];
};

export type BffCartResultTotalPrice = {
    __typename?: 'BffCartResultTotalPrice';
    currencyCode?: Maybe<Scalars['String']['output']>;
    formatted: Scalars['String']['output'];
    key: Scalars['String']['output'];
    rate?: Maybe<Scalars['String']['output']>;
    raw?: Maybe<Scalars['Float']['output']>;
};

export type BffCartShareResult = {
    __typename?: 'BffCartShareResult';
    url?: Maybe<Scalars['String']['output']>;
};

export type BffConfigurator = {
    __typename?: 'BffConfigurator';
    cached?: Maybe<Scalars['Boolean']['output']>;
    configurations?: Maybe<Array<BffConfiguratorConfiguration>>;
    delivery?: Maybe<BffConfiguratorDelivery>;
    image?: Maybe<BffProductImage>;
    name: Scalars['String']['output'];
    price?: Maybe<BffPrice>;
    showAddToCart: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
};

export type BffConfiguratorConfiguration = BffConfiguratorConfigurationAttributes | BffConfiguratorConfigurationColor;

export type BffConfiguratorConfigurationAttributes = {
    __typename?: 'BffConfiguratorConfigurationAttributes';
    id: Scalars['String']['output'];
    selected?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
    values?: Maybe<Array<BffConfiguratorConfigurationAttributesValue>>;
    viewpoint?: Maybe<Scalars['String']['output']>;
};

export type BffConfiguratorConfigurationAttributesValue = {
    __typename?: 'BffConfiguratorConfigurationAttributesValue';
    id: Scalars['String']['output'];
    image?: Maybe<BffProductImage>;
    info?: Maybe<Scalars['String']['output']>;
    selected: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
    subtitle?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
};

export type BffConfiguratorConfigurationColor = {
    __typename?: 'BffConfiguratorConfigurationColor';
    colors?: Maybe<Array<BffConfiguratorConfigurationColorItem>>;
    id: Scalars['String']['output'];
    materials?: Maybe<Array<BffConfiguratorConfigurationColorMaterial>>;
    selected?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
    viewpoint?: Maybe<Scalars['String']['output']>;
};

export type BffConfiguratorConfigurationColorItem = {
    __typename?: 'BffConfiguratorConfigurationColorItem';
    attributes?: Maybe<Array<BffConfiguratorConfigurationItemAttribute>>;
    colorFamilies?: Maybe<Array<IConfiguratorConfigurationColorItemColorFamily>>;
    features?: Maybe<Array<IConfiguratorConfigurationColorItemFeatures>>;
    fullSizeImage?: Maybe<BffProductImage>;
    id: Scalars['String']['output'];
    listingImage?: Maybe<BffProductImage>;
    material: Scalars['String']['output'];
    priceGroup?: Maybe<IConfiguratorConfigurationColorItemPriceGroup>;
    sampleArticleNumber?: Maybe<Scalars['String']['output']>;
    selected: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type BffConfiguratorConfigurationColorMaterial = {
    __typename?: 'BffConfiguratorConfigurationColorMaterial';
    attributes?: Maybe<Array<BffConfiguratorConfigurationItemAttribute>>;
    factsheetUrl?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    text?: Maybe<Scalars['String']['output']>;
};

export type BffConfiguratorConfigurationItemAttribute =
    | BffConfiguratorConfigurationItemAttributeArray
    | BffConfiguratorConfigurationItemAttributeBoolean
    | BffConfiguratorConfigurationItemAttributeMeter
    | BffConfiguratorConfigurationItemAttributeNumber
    | BffConfiguratorConfigurationItemAttributeText;

export type BffConfiguratorConfigurationItemAttributeArray = {
    __typename?: 'BffConfiguratorConfigurationItemAttributeArray';
    key: Scalars['String']['output'];
    type: Scalars['String']['output'];
    values?: Maybe<Array<BffConfiguratorConfigurationItemAttributeText>>;
};

export type BffConfiguratorConfigurationItemAttributeBoolean = {
    __typename?: 'BffConfiguratorConfigurationItemAttributeBoolean';
    key: Scalars['String']['output'];
    type: Scalars['String']['output'];
    value: Scalars['Boolean']['output'];
};

export type BffConfiguratorConfigurationItemAttributeMeter = {
    __typename?: 'BffConfiguratorConfigurationItemAttributeMeter';
    key: Scalars['String']['output'];
    rawValue?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
    value: Scalars['Int']['output'];
};

export type BffConfiguratorConfigurationItemAttributeNumber = {
    __typename?: 'BffConfiguratorConfigurationItemAttributeNumber';
    key: Scalars['String']['output'];
    type: Scalars['String']['output'];
    value: Scalars['Int']['output'];
};

export type BffConfiguratorConfigurationItemAttributeText = {
    __typename?: 'BffConfiguratorConfigurationItemAttributeText';
    key: Scalars['String']['output'];
    type: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type BffConfiguratorDelivery = {
    __typename?: 'BffConfiguratorDelivery';
    cached?: Maybe<Scalars['Boolean']['output']>;
    deliveryTime: Scalars['String']['output'];
    endDate?: Maybe<Scalars['String']['output']>;
    startDate?: Maybe<Scalars['String']['output']>;
    type: BffDeliveryType;
    weekRange?: Maybe<Array<Scalars['Int']['output']>>;
};

export type BffConfiguratorModular = {
    __typename?: 'BffConfiguratorModular';
    articleNumber: Scalars['String']['output'];
    categories?: Maybe<Array<BffConfiguratorModularCategories>>;
    colors?: Maybe<Array<BffConfiguratorConfigurationColorItem>>;
    commonConfigurations?: Maybe<Array<BffConfiguratorConfiguration>>;
    delivery?: Maybe<BffConfiguratorDelivery>;
    materials?: Maybe<Array<BffConfiguratorConfigurationColorMaterial>>;
    name: Scalars['String']['output'];
    price?: Maybe<BffPrice>;
    products?: Maybe<Array<BffConfiguratorModularProduct>>;
};

export type BffConfiguratorModularCategories = {
    __typename?: 'BffConfiguratorModularCategories';
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    quantity?: Maybe<Scalars['Int']['output']>;
};

export type BffConfiguratorModularPreset = {
    __typename?: 'BffConfiguratorModularPreset';
    delivery?: Maybe<BffConfiguratorDelivery>;
    emersyaId: Scalars['String']['output'];
    image?: Maybe<BffProductImage>;
    price?: Maybe<BffPrice>;
    productsAndCategories: Array<BffConfiguratorModularPresetsItem>;
};

export type BffConfiguratorModularPresetsItem = {
    __typename?: 'BffConfiguratorModularPresetsItem';
    categoryId: Scalars['String']['output'];
    image?: Maybe<BffProductImage>;
    name: Scalars['String']['output'];
    quantity?: Maybe<Scalars['Int']['output']>;
    skus: Array<Scalars['String']['output']>;
};

export type BffConfiguratorModularProduct = {
    __typename?: 'BffConfiguratorModularProduct';
    category?: Maybe<Scalars['String']['output']>;
    configurations?: Maybe<Array<BffConfiguratorConfiguration>>;
    image?: Maybe<BffProductImage>;
    name: Scalars['String']['output'];
    price?: Maybe<BffPrice>;
    quantity?: Maybe<Scalars['Int']['output']>;
    sku: Scalars['String']['output'];
};

export type BffConfiguratorSaved = {
    __typename?: 'BffConfiguratorSaved';
    cached?: Maybe<Scalars['Boolean']['output']>;
    delivery?: Maybe<BffConfiguratorDelivery>;
    image?: Maybe<BffProductImage>;
    isInCart: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    price?: Maybe<BffPrice>;
    showAddToCart: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
};

export enum BffCountry {
    At = 'AT',
    Be = 'BE',
    Ch = 'CH',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Es = 'ES',
    Fi = 'FI',
    Fr = 'FR',
    Gb = 'GB',
    Ie = 'IE',
    It = 'IT',
    Li = 'LI',
    Lu = 'LU',
    Nl = 'NL',
    No = 'NO',
    Pl = 'PL',
    Pt = 'PT',
    Se = 'SE',
    Us = 'US'
}

export type BffCustomerAddress = {
    __typename?: 'BffCustomerAddress';
    additionalAddressInfo?: Maybe<Scalars['String']['output']>;
    apartment?: Maybe<Scalars['String']['output']>;
    billing?: Maybe<Scalars['Boolean']['output']>;
    buildingName?: Maybe<Scalars['String']['output']>;
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    defaultShippingAddress: Scalars['Boolean']['output'];
    firstName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    locality?: Maybe<Scalars['String']['output']>;
    mobile?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    postalCode: Scalars['String']['output'];
    region?: Maybe<Scalars['String']['output']>;
    salutation: Scalars['String']['output'];
    shipping?: Maybe<Scalars['Boolean']['output']>;
    streetName: Scalars['String']['output'];
    streetNumber: Scalars['String']['output'];
    title?: Maybe<Scalars['String']['output']>;
};

export type BffCustomerAddressDeleteResult = {
    __typename?: 'BffCustomerAddressDeleteResult';
    id: Scalars['String']['output'];
};

export type BffCustomerAddressUpdate = {
    additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
    addressType?: InputMaybe<McAddressRequestAddressTypeEnum>;
    apartment?: InputMaybe<Scalars['String']['input']>;
    billing?: InputMaybe<Scalars['Boolean']['input']>;
    buildingName?: InputMaybe<Scalars['String']['input']>;
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    locality?: InputMaybe<Scalars['String']['input']>;
    mobile?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    postalCode: Scalars['String']['input'];
    region?: InputMaybe<Scalars['String']['input']>;
    salutation: Scalars['String']['input'];
    shipping?: InputMaybe<Scalars['Boolean']['input']>;
    streetName: Scalars['String']['input'];
    streetNumber: Scalars['String']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type BffCustomerAddressUpdateResult = {
    __typename?: 'BffCustomerAddressUpdateResult';
    additionalAddressInfo?: Maybe<Scalars['String']['output']>;
    apartment?: Maybe<Scalars['String']['output']>;
    billing?: Maybe<Scalars['Boolean']['output']>;
    buildingName?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    defaultShippingAddress?: Maybe<Scalars['Boolean']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    locality?: Maybe<Scalars['String']['output']>;
    mobile?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    region?: Maybe<Scalars['String']['output']>;
    salutation?: Maybe<Scalars['String']['output']>;
    shipping?: Maybe<Scalars['Boolean']['output']>;
    streetName?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type BffCustomerAddressesGetResult = {
    __typename?: 'BffCustomerAddressesGetResult';
    addresses?: Maybe<Array<BffCustomerAddress>>;
};

export type BffCustomerCreate = {
    companyName?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    password: Scalars['String']['input'];
    preferredCountry: McCustomerCreateRequestPreferredCountryEnum;
    preferredLanguage: McCustomerCreateRequestPreferredLanguageEnum;
    salutation: Scalars['String']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
    vatId?: InputMaybe<Scalars['String']['input']>;
    vatIdCountry?: InputMaybe<Scalars['String']['input']>;
};

export type BffCustomerModel = {
    __typename?: 'BffCustomerModel';
    addressBook: Array<BffCustomerAddress>;
    companyName?: Maybe<Scalars['String']['output']>;
    customerNumber: Scalars['String']['output'];
    customerType: Scalars['String']['output'];
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    orders?: Maybe<Array<BffOrderSummary>>;
    preferredCountry?: Maybe<Scalars['String']['output']>;
    preferredLanguage?: Maybe<Scalars['String']['output']>;
    salutation: Scalars['String']['output'];
    scope?: Maybe<Array<Scalars['String']['output']>>;
    title?: Maybe<Scalars['String']['output']>;
    vatId?: Maybe<Scalars['String']['output']>;
    vatIdCountry?: Maybe<Scalars['String']['output']>;
};

export type BffCustomerModelAddressBookArgs = {
    country: BffCountry;
};

export type BffCustomerModelOrdersArgs = {
    country: BffCountry;
};

export type BffCustomerUpdate = {
    companyName?: InputMaybe<Scalars['String']['input']>;
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    salutation: Scalars['String']['input'];
};

export type BffCustomerVerifyModel = {
    __typename?: 'BffCustomerVerifyModel';
    verified: Scalars['String']['output'];
};

export enum BffDataSource {
    Censhare = 'censhare',
    Commercetools = 'commercetools'
}

export type BffDelivery = {
    __typename?: 'BffDelivery';
    cached?: Maybe<Scalars['Boolean']['output']>;
    deliveryTime: Scalars['String']['output'];
    endDate?: Maybe<Scalars['String']['output']>;
    startDate?: Maybe<Scalars['String']['output']>;
    type: BffDeliveryType;
    weekRange?: Maybe<Array<Scalars['Int']['output']>>;
};

export type BffDeliveryModel = {
    __typename?: 'BffDeliveryModel';
    articleNo?: Maybe<Scalars['String']['output']>;
    deliveryEndDate?: Maybe<Scalars['String']['output']>;
    deliveryStartDate?: Maybe<Scalars['String']['output']>;
    shippingCost?: Maybe<Scalars['Float']['output']>;
    shippingTime?: Maybe<Scalars['Int']['output']>;
    shippingTimeMax?: Maybe<Scalars['Int']['output']>;
    shippingTimeMin?: Maybe<Scalars['Int']['output']>;
    shippingType?: Maybe<Scalars['String']['output']>;
    weekRange?: Maybe<Scalars['String']['output']>;
};

export enum BffDeliveryType {
    DateRange = 'dateRange',
    FastShipping = 'fastShipping',
    NotAvailable = 'notAvailable',
    WeekRange = 'weekRange',
    WeekRangeFallback = 'weekRangeFallback'
}

export type BffError = {
    cart?: Maybe<BffCartResult>;
    message: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export enum BffLanguage {
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Ja = 'ja',
    Nl = 'nl'
}

export type BffLink = {
    __typename?: 'BffLink';
    href: Scalars['String']['output'];
    id?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export enum BffMarket {
    As = 'AS',
    At = 'AT',
    Au = 'AU',
    Be = 'BE',
    Br = 'BR',
    Ch = 'CH',
    Cn = 'CN',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Es = 'ES',
    Fi = 'FI',
    Fr = 'FR',
    Gb = 'GB',
    Gr = 'GR',
    Hu = 'HU',
    Ie = 'IE',
    Is = 'IS',
    It = 'IT',
    Jp = 'JP',
    Li = 'LI',
    Lp = 'LP',
    Lu = 'LU',
    Me = 'ME',
    Mt = 'MT',
    Mx = 'MX',
    Nl = 'NL',
    No = 'NO',
    Pl = 'PL',
    Pt = 'PT',
    Se = 'SE',
    Tr = 'TR',
    Un = 'UN',
    Us = 'US',
    Vi = 'VI'
}

export type BffOrder = {
    __typename?: 'BffOrder';
    analytics?: Maybe<Scalars['JSON']['output']>;
    billingAddress: BffCartAddress;
    date: Scalars['String']['output'];
    deliveries: Array<BffOrderDelivery>;
    deliveryNote?: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    id: Scalars['String']['output'];
    orderNumber: Scalars['String']['output'];
    orderStatus: Scalars['String']['output'];
    paymentBic?: Maybe<Scalars['String']['output']>;
    paymentIban?: Maybe<Scalars['String']['output']>;
    paymentMethod: Scalars['String']['output'];
    paymentStatus: Scalars['String']['output'];
    shippingAddress: BffCartAddress;
    totals: Array<BffCartResultTotalPrice>;
};

export type BffOrderDelivery = {
    __typename?: 'BffOrderDelivery';
    batchId: Scalars['String']['output'];
    delivery: BffCartResultDelivery;
    id: Scalars['String']['output'];
    items: Array<BffOrderDeliveryItems>;
    parcels: Array<BffOrderDeliveryParcel>;
    shippingDescription?: Maybe<Scalars['String']['output']>;
    shippingOptionDescription?: Maybe<Scalars['String']['output']>;
};

export type BffOrderDeliveryItems = {
    __typename?: 'BffOrderDeliveryItems';
    articleNumber?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    image?: Maybe<BffCartResultItemImage>;
    itemStatus?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    quantity: Scalars['Int']['output'];
    sku: Scalars['String']['output'];
    totalPrice: BffProductPrice;
};

export type BffOrderDeliveryParcel = {
    __typename?: 'BffOrderDeliveryParcel';
    id?: Maybe<Scalars['String']['output']>;
    trackingData?: Maybe<BffOrderDeliveryParcelTrackingData>;
};

export type BffOrderDeliveryParcelTrackingData = {
    __typename?: 'BffOrderDeliveryParcelTrackingData';
    carrier?: Maybe<Scalars['String']['output']>;
    trackingId?: Maybe<Scalars['String']['output']>;
};

export type BffOrderSummary = {
    __typename?: 'BffOrderSummary';
    date: Scalars['String']['output'];
    id: Scalars['String']['output'];
    numberOfArticles: Scalars['String']['output'];
    orderNumber: Scalars['String']['output'];
    orderState: Scalars['String']['output'];
    paymentState: Scalars['String']['output'];
    totalPrice: BffProductPrice;
};

export type BffPrice = {
    __typename?: 'BffPrice';
    amount?: Maybe<Scalars['Float']['output']>;
    centAmount?: Maybe<Scalars['Int']['output']>;
    currencyCode?: Maybe<Scalars['String']['output']>;
};

export type BffProduct = {
    __typename?: 'BffProduct';
    analytics?: Maybe<Scalars['JSON']['output']>;
    cached?: Maybe<Scalars['Boolean']['output']>;
    configurations?: Maybe<Array<BffProductConfiguration>>;
    delivery?: Maybe<BffDelivery>;
    designer?: Maybe<Scalars['String']['output']>;
    image?: Maybe<BffProductImage>;
    name: Scalars['String']['output'];
    price?: Maybe<BffProductPrice>;
    requestedSku: Scalars['String']['output'];
    showAddToCart: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
};

export type BffProductAvailability = {
    __typename?: 'BffProductAvailability';
    delivery?: Maybe<BffConfiguratorDelivery>;
    isInCart: Scalars['Boolean']['output'];
    isMaterialLimitReached?: Maybe<Scalars['Boolean']['output']>;
    isOrderable: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
};

export type BffProductConfiguration = {
    __typename?: 'BffProductConfiguration';
    id: Scalars['String']['output'];
    image?: Maybe<BffProductImage>;
    selected: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: Scalars['String']['output'];
    values?: Maybe<Array<BffProductConfigurationValue>>;
};

export type BffProductConfigurationValue = {
    __typename?: 'BffProductConfigurationValue';
    image?: Maybe<BffProductImage>;
    name: Scalars['String']['output'];
    selected: Scalars['Boolean']['output'];
    sku: Scalars['String']['output'];
};

export type BffProductImage = {
    __typename?: 'BffProductImage';
    height?: Maybe<Scalars['Int']['output']>;
    src: Scalars['String']['output'];
    width?: Maybe<Scalars['Int']['output']>;
};

export type BffProductInputProduct = {
    quantity?: InputMaybe<Scalars['Int']['input']>;
    sku: Scalars['Sku']['input'];
};

export type BffProductModel = {
    __typename?: 'BffProductModel';
    delivery?: Maybe<BffDeliveryModel>;
    designer?: Maybe<Scalars['String']['output']>;
    image?: Maybe<BffCartResultItemImage>;
    name?: Maybe<Scalars['String']['output']>;
    /** @deprecated Use ProductModelV2 instead */
    price?: Maybe<BffProductPriceModel>;
    quantity?: Maybe<Scalars['Int']['output']>;
    sku: Scalars['String']['output'];
    url?: Maybe<Scalars['String']['output']>;
};

export type BffProductModelPriceArgs = {
    language: BffLanguage;
};

export type BffProductPrice = {
    __typename?: 'BffProductPrice';
    amount?: Maybe<Scalars['Float']['output']>;
    currencyCode?: Maybe<Scalars['String']['output']>;
    /** @deprecated Use currencyCode */
    currencySymbol?: Maybe<Scalars['String']['output']>;
    /** @deprecated Use raw */
    formatted?: Maybe<Scalars['String']['output']>;
    raw?: Maybe<Scalars['Float']['output']>;
};

export type BffProductPriceModel = {
    __typename?: 'BffProductPriceModel';
    articleNumber?: Maybe<Scalars['String']['output']>;
    censhareId?: Maybe<Scalars['Int']['output']>;
    censhareSlug?: Maybe<Scalars['String']['output']>;
    configurationId?: Maybe<Scalars['String']['output']>;
    grossPrice?: Maybe<BffProductPrice>;
    isFromPrice?: Maybe<Scalars['Boolean']['output']>;
    netPrice?: Maybe<BffProductPrice>;
    price?: Maybe<BffProductPrice>;
};

export enum BffProfession {
    Architect = 'architect',
    Designer = 'designer',
    InteriorArchitect = 'interior_architect',
    Other = 'other',
    Private = 'private',
    Retailer = 'retailer'
}

export type BffProxyError = BffError & {
    __typename?: 'BffProxyError';
    cart?: Maybe<BffCartResult>;
    message: Scalars['String']['output'];
    name: Scalars['String']['output'];
    status: Scalars['Int']['output'];
    traceId: Scalars['String']['output'];
};

export type BffShoppingList = {
    __typename?: 'BffShoppingList';
    id: Scalars['String']['output'];
    isCartShare: Scalars['Boolean']['output'];
    lineItems: Array<BffShoppingListLineItem>;
};

export type BffShoppingListLineItem = {
    __typename?: 'BffShoppingListLineItem';
    censhareId: Scalars['String']['output'];
    delivery?: Maybe<BffConfiguratorDelivery>;
    id: Scalars['String']['output'];
    image: BffCartResultItemImage;
    isSellable: Scalars['Boolean']['output'];
    link?: Maybe<BffLink>;
    name: Scalars['String']['output'];
    price?: Maybe<BffPrice>;
    productType: Scalars['String']['output'];
    sku: Scalars['String']['output'];
};

export type BffShoppingListLineItemInput = {
    sku: Scalars['String']['input'];
};

export type BffShoppingListShareLink = {
    __typename?: 'BffShoppingListShareLink';
    encodedShoppingListId?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
};

export enum BffStore {
    WebEu = 'web_EU',
    WebUs = 'web_US'
}

export type BffSubmitOrderResult = {
    __typename?: 'BffSubmitOrderResult';
    cartId: Scalars['String']['output'];
    orderNumber?: Maybe<Scalars['String']['output']>;
    payment: BffSubmitOrderResultPayment;
    success: Scalars['Boolean']['output'];
};

export type BffSubmitOrderResultPayment = {
    __typename?: 'BffSubmitOrderResultPayment';
    action?: Maybe<Scalars['JSON']['output']>;
    paymentMethod?: Maybe<Scalars['JSON']['output']>;
    pspReference?: Maybe<Scalars['String']['output']>;
    refusalReason?: Maybe<Scalars['String']['output']>;
    refusalReasonCode?: Maybe<Scalars['String']['output']>;
};

export type BffUpdateDesiredDeilveryDateResult = {
    __typename?: 'BffUpdateDesiredDeilveryDateResult';
    calendarEndDate?: Maybe<Scalars['String']['output']>;
    calendarStartDate?: Maybe<Scalars['String']['output']>;
    deliveryTime?: Maybe<Scalars['String']['output']>;
    desiredDateSelected?: Maybe<Scalars['Boolean']['output']>;
    shippingBatchId?: Maybe<Scalars['String']['output']>;
    userSelectedDeliveryEndDate?: Maybe<Scalars['String']['output']>;
    userSelectedDeliveryStartDate?: Maybe<Scalars['String']['output']>;
};

export type BffVatResponse = {
    __typename?: 'BffVatResponse';
    isValid: Scalars['Boolean']['output'];
    name?: Maybe<Scalars['String']['output']>;
    vatNumber: Scalars['String']['output'];
};

export type Error = {
    __typename?: 'Error';
    message: Scalars['String']['output'];
};

export enum McAddressRequestAddressTypeEnum {
    Billing = 'BILLING',
    Shipping = 'SHIPPING'
}

export enum McCustomerCreateRequestPreferredCountryEnum {
    Ad = 'AD',
    Ae = 'AE',
    Ao = 'AO',
    Ar = 'AR',
    At = 'AT',
    Au = 'AU',
    Ba = 'BA',
    Be = 'BE',
    Bg = 'BG',
    Bh = 'BH',
    Bq = 'BQ',
    Br = 'BR',
    By = 'BY',
    Ca = 'CA',
    Ch = 'CH',
    Cl = 'CL',
    Cn = 'CN',
    Co = 'CO',
    Cw = 'CW',
    Cy = 'CY',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Do = 'DO',
    Ec = 'EC',
    Ee = 'EE',
    Eg = 'EG',
    Es = 'ES',
    Fi = 'FI',
    Fo = 'FO',
    Fr = 'FR',
    Gb = 'GB',
    Ge = 'GE',
    Gr = 'GR',
    Gt = 'GT',
    Hk = 'HK',
    Hr = 'HR',
    Hu = 'HU',
    Id = 'ID',
    Ie = 'IE',
    Il = 'IL',
    In = 'IN',
    Ir = 'IR',
    Is = 'IS',
    It = 'IT',
    Jo = 'JO',
    Jp = 'JP',
    Kr = 'KR',
    Kw = 'KW',
    Kz = 'KZ',
    Lb = 'LB',
    Li = 'LI',
    Lk = 'LK',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Ma = 'MA',
    Mk = 'MK',
    Mt = 'MT',
    Mx = 'MX',
    My = 'MY',
    Nc = 'NC',
    Nl = 'NL',
    No = 'NO',
    Nz = 'NZ',
    Om = 'OM',
    Pa = 'PA',
    Pe = 'PE',
    Ph = 'PH',
    Pl = 'PL',
    Pr = 'PR',
    Ps = 'PS',
    Pt = 'PT',
    Qa = 'QA',
    Ro = 'RO',
    Rs = 'RS',
    Ru = 'RU',
    Sa = 'SA',
    Se = 'SE',
    Sg = 'SG',
    Si = 'SI',
    Sk = 'SK',
    Th = 'TH',
    Tn = 'TN',
    Tr = 'TR',
    Tw = 'TW',
    Ua = 'UA',
    Us = 'US',
    Uy = 'UY',
    Ve = 'VE',
    Ye = 'YE',
    Za = 'ZA'
}

export enum McCustomerCreateRequestPreferredLanguageEnum {
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Ja = 'ja',
    Nl = 'nl',
    Zh = 'zh'
}

export type Mutation = {
    __typename?: 'Mutation';
    addDiscountCodeToCart: MutationAddDiscountCodeToCartResult;
    addToCart: MutationAddToCartResult;
    changeCart: MutationChangeCartResult;
    changeCartAddresses: MutationChangeCartAddressesResult;
    createCartShare: MutationCreateCartShareResult;
    createCustomer: MutationCreateCustomerResult;
    createCustomerAddress: MutationCreateCustomerAddressResult;
    createCustomerShoppingList: MutationCreateCustomerShoppingListResult;
    deleteCustomerAddress: MutationDeleteCustomerAddressResult;
    deleteCustomerShoppingListItem: MutationDeleteCustomerShoppingListItemResult;
    initiatePayment?: Maybe<MutationInitiatePaymentResult>;
    removeDiscountCodeFromCart: MutationRemoveDiscountCodeFromCartResult;
    splitOrder: MutationSplitOrderResult;
    submitExpressOrder?: Maybe<MutationSubmitExpressOrderResult>;
    submitMaterialsOrder?: Maybe<MutationSubmitMaterialsOrderResult>;
    submitOrder?: Maybe<MutationSubmitOrderResult>;
    submitPaymentAction?: Maybe<MutationSubmitPaymentActionResult>;
    updateCustomer: MutationUpdateCustomerResult;
    updateCustomerAddress: MutationUpdateCustomerAddressResult;
    updateDeliveryNoteAndProfession?: Maybe<MutationUpdateDeliveryNoteAndProfessionResult>;
    updateDesiredDeliveryDate?: Maybe<MutationUpdateDesiredDeliveryDateResult>;
    updateShippingOption?: Maybe<MutationUpdateShippingOptionResult>;
};

export type MutationAddDiscountCodeToCartArgs = {
    code: Scalars['String']['input'];
    country: BffCountry;
};

export type MutationAddToCartArgs = {
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
    sku: Scalars['Sku']['input'];
};

export type MutationChangeCartArgs = {
    country: BffCountry;
    lineItemId: Scalars['String']['input'];
    quantity: Scalars['Int']['input'];
};

export type MutationChangeCartAddressesArgs = {
    billingAddress: BffCartAddressUpdate;
    country: BffCountry;
    shippingAddress: BffCartAddressUpdate;
};

export type MutationCreateCartShareArgs = {
    country: BffCountry;
};

export type MutationCreateCustomerArgs = {
    country: BffCountry;
    customerData: BffCustomerCreate;
    turnstile: Scalars['String']['input'];
};

export type MutationCreateCustomerAddressArgs = {
    address: BffCustomerAddressUpdate;
    country: BffCountry;
};

export type MutationCreateCustomerShoppingListArgs = {
    country: Scalars['String']['input'];
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']>>;
    lineItems: Array<BffShoppingListLineItemInput>;
};

export type MutationDeleteCustomerAddressArgs = {
    country: BffCountry;
    id: Scalars['String']['input'];
};

export type MutationDeleteCustomerShoppingListItemArgs = {
    country: Scalars['String']['input'];
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']>>;
    id: Scalars['String']['input'];
};

export type MutationInitiatePaymentArgs = {
    country: BffCountry;
    paymentMethod: Scalars['JSON']['input'];
    riskData?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationRemoveDiscountCodeFromCartArgs = {
    code: Scalars['String']['input'];
    country: BffCountry;
};

export type MutationSplitOrderArgs = {
    country: BffCountry;
};

export type MutationSubmitExpressOrderArgs = {
    country: BffCountry;
    deliveryAddress: BffCustomerAddressUpdate;
    email: Scalars['String']['input'];
    language: BffLanguage;
    paymentDetails: Scalars['JSON']['input'];
    paymentMethod: Scalars['String']['input'];
};

export type MutationSubmitMaterialsOrderArgs = {
    country: BffCountry;
    customerProfession: BffProfession;
    deliveryNote?: InputMaybe<Scalars['String']['input']>;
    language: BffLanguage;
    turnstile: Scalars['String']['input'];
};

export type MutationSubmitOrderArgs = {
    country: BffCountry;
    customerProfession?: InputMaybe<BffProfession>;
    deliveryNote?: InputMaybe<Scalars['String']['input']>;
    language: BffLanguage;
    paymentDetails: Scalars['JSON']['input'];
    paymentMethod: Scalars['String']['input'];
    turnstile: Scalars['String']['input'];
};

export type MutationSubmitPaymentActionArgs = {
    country: BffCountry;
    data: Scalars['JSON']['input'];
    paymentMethod: Scalars['String']['input'];
};

export type MutationUpdateCustomerArgs = {
    country: BffCountry;
    customerData: BffCustomerUpdate;
};

export type MutationUpdateCustomerAddressArgs = {
    address: BffCustomerAddressUpdate;
    country: BffCountry;
    id: Scalars['String']['input'];
};

export type MutationUpdateDeliveryNoteAndProfessionArgs = {
    country: BffCountry;
    customerProfession?: InputMaybe<Scalars['String']['input']>;
    deliveryNote?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDesiredDeliveryDateArgs = {
    country: BffCountry;
    date?: InputMaybe<Scalars['String']['input']>;
    shippingBatchId: Scalars['String']['input'];
};

export type MutationUpdateShippingOptionArgs = {
    country: BffCountry;
    shippingOptionId: Scalars['String']['input'];
    shippingOptionKey: Scalars['String']['input'];
};

export type MutationAddDiscountCodeToCartResult = BffCartResult | BffProxyError | Error;

export type MutationAddToCartResult = BffCartResult | BffProxyError | Error;

export type MutationChangeCartAddressesResult = BffCartResult | BffProxyError | Error;

export type MutationChangeCartResult = BffCartResult | BffProxyError | Error;

export type MutationCreateCartShareResult = BffCartShareResult | BffProxyError | Error;

export type MutationCreateCustomerAddressResult = BffCustomerAddressUpdateResult | BffProxyError | Error;

export type MutationCreateCustomerResult = BffCustomerModel | BffProxyError | Error;

export type MutationCreateCustomerShoppingListResult = BffProxyError | BffShoppingList | Error;

export type MutationDeleteCustomerAddressResult = BffCustomerAddressDeleteResult | BffProxyError | Error;

export type MutationDeleteCustomerShoppingListItemResult = BffProxyError | BffShoppingList | Error;

export type MutationInitiatePaymentResult = BffProxyError | Error | MutationInitiatePaymentSuccess;

export type MutationInitiatePaymentSuccess = {
    __typename?: 'MutationInitiatePaymentSuccess';
    data: Scalars['JSON']['output'];
};

export type MutationRemoveDiscountCodeFromCartResult = BffCartResult | BffProxyError | Error;

export type MutationSplitOrderResult = BffCartResult | BffProxyError | Error;

export type MutationSubmitExpressOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitMaterialsOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitPaymentActionResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationUpdateCustomerAddressResult = BffCustomerAddressUpdateResult | BffProxyError | Error;

export type MutationUpdateCustomerResult = BffCustomerModel | BffProxyError | Error;

export type MutationUpdateDeliveryNoteAndProfessionResult = BffCartResult | BffProxyError | Error;

export type MutationUpdateDesiredDeliveryDateResult = BffProxyError | BffUpdateDesiredDeilveryDateResult | Error;

export type MutationUpdateShippingOptionResult = BffCartResult | BffProxyError | Error;

export type Query = {
    __typename?: 'Query';
    checkVatNumber?: Maybe<QueryCheckVatNumberResult>;
    getAvailability?: Maybe<QueryGetAvailabilityResult>;
    getCart?: Maybe<QueryGetCartResult>;
    getConfigurator?: Maybe<QueryGetConfiguratorResult>;
    getCustomer?: Maybe<QueryGetCustomerResult>;
    getFromPriceBySlug?: Maybe<QueryGetFromPriceBySlugResult>;
    getFromPrices?: Maybe<Array<BffProductPriceModel>>;
    getFromPricesBySlugs?: Maybe<Array<BffProductPriceModel>>;
    getModularConfigurator?: Maybe<QueryGetModularConfiguratorResult>;
    getModularConfiguratorPresets?: Maybe<QueryGetModularConfiguratorPresetsResult>;
    getOrder?: Maybe<QueryGetOrderResult>;
    getOrderByCartId?: Maybe<QueryGetOrderByCartIdResult>;
    getProducts: Array<BffProductModel>;
    getProductsAndConfiguration?: Maybe<QueryGetProductsAndConfigurationResult>;
    getShoppingList?: Maybe<QueryGetShoppingListResult>;
    mergeCart?: Maybe<QueryMergeCartResult>;
    shareShoppingList?: Maybe<QueryShareShoppingListResult>;
    verifyCustomer?: Maybe<QueryVerifyCustomerResult>;
};

export type QueryCheckVatNumberArgs = {
    country: BffCountry;
    vat: Scalars['String']['input'];
};

export type QueryGetAvailabilityArgs = {
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']>;
};

export type QueryGetCartArgs = {
    country: BffCountry;
    refresh?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetConfiguratorArgs = {
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']>;
};

export type QueryGetCustomerArgs = {
    country: BffCountry;
};

export type QueryGetFromPriceBySlugArgs = {
    market: BffMarket;
    slug: Scalars['String']['input'];
};

export type QueryGetFromPricesArgs = {
    censhareProductIds: Array<Scalars['Int']['input']>;
    market: BffMarket;
};

export type QueryGetFromPricesBySlugsArgs = {
    market: BffMarket;
    slugs: Array<Scalars['String']['input']>;
};

export type QueryGetModularConfiguratorArgs = {
    language: BffLanguage;
    market: BffMarket;
    products: Array<BffProductInputProduct>;
};

export type QueryGetModularConfiguratorPresetsArgs = {
    emersyaIds: Array<Scalars['String']['input']>;
    language: BffLanguage;
    market: BffMarket;
};

export type QueryGetOrderArgs = {
    country: BffCountry;
    id: Scalars['String']['input'];
};

export type QueryGetOrderByCartIdArgs = {
    cartId: Scalars['String']['input'];
    country: BffCountry;
};

export type QueryGetProductsArgs = {
    dataSource?: InputMaybe<BffDataSource>;
    market: BffMarket;
    products: Array<BffProductInputProduct>;
};

export type QueryGetProductsAndConfigurationArgs = {
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']>;
};

export type QueryGetShoppingListArgs = {
    country: Scalars['String']['input'];
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']>>;
    id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMergeCartArgs = {
    anonymousToken: Scalars['String']['input'];
    country: BffCountry;
};

export type QueryShareShoppingListArgs = {
    country: BffCountry;
    skus?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryVerifyCustomerArgs = {
    country: BffCountry;
    email: Scalars['String']['input'];
};

export type QueryCheckVatNumberResult = BffProxyError | BffVatResponse | Error;

export type QueryGetAvailabilityResult = BffProxyError | Error | QueryGetAvailabilitySuccess;

export type QueryGetAvailabilitySuccess = {
    __typename?: 'QueryGetAvailabilitySuccess';
    data: Array<BffProductAvailability>;
};

export type QueryGetCartResult = BffCartResult | BffProxyError | Error;

export type QueryGetConfiguratorResult = BffProxyError | Error | QueryGetConfiguratorSuccess;

export type QueryGetConfiguratorSuccess = {
    __typename?: 'QueryGetConfiguratorSuccess';
    data: Array<BffConfigurator>;
};

export type QueryGetCustomerResult = BffCustomerModel | BffProxyError | Error;

export type QueryGetFromPriceBySlugResult = BffProductPriceModel | BffProxyError | Error;

export type QueryGetModularConfiguratorPresetsResult =
    | BffProxyError
    | Error
    | QueryGetModularConfiguratorPresetsSuccess;

export type QueryGetModularConfiguratorPresetsSuccess = {
    __typename?: 'QueryGetModularConfiguratorPresetsSuccess';
    data: Array<BffConfiguratorModularPreset>;
};

export type QueryGetModularConfiguratorResult = BffProxyError | Error | QueryGetModularConfiguratorSuccess;

export type QueryGetModularConfiguratorSuccess = {
    __typename?: 'QueryGetModularConfiguratorSuccess';
    data: BffConfiguratorModular;
};

export type QueryGetOrderByCartIdResult = BffOrder | BffProxyError | Error;

export type QueryGetOrderResult = BffOrder | BffProxyError | Error;

export type QueryGetProductsAndConfigurationResult = BffProxyError | Error | QueryGetProductsAndConfigurationSuccess;

export type QueryGetProductsAndConfigurationSuccess = {
    __typename?: 'QueryGetProductsAndConfigurationSuccess';
    data: Array<BffProduct>;
};

export type QueryGetShoppingListResult = BffProxyError | BffShoppingList | Error;

export type QueryMergeCartResult = BffCartResult | BffProxyError | Error;

export type QueryShareShoppingListResult = BffProxyError | BffShoppingListShareLink | Error;

export type QueryVerifyCustomerResult = BffCustomerVerifyModel | BffProxyError | Error;

export enum IConfiguratorConfigurationColorItemColorFamily {
    BlackDarkGrey = 'black_dark_grey',
    Blue = 'blue',
    Brown = 'brown',
    Green = 'green',
    PurplePink = 'purple_pink',
    RedOrange = 'red_orange',
    WhiteLightGrey = 'white_light_grey',
    Yellow = 'yellow'
}

export enum IConfiguratorConfigurationColorItemFeatures {
    Cooling = 'cooling',
    Cosy = 'cosy',
    DirectSunlight = 'direct_sunlight',
    Fabric = 'fabric',
    FamilyFriendly = 'family_friendly',
    Leather = 'leather',
    Natural = 'natural',
    Patina = 'patina',
    PetFriendly = 'pet_friendly',
    RobustChoice = 'robust_choice',
    SensitiveSkin = 'sensitive_skin',
    StainResistant = 'stain_resistant',
    Vegan = 'vegan'
}

export enum IConfiguratorConfigurationColorItemPriceGroup {
    PriceGroup10 = 'PriceGroup10',
    PriceGroup20 = 'PriceGroup20',
    PriceGroup30 = 'PriceGroup30',
    PriceGroup40 = 'PriceGroup40',
    PriceGroup50 = 'PriceGroup50'
}

export type CartInfoFragment = {
    __typename?: 'BffCartResult';
    id: string;
    version?: string | null;
    count: number;
    anayltics?: any | null;
    canSplitOrder: boolean;
    isOrderSplit: boolean;
    showReturnNote: boolean;
    discountCode?: string | null;
    isBlocked: boolean;
    skipPayment: boolean;
    custom?: any | null;
    payment?: any | null;
    totals: Array<{
        __typename?: 'BffCartResultTotalPrice';
        formatted: string;
        rate?: string | null;
        key: string;
        raw?: number | null;
        currencyCode?: string | null;
    }>;
    messages: Array<{ __typename?: 'BffCartResultMessage'; type: string; sku?: string | null; message: string }>;
    paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
    cartItems: Array<{
        __typename?: 'BffCartResultCartItem';
        id: string;
        lineItems?: Array<{
            __typename?: 'BffCartResultItem';
            id: string;
            sku: string;
            name: string;
            type: string;
            quantity: number;
            articleNo: string;
            showQuantity: boolean;
            isConfigurable: boolean;
            showReturnNote: boolean;
            orderLimit: number;
            image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
            totalPrice: {
                __typename?: 'BffProductPrice';
                formatted?: string | null;
                raw?: number | null;
                currencyCode?: string | null;
            };
            messages: Array<{
                __typename?: 'BffCartResultMessage';
                type: string;
                sku?: string | null;
                message: string;
            }>;
        }> | null;
        shippingOption?: {
            __typename?: 'BffCartResultShippingOption';
            id?: string | null;
            items: Array<{
                __typename?: 'BffCartResultShippingOptionItem';
                key: string;
                description: string;
                selected: boolean;
                price: {
                    __typename?: 'BffCartResultShippingCostPrice';
                    formatted: string;
                    raw?: number | null;
                    currencyCode?: string | null;
                };
            }>;
        } | null;
    }>;
};

export type AddToCartMutationVariables = Exact<{
    sku: Scalars['Sku']['input'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AddToCartMutation = {
    __typename?: 'Mutation';
    addToCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  count: number;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type CreateCustomerShoppingListMutationVariables = Exact<{
    country: Scalars['String']['input'];
    lineItems: Array<BffShoppingListLineItemInput> | BffShoppingListLineItemInput;
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type CreateCustomerShoppingListMutation = {
    __typename?: 'Mutation';
    createCustomerShoppingList:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: string;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                  delivery?: { __typename?: 'BffConfiguratorDelivery'; deliveryTime: string } | null;
                  price?: {
                      __typename?: 'BffPrice';
                      amount?: number | null;
                      centAmount?: number | null;
                      currencyCode?: string | null;
                  } | null;
              }>;
          }
        | { __typename: 'Error'; message: string };
};

export type DeleteCustomerShoppingListItemMutationVariables = Exact<{
    country: Scalars['String']['input'];
    id: Scalars['String']['input'];
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type DeleteCustomerShoppingListItemMutation = {
    __typename?: 'Mutation';
    deleteCustomerShoppingListItem:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: string;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
              }>;
          }
        | { __typename: 'Error'; message: string };
};

type ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeArray_Fragment = {
    __typename: 'BffConfiguratorConfigurationItemAttributeArray';
    key: string;
    type: string;
    values?: Array<{
        __typename: 'BffConfiguratorConfigurationItemAttributeText';
        key: string;
        type: string;
        textValue: string;
    }> | null;
};

type ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeBoolean_Fragment = {
    __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
    key: string;
    type: string;
    booleanValue: boolean;
};

type ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeMeter_Fragment = {
    __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
    key: string;
    rawValue?: string | null;
    type: string;
    meterValue: number;
};

type ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeNumber_Fragment = {
    __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
    key: string;
    type: string;
    numberValue: number;
};

type ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeText_Fragment = {
    __typename: 'BffConfiguratorConfigurationItemAttributeText';
    key: string;
    type: string;
    textValue: string;
};

export type ConfiguratorConfigurationItemAttributesFragment =
    | ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeArray_Fragment
    | ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeBoolean_Fragment
    | ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeMeter_Fragment
    | ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeNumber_Fragment
    | ConfiguratorConfigurationItemAttributes_BffConfiguratorConfigurationItemAttributeText_Fragment;

export type ConfiguratorConfigurationColorsFragment = {
    __typename?: 'BffConfiguratorConfigurationColorItem';
    id: string;
    colorFamilies?: Array<IConfiguratorConfigurationColorItemColorFamily> | null;
    features?: Array<IConfiguratorConfigurationColorItemFeatures> | null;
    material: string;
    priceGroup?: IConfiguratorConfigurationColorItemPriceGroup | null;
    selected: boolean;
    sku: string;
    sampleArticleNumber?: string | null;
    title: string;
    fullSizeImage?: { __typename?: 'BffProductImage'; src: string } | null;
    listingImage?: { __typename?: 'BffProductImage'; src: string } | null;
    attributes?: Array<
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeArray';
              key: string;
              type: string;
              values?: Array<{
                  __typename: 'BffConfiguratorConfigurationItemAttributeText';
                  key: string;
                  type: string;
                  textValue: string;
              }> | null;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
              key: string;
              type: string;
              booleanValue: boolean;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
              key: string;
              rawValue?: string | null;
              type: string;
              meterValue: number;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
              key: string;
              type: string;
              numberValue: number;
          }
        | { __typename: 'BffConfiguratorConfigurationItemAttributeText'; key: string; type: string; textValue: string }
    > | null;
};

export type ConfiguratorConfigurationMaterialsFragment = {
    __typename?: 'BffConfiguratorConfigurationColorMaterial';
    factsheetUrl?: string | null;
    id: string;
    name: string;
    text?: string | null;
    attributes?: Array<
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeArray';
              key: string;
              type: string;
              values?: Array<{
                  __typename: 'BffConfiguratorConfigurationItemAttributeText';
                  key: string;
                  type: string;
                  textValue: string;
              }> | null;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
              key: string;
              type: string;
              booleanValue: boolean;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
              key: string;
              rawValue?: string | null;
              type: string;
              meterValue: number;
          }
        | {
              __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
              key: string;
              type: string;
              numberValue: number;
          }
        | { __typename: 'BffConfiguratorConfigurationItemAttributeText'; key: string; type: string; textValue: string }
    > | null;
};

type ConfiguratorConfiguration_BffConfiguratorConfigurationAttributes_Fragment = {
    __typename: 'BffConfiguratorConfigurationAttributes';
    id: string;
    type: string;
    viewpoint?: string | null;
    title?: string | null;
    selected?: string | null;
    values?: Array<{
        __typename?: 'BffConfiguratorConfigurationAttributesValue';
        sku: string;
        title: string;
        subtitle?: string | null;
        selected: boolean;
        image?: { __typename?: 'BffProductImage'; src: string } | null;
    }> | null;
};

type ConfiguratorConfiguration_BffConfiguratorConfigurationColor_Fragment = {
    __typename: 'BffConfiguratorConfigurationColor';
    id: string;
    type: string;
    viewpoint?: string | null;
    title?: string | null;
    selected?: string | null;
    colors?: Array<{
        __typename?: 'BffConfiguratorConfigurationColorItem';
        id: string;
        colorFamilies?: Array<IConfiguratorConfigurationColorItemColorFamily> | null;
        features?: Array<IConfiguratorConfigurationColorItemFeatures> | null;
        material: string;
        priceGroup?: IConfiguratorConfigurationColorItemPriceGroup | null;
        selected: boolean;
        sku: string;
        sampleArticleNumber?: string | null;
        title: string;
        fullSizeImage?: { __typename?: 'BffProductImage'; src: string } | null;
        listingImage?: { __typename?: 'BffProductImage'; src: string } | null;
        attributes?: Array<
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                  key: string;
                  type: string;
                  values?: Array<{
                      __typename: 'BffConfiguratorConfigurationItemAttributeText';
                      key: string;
                      type: string;
                      textValue: string;
                  }> | null;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                  key: string;
                  type: string;
                  booleanValue: boolean;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                  key: string;
                  rawValue?: string | null;
                  type: string;
                  meterValue: number;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                  key: string;
                  type: string;
                  numberValue: number;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeText';
                  key: string;
                  type: string;
                  textValue: string;
              }
        > | null;
    }> | null;
    materials?: Array<{
        __typename?: 'BffConfiguratorConfigurationColorMaterial';
        factsheetUrl?: string | null;
        id: string;
        name: string;
        text?: string | null;
        attributes?: Array<
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                  key: string;
                  type: string;
                  values?: Array<{
                      __typename: 'BffConfiguratorConfigurationItemAttributeText';
                      key: string;
                      type: string;
                      textValue: string;
                  }> | null;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                  key: string;
                  type: string;
                  booleanValue: boolean;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                  key: string;
                  rawValue?: string | null;
                  type: string;
                  meterValue: number;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                  key: string;
                  type: string;
                  numberValue: number;
              }
            | {
                  __typename: 'BffConfiguratorConfigurationItemAttributeText';
                  key: string;
                  type: string;
                  textValue: string;
              }
        > | null;
    }> | null;
};

export type ConfiguratorConfigurationFragment =
    | ConfiguratorConfiguration_BffConfiguratorConfigurationAttributes_Fragment
    | ConfiguratorConfiguration_BffConfiguratorConfigurationColor_Fragment;

type ConfiguratorConfigurationIdsOnly_BffConfiguratorConfigurationAttributes_Fragment = {
    __typename: 'BffConfiguratorConfigurationAttributes';
    id: string;
    type: string;
    viewpoint?: string | null;
    title?: string | null;
    selected?: string | null;
    values?: Array<{
        __typename?: 'BffConfiguratorConfigurationAttributesValue';
        id: string;
        sku: string;
        title: string;
        subtitle?: string | null;
        selected: boolean;
        image?: { __typename?: 'BffProductImage'; src: string } | null;
    }> | null;
};

type ConfiguratorConfigurationIdsOnly_BffConfiguratorConfigurationColor_Fragment = {
    __typename: 'BffConfiguratorConfigurationColor';
    id: string;
    type: string;
    viewpoint?: string | null;
    title?: string | null;
    selected?: string | null;
    colors?: Array<{ __typename?: 'BffConfiguratorConfigurationColorItem'; id: string; sku: string }> | null;
    materials?: Array<{ __typename?: 'BffConfiguratorConfigurationColorMaterial'; id: string }> | null;
};

export type ConfiguratorConfigurationIdsOnlyFragment =
    | ConfiguratorConfigurationIdsOnly_BffConfiguratorConfigurationAttributes_Fragment
    | ConfiguratorConfigurationIdsOnly_BffConfiguratorConfigurationColor_Fragment;

export type GetConfiguratorQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetConfiguratorQuery = {
    __typename?: 'Query';
    getConfigurator?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetConfiguratorSuccess';
              data: Array<{
                  __typename?: 'BffConfigurator';
                  name: string;
                  sku: string;
                  showAddToCart: boolean;
                  delivery?: {
                      __typename?: 'BffConfiguratorDelivery';
                      type: BffDeliveryType;
                      deliveryTime: string;
                  } | null;
                  image?: { __typename?: 'BffProductImage'; src: string } | null;
                  price?: {
                      __typename?: 'BffPrice';
                      amount?: number | null;
                      currencyCode?: string | null;
                      centAmount?: number | null;
                  } | null;
                  configurations?: Array<
                      | {
                            __typename: 'BffConfiguratorConfigurationAttributes';
                            id: string;
                            type: string;
                            viewpoint?: string | null;
                            title?: string | null;
                            selected?: string | null;
                            values?: Array<{
                                __typename?: 'BffConfiguratorConfigurationAttributesValue';
                                sku: string;
                                title: string;
                                subtitle?: string | null;
                                selected: boolean;
                                image?: { __typename?: 'BffProductImage'; src: string } | null;
                            }> | null;
                        }
                      | {
                            __typename: 'BffConfiguratorConfigurationColor';
                            id: string;
                            type: string;
                            viewpoint?: string | null;
                            title?: string | null;
                            selected?: string | null;
                            colors?: Array<{
                                __typename?: 'BffConfiguratorConfigurationColorItem';
                                id: string;
                                colorFamilies?: Array<IConfiguratorConfigurationColorItemColorFamily> | null;
                                features?: Array<IConfiguratorConfigurationColorItemFeatures> | null;
                                material: string;
                                priceGroup?: IConfiguratorConfigurationColorItemPriceGroup | null;
                                selected: boolean;
                                sku: string;
                                sampleArticleNumber?: string | null;
                                title: string;
                                fullSizeImage?: { __typename?: 'BffProductImage'; src: string } | null;
                                listingImage?: { __typename?: 'BffProductImage'; src: string } | null;
                                attributes?: Array<
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                                          key: string;
                                          type: string;
                                          values?: Array<{
                                              __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                              key: string;
                                              type: string;
                                              textValue: string;
                                          }> | null;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                                          key: string;
                                          type: string;
                                          booleanValue: boolean;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                                          key: string;
                                          rawValue?: string | null;
                                          type: string;
                                          meterValue: number;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                                          key: string;
                                          type: string;
                                          numberValue: number;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                          key: string;
                                          type: string;
                                          textValue: string;
                                      }
                                > | null;
                            }> | null;
                            materials?: Array<{
                                __typename?: 'BffConfiguratorConfigurationColorMaterial';
                                factsheetUrl?: string | null;
                                id: string;
                                name: string;
                                text?: string | null;
                                attributes?: Array<
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                                          key: string;
                                          type: string;
                                          values?: Array<{
                                              __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                              key: string;
                                              type: string;
                                              textValue: string;
                                          }> | null;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                                          key: string;
                                          type: string;
                                          booleanValue: boolean;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                                          key: string;
                                          rawValue?: string | null;
                                          type: string;
                                          meterValue: number;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                                          key: string;
                                          type: string;
                                          numberValue: number;
                                      }
                                    | {
                                          __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                          key: string;
                                          type: string;
                                          textValue: string;
                                      }
                                > | null;
                            }> | null;
                        }
                  > | null;
              }>;
          }
        | null;
};

export type GetModularConfiguratorQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    products: Array<BffProductInputProduct> | BffProductInputProduct;
}>;

export type GetModularConfiguratorQuery = {
    __typename?: 'Query';
    getModularConfigurator?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetModularConfiguratorSuccess';
              data: {
                  __typename?: 'BffConfiguratorModular';
                  name: string;
                  categories?: Array<{
                      __typename?: 'BffConfiguratorModularCategories';
                      id: string;
                      name: string;
                      quantity?: number | null;
                  }> | null;
                  products?: Array<{
                      __typename?: 'BffConfiguratorModularProduct';
                      name: string;
                      sku: string;
                      quantity?: number | null;
                      category?: string | null;
                      image?: { __typename?: 'BffProductImage'; src: string } | null;
                      price?: {
                          __typename?: 'BffPrice';
                          amount?: number | null;
                          centAmount?: number | null;
                          currencyCode?: string | null;
                      } | null;
                      configurations?: Array<
                          | {
                                __typename: 'BffConfiguratorConfigurationAttributes';
                                id: string;
                                type: string;
                                viewpoint?: string | null;
                                title?: string | null;
                                selected?: string | null;
                                values?: Array<{
                                    __typename?: 'BffConfiguratorConfigurationAttributesValue';
                                    id: string;
                                    sku: string;
                                    title: string;
                                    subtitle?: string | null;
                                    selected: boolean;
                                    image?: { __typename?: 'BffProductImage'; src: string } | null;
                                }> | null;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationColor';
                                id: string;
                                type: string;
                                viewpoint?: string | null;
                                title?: string | null;
                                selected?: string | null;
                                colors?: Array<{
                                    __typename?: 'BffConfiguratorConfigurationColorItem';
                                    id: string;
                                    sku: string;
                                }> | null;
                                materials?: Array<{
                                    __typename?: 'BffConfiguratorConfigurationColorMaterial';
                                    id: string;
                                }> | null;
                            }
                      > | null;
                  }> | null;
                  delivery?: { __typename?: 'BffConfiguratorDelivery'; deliveryTime: string } | null;
                  price?: {
                      __typename?: 'BffPrice';
                      amount?: number | null;
                      centAmount?: number | null;
                      currencyCode?: string | null;
                  } | null;
                  colors?: Array<{
                      __typename?: 'BffConfiguratorConfigurationColorItem';
                      id: string;
                      colorFamilies?: Array<IConfiguratorConfigurationColorItemColorFamily> | null;
                      features?: Array<IConfiguratorConfigurationColorItemFeatures> | null;
                      material: string;
                      priceGroup?: IConfiguratorConfigurationColorItemPriceGroup | null;
                      selected: boolean;
                      sku: string;
                      sampleArticleNumber?: string | null;
                      title: string;
                      fullSizeImage?: { __typename?: 'BffProductImage'; src: string } | null;
                      listingImage?: { __typename?: 'BffProductImage'; src: string } | null;
                      attributes?: Array<
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                                key: string;
                                type: string;
                                values?: Array<{
                                    __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                    key: string;
                                    type: string;
                                    textValue: string;
                                }> | null;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                                key: string;
                                type: string;
                                booleanValue: boolean;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                                key: string;
                                rawValue?: string | null;
                                type: string;
                                meterValue: number;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                                key: string;
                                type: string;
                                numberValue: number;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                key: string;
                                type: string;
                                textValue: string;
                            }
                      > | null;
                  }> | null;
                  materials?: Array<{
                      __typename?: 'BffConfiguratorConfigurationColorMaterial';
                      factsheetUrl?: string | null;
                      id: string;
                      name: string;
                      text?: string | null;
                      attributes?: Array<
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeArray';
                                key: string;
                                type: string;
                                values?: Array<{
                                    __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                    key: string;
                                    type: string;
                                    textValue: string;
                                }> | null;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeBoolean';
                                key: string;
                                type: string;
                                booleanValue: boolean;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeMeter';
                                key: string;
                                rawValue?: string | null;
                                type: string;
                                meterValue: number;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeNumber';
                                key: string;
                                type: string;
                                numberValue: number;
                            }
                          | {
                                __typename: 'BffConfiguratorConfigurationItemAttributeText';
                                key: string;
                                type: string;
                                textValue: string;
                            }
                      > | null;
                  }> | null;
                  commonConfigurations?: Array<
                      | {
                            __typename: 'BffConfiguratorConfigurationAttributes';
                            id: string;
                            type: string;
                            viewpoint?: string | null;
                            title?: string | null;
                            selected?: string | null;
                            values?: Array<{
                                __typename?: 'BffConfiguratorConfigurationAttributesValue';
                                id: string;
                                sku: string;
                                title: string;
                                subtitle?: string | null;
                                selected: boolean;
                                image?: { __typename?: 'BffProductImage'; src: string } | null;
                            }> | null;
                        }
                      | {
                            __typename: 'BffConfiguratorConfigurationColor';
                            id: string;
                            type: string;
                            viewpoint?: string | null;
                            title?: string | null;
                            selected?: string | null;
                            colors?: Array<{
                                __typename?: 'BffConfiguratorConfigurationColorItem';
                                id: string;
                                sku: string;
                            }> | null;
                            materials?: Array<{
                                __typename?: 'BffConfiguratorConfigurationColorMaterial';
                                id: string;
                            }> | null;
                        }
                  > | null;
              };
          }
        | null;
};

export type GetModularConfiguratorPresetsQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    emersyaIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetModularConfiguratorPresetsQuery = {
    __typename?: 'Query';
    getModularConfiguratorPresets?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetModularConfiguratorPresetsSuccess';
              data: Array<{
                  __typename?: 'BffConfiguratorModularPreset';
                  emersyaId: string;
                  productsAndCategories: Array<{
                      __typename?: 'BffConfiguratorModularPresetsItem';
                      skus: Array<string>;
                      categoryId: string;
                      name: string;
                      quantity?: number | null;
                      image?: { __typename?: 'BffProductImage'; src: string } | null;
                  }>;
                  image?: { __typename?: 'BffProductImage'; src: string } | null;
                  delivery?: {
                      __typename?: 'BffConfiguratorDelivery';
                      deliveryTime: string;
                      endDate?: string | null;
                      startDate?: string | null;
                  } | null;
                  price?: { __typename?: 'BffPrice'; currencyCode?: string | null; amount?: number | null } | null;
              }>;
          }
        | null;
};

export type GetProductsAndConfigurationQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetProductsAndConfigurationQuery = {
    __typename?: 'Query';
    getProductsAndConfiguration?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetProductsAndConfigurationSuccess';
              data: Array<{
                  __typename?: 'BffProduct';
                  name: string;
                  sku: string;
                  showAddToCart: boolean;
                  analytics?: any | null;
                  delivery?: { __typename?: 'BffDelivery'; type: BffDeliveryType; deliveryTime: string } | null;
                  image?: { __typename?: 'BffProductImage'; src: string } | null;
                  price?: {
                      __typename?: 'BffProductPrice';
                      currencyCode?: string | null;
                      amount?: number | null;
                  } | null;
                  configurations?: Array<{
                      __typename?: 'BffProductConfiguration';
                      id: string;
                      type: string;
                      title: string;
                      selected: string;
                      image?: { __typename?: 'BffProductImage'; src: string } | null;
                      values?: Array<{
                          __typename?: 'BffProductConfigurationValue';
                          sku: string;
                          name: string;
                          selected: boolean;
                          image?: { __typename?: 'BffProductImage'; src: string } | null;
                      }> | null;
                  }> | null;
              }>;
          }
        | null;
};

export type GetAvailabilityQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetAvailabilityQuery = {
    __typename?: 'Query';
    getAvailability?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetAvailabilitySuccess';
              data: Array<{
                  __typename?: 'BffProductAvailability';
                  sku: string;
                  isOrderable: boolean;
                  isInCart: boolean;
                  isMaterialLimitReached?: boolean | null;
              }>;
          }
        | null;
};

export type GetShoppingListQueryVariables = Exact<{
    country: Scalars['String']['input'];
    id?: InputMaybe<Scalars['String']['input']>;
    filterBySkus?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetShoppingListQuery = {
    __typename?: 'Query';
    getShoppingList?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: string;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                  delivery?: { __typename?: 'BffConfiguratorDelivery'; deliveryTime: string } | null;
                  price?: {
                      __typename?: 'BffPrice';
                      amount?: number | null;
                      centAmount?: number | null;
                      currencyCode?: string | null;
                  } | null;
              }>;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type ShareShoppingListQueryVariables = Exact<{
    country: BffCountry;
}>;

export type ShareShoppingListQuery = {
    __typename?: 'Query';
    shareShoppingList?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'BffShoppingListShareLink'; encodedShoppingListId?: string | null }
        | { __typename: 'Error'; message: string }
        | null;
};

export const CartInfoFragmentDoc = gql`
    fragment CartInfo on BffCartResult {
        id
        version
        count
        anayltics
        canSplitOrder
        isOrderSplit
        showReturnNote
        discountCode
        isBlocked
        skipPayment
        totals {
            formatted
            rate
            key
            raw
            currencyCode
        }
        custom
        payment(country: $country, language: $language)
        messages {
            type
            sku
            message
        }
        paymentAmount {
            currency
            value
        }
        cartItems {
            id
            lineItems {
                id
                sku
                name
                type
                quantity
                articleNo
                showQuantity
                isConfigurable
                showReturnNote
                orderLimit
                image {
                    src
                    width
                    height
                }
                totalPrice {
                    formatted
                    raw
                    currencyCode
                }
                messages {
                    type
                    sku
                    message
                }
            }
            shippingOption {
                id
                items {
                    key
                    description
                    price {
                        formatted
                        raw
                        currencyCode
                    }
                    selected
                }
            }
        }
    }
`;
export const ConfiguratorConfigurationItemAttributesFragmentDoc = gql`
    fragment ConfiguratorConfigurationItemAttributes on BffConfiguratorConfigurationItemAttribute {
        __typename
        ... on BffConfiguratorConfigurationItemAttributeText {
            key
            textValue: value
            type
        }
        ... on BffConfiguratorConfigurationItemAttributeArray {
            key
            values {
                __typename
                ... on BffConfiguratorConfigurationItemAttributeText {
                    key
                    textValue: value
                    type
                }
            }
            type
        }
        ... on BffConfiguratorConfigurationItemAttributeMeter {
            key
            meterValue: value
            rawValue
            type
        }
        ... on BffConfiguratorConfigurationItemAttributeNumber {
            key
            numberValue: value
            type
        }
        ... on BffConfiguratorConfigurationItemAttributeBoolean {
            key
            booleanValue: value
            type
        }
    }
`;
export const ConfiguratorConfigurationColorsFragmentDoc = gql`
    fragment ConfiguratorConfigurationColors on BffConfiguratorConfigurationColorItem {
        id
        colorFamilies
        features
        fullSizeImage {
            src
        }
        listingImage {
            src
        }
        material
        priceGroup
        selected
        sku
        sampleArticleNumber
        title
        attributes {
            ...ConfiguratorConfigurationItemAttributes
        }
    }
    ${ConfiguratorConfigurationItemAttributesFragmentDoc}
`;
export const ConfiguratorConfigurationMaterialsFragmentDoc = gql`
    fragment ConfiguratorConfigurationMaterials on BffConfiguratorConfigurationColorMaterial {
        factsheetUrl
        id
        name
        text
        attributes {
            ...ConfiguratorConfigurationItemAttributes
        }
    }
    ${ConfiguratorConfigurationItemAttributesFragmentDoc}
`;
export const ConfiguratorConfigurationFragmentDoc = gql`
    fragment ConfiguratorConfiguration on BffConfiguratorConfiguration {
        __typename
        ... on BffConfiguratorConfigurationColor {
            id
            type
            viewpoint
            title
            selected
            colors {
                ...ConfiguratorConfigurationColors
            }
            materials {
                ...ConfiguratorConfigurationMaterials
            }
        }
        ... on BffConfiguratorConfigurationAttributes {
            id
            type
            viewpoint
            title
            selected
            values {
                sku
                title
                subtitle
                selected
                image {
                    src
                }
            }
        }
    }
    ${ConfiguratorConfigurationColorsFragmentDoc}
    ${ConfiguratorConfigurationMaterialsFragmentDoc}
`;
export const ConfiguratorConfigurationIdsOnlyFragmentDoc = gql`
    fragment ConfiguratorConfigurationIdsOnly on BffConfiguratorConfiguration {
        __typename
        ... on BffConfiguratorConfigurationColor {
            id
            type
            viewpoint
            title
            selected
            colors {
                id
                sku
            }
            materials {
                id
            }
        }
        ... on BffConfiguratorConfigurationAttributes {
            id
            type
            viewpoint
            title
            selected
            values {
                id
                sku
                title
                subtitle
                selected
                image {
                    src
                }
            }
        }
    }
`;
export const AddToCartDocument = gql`
    mutation addToCart($sku: Sku!, $country: BffCountry!, $language: BffLanguage, $quantity: Int) {
        addToCart(country: $country, language: $language, sku: $sku, quantity: $quantity) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                    count
                    messages {
                        type
                        sku
                        message
                    }
                }
            }
            ... on BffCartResult {
                ...CartInfo
            }
        }
    }
    ${CartInfoFragmentDoc}
`;
export const CreateCustomerShoppingListDocument = gql`
    mutation createCustomerShoppingList(
        $country: String!
        $lineItems: [BffShoppingListLineItemInput!]!
        $filterBySkus: [String!]
    ) {
        createCustomerShoppingList(country: $country, lineItems: $lineItems, filterBySkus: $filterBySkus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                    delivery {
                        deliveryTime
                    }
                    price {
                        amount
                        centAmount
                        currencyCode
                    }
                }
            }
        }
    }
`;
export const DeleteCustomerShoppingListItemDocument = gql`
    mutation deleteCustomerShoppingListItem($country: String!, $id: String!, $filterBySkus: [String!]) {
        deleteCustomerShoppingListItem(country: $country, id: $id, filterBySkus: $filterBySkus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
export const GetConfiguratorDocument = gql`
    query getConfigurator($language: BffLanguage!, $market: BffMarket!, $skus: [String!]!) {
        getConfigurator(language: $language, market: $market, skus: $skus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetConfiguratorSuccess {
                data {
                    name
                    sku
                    showAddToCart
                    delivery {
                        type
                        deliveryTime
                    }
                    image {
                        src
                    }
                    price {
                        amount
                        currencyCode
                        centAmount
                    }
                    configurations {
                        ...ConfiguratorConfiguration
                    }
                }
            }
        }
    }
    ${ConfiguratorConfigurationFragmentDoc}
`;
export const GetModularConfiguratorDocument = gql`
    query getModularConfigurator($language: BffLanguage!, $market: BffMarket!, $products: [BffProductInputProduct!]!) {
        getModularConfigurator(language: $language, market: $market, products: $products) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetModularConfiguratorSuccess {
                data {
                    name
                    categories {
                        id
                        name
                        quantity
                    }
                    products {
                        name
                        sku
                        quantity
                        category
                        image {
                            src
                        }
                        price {
                            amount
                            centAmount
                            currencyCode
                        }
                        configurations {
                            ...ConfiguratorConfigurationIdsOnly
                        }
                    }
                    delivery {
                        deliveryTime
                    }
                    price {
                        amount
                        centAmount
                        currencyCode
                    }
                    colors {
                        ...ConfiguratorConfigurationColors
                    }
                    materials {
                        ...ConfiguratorConfigurationMaterials
                    }
                    commonConfigurations {
                        ...ConfiguratorConfigurationIdsOnly
                    }
                }
            }
        }
    }
    ${ConfiguratorConfigurationIdsOnlyFragmentDoc}
    ${ConfiguratorConfigurationColorsFragmentDoc}
    ${ConfiguratorConfigurationMaterialsFragmentDoc}
`;
export const GetModularConfiguratorPresetsDocument = gql`
    query getModularConfiguratorPresets($language: BffLanguage!, $market: BffMarket!, $emersyaIds: [String!]!) {
        getModularConfiguratorPresets(language: $language, market: $market, emersyaIds: $emersyaIds) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetModularConfiguratorPresetsSuccess {
                data {
                    emersyaId
                    productsAndCategories {
                        skus
                        categoryId
                        name
                        quantity
                        image {
                            src
                        }
                    }
                    image {
                        src
                    }
                    delivery {
                        deliveryTime
                        endDate
                        startDate
                    }
                    price {
                        currencyCode
                        amount
                    }
                }
            }
        }
    }
`;
export const GetProductsAndConfigurationDocument = gql`
    query getProductsAndConfiguration($language: BffLanguage!, $market: BffMarket!, $skus: [String!]!) {
        getProductsAndConfiguration(language: $language, market: $market, skus: $skus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetProductsAndConfigurationSuccess {
                data {
                    name
                    sku
                    showAddToCart
                    delivery {
                        type
                        deliveryTime
                    }
                    image {
                        src
                    }
                    price {
                        currencyCode
                        amount
                    }
                    analytics
                    configurations {
                        id
                        type
                        title
                        selected
                        image {
                            src
                        }
                        values {
                            sku
                            name
                            selected
                            image {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GetAvailabilityDocument = gql`
    query getAvailability($language: BffLanguage!, $market: BffMarket!, $skus: [String!]!) {
        getAvailability(language: $language, market: $market, skus: $skus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetAvailabilitySuccess {
                data {
                    sku
                    isOrderable
                    isInCart
                    isMaterialLimitReached
                }
            }
        }
    }
`;
export const GetShoppingListDocument = gql`
    query getShoppingList($country: String!, $id: String, $filterBySkus: [String!]) {
        getShoppingList(country: $country, id: $id, filterBySkus: $filterBySkus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                    delivery {
                        deliveryTime
                    }
                    price {
                        amount
                        centAmount
                        currencyCode
                    }
                }
            }
        }
    }
`;
export const ShareShoppingListDocument = gql`
    query shareShoppingList($country: BffCountry!) {
        shareShoppingList(country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingListShareLink {
                encodedShoppingListId
            }
        }
    }
`;

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string,
    variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        addToCart(
            variables: AddToCartMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<AddToCartMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AddToCartMutation>(AddToCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'addToCart',
                'mutation',
                variables
            );
        },
        createCustomerShoppingList(
            variables: CreateCustomerShoppingListMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<CreateCustomerShoppingListMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCustomerShoppingListMutation>(CreateCustomerShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'createCustomerShoppingList',
                'mutation',
                variables
            );
        },
        deleteCustomerShoppingListItem(
            variables: DeleteCustomerShoppingListItemMutationVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<DeleteCustomerShoppingListItemMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<DeleteCustomerShoppingListItemMutation>(
                        DeleteCustomerShoppingListItemDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'deleteCustomerShoppingListItem',
                'mutation',
                variables
            );
        },
        getConfigurator(
            variables: GetConfiguratorQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetConfiguratorQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetConfiguratorQuery>(GetConfiguratorDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getConfigurator',
                'query',
                variables
            );
        },
        getModularConfigurator(
            variables: GetModularConfiguratorQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetModularConfiguratorQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetModularConfiguratorQuery>(GetModularConfiguratorDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getModularConfigurator',
                'query',
                variables
            );
        },
        getModularConfiguratorPresets(
            variables: GetModularConfiguratorPresetsQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetModularConfiguratorPresetsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetModularConfiguratorPresetsQuery>(
                        GetModularConfiguratorPresetsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'getModularConfiguratorPresets',
                'query',
                variables
            );
        },
        getProductsAndConfiguration(
            variables: GetProductsAndConfigurationQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetProductsAndConfigurationQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductsAndConfigurationQuery>(GetProductsAndConfigurationDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getProductsAndConfiguration',
                'query',
                variables
            );
        },
        getAvailability(
            variables: GetAvailabilityQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetAvailabilityQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetAvailabilityQuery>(GetAvailabilityDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getAvailability',
                'query',
                variables
            );
        },
        getShoppingList(
            variables: GetShoppingListQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<GetShoppingListQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetShoppingListQuery>(GetShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getShoppingList',
                'query',
                variables
            );
        },
        shareShoppingList(
            variables: ShareShoppingListQueryVariables,
            requestHeaders?: GraphQLClientRequestHeaders
        ): Promise<ShareShoppingListQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ShareShoppingListQuery>(ShareShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'shareShoppingList',
                'query',
                variables
            );
        }
    };
}
export type Sdk = ReturnType<typeof getSdk>;

// we include all of them at once, it's not a lot of data
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { media } from '@common/styles/media';
import { css, cx } from '@linaria/core';
import { i18n } from '@lingui/core';
import { I18nProvider as DefaultI18nProvider } from '@lingui/react';
import { forwardRef, useEffect } from 'react';

import { messages as messagesDE } from './../../i18n/translations/de-DE';
import { messages as messagesEN } from './../../i18n/translations/en-US';
import { messages as messagesES } from './../../i18n/translations/es-ES';
import { messages as messagesFR } from './../../i18n/translations/fr-FR';
import { messages as messagesIT } from './../../i18n/translations/it-IT';
import { messages as messagesJA } from './../../i18n/translations/ja-JP';
import { messages as messagesNL } from './../../i18n/translations/nl-NL';

const styles = {
    container: css`
        position: relative;
        ${media.md} {
            z-index: 51;
        }
    `,
    wrapper: css`
        margin: 0 auto;
    `
};

interface BaseWidgetProps {
    children: React.ReactNode;
    className?: string;
    id?: string;
}

export const I18nProvider = ({ children }: { children: React.ReactNode }) => {
    const { language } = getBffLanguageAndCountry();
    useEffect(() => {
        i18n.load('en', messagesEN);
        i18n.load('de', messagesDE);
        i18n.load('es', messagesES);
        i18n.load('fr', messagesFR);
        i18n.load('it', messagesIT);
        i18n.load('ja', messagesJA);
        i18n.load('nl', messagesNL);
        i18n.activate(language);
    }, []);

    return <DefaultI18nProvider i18n={i18n}>{children}</DefaultI18nProvider>;
};

export const BaseWidget = forwardRef<HTMLDivElement, BaseWidgetProps>((props, ref) => {
    return (
        <div id={props.id} className={cx('base-widget', styles.container, props.className)}>
            <div ref={ref} className={styles.wrapper}>
                <I18nProvider>{props.children}</I18nProvider>
            </div>
        </div>
    );
});

BaseWidget.displayName = 'BaseWidget';

import { BffConfiguratorConfigurationColorItem, BffConfiguratorConfigurationColorMaterial } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Spinner } from '@common/primitives/spinner/spinner';
import { colorFamilies, colors as themeColors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { css } from '@linaria/core';
import { useEffect, useState } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';

import { PANEL_WIDTH } from '../panel/constants';
import { MaterialDetailPanel } from './material-detail-panel';

const styles = {
    overlay: css`
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        background-size: cover;
        background-position: center;
        background-color: ${themeColors.black};
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};
    `,
    modal: css`
        height: 100dvh;
        width: 100dvw;

        ${media.md} {
            width: auto;
            height: calc(100% - 12px * 2);
            margin: 12px;
        }
    `,
    spinnerContainer: css`
        position: absolute;
        display: flex;
        height: 100vh;
        width: 100vw;
        justify-content: center;
        align-items: center;
        transform: translateX(calc(0px - ${PANEL_WIDTH.BASE}px / 2));
    `
};

export interface MaterialDetailProps {
    activeColor?: BffConfiguratorConfigurationColorItem;
    material?: BffConfiguratorConfigurationColorMaterial;
    colorsForMaterial: BffConfiguratorConfigurationColorItem[];
    currencyCode?: string;
    onChangeActiveColor: (color: BffConfiguratorConfigurationColorItem) => void;
    onClose: () => void;
    onApplyColor: (color: BffConfiguratorConfigurationColorItem) => void;
    colorIsSelected: (color: BffConfiguratorConfigurationColorItem) => boolean;
}

export const MaterialDetail = ({
    material,
    activeColor,
    colorsForMaterial,
    currencyCode,
    onClose,
    onApplyColor,
    colorIsSelected,
    onChangeActiveColor
}: MaterialDetailProps) => {
    const { md } = useMedia();
    const isOpen = !!activeColor;
    const [imageLoaded, setImageLoaded] = useState(false);
    const colorFamily = activeColor?.colorFamilies?.[0];

    useEffect(() => {
        setImageLoaded(false);
    }, [activeColor]);

    const panel = (
        <Modal className={styles.modal}>
            <MaterialDetailPanel
                material={material}
                activeColor={activeColor}
                colorsForMaterial={colorsForMaterial}
                colorIsSelected={colorIsSelected}
                onChangeActiveColor={onChangeActiveColor}
                onClose={onClose}
                onApplyColor={onApplyColor}
                currencyCode={currencyCode}
            />
        </Modal>
    );

    if (md) {
        return (
            <ModalOverlay
                isOpen={isOpen}
                className={styles.overlay}
                style={{
                    backgroundColor: colorFamily ? colorFamilies[colorFamily] : themeColors.white,
                    backgroundImage: `url(${activeColor?.fullSizeImage?.src})`
                }}
            >
                <img
                    src={activeColor?.fullSizeImage?.src}
                    alt=""
                    style={{ display: 'none' }}
                    onLoad={() => {
                        setImageLoaded(true);
                    }}
                />

                {!imageLoaded && (
                    <div className={styles.spinnerContainer}>
                        <Spinner color={colorFamily ? '#ffffff' : undefined} />
                    </div>
                )}

                {panel}
            </ModalOverlay>
        );
    }

    return (
        <ModalOverlay isOpen={isOpen} className={styles.overlay}>
            {panel}
        </ModalOverlay>
    );
};

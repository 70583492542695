import * as React from "react";
const SvgFilterForAnimation = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 15.96, height: 13.484, ...props }, /* @__PURE__ */ React.createElement("g", { id: "Back" }, /* @__PURE__ */ React.createElement("path", { d: "M15.347 3.676a.614.614 0 0 0 0-1.227H.614a.614.614 0 0 0 0 1.227z", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.347 11.042a.614.614 0 0 0 0-1.227H.614a.614.614 0 0 0 0 1.227z", style: {
  fillRule: "evenodd"
} })), /* @__PURE__ */ React.createElement("g", { id: "CircleLower" }, /* @__PURE__ */ React.createElement("path", { d: "M3.066 8.58c1.015 0 1.842.826 1.842 1.842s-.827 1.842-1.842 1.842-1.843-.826-1.843-1.842S2.05 8.58 3.066 8.58m0-1.22a3.062 3.062 0 1 0 0 6.124 3.062 3.062 0 0 0 0-6.124" }), /* @__PURE__ */ React.createElement("path", { d: "M3.066 8.58c1.015 0 1.842.826 1.842 1.842s-.827 1.842-1.842 1.842-1.843-.826-1.843-1.842S2.05 8.58 3.066 8.58", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("g", { id: "CircleUpper" }, /* @__PURE__ */ React.createElement("path", { d: "M12.887 1.22c1.016 0 1.842.826 1.842 1.842s-.826 1.842-1.842 1.842-1.842-.826-1.842-1.842.826-1.843 1.842-1.843m0-1.219a3.062 3.062 0 1 0 0 6.123 3.062 3.062 0 0 0 0-6.123" }), /* @__PURE__ */ React.createElement("path", { d: "M12.887 1.22c1.016 0 1.842.826 1.842 1.842s-.826 1.842-1.842 1.842-1.842-.826-1.842-1.842.826-1.843 1.842-1.843", style: {
  fill: "#fff"
} })));
export default SvgFilterForAnimation;

import { client } from '@common/graphql/client';
import { BffCountry, BffLanguage } from '@common/graphql/sdk';
import { emitter } from '@common/hooks/use-events';
import { plural, t } from '@lingui/macro';

export const addToCart = async ({
    country,
    language,
    sku
}: {
    country: BffCountry;
    language: BffLanguage;
    sku: string;
}) => {
    const { addToCart } = await client.addToCart({
        country,
        language,
        sku
    });

    if (addToCart?.__typename === 'BffCartResult') {
        emitter.emit('cart:setCartCount', addToCart.count);
    }

    if (addToCart?.__typename === 'BffProxyError' || addToCart?.__typename === 'Error') {
        console.error(addToCart);
        throw new Error(addToCart.message);
    }

    return addToCart;
};

export const addSamplesToCart = async ({
    skus,
    country,
    language
}: {
    skus: string[];
    country: BffCountry;
    language: BffLanguage;
}) => {
    try {
        for (const sku of skus) {
            await addToCart({
                sku: sku,
                country,
                language
            });
        }

        return {
            __typename: 'Success',
            message: plural(skus?.length || 0, {
                one: 'We added # sample to your cart.',
                other: 'We added # samples to your cart.'
            })
        };
    } catch (error) {
        console.error(error);
        return { __typename: 'Error', message: t`There was a problem adding one or more samples to your cart` };
    }
};

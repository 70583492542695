import {
    CompareForAnimation as CompareIcon,
    FilterForAnimation as FilterIcon,
    SearchForAnimation as SearchIcon
} from '@common/icons';
import { colors } from '@common/styles/colors';
import { commonStyles } from '@common/styles/styles';
import { allColorsIds, useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { Button as DefaultButton } from 'react-aria-components';

const styles = {
    toolbarMaterials: css`
        position: relative;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
    `,
    toolbarMaterialsSurface: css`
        height: 39px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: -2;
            background: ${colors.white};
            width: 100%;
            height: 100%;
            border-radius: 90px;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        }
    `,
    toolbarMaterialsSection: css`
        position: relative;

        &:first-child {
            padding-left: 16px;
            flex: 1 0 50%;
            display: flex;
            justify-content: flex-end;
        }

        &:last-child {
            padding-right: 16px;
            flex: 1 0 50%;
            display: flex;
            justify-content: flex-start;
        }
    `,
    toolbarMaterialsSectionLarge: css`
        &:first-child {
            padding-left: 40px;
        }

        &:last-child {
            padding-right: 40px;
        }
    `,
    toolbarMaterialsBadge: css`
        position: absolute;
        background: ${colors.coral2};
        color: ${colors.white};
        line-height: normal;
        font-size: 9px;
        width: 14px;
        height: 14px;
        top: -2px;
        right: -16px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    `,
    toolbarMaterialsBadgeSearch: css`
        top: 0;
        right: -2px;
        width: 7px;
        height: 7px;
    `,
    toolbarMaterialsBadgeSearchSmall: css`
        top: 0;
        right: -2px;
    `,
    toolbarMaterialsButton: css`
        ${commonStyles.buttonReset}
        padding: 4px 12px;
        border-radius: 3px;
        display: flex;

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            outline-color: ${colors.coral2};
        }

        &[data-hovered],
        &[data-pressed] {
            color: ${colors.coral2};
        }
    `,
    toolbarMaterialsButtonLarge: css`
        width: 100%;
        padding: 0 20px;
    `,
    toolbarMaterialsButtonActive: css`
        color: ${colors.coral2};
    `,
    toolbarMaterialsButtonInner: css`
        position: relative;
        display: flex;
        min-height: 28px;
        align-items: center;
    `,
    toolbarMaterialsButtonSearch: css`
        position: relative;
        z-index: 2;
    `,
    toolbarMaterialsButtonSearchLarge: css`
        border-radius: 100%;

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            background: ${colors.white};
            width: 46px;
            height: 46px;
            border-radius: 100%;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        }
    `,
    toolbarMaterialsButtonFilterAnimation: css`
        g {
            transition: transform 0.3s ease-in-out;
            transform: translateX(0);
        }

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            g:nth-child(2) {
                transform: translateX(9px);
            }

            g:nth-child(3) {
                transform: translateX(-9px);
            }
        }
    `,
    toolbarMaterialsButtonSearchAnimation: css`
        path {
            transition: transform 0.3s ease-in-out;
            transform: scale(1);
        }

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            path:last-child {
                transform: scale(1.1);
            }
        }
    `,
    toolbarMaterialsButtonCompareAnimation: css`
        g {
            transition: transform 0.3s ease-in-out;
            transform: translateX(0);
        }

        @keyframes slide {
            0% {
                transform: translateX(0);
            }

            50% {
                transform: translateX(-3px);
            }

            100% {
                transform: translateX(3px);
            }
        }

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            g:nth-child(2) {
                animation: slide 0.6s ease-in-out forwards;
            }
        }
    `
};

interface ToolbarMaterialsProps {
    className?: string;
    filterCount?: number;
    searchBadge?: boolean;
    onFilterPress: () => void;
    onSearchPress: () => void;
    onComparePress?: () => void;
    appearance?: 'large' | 'small';
}

export const ToolbarMaterials = ({
    className,
    appearance = 'small',
    filterCount,
    searchBadge,
    ...props
}: ToolbarMaterialsProps) => {
    const secondaryStack = useModularConfigurator((state) => state.ui.secondaryStack);
    const currentSecondaryLayer = secondaryStack.at(-1);
    const isFilterLayerActive = currentSecondaryLayer === allColorsIds;

    return (
        <div className={cx('toolbar-materials', styles.toolbarMaterials, className)}>
            <div className={cx('toolbar-materials-surface', styles.toolbarMaterialsSurface)}>
                <div
                    className={cx(
                        'toolbar-materials-section',
                        styles.toolbarMaterialsSection,
                        appearance === 'large' && styles.toolbarMaterialsSectionLarge
                    )}
                >
                    <DefaultButton
                        className={cx(
                            'toolbar-materials-section-button',
                            styles.toolbarMaterialsButton,
                            styles.toolbarMaterialsButtonFilterAnimation,
                            isFilterLayerActive && styles.toolbarMaterialsButtonActive,
                            appearance === 'large' && styles.toolbarMaterialsButtonLarge
                        )}
                        onPress={props.onFilterPress}
                    >
                        <span className={styles.toolbarMaterialsButtonInner}>
                            {filterCount !== 0 && (
                                <span className={cx(styles.toolbarMaterialsBadge)}>{filterCount}</span>
                            )}
                            {appearance === 'small' && <FilterIcon />}
                            {appearance !== 'small' && <span>{t`Filter`}</span>}
                        </span>
                    </DefaultButton>
                </div>
                <div
                    className={cx(
                        'toolbar-materials-section',
                        styles.toolbarMaterialsSection,
                        appearance === 'large' && styles.toolbarMaterialsSectionLarge
                    )}
                >
                    <DefaultButton
                        className={cx(
                            'toolbar-materials-section-button',
                            styles.toolbarMaterialsButton,
                            styles.toolbarMaterialsButtonSearch,
                            styles.toolbarMaterialsButtonSearchAnimation,
                            appearance === 'large' && styles.toolbarMaterialsButtonSearchLarge
                        )}
                        onPress={props.onSearchPress}
                    >
                        <span className={styles.toolbarMaterialsButtonInner}>
                            {searchBadge && (
                                <span
                                    className={cx(
                                        styles.toolbarMaterialsBadge,
                                        appearance === 'small' && styles.toolbarMaterialsBadgeSearch,
                                        appearance === 'small' && styles.toolbarMaterialsBadgeSearchSmall
                                    )}
                                />
                            )}
                            <SearchIcon />
                        </span>
                    </DefaultButton>
                </div>
                <div
                    className={cx(
                        'toolbar-materials-section',
                        styles.toolbarMaterialsSection,
                        appearance === 'large' && styles.toolbarMaterialsSectionLarge
                    )}
                >
                    <DefaultButton
                        className={cx(
                            'toolbar-materials-section-button',
                            styles.toolbarMaterialsButton,
                            styles.toolbarMaterialsButtonCompareAnimation,
                            appearance === 'large' && styles.toolbarMaterialsButtonLarge
                        )}
                        onPress={props.onComparePress}
                    >
                        <span className={styles.toolbarMaterialsButtonInner}>
                            {appearance === 'small' && <CompareIcon />}
                            {appearance !== 'small' && <span>{t`Compare`}</span>}
                        </span>
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
};

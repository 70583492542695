import { useEffect, useRef, useState } from 'react';

interface UseElementLeavesViewportParams {
    ref: React.RefObject<HTMLElement>;
    offset?: number;
    delay?: number;
    rootElementSelector: string;
}

export function useElementLeavesViewport(params: UseElementLeavesViewportParams, deps: any[] = []) {
    const { ref, offset = 0, delay = 0, rootElementSelector } = params;
    const [outOfViewPort, setOutOfViewPort] = useState(false);
    const rootElementRef = useRef<Element | null>(null);

    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        let timeoutId: NodeJS.Timeout | null = null;

        if (!rootElementSelector) {
            return;
        }

        const getRootElement = () => {
            rootElementRef.current = (document.querySelectorAll(rootElementSelector) || [])[0];
        };

        const startObserving = () => {
            if (!rootElementRef.current) {
                console.log('Root element not found');
                return;
            }
            observer = new IntersectionObserver(
                ([entry]) => {
                    setOutOfViewPort(entry.isIntersecting && entry.intersectionRatio < 1);
                },
                {
                    root: rootElementRef.current,
                    threshold: [0, 1],
                    rootMargin: `${offset}px 0px`
                }
            );

            if (ref.current) {
                observer.observe(ref.current);
            }
        };

        const stopObserving = () => {
            if (observer && ref.current) {
                observer.unobserve(ref.current);
            }
        };

        if (delay > 0) {
            timeoutId = setTimeout(() => {
                getRootElement();
                startObserving();
            }, delay);
        } else {
            getRootElement();
            startObserving();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            stopObserving();
        };
    }, deps); // Include delay in the dependency array

    return outOfViewPort;
}

import {
    BffConfiguratorConfiguration,
    BffConfiguratorDelivery,
    BffDelivery,
    BffPrice,
    BffProductImage
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { Tick } from '@common/icons';
import { Button, Tooltip, TooltipTrigger } from '@common/primitives';
import { Dots } from '@common/primitives/dots';
import { MarqueeText } from '@common/primitives/marquee-text';
import { Spinner } from '@common/primitives/spinner/spinner';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { BffConfiguratorResponseConfiguration } from '@components/configurator/configurator-types';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import useEmblaCarousel from 'embla-carousel-react';
import { easeOut, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { HeaderPrice } from '../header/header';
import { TAB_HEIGHT_MOBILE } from '../panel/constants';

const styles = {
    // we need a higher width than 100% for the entry animation
    wrapper: css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 24px;
        justify-content: center;
        user-select: none;
        transform-origin: center;
        align-self: center;
    `,
    wrapperLarge: css`
        height: calc(100dvh - ${TAB_HEIGHT_MOBILE}px);

        ${media.md} {
            width: 120%;
        }
    `,
    spinnerEdit: css`
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
    `,
    spinner: css`
        align-self: center;

        /* same as the edit buttton height */
        height: 37.8px;
    `,
    sliderWrapper: css`
        overflow: hidden;
    `,
    sliderContainer: css`
        display: flex;
        gap: 0 100px;
        width: 100%;
        container-type: inline-size;
    `,
    slide: css`
        flex: 0 0 100%;
        transition: transform 0.3s ease-in-out;

        @container (min-width: 480px) {
            flex: 0 0 33%;
            display: flex;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
    `,
    sliderPagination: css`
        margin-bottom: 30px;
        margin-top: 12px;
    `,
    sliderText: css`
        text-align: center;
    `,
    sliderTextHeading: css`
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
    `,
    sliderTextBody: css`
        color: #333;
        font-weight: 400;
        margin-bottom: 22px;
        font-size: 13px;
    `,
    sliderTextButton: css`
        color: ${colors.black};
    `,
    sliderTextSeparator: css`
        display: inline-block;
        width: 1px;
        height: 18px;
        background: #d9d9d9;
        margin: 0 8px;
        transform: translateY(2px);
    `,
    sliderHeaderText: css`
        font-size: 16px;
        line-height: 130%;
    `,
    sliderTextTooltip: css`
        display: flex;
        gap: 9px;
        align-items: center;
    `
};

export type ConfiguratorRecommendation = {
    id: string;
    name: string;
    image?: BffProductImage;
    delivery?: BffDelivery | BffConfiguratorDelivery | null;
    price?: BffPrice | null;
    configurations?: BffConfiguratorConfiguration[] | BffConfiguratorResponseConfiguration[] | null;
};

type ConfiguratorRecommendationsProps = {
    isLoadingSharedConfiguration?: boolean;
    recommendations: ConfiguratorRecommendation[];
    onEdit?: (item: ConfiguratorRecommendation) => void;
    onSelect?: (item: ConfiguratorRecommendation) => void;
};

const preloadImage = async (src: string) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.addEventListener('load', function () {
            resolve(img);
        });
        // we always resolve, otherwise it does not show the widget if an image fails to load
        img.addEventListener('error', function () {
            resolve(img);
        });
        img.src = src;
    });
};

export const Recommendations: React.FC<ConfiguratorRecommendationsProps> = ({
    isLoadingSharedConfiguration,
    recommendations,
    onEdit,
    onSelect
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ containScroll: false, loop: false });
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const handleSlideSelect = (index: number) => {
        setCurrentSlide(index);
        onSelect?.(recommendations[index]);
    };

    const handleSlideClick = (index: number) => () => {
        if (currentSlide === index) {
            onEdit?.(recommendations[index]);
        } else {
            emblaApi?.scrollTo(index);
        }
    };

    const handleEdit = () => {
        onEdit?.(recommendations[currentSlide]);
    };

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('select', () => {
                const ix = emblaApi.selectedScrollSnap();
                handleSlideSelect(ix);
            });
        }
    }, [emblaApi]);

    const [assetsLoaded, setAssetsLoaded] = useState(false);
    useEffect(() => {
        let isCancelled = false;
        const run = async () => {
            if (isCancelled) {
                return;
            }
            // Preload images
            await Promise.all(
                recommendations
                    .filter((item) => !!(item.image && item.image.src))
                    .map((item) => item.image!.src)
                    .map(preloadImage)
            );
            if (isCancelled) {
                return;
            }
            setAssetsLoaded(true);
        };
        run();

        return () => {
            isCancelled = true;
        };
    }, []);

    if (recommendations.length === 0) {
        return null;
    }
    if (!assetsLoaded) {
        return (
            <div className={styles.wrapper}>
                <Spinner className={styles.spinner} />
            </div>
        );
    }

    const selectedRecommenaton = recommendations[currentSlide];
    const price = selectedRecommenaton?.price;
    const shipping = selectedRecommenaton?.delivery?.deliveryTime;
    const { includingTax } = getBffLanguageAndCountry();
    return (
        <motion.div
            className={cx(styles.wrapper, styles.wrapperLarge)}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 1.1, opacity: 0 }}
            transition={{ duration: 0.35, ease: easeOut }}
            layout
        >
            <div className={styles.sliderWrapper} ref={emblaRef}>
                <div className={styles.sliderContainer}>
                    {recommendations.map((recommentaion, index) => (
                        <div key={index} className={styles.slide} onClick={handleSlideClick(index)} aria-hidden="true">
                            {recommentaion.image && (
                                <img
                                    alt={recommentaion.id}
                                    style={{
                                        width: '100%',
                                        display: 'inline-flex',
                                        aspectRatio: '1'
                                    }}
                                    src={recommentaion.image.src}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.sliderPagination}>
                <Dots
                    areaLabel={t`Recommendations`}
                    onPress={emblaApi?.scrollTo}
                    items={recommendations.map((item) => item.id)}
                    current={currentSlide}
                />
            </div>

            <div className={styles.sliderText}>
                <h2 className={styles.sliderTextHeading}>
                    <MarqueeText active={true}>{selectedRecommenaton.name}</MarqueeText>
                </h2>
                <div className={styles.sliderTextBody}>
                    <TooltipTrigger>
                        <div className="header-body">
                            {price && <HeaderPrice price={price} />}
                            {price && shipping && <span className={styles.sliderTextSeparator} />}
                            <span className={styles.sliderHeaderText}>{shipping}</span>
                        </div>
                        <Tooltip>
                            <span className={cx(styles.sliderTextTooltip)}>
                                <Tick color={colors.coral2} />

                                {!includingTax && <Trans>Excluding tax</Trans>}
                                {includingTax && <Trans>Including tax</Trans>}

                                <span className={cx(styles.sliderTextSeparator)} />
                                <span>
                                    <Trans>Excluding shipping</Trans>
                                </span>
                            </span>
                        </Tooltip>
                    </TooltipTrigger>
                </div>
                {isLoadingSharedConfiguration ? (
                    <div className={styles.spinnerEdit}>
                        <Spinner className={styles.spinner} />
                    </div>
                ) : (
                    <Button className={styles.sliderTextButton} rounded onPressStart={handleEdit}>
                        {t`Edit`}
                    </Button>
                )}
            </div>
        </motion.div>
    );
};

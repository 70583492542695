import { OnboardingDragAndDrop } from '@common/icons';
import { media } from '@common/styles/media';
import { useModularConfigurator } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import React from 'react';

const styles = {
    viewerEmpty: css`
        position: absolute;
        inset: 0;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        color: #a9a9a9;
        font-size: 26px;
        line-height: 139%;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;

        ${media.md} {
            flex-direction: column;
        }
    `,
    viewerEmptyIcon: css`
        transform: rotate(90deg);

        ${media.md} {
            transform: rotate(0);
        }
    `
};
export const ViewerModularEmpty: React.FC = () => {
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const tab = useModularConfigurator((state) => state.ui.tab);
    const isTopLevel = (primaryStack.length === 1 && primaryStack[0] === 'add-element') || primaryStack.length === 0;

    const dragOverlayPayload = useModularConfigurator((state) => state.ui.dragOverlayPayload);

    if (isTopLevel && !['presets'].includes(tab ?? '')) {
        return (
            <div className={styles.viewerEmpty}>
                <Trans>Add a platform element to get started</Trans>
            </div>
        );
    }

    return (
        <div className={styles.viewerEmpty}>
            <div className={styles.viewerEmptyIcon}>
                <OnboardingDragAndDrop height={64} width={64} />
            </div>
            {dragOverlayPayload ? t`Drop it here` : t`Drag it here`}
        </div>
    );
};

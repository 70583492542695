import { BffConfiguratorConfigurationAttributes } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { unmarshalSKU } from '@common/utils/sku';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { Panel } from '@components/configurator/components/panel';
import { makeSkuFromNode, useModularConfigurator, useViewer } from '@components/configurator/hooks';
import { useEffect, useState } from 'react';

export const LayerModularAttribute = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const nodeAndConfiguration = currentLayer?.replace('change-node-attribute-', '') || '';
    const [nodeId, configurationIx] = nodeAndConfiguration.split('-');
    const getProductByNodeLocalId = useModularConfigurator((state) => state.getProductByNodeLocalId);
    const setModularConfiguratorFurnitureProductConfiguration = useViewer(
        (state) => state.setModularConfiguratorFurnitureProductConfiguration
    );
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);

    const activeData = useModularConfigurator((state) => state.activeData)!;
    const product = getProductByNodeLocalId(nodeId ?? '');

    const configurationIndex = Number.parseInt(configurationIx, 10);
    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];
    // Duplicate the allow optimistic updates
    const [activeSku, setActiveSKU] = useState<string | null>(null);

    useEffect(() => {
        setActiveSKU(product?.sku ?? null);
    }, [product?.sku]);

    if (!product) {
        return null;
    }

    const currentNodeSKU = makeSkuFromNode(product?.node);

    const configuration = (activeData?.products || [])
        .find((p) => p.sku === currentNodeSKU)
        ?.configurations?.at(configurationIndex) as BffConfiguratorConfigurationAttributes;

    if (!configuration) {
        return null;
    }

    const onAppplyConfiguration = (sku: string) => {
        const { configurationId } = unmarshalSKU(sku);
        setIsBlocked(true);
        setActiveSKU(sku);
        setModularConfiguratorFurnitureProductConfiguration(product.node.localId, configurationId!);
    };

    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {configuration.values?.map(({ sku, title, image, subtitle }) => {
                return (
                    <ButtonTile.Item key={sku} direction={tileDirection}>
                        <ButtonTile.Surface
                            direction={tileDirection}
                            onClick={() => onAppplyConfiguration(sku)}
                            selected={activeSku === sku}
                        >
                            <ButtonTile.Image size={thumbnailSize} direction={tileDirection} image={image as any} />
                            <ButtonTile.Text title={title} direction={tileDirection} size="large" subtitle={subtitle} />
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                );
            })}
        </ButtonTile.List>
    );

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" isDisabled={isBlocked} onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{configuration.title}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation
                kind="back"
                isDisabled={isBlocked}
                onPress={popLayer}
                appearance="mobile"
                title={configuration.title as string}
            />
            <Panel.ScrollArea direction="horizontal">{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

import { useMedia } from '@common/hooks/use-media';
import { MobileHeaderBack } from '@common/icons';
import { Skeleton } from '@common/primitives/skeleton';
import { media } from '@common/styles/media';
import { AddToCartButton, ButtonIcon, Header } from '@components/configurator';
import { useConfigurator } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { t } from '@lingui/macro';

const styles = {
    header: css`
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 12px 10px;
        height: 70px;
        gap: 8px;
        position: relative;

        ${media.md} {
            padding: 26px 0 0 26px;
        }
    `,
    headerInner: css`
        overflow: hidden;
    `,
    headerAddToCart: css`
        margin-left: auto;
    `,
    headerSkeleton: css`
        display: flex;
        flex: 1;
        flex-direction: column;
        z-index: 1;
        gap: 8px;
    `
};

export const ConfiguratorStandardHeader = () => {
    const data = useConfigurator((state) => state.data);

    const { md } = useMedia();
    const setIsModalOpen = useConfigurator((state) => state.setIsModalOpen);

    const skeleton = (
        <div className={styles.headerSkeleton}>
            <Skeleton width={200} height={20} />
            <Skeleton width={140} height={20} />
        </div>
    );

    if (md) {
        return (
            <div className={styles.header}>
                {data ? (
                    <Header name={data.name} price={data.price} shipping={data.delivery?.deliveryTime} />
                ) : (
                    skeleton
                )}
            </div>
        );
    }

    return (
        <div className={styles.header}>
            <ButtonIcon
                aria-label={t`Close`}
                variant="ghost"
                size="xs"
                icon={<MobileHeaderBack />}
                onPress={() => setIsModalOpen(false)}
            />

            {data ? (
                <>
                    <Header
                        className={styles.headerInner}
                        name={data.name}
                        price={data.price}
                        shipping={data.delivery?.deliveryTime}
                    />
                    <AddToCartButton sku={data.sku} className={styles.headerAddToCart} />
                </>
            ) : (
                skeleton
            )}
        </div>
    );
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import {
    Tab as DefaultTab,
    TabList as DefaultTabList,
    TabPanel as DefaultTabPanel,
    Tabs as DefaultTabs,
    TabPanelProps,
    TabProps,
    TabsProps
} from 'react-aria-components';

const styles = {
    tab: css`
        padding: 8px;
        position: relative;
        cursor: pointer;
        outline: none;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;

        &[data-selected] {
            color: ${colors.coral2};
        }

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
        }
    `,
    tabs: css`
        display: flex;
        flex: 1;
        height: 100%;

        &[data-orientation='horizontal'] {
            flex-direction: column;
        }
    `,
    tablist: css`
        display: flex;
        justify-content: space-around;
    `,
    tabPanel: css`
        height: 100%;
    `
};

export const Tab = (props: TabProps) => {
    return <DefaultTab {...props} className={cx('tab', styles.tab)} />;
};

export const Tabs = (props: TabsProps) => {
    return <DefaultTabs {...props} className={cx('tabs', styles.tabs)} />;
};

interface TabsPropsExtended extends TabsProps {
    className?: string;
}

interface TabPanelPropsExtended extends TabPanelProps {
    className?: string;
}

export const TabList = (props: TabsPropsExtended) => {
    return <DefaultTabList {...props} className={cx('tab-list', styles.tablist, props.className)} />;
};

export const TabPanel = (props: TabPanelPropsExtended) => {
    return <DefaultTabPanel {...props} className={cx('tab-panel', styles.tabPanel, props.className)} />;
};

import { useEvents } from '@common/hooks/use-events';
import { useMedia } from '@common/hooks/use-media';
import { media } from '@common/styles/media';
import { WidgetConfigurator } from '@components/configurator/configurator-types';
import { useConfigurator, useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { useInView } from 'framer-motion';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';

import { ConfiguratorStandard } from './configurator-standard';

const styles = {
    widget: css`
        height: 100dvh;
        ${media.md} {
            max-height: 1050px;
        }
    `
};
export const ConfiguratorStandardWidget = (props: WidgetConfigurator) => {
    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true });
    const { md } = useMedia();
    const isModularProduct = useModularConfigurator((state) => state.isModularProduct);
    const initWidget = useConfigurator((state) => state.initWidget);

    const [sharedConfigutationId, setSharedConfigurationId] = useState<string | undefined>();

    // Init Widget - Save the basic widget data
    useEffect(() => {
        initWidget({ articleNo: props.content.product.id });
    }, []);

    // Location params
    // productId: the product id to load
    // noScroll: if true, do not scroll to the product
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const pid = (parsed.productId || parsed.product_id || parsed.productid) as string;
        const noScroll = parsed.noScroll || false;
        const isShare = pid && isModularProduct(pid);
        if (isShare && ref.current) {
            setSharedConfigurationId(pid);
            if (!noScroll) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
    }, []);

    // Shared if from event
    const loadConfiguration = (productId: string) => {
        if (isModularProduct(productId) || !ref.current) {
            return;
        }
        setSharedConfigurationId(productId);
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };
    useEvents('global--configure', { loadConfiguration });

    const render = () => {
        if (isInView || md) {
            return <ConfiguratorStandard {...props} sharedProductId={sharedConfigutationId} />;
        }
    };

    return (
        <div ref={ref} className={cx(!isInView && !md && styles.widget)}>
            {render()}
        </div>
    );
};

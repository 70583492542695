import { Check } from '@common/icons';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { css, cx } from '@linaria/core';
import { Checkbox as DefaultCheckbox, CheckboxProps as DefaultCheckboxProps } from 'react-aria-components';

export const styles = {
    base: css`
        position: relative;

        &::before {
            content: '';
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 100%;
        }

        &[data-focus-visible]::before {
            box-shadow: 0 0 0 11px rgb(0 0 0 / 7%);
        }

        &[data-pressed] {
            > div {
                transform: scale(0.97);
                transition: transform 0.1s;
            }
        }

        ${media.md} {
            &[data-pressed] {
                > div {
                    transform: none;
                }
            }
        }

        &[data-focus-visible][data-pressed]::before,
        &[data-focus-visible][data-selected]::before {
            box-shadow: 0 0 0 11px rgb(239 107 107 / 12%);
        }

        &[data-hovered] {
            > div {
                background-color: #e6e6e6;
            }

            svg {
                color: rgb(16 16 16 / 10%);
            }
        }

        &[data-selected],
        &[data-selected][data-hovered] {
            > div,
            &[data-pressed] > div {
                background-color: ${colors.coral2};
            }

            svg {
                color: ${colors.white};
            }
        }
    `,
    checkbox: css`
        width: 18px;
        height: 18px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #f5f5f5;
        transition:
            color 0.1s,
            background-color 0.1s;
        z-index: 1;
    `,
    svg: css`
        fill: none;
        color: transparent;
    `
};

interface CheckboxProps extends DefaultCheckboxProps {
    children?: React.ReactNode;
}

export const Checkbox = ({ children, ...props }: CheckboxProps) => {
    return (
        <DefaultCheckbox {...props} className={cx(styles.base)}>
            <div className={cx(styles.checkbox)}>
                <Check className={cx(styles.svg)} />
            </div>
            {children}
        </DefaultCheckbox>
    );
};

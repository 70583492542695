import { useMedia } from '@common/hooks/use-media';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import { useModularConfigurator } from '@components/configurator/hooks';

/**
 *  This layer renders the nodes which are already in the scene
 *  Its not accessed via th builder flow
 */
export const LayerModularCategoryNode = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const pushLayer = useModularConfigurator((state) => state.pushLayer);
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const currentNodeId = currentLayer?.replace('change-node-overview-', '');

    const getProductByNodeLocalId = useModularConfigurator((state) => state.getProductByNodeLocalId);
    const activeData = useModularConfigurator((state) => state.activeData)!;
    const product = getProductByNodeLocalId(currentNodeId ?? '');

    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    if (!product) {
        console.log(`NO PRODUCT with node id ${currentNodeId}. Maybe it has been deleted via the context menu?`);
        popLayer();
        return null;
    }

    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {product?.configurations.map((configuration, ix) => {
                if (configuration.__typename === 'BffConfiguratorConfigurationColor') {
                    const selected = configuration.colors?.find((item) => {
                        return item.sku === product.sku;
                    });
                    const selectedColor = (activeData.colors || []).find((color) => color.id === selected?.id);
                    const material = (activeData.materials || []).find((m) => m.id === selectedColor?.material);
                    const selectedName = [material?.name, selectedColor?.title].join(': ');

                    return (
                        <ButtonTile.Item key={configuration.id} direction={tileDirection}>
                            <ButtonTile.Surface
                                direction={tileDirection}
                                onClick={() => {
                                    pushLayer(`change-node-colors-${product.node.localId}-${ix}`);
                                }}
                            >
                                <ButtonTile.Image
                                    size={thumbnailSize}
                                    direction={tileDirection}
                                    image={selectedColor?.listingImage as ButtonTileImageImage}
                                />
                                <ButtonTile.Text
                                    direction={tileDirection}
                                    title={configuration.title}
                                    size="large"
                                    subtitle={selectedName}
                                />
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                }
                if (configuration.__typename === 'BffConfiguratorConfigurationAttributes') {
                    const selected = configuration.values?.find((item) => item.sku === product.sku);
                    return (
                        <ButtonTile.Item key={configuration.id} direction={tileDirection}>
                            <ButtonTile.Surface
                                direction={tileDirection}
                                onClick={() => {
                                    pushLayer(`change-node-attribute-${product.node.localId}-${ix}`);
                                }}
                            >
                                <ButtonTile.Image
                                    direction={tileDirection}
                                    size={thumbnailSize}
                                    image={selected?.image as ButtonTileImageImage}
                                />
                                <ButtonTile.Text
                                    direction={tileDirection}
                                    title={configuration.title}
                                    size="large"
                                    subtitle={selected?.title}
                                />
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                }
            })}
        </ButtonTile.List>
    );

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{product.name}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation kind="back" onPress={popLayer} appearance="mobile" title={product.name} />
            <Panel.ScrollArea direction="horizontal">{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

import { BffProductImage } from '@common/graphql/sdk';
import { useIsTouchDevice } from '@common/hooks/use-is-touch-device';
import { useMedia } from '@common/hooks/use-media';
import { media } from '@common/styles/media';
import { unmarshalSKU } from '@common/utils/sku';
import { BaseWidget } from '@components/base-widget';
import { ErrorBlocking } from '@components/configurator/components/error';
import {
    EmersyaDragPayload,
    EmersyaPayloadNodeInstance
} from '@components/configurator/components/viewer/viewer-types';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { WidgetConfigurator } from '@components/configurator/configurator-types';
import { useModularConfigurator } from '@components/configurator/hooks';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import {
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { styles as buttonStyles } from '@primitives/button/button';
import hero from '@static/images/modular-widget-hero.jpg';
import { useEffect, useRef, useState } from 'react';
import { Button as DefaultButton, Modal, ModalOverlay } from 'react-aria-components';
import { useEventListener } from 'usehooks-ts';

import { ConfiguratorModularHeader } from './configurator-modular-header';
import { ConfiguratorModularPanels } from './configurator-modular-panels';
import { ConfiguratorModularPresets } from './configurator-modular-presets';
import { ConfiguratorModularViewer } from './configurator-modular-viewer';

export interface DragPayload {
    article: string;
    configuration: string;
    image: BffProductImage;
    dragId: UniqueIdentifier;
}

const styles = {
    configuratorWrapper: css`
        margin-bottom: 3.8rem;
        overflow: hidden;

        > h2 {
            font-weight: 100;
            text-align: center;
            font-size: 1.882rem;
            line-height: 3.8rem;
            padding-bottom: 1.9rem;

            ${media.md} {
                padding-bottom: 52px;
                font-size: 2.942rem;
                line-height: 3.8rem;
            }
        }
    `,
    configuratorWrapperDoubleMargin: css`
        margin-bottom: 7.6rem;
    `,
    configuratorWrapperNoMargin: css`
        margin-bottom: 0;
    `,
    configuratorContainer: css`
        width: 100dvw;
        height: 100dvh;
        overflow: hidden;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #f5f5f5;

        ${media.md} {
            width: 100%;
            position: relative;
            height: 100dvh;
            max-height: 800px;
            max-width: 1600px;
            margin: auto;
            flex: 1;
        }
    `,
    configuratorContent: css`
        min-height: 612px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f5f5f5;
        padding: 64px 0;

        ${media.md} {
            padding: 0;
            height: 100vh;
            max-height: 800px;
        }
    `,
    configuratorInner: css`
        display: flex;
        flex-direction: column;
    `,
    configuratorImage: css`
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: #f5f5f5;
        mix-blend-mode: multiply;
    `,
    configuratorImageWrapper: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    configuratorTextContent: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;
        background: #f5f5f5;
        justify-content: center;

        .header-body,
        .header-title {
            align-self: center;
        }
    `,
    configuratorButton: css`
        padding: 14px 21px;
        background-color: #333;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%;
        letter-spacing: 1.96px;
        text-transform: uppercase;

        &[data-pressed] {
            background-color: #666;
        }
    `,
    configuratorModal: css`
        position: fixed;
        inset: 0;
        display: flex;
        background-color: #f5f5f5;
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX};
    `,
    configuratorErrorModal: css`
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        z-index: 1000000;
    `
};

type ConfiguratorModularFullProps = WidgetConfigurator & { sharedProductId?: string };

export const ConfiguratorModular = (props: ConfiguratorModularFullProps) => {
    const { id, content, sharedProductId } = props;
    const isTouchDevice = useIsTouchDevice(); // // Drag refs
    const activeDrag = useRef<EmersyaDragPayload | null>(null);
    const isModalOpen = useModularConfigurator((state) => state.ui.isModalOpen);
    const { md } = useMedia();

    const storeScrollTop = useRef(0);
    useEffect(() => {
        const body = document.querySelector('body');
        if (isModalOpen) {
            storeScrollTop.current = window ? window.scrollY : 0;
        }
        body!.style.overflow = isModalOpen ? 'hidden' : 'auto';
        body!.style.position = isModalOpen ? 'fixed' : 'initial';
        return () => {
            body!.style.overflow = 'auto';
            body!.style.position = 'initial';
            body!.style.overscrollBehaviorY = 'none';
            if (!isModalOpen || !window) return;
            window!.scrollTo(0, storeScrollTop.current);
        };
    }, [isModalOpen]);

    // Sensors for drag and drop
    const keyboardSensor = useSensor(KeyboardSensor);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: { y: md ? 0 : 30, x: md ? 10 : 0 }
        }
    });

    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            distance: { y: md ? 0 : 30, x: md ? 10 : 0 }
        }
    });

    const baseSensor = isTouchDevice ? touchSensor : mouseSensor;
    const sensors = useSensors(baseSensor, keyboardSensor);

    // Viewer State
    const modularGetAllProducts = useViewer((state) => state.modularGetAllProducts);
    const loadModularViewer = useViewer((state) => state.loadModularViewer);
    const startModularConfiguratorProductDrop = useViewer((state) => state.startModularConfiguratorProductDrop);
    const stopModularConfiguratorProductDrop = useViewer((state) => state.stopModularConfiguratorProductDrop);
    const modularLoadSharedConfiguration = useViewer((state) => state.modularLoadSharedConfiguration);
    const onSetTab = useModularConfigurator((state) => state.onSetTab);
    const updateModularConfiguratorProductDragPosition = useViewer(
        (state) => state.updateModularConfiguratorProductDragPosition
    );
    const recenterViewpoint = useViewer((state) => state.recenterViewpoint);

    // Configurator State
    const skusOrQuanityChangedInNodes = useModularConfigurator((state) => state.skusOrQuanityChangedInNodes);
    const modularConfiguratorAllProductsGet = useModularConfigurator(
        (state) => state.modularConfiguratorAllProductsGet
    );
    const triggerGhostedProductNodes = useModularConfigurator((state) => state.triggerGhostedProductNodes);
    const initRecommendations = useModularConfigurator((state) => state.initRecommendations);
    const updateProductsFromViewer = useModularConfigurator((state) => state.updateProductsFromViewer);
    const setSelectedProductSlot = useModularConfigurator((state) => state.setSelectedProductSlot);
    const setTrackedProductNodes = useModularConfigurator((state) => state.setTrackedProductNodes);
    const updateDragOverlay = useModularConfigurator((state) => state.updateDragOverlay);
    const setIsModalOpen = useModularConfigurator((state) => state.setIsModalOpen);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const nodes = useModularConfigurator((state) => state.nodes);
    const selectedProduct = useModularConfigurator((state) => state.selectedProduct);

    const destroyViewer = useViewer((state) => state.destroyViewer);
    const setZoomProportion = useViewer((state) => state.setZoomProportion);

    const baseWidgetRef = useRef<HTMLDivElement>(null);
    const blockingError = useModularConfigurator((state) => state.ui.blockingError);
    const history = useModularConfigurator((state) => state.history);
    const clearHistory = useModularConfigurator((state) => state.clearHistory);
    const hasHistory = history.length > 1;
    const removeHistory = useModularConfigurator((state) => state.removeHistory);
    const setModularConfiguratorScene = useViewer((state) => state.setModularConfiguratorScene);
    const initialData = useModularConfigurator((state) => state.initialData);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const initialized = useModularConfigurator((state) => state.ui.initialized);

    // update UI state (immediately via useState)
    const [hasProducts, setHasProducts] = useState(false);

    // Recenter viewport when the number of products changed
    useEffect(() => {
        if (history.at(-1)?.allNodes.length !== history.at(-2)?.allNodes.length && !selectedProduct) {
            // recenter only if no product is selected
            recenterViewpoint({ border: md ? 0.25 : 0.05 });
        }
    }, [history]);

    useEffect(() => {
        triggerGhostedProductNodes();
        recenterViewpoint({ border: 0.15, nodeLocalIds: selectedProduct?.localIds });
    }, [selectedProduct]);

    useEffect(() => {
        const loadViewer = async () => {
            await loadModularViewer(
                {
                    // Register all eventlisteners to the viewer
                    onModularConfiguratorSceneUpdated: async (data) => {
                        if (!skusOrQuanityChangedInNodes(data.allNodes)) {
                            recenterViewpoint({ border: md ? 0.25 : 0.05 });
                            return;
                        }

                        setIsBlocked(true); // unblock the ui
                        setHasProducts(data.allNodes.length > 1); // update the ui
                        await updateProductsFromViewer(data); // update the ui
                        await modularGetAllProducts(); // get the new product restriction
                        triggerGhostedProductNodes(); // Only remove this code if deleting itself resets the ghosted nodes
                        setIsBlocked(false); // unblock the ui
                    },
                    onModularConfiguratorAllProductsGet: modularConfiguratorAllProductsGet,
                    onModularConfiguratorSlotSelected: (data) => {
                        setSelectedProductSlot(data);
                    },
                    onModularConfiguratorSlotDeselected: () => {
                        setSelectedProductSlot(null);
                    },
                    onProductNodeInstanceTrackingUpdate: (data: { nodes: EmersyaPayloadNodeInstance[] }) => {
                        if (data.nodes.length > 0) {
                            setTrackedProductNodes(data.nodes);
                        }
                    },
                    onEndCameraAnimation: () => {
                        setZoomProportion();
                    },
                    onEndCameraInteraction: () => {
                        setZoomProportion();
                    }
                },
                nodes
            );
        };

        (isModalOpen || md) && loadViewer();

        const destroy = async () => {
            await destroyViewer();
            clearHistory();
        };

        return () => {
            if (isModalOpen) {
                destroy();
            }
        };
    }, [isModalOpen, md]);

    // Initalizer, load from Emersya ID or initRecommendations
    useEffect(() => {
        const loadPresets = async () => {
            if (content) {
                await initRecommendations(content.recommendations);
            }
        };

        // Start Up Routine
        // either load from share link or initRecommendations
        const loadOnStart = async () => {
            let emersyaIdOnLoad = '';
            if (sharedProductId) {
                const skuData = unmarshalSKU(sharedProductId as string);
                emersyaIdOnLoad = skuData.configurationId || '';
            }

            // desktop
            if (md) {
                if (!initialData) {
                    return;
                }
                if (sharedProductId && emersyaIdOnLoad) {
                    setIsBlocked(true);
                    await modularLoadSharedConfiguration(emersyaIdOnLoad).then(() => {
                        onSetTab('builder');
                        loadPresets();
                    });
                    setIsBlocked(false);
                } else {
                    loadPresets();
                }
                return;
            }

            // on mobile we first need to open the modal
            if (sharedProductId && emersyaIdOnLoad) {
                setIsModalOpen(true);
                if (!initialData) {
                    // wait for the configurator to load
                    return;
                }
                setIsBlocked(true);
                await modularLoadSharedConfiguration(emersyaIdOnLoad).then(() => {
                    onSetTab('builder');
                    loadPresets();
                });
                setIsBlocked(false);
            } else {
                loadPresets();
            }
        };

        loadOnStart();
    }, [initialized, sharedProductId]);

    const onTouchMove = (event: TouchEvent) => {
        if (activeDrag.current) {
            const touch = event.touches[0];
            const x = touch.clientX;
            const y = touch.clientY;
            updateModularConfiguratorProductDragPosition({ x, y: y, dragId: activeDrag.current.dragId });
        }
    };

    // Global touchmove event listener works more consistently than the touchmove event listener in the DnD context
    useEventListener('touchmove', onTouchMove);

    function handleDragStart(event: DragStartEvent) {
        const dragId = event.active.id;
        const article = event.active.data.current?.article;
        const image = event.active.data.current?.image;
        const configuration = event.active.data.current?.configuration;

        const dragPayload = {
            article,
            configuration,
            image,
            // Important: DND requires dragId to be a string, emersya expects a number
            dragId: Number.parseInt(`${dragId}`, 10)
        };

        activeDrag.current = dragPayload;
        updateDragOverlay(dragPayload);
        startModularConfiguratorProductDrop(dragPayload);
    }

    function handleDragEnd(event: DragEndEvent) {
        if (event.over && event.over.id === 'droppable' && activeDrag.current) {
            stopModularConfiguratorProductDrop(activeDrag.current);
            updateDragOverlay(null);
            activeDrag.current = null;
        } else {
            if (activeDrag.current) {
                stopModularConfiguratorProductDrop(activeDrag.current);
            }
        }
        // @todo: Should be removed anytime in the future
        // Workaround for some of the viewer events not being triggered
        // especially the context menu is broken after the first drag n drop
        setTimeout(() => {
            const resizeEvent = new Event('resize');
            window.dispatchEvent(resizeEvent);
        }, 500);
    }

    function handleDragMove(event: DragMoveEvent) {
        if (isTouchDevice) return;

        // For full keybaord navigation we must set the coordinated via the viewer apu here
        if (event.activatorEvent instanceof KeyboardEvent) {
            const x = (event.over?.rect?.width || 0) + event.delta.x;
            updateModularConfiguratorProductDragPosition({
                x,
                y: 0,
                dragId: Number.parseInt(`${event.active.id}`, 10)
            });
        }
    }

    const configuratorElement = (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            sensors={sensors}
            modifiers={isTouchDevice ? [] : [restrictToWindowEdges]}
            autoScroll={false}
        >
            <div className={cx('configurator-modular', 'configurator-fullscreen', styles.configuratorContainer)}>
                <ConfiguratorModularHeader />
                <ConfiguratorModularPanels />
                <ConfiguratorModularPresets />
                <ConfiguratorModularViewer hasProducts={hasProducts} />
            </div>
        </DndContext>
    );

    const onRevert = () => {
        const lastElement = history.at(-2);
        if (lastElement) {
            removeHistory();
            setModularConfiguratorScene(lastElement);
        }
    };

    const errorElement = blockingError ? (
        <Modal className={styles.configuratorErrorModal}>
            <ErrorBlocking
                onRevert={onRevert}
                showRevert={hasHistory}
                showReload={true}
                message={blockingError.message}
                trace={blockingError.trace}
            />
        </Modal>
    ) : null;

    if (md) {
        return (
            <BaseWidget id="configure" key={id} ref={baseWidgetRef}>
                <ModalOverlay UNSTABLE_portalContainer={baseWidgetRef.current as Element} isOpen={!!blockingError}>
                    {errorElement}
                </ModalOverlay>
                <div
                    ref={wrapperRef}
                    className={cx(
                        styles.configuratorWrapper,
                        props.options.margin === 'DoubleBottomMargin' && styles.configuratorWrapperDoubleMargin,
                        props.options.margin === 'NoBottomMargin' && styles.configuratorWrapperNoMargin
                    )}
                >
                    <h2>
                        <Trans>Configure</Trans>
                    </h2>
                    <div className={styles.configuratorContent}>{configuratorElement}</div>
                </div>
            </BaseWidget>
        );
    }

    // If it's mobile render a PDP that triggers a modal
    return (
        <BaseWidget id="configure" key={id}>
            <div
                className={cx(
                    styles.configuratorWrapper,
                    props.options.margin === 'DoubleBottomMargin' && styles.configuratorWrapperDoubleMargin,
                    props.options.margin === 'NoBottomMargin' && styles.configuratorWrapperNoMargin
                )}
            >
                <h2>
                    <Trans>Configure</Trans>
                </h2>
                <div className={styles.configuratorContent}>
                    <div className={styles.configuratorInner}>
                        <div className={styles.configuratorImageWrapper}>
                            <img className={styles.configuratorImage} src={hero} alt={''} />
                        </div>
                        <div className={styles.configuratorTextContent}>
                            <DefaultButton
                                className={cx(buttonStyles.reset, styles.configuratorButton)}
                                onPress={() => setIsModalOpen(true)}
                            >
                                <Trans>Start now</Trans>
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
            <ModalOverlay isOpen={!!blockingError}>{errorElement}</ModalOverlay>
            <ModalOverlay isOpen={isModalOpen}>
                <Modal className={cx('modular-widget-modal', styles.configuratorModal)}>{configuratorElement}</Modal>
            </ModalOverlay>
        </BaseWidget>
    );
};

import { useEffect, useState } from 'react';

export function useIsTouchDevice() {
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        function checkTouchCapability() {
            // This is from airbnbs library
            const hasTouch =
                !!(
                    typeof window !== 'undefined' &&
                    ('ontouchstart' in window ||
                        ((window as any).DocumentTouch &&
                            typeof document !== 'undefined' &&
                            document instanceof (window as any).DocumentTouch))
                ) ||
                !!(
                    typeof navigator !== 'undefined' &&
                    (navigator.maxTouchPoints || (navigator as any).msMaxTouchPoints)
                );
            if (!isTouchDevice && hasTouch) {
                setIsTouchDevice(true);
            }
        }

        // Initial check for touch capabilities
        checkTouchCapability();

        // Listen to pointer events for touch interactions and check if we identified this device as a touch device already
        // This adresses devices like a surface laptop which only report touch events when the touch screen is used the first time
        function handlePointerEvent(e: PointerEvent) {
            if (!isTouchDevice && e.pointerType === 'touch') {
                setIsTouchDevice(true);
            }
        }

        window.addEventListener('pointerdown', handlePointerEvent);

        return () => {
            window.removeEventListener('pointerdown', handlePointerEvent);
        };
    }, [isTouchDevice]);

    return isTouchDevice;
}

import { BffConfiguratorConfigurationColor, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { unmarshalSKU } from '@common/utils/sku';
import { allColorsIds, LayerId, useModularConfigurator } from '@components/configurator/hooks';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import { t } from '@lingui/macro';
import React, { useEffect } from 'react';

import { LayerConfigureColor } from '../layer-configure/layer-configure-color/layer-configure-color';

export const LayerModularColorsChangeAll = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);

    const setModularConfiguratorFurnitureProductsConfigurations = useViewer(
        (state) => state.setModularConfiguratorFurnitureProductsConfigurations
    );

    const colors = useModularConfigurator((state) => state.initialData?.colors) || [];
    const materials = useModularConfigurator((state) => state.initialData?.materials) || [];
    const filters = useModularConfigurator((state) => state.ui.filters);
    const onSetFilter = useModularConfigurator((state) => state.onSetFilter);
    const onClearFilter = useModularConfigurator((state) => state.onClearFilter);
    const clearSecondaryLayer = useModularConfigurator((state) => state.clearSecondaryLayer);
    const setSecondaryLayer = useModularConfigurator((state) => state.setSecondaryLayer);
    const getFilteredColors = useModularConfigurator((state) => state.getFilteredColors);
    const getFilterCount = useModularConfigurator((state) => state.getFilterCount);
    const allNodes = useModularConfigurator((state) => state.nodes?.allNodes);
    const activeData = useModularConfigurator((state) => state.activeData);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);

    const isCompareOpen = useModularConfigurator((state) => state.ui.isCompareOpen);
    const setIsCompareModalOpen = useModularConfigurator((state) => state.setIsCompareOpen);

    const onToggleFilterLayerById = (id?: string) => {
        if (id) {
            setSecondaryLayer(id as LayerId);
        } else {
            clearSecondaryLayer();
        }
    };

    const activeSkus = activeData?.products?.map((product) => product.sku) || [];
    const [selectedColors, setSelectedColors] = React.useState<Set<string>>(new Set());

    const getColorMapForProduct = useModularConfigurator((state) => state.getColorMapForProduct);
    const colorMap = getColorMapForProduct();

    const getSelectedColorIds = () => {
        return (activeData?.products || [])
            .flatMap((product) => {
                return (product.configurations || []).flatMap((config) => {
                    if (config.__typename === 'BffConfiguratorConfigurationColor') {
                        return config?.colors || [];
                    }
                    return [];
                });
            })
            .map((color) => color)
            .filter((color) => activeSkus.includes(color.sku))
            .map((color) => color.id);
    };

    useEffect(() => {
        setSelectedColors(new Set(getSelectedColorIds()));
    }, [activeData]);

    const filterCount = getFilterCount();
    const filteredColors = getFilteredColors().filter((color) => colorMap.has(color.id));
    const productColors = colors.filter((color) => colorMap.has(color.id));

    const updateConfiguration = async (color: BffConfiguratorConfigurationColorItem) => {
        if (!activeData || !activeData.products) {
            return;
        }
        const productsToUpdate: { localId: number; configuration: string }[] = [];
        for (const product of activeData.products) {
            // Check if we have a color configuration
            const colorConfiguration = product.configurations?.find(
                (config) => config.__typename === 'BffConfiguratorConfigurationColor'
            ) as BffConfiguratorConfigurationColor;
            if (!colorConfiguration) {
                continue;
            }
            const matchingColor = colorConfiguration.colors?.find((pcolor) => pcolor.id === color.id);
            if (matchingColor) {
                const { articleNo, configurationId } = unmarshalSKU(product.sku);
                const matchingNodes =
                    allNodes?.filter(
                        (node) => node.furnitureArticle === articleNo && node.furnitureConfiguration === configurationId
                    ) || [];

                for (const node of matchingNodes) {
                    const { configurationId } = unmarshalSKU(matchingColor.sku);
                    productsToUpdate.push({ localId: node.localId, configuration: configurationId! });
                }
            }
        }
        setIsBlocked(true);
        setSelectedColors(new Set([color.id]));
        setModularConfiguratorFurnitureProductsConfigurations(productsToUpdate);
    };

    const colorIsSelected = (color: BffConfiguratorConfigurationColorItem): boolean => {
        return selectedColors.has(color.id);
    };

    const configuration: BffConfiguratorConfigurationColor = {
        colors: productColors,
        materials,
        id: allColorsIds,
        type: 'color',
        title: t`Change all Materials`
    };

    return (
        <LayerConfigureColor
            isCompareOpen={isCompareOpen}
            currencyCode={activeData?.price?.currencyCode || 'EUR'}
            setIsCompareModalOpen={setIsCompareModalOpen}
            configuration={configuration}
            disabledBackButton={isBlocked}
            filteredColors={filteredColors}
            filterCount={filterCount}
            searchText={filters?.search || ''}
            onChangeColor={updateConfiguration}
            onClose={popLayer}
            colorIsSelected={colorIsSelected}
            onSetFilter={onSetFilter}
            onClearFilter={onClearFilter}
            onToggleFilterLayerById={onToggleFilterLayerById}
        />
    );
};

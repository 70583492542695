import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Switch as DefaultSwitch, SwitchProps as DefaultSwitchProps } from 'react-aria-components';

const styles = {
    base: css`
        display: flex;
        gap: 8px;

        &[data-pressed] {
            > div::after {
                background-color: #a9a9a9;
            }
        }

        &[data-focus-visible] > div::after {
            box-shadow:
                0 0.78px 0.78px rgb(0 0 0 / 25%),
                0 0 0 6px rgb(0 0 0 / 7%);
        }

        &[data-selected],
        &[data-selected][data-pressed] {
            > div::before {
                background: ${colors.coral1};
            }

            > div::after {
                background: ${colors.coral2};
                transform: translateX(10px);
            }
        }

        &[data-focus-visible][data-pressed],
        &[data-focus-visible][data-selected] {
            > div::after {
                box-shadow:
                    0 0.78px 0.78px rgb(0 0 0 / 25%),
                    0 0 0 6px rgb(239 107 107 / 12%);
            }
        }
    `,
    switch: css`
        width: 24px;
        height: 16px;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 9px;
            background-color: #c4c4c4;
            border-radius: 71px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background: #d9d9d9;
            box-shadow: 0 0.78px 0.78px rgb(0 0 0 / 25%);
            border-radius: 16px;
            transition: all 200ms;
            pointer-events: none;
        }
    `
};

interface SwitchProps extends DefaultSwitchProps {
    children?: React.ReactNode;
}

export const Switch = ({ children, ...props }: SwitchProps) => {
    return (
        <DefaultSwitch {...props} className={cx(styles.base)}>
            <div className={cx(styles.switch)} />
            {children}
        </DefaultSwitch>
    );
};

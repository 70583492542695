import { client } from '@common/graphql/client';
import { BffShoppingList, BffShoppingListLineItemInput } from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { emitter } from '@common/hooks/use-events';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type ShoppingListStore = {
    shoppingList: BffShoppingList | null;
    status: 'idle' | 'loading' | 'error' | 'success';
    shoppingListNotification: number; // incremented on every update, reset if the shopping list is viewed
};

type ShoppingListActions = {
    initShoppingList: (filterBySkus: string[]) => Promise<void>;
    updateShoppingList: (skus: BffShoppingListLineItemInput[], filterBySkus: string[]) => Promise<void>;
    deleteShoppingListLineItem: (lineItemId: string, filterBySkus: string[]) => Promise<void>;
    clearShoppingListNotification: () => void;
    shareShoppingList: () => Promise<string | undefined>;
};
export const useShoppingList = create<ShoppingListStore & ShoppingListActions>()(
    immer((set, _) => ({
        shoppingList: null,
        status: 'idle',
        shoppingListNotification: 0,
        initShoppingList: async (filterBySkus: string[]) => {
            set((draft) => {
                draft.status = 'loading';
            });
            const { market } = getBffLanguageAndCountry();

            const { getShoppingList } = await client.getShoppingList({
                country: market,
                filterBySkus
            });

            if (getShoppingList?.__typename === 'BffShoppingList') {
                set((draft) => {
                    draft.status = 'success';
                    draft.shoppingList = getShoppingList as BffShoppingList;
                });
            } else {
                set((draft) => {
                    draft.status = 'error';
                });
            }
        },
        updateShoppingList: async (lineItems: BffShoppingListLineItemInput[], filterBySkus: string[]) => {
            set((draft) => {
                draft.status = 'loading';
            });
            const { market } = getBffLanguageAndCountry();

            const { createCustomerShoppingList } = await client.createCustomerShoppingList({
                country: market,
                lineItems,
                filterBySkus
            });

            // @todo: error handling
            if (createCustomerShoppingList?.__typename === 'BffShoppingList') {
                set((draft) => {
                    draft.status = 'success';
                    draft.shoppingListNotification = draft.shoppingListNotification + 1;
                    draft.shoppingList = createCustomerShoppingList as BffShoppingList;
                });
            } else {
                set((draft) => {
                    draft.status = 'error';
                });
            }
            // the emitter is used to inform other components about the wishlist changes
            // e.g the wishlist count in the navigation.
            emitter.emit('wishlist:setWishlistCount', (createCustomerShoppingList as BffShoppingList).lineItems.length);
        },
        deleteShoppingListLineItem: async (lineItemId: string, filterBySkus: string[]) => {
            set((draft) => {
                draft.status = 'loading';
            });
            const { market } = getBffLanguageAndCountry();

            const { deleteCustomerShoppingListItem } = await client.deleteCustomerShoppingListItem({
                country: market,
                id: lineItemId,
                filterBySkus
            });
            // @todo: error handling
            if (deleteCustomerShoppingListItem?.__typename === 'BffShoppingList') {
                set((draft) => {
                    draft.status = 'success';
                    draft.shoppingList = deleteCustomerShoppingListItem;
                });

                // the emitter is used to inform other components about the wishlist changes
                // e.g the wishlist count in the navigation.
                emitter.emit('wishlist:setWishlistCount', deleteCustomerShoppingListItem.lineItems.length);
            } else {
                set((draft) => {
                    draft.status = 'error';
                });
            }
        },
        shareShoppingList: async () => {
            const { market, code } = getBffLanguageAndCountry();

            const { shareShoppingList } = await client.shareShoppingList({
                country: market as any
            });

            if (shareShoppingList?.__typename === 'BffShoppingListShareLink') {
                return `${window.location.protocol}//${window.location.host}/${code}/wishlist/${shareShoppingList.encodedShoppingListId}`;
            }
        },
        clearShoppingListNotification: () => {
            set((draft) => {
                draft.shoppingListNotification = 0;
            });
        }
    }))
);

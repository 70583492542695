import { Button, Spinner } from '@common/primitives';
import { Saved } from '@common/primitives/indicators/saved';
import { useModularConfigurator, useShoppingList } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

const styles = {
    button: css`
        font-size: 15px;
    `,
    buttonSpinner: css`
        width: 16px;
        height: 16px;

        span {
            opacity: 1 !important;
            max-width: 7rem !important;
        }
    `
};

interface ButtonAddToWishlistProps {
    updateShoppingList: () => Promise<void>;
    isModular?: boolean;
    children?: React.ReactNode;
    className?: string;
}

export const ButtonAddToWishlist = ({
    children,
    updateShoppingList,
    isModular = true,
    className
}: ButtonAddToWishlistProps) => {
    const shoppingList = useShoppingList((state) => state.shoppingList);
    const shoppingListStatus = useShoppingList((state) => state.status);
    const shoppingListNotification = useShoppingList((state) => state.shoppingListNotification);
    const onSetTab = useModularConfigurator((state) => state.onSetTab);
    const onClearLayer = useModularConfigurator((state) => state.onClearLayer);
    const nodes = useModularConfigurator((state) => state.nodes);

    const [buttonSuccess, setButtonSuccess] = useState(false);

    useEffect(() => {
        setButtonSuccess(shoppingListNotification > 0);
    }, [shoppingListNotification]);

    // Clear the success style when the nodes change
    useEffect(() => {
        if (buttonSuccess) {
            setButtonSuccess(false);
        }
    }, [nodes]);

    const handleOnPress = async () => {
        try {
            // The first time we add a product to the wishlist we will open the saved tab
            if (shoppingList?.lineItems?.length === 0 && isModular) {
                onClearLayer();
                onSetTab('saved');
            }
            await updateShoppingList();
            setButtonSuccess(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Button
            aria-label={t`Add to wishlist`}
            className={cx(styles.button, className)}
            onPress={handleOnPress}
            isDisabled={shoppingListStatus === 'loading'}
        >
            {shoppingListStatus === 'loading' && <Spinner className={cx(styles.buttonSpinner)} />}
            {shoppingListStatus !== 'loading' && <Saved success={buttonSuccess} />}
            <span>{children}</span>
        </Button>
    );
};

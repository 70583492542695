import { MarqueeText } from '@common/primitives/marquee-text';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import React from 'react';
import { ButtonProps as DefaultButtonProps } from 'react-aria-components';

import { useButtonTileSurfaceContext } from './button-tile-surface';

const styles = {
    tileText: css`
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        justify-content: center;
    `,
    tileTextColumn: css`
        padding: 13px 0;
        text-align: center;
        overflow: hidden;
        max-width: 100%;

        .tile-text-title {
            font-weight: 600;
        }
    `,
    tileTextTitle: css`
        font-size: 14px;
        font-weight: 700;
        line-height: 122%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-grid;
        margin-bottom: 2px;
    `,
    tileTextTitleLarge: css`
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        white-space: wrap;
    `,
    tileTextSubtitle: css`
        font-size: 13px;
        font-weight: 400;
        display: inline-grid;
    `
};

interface ButtonTileTextProps extends DefaultButtonProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    direction?: 'row' | 'column';
    size?: 'default' | 'large';
    className?: string;
    active?: boolean;
}

export const ButtonTileText = (props: ButtonTileTextProps) => {
    const { title, subtitle, direction = 'row', size = 'default', className } = props;
    const { isPressed = false } = useButtonTileSurfaceContext();

    return (
        <span className={cx('tile-text', styles.tileText, direction === 'column' && styles.tileTextColumn, className)}>
            <span
                className={cx('tile-text-title', styles.tileTextTitle, size === 'large' && styles.tileTextTitleLarge)}
            >
                <MarqueeText active={isPressed}>{title}</MarqueeText>
            </span>
            {subtitle && (
                <span className={cx('tile-text-subtitle', styles.tileTextSubtitle)}>
                    <MarqueeText active={isPressed}>{subtitle}</MarqueeText>
                </span>
            )}
        </span>
    );
};

import { colors } from '@common/styles/colors';
import { css } from '@linaria/core';

const styles = {
    container: css`
        display: flex;
        gap: 6px;
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: fit-content;
        padding: 11px 23px;
    `,
    separator: css`
        width: 0.5px;
        height: 20px;
        transform: rotate(18deg);
        flex-shrink: 0;
        background-color: #a9a9a9;
    `
};

type PanelCounterProps = {
    'aria-label': string;
    filteredValue: number;
    totalValue: number;
};

export const PanelCounter = ({ filteredValue, totalValue, ...props }: PanelCounterProps) => {
    return (
        <span className={styles.container} {...props}>
            {filteredValue} <span className={styles.separator} /> {totalValue}
        </span>
    );
};

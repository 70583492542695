import { ROW_WIDTH } from '@components/material-comparison/constants';
import { css, cx } from '@linaria/core';

const styles = {
    emptyContent: css`
        height: 7px;
        background-color: #f5f5f5;
        border-radius: 5px;
        align-self: center;
    `
};

interface TableEmptyContentProps {
    width?: string | number;
}

export const TableEmptyContent: React.FC<TableEmptyContentProps> = ({ width }) => {
    return <div className={cx(styles.emptyContent)} style={{ width: width ?? ROW_WIDTH }} />;
};

import { useMedia } from '@common/hooks/use-media';
import { Skeleton } from '@common/primitives/skeleton';
import { ButtonTile } from '@components/configurator';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { TAB_HEIGHT_DESKTOP } from '@components/configurator/components/panel/constants';
import { useModularConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { plural } from '@lingui/macro';

const styles = {
    layerPresetsSkeleton: css`
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    `,
    layerPresetsSkeletonOption: css`
        display: flex;
        flex-direction: row;
        gap: 16px;

        &:last-child {
            margin-bottom: ${TAB_HEIGHT_DESKTOP}px;
        }
    `,
    layerPresetsSkeletonText: css`
        display: flex;
        align-self: center;
        flex-direction: column;
        gap: 16px;
    `,
    lastButtonTileDesktop: css`
        &:last-child {
            margin-bottom: ${TAB_HEIGHT_DESKTOP}px;
        }
    `
};

export const LayerSkeleton = () => {
    return (
        <div className={styles.layerPresetsSkeleton}>
            <div className={styles.layerPresetsSkeletonOption}>
                <Skeleton width={82} height={82} />
                <div className={styles.layerPresetsSkeletonText}>
                    <Skeleton width={200} height={20} />
                    <Skeleton width={140} height={20} />
                </div>
            </div>
            <div className={styles.layerPresetsSkeletonOption}>
                <Skeleton width={82} height={82} />
                <div className={styles.layerPresetsSkeletonText}>
                    <Skeleton width={200} height={20} />
                    <Skeleton width={140} height={20} />
                </div>
            </div>
            <div className={styles.layerPresetsSkeletonOption}>
                <Skeleton width={82} height={82} />
                <div className={styles.layerPresetsSkeletonText}>
                    <Skeleton width={200} height={20} />
                    <Skeleton width={140} height={20} />
                </div>
            </div>
        </div>
    );
};

export const LayerOverviewModularPresets = () => {
    const uiIsLoading = useModularConfigurator((state) => state.ui.isLoading);
    const uiIsBlocked = useModularConfigurator((state) => state.ui.isBlocked);
    const presets = useModularConfigurator((state) => state.presets);
    const onSetTab = useModularConfigurator((state) => state.onSetTab);
    const pushLayer = useModularConfigurator((state) => state.pushLayer);
    const modularLoadSharedConfiguration = useViewer((state) => state.modularLoadSharedConfiguration);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);

    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';

    // We only show the sekeleton in the desktop variant.
    // THe moble version of the recommendations doesnt preview the individual products
    if (!md) {
        return;
    }

    if (uiIsBlocked || uiIsLoading || presets.state === 'loading') {
        return <LayerSkeleton />;
    }

    const { currentEmersyaId } = presets;
    if (!currentEmersyaId) {
        return null;
    }

    const currentPreset = presets?.data?.find((preset) => preset.emersyaId === currentEmersyaId);

    if (!currentPreset) {
        return null;
    }

    return (
        <ButtonTile.List direction={listDirection}>
            {currentPreset.productsAndCategories.map((category) => {
                return (
                    <ButtonTile.Item
                        className={cx(md && styles.lastButtonTileDesktop)}
                        direction={tileDirection}
                        key={category.categoryId}
                    >
                        <ButtonTile.Surface
                            immediate={true}
                            direction={tileDirection}
                            onClick={async () => {
                                setIsBlocked(true);
                                await modularLoadSharedConfiguration(currentEmersyaId);
                                onSetTab('builder');
                                pushLayer(`category-detail-${category.categoryId}`);
                                setIsBlocked(false);
                            }}
                        >
                            <ButtonTile.Image
                                direction={tileDirection}
                                size={[82, 82]}
                                image={category.image as ButtonTileImageImage}
                            >
                                <ButtonTile.ImageCount>{category.quantity}</ButtonTile.ImageCount>
                            </ButtonTile.Image>
                            {category.quantity && (
                                <ButtonTile.Text
                                    direction={tileDirection}
                                    title={category.name}
                                    subtitle={
                                        md
                                            ? plural(category.quantity, {
                                                  one: '# element',
                                                  other: '# elements'
                                              })
                                            : null
                                    }
                                />
                            )}
                            {md && <ButtonTile.Chevron />}
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                );
            })}
        </ButtonTile.List>
    );
};

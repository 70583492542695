import {
    BffConfiguratorConfiguration,
    BffConfiguratorConfigurationAttributesValue,
    BffConfiguratorConfigurationColorItem
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { ButtonTile, Panel } from '@components/configurator';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useState } from 'react';

const styles = {
    layerTitleCounter: css`
        margin-left: 8px;
        color: #a2a2a2;
        font-weight: 400;
        opacity: 0;
    `,
    layerTitleCounterVisible: css`
        opacity: 1;
    `
};

type LayerOverviewConfigureOption = {
    screenshot?: string;
    values?: BffConfiguratorConfigurationAttributesValue[];
    colors?: BffConfiguratorConfigurationColorItem[];
} & BffConfiguratorConfiguration;

const LayerOverviewConfigureOption = (props: LayerOverviewConfigureOption) => {
    const onToggleById = useConfigurator((state) => state.onToggleById);
    const { id, screenshot, selected, title, type } = props;
    const [isHovered, setIsHovered] = useState(false);
    const { md } = useMedia();

    const imageSize = md ? [80, 80] : [102, 102];
    const direction = md ? 'row' : 'column';

    let count = 0;

    if (type === 'values') {
        count = props.values?.length ?? 0;
    }

    if (type === 'colors') {
        count = props.colors?.length ?? 0;
    }

    const titleElement = md ? (
        <div>
            {title}
            <span className={cx(styles.layerTitleCounter, isHovered && styles.layerTitleCounterVisible)}>
                <Trans>{count} options</Trans>
            </span>
        </div>
    ) : (
        title
    );

    const subTitleElement = md ? selected : null;

    const handleClick = () => onToggleById(id);

    return (
        <ButtonTile.Item direction={direction}>
            <ButtonTile.Surface direction={direction} onClick={handleClick} onHoverChange={setIsHovered}>
                <ButtonTile.Image
                    direction={direction}
                    size={imageSize as [number, number]}
                    image={
                        screenshot
                            ? {
                                  src: screenshot
                              }
                            : null
                    }
                />
                <ButtonTile.Text direction={direction} title={titleElement} subtitle={subTitleElement} />
                {md && <ButtonTile.Chevron />}
            </ButtonTile.Surface>
        </ButtonTile.Item>
    );
};

export const LayerOverviewConfigure = () => {
    const { data } = useConfigurator();
    const { screenshots, screenshotFallback } = useViewer();
    const { md } = useMedia();
    const direction = md ? 'column' : 'row';

    const renderConfigurationOptions = () => {
        const items = data?.configurations?.map((option) => {
            const screenshot = screenshots.find((screenshot) => {
                return screenshot.viewpoint?.indexOf(option.viewpoint || '') !== -1;
            });
            let screenshotSrc = screenshot?.screenshot;
            if (!screenshotSrc) {
                screenshotSrc = screenshotFallback;
            }

            return (
                <LayerOverviewConfigureOption
                    key={option.id}
                    screenshot={screenshotSrc}
                    {...(option as LayerOverviewConfigureOption)}
                />
            );
        });

        return <ButtonTile.List direction={direction}>{items}</ButtonTile.List>;
    };

    if (md) {
        return renderConfigurationOptions();
    }

    return <Panel.ScrollArea direction="horizontal">{renderConfigurationOptions()}</Panel.ScrollArea>;
};

import { useMedia } from '@common/hooks/use-media';
import { AddElement } from '@common/icons';
import { Button } from '@common/primitives';
import { Spinner } from '@common/primitives/spinner/spinner';
import { media } from '@common/styles/media';
import { Panel } from '@components/configurator';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { TAB_HEIGHT_DESKTOP, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import materials from '@static/images/materials.png';

const styles = {
    container: css`
        display: flex;
        align-items: center;
        width: 100%;

        ${media.md} {
            justify-content: center;
        }
    `,
    containerEmpty: css`
        justify-content: center;
    `,
    containerEmptyDesktop: css`
        > button {
            margin-top: -${TAB_HEIGHT_DESKTOP}px;
        }
    `,
    containerEmptyMobile: css`
        > button {
            margin-top: -${TAB_HEIGHT_MOBILE / 2}px;
        }
    `,
    changeMaterialsImg: css`
        display: flex;
        justify-content: center;

        img {
            width: 60px;
            height: auto;
            place-self: center;
        }
    `
};

export const LayerOverviewModularBuilder = () => {
    const hasProducts = useModularConfigurator((state) => state.hasProducts)();
    const pushLayer = useModularConfigurator((state) => state.pushLayer);
    const isLoading = useModularConfigurator((state) => state.ui.isLoading);
    const categoriesWithProducts = useModularConfigurator((state) => state.categoriesWithProducts);
    const commonConfigurations = useModularConfigurator((state) => state.activeData?.commonConfigurations || []);
    const displayShowAllMaterials = commonConfigurations.length > 0;
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);

    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';

    if (isLoading || isBlocked) {
        return (
            <div className={cx(styles.container, styles.containerEmpty)}>
                <Spinner />
            </div>
        );
    }

    if (!hasProducts) {
        return (
            <div
                className={cx(
                    styles.container,
                    styles.containerEmpty,
                    md && styles.containerEmptyDesktop,
                    !md && styles.containerEmptyMobile
                )}
            >
                <Button rounded variant="coral" onPress={() => pushLayer('add-element')} isDisabled={isLoading}>
                    <AddElement />
                    <Trans>Add element</Trans>
                </Button>
            </div>
        );
    }

    const body = (
        <div className={styles.container}>
            <ButtonTile.List direction={listDirection}>
                <ButtonTile.Item direction={tileDirection}>
                    <ButtonTile.Surface direction={tileDirection} onClick={() => pushLayer('add-element')}>
                        <ButtonTile.ImageAddElement direction={tileDirection} />
                        {md && <ButtonTile.Text direction={tileDirection} title={<Trans>Add element</Trans>} />}
                    </ButtonTile.Surface>
                </ButtonTile.Item>
                {displayShowAllMaterials && (
                    <ButtonTile.Item direction={tileDirection}>
                        <ButtonTile.Surface
                            direction={tileDirection}
                            immediate={true}
                            onClick={() => pushLayer('change-all-overview')}
                        >
                            <ButtonTile.Image
                                size={md ? [82, 82] : [102, 102]}
                                direction={tileDirection}
                                image={{ src: materials, alt: t`Materials` }}
                                className={styles.changeMaterialsImg}
                            />
                            <ButtonTile.Text
                                direction={tileDirection}
                                title={md ? <Trans>Change Materials</Trans> : <Trans>Materials</Trans>}
                                subtitle={md && <Trans>for all elements</Trans>}
                            />
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                )}
                {categoriesWithProducts.map((category) => {
                    const lastProduct = category.products.at(-1);
                    return (
                        <ButtonTile.Item key={`${category.id}-${lastProduct?.sku}`} direction={tileDirection}>
                            <ButtonTile.Surface
                                immediate={true}
                                direction={tileDirection}
                                onClick={() => {
                                    pushLayer(`category-detail-${category.id}`);
                                }}
                            >
                                <ButtonTile.Image
                                    size={md ? [82, 82] : [102, 102]}
                                    direction={tileDirection}
                                    image={lastProduct?.image as ButtonTileImageImage}
                                >
                                    <ButtonTile.ImageCount>{category.products.length}</ButtonTile.ImageCount>
                                </ButtonTile.Image>

                                <ButtonTile.Text direction={tileDirection} title={category.name} />
                                {md && <ButtonTile.Chevron />}
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                })}
            </ButtonTile.List>
        </div>
    );

    if (md) {
        return body;
    }

    return <Panel.ScrollArea direction={'horizontal'}>{body}</Panel.ScrollArea>;
};

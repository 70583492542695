import { isNotProduction, useConfig } from '@common/hooks/use-config';
import type { RequestMiddleware } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';

import { getSdk } from './sdk';

interface AbortableOperation {
    operationName: string;
    controller: AbortController;
}
let abortStack: AbortableOperation[] = [];
export const abortRunningOperation = (operationName: string) => {
    const abort = abortStack.find((a) => a.operationName === operationName);
    if (abort) {
        abort.controller.abort();
        abortStack.splice(abortStack.indexOf(abort), 1);
    }
};

const requestMiddleware: RequestMiddleware = async (request) => {
    // needed to mock this our in storbybook
    request.url = useConfig('bff', '');
    const abort = new AbortController();
    if (request.operationName) {
        abortStack.push({ operationName: request.operationName, controller: abort });
    }
    request.signal = abort.signal;
    return request;
};

const responseMiddleware = async (response: any) => {
    if (response?.extensions?.trace) {
        if (!isNotProduction) {
            const styles = ['color: white', 'background: green'].join(';');
            const message = `${response?.extensions?.trace.operationName} - TraceId: ${response?.extensions?.trace.traceId} - Duration: ${response?.extensions?.trace.duration}}`;
            console.log('%c%s', styles, message);
        }
        response.data.trace = response?.extensions?.trace;
    }

    // clear the abort stack
    abortStack = [];
};

export const gqlClient = new GraphQLClient(useConfig('bff', ''), {
    requestMiddleware,
    responseMiddleware,
    credentials: 'include'
});

export const client = getSdk(gqlClient);

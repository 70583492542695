import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { Label, Slider, SliderOutput, SliderThumb, SliderTrack } from 'react-aria-components';

const styles = {
    slider: css`
        display: flex;
        flex-direction: column;
        max-width: 300px;
        padding: 0 22px 38px;
        flex: 1;
    `,
    label: css`
        color: ${colors.black};
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
        text-align: center;
        margin-bottom: 29px;
    `,
    output: css`
        display: flex;
        width: 100%;
        position: relative;
    `,
    outputLabel: css`
        position: absolute;
        color: ${colors.text1};
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%;
        letter-spacing: 0.768px;
        text-transform: uppercase;
        pointer-events: none;
        text-align: center;
        white-space: nowrap;
        transform: translateX(-50%);
        top: 20px;
    `,
    outputLabelSubtle: css`
        color: #e6e6e6;
    `,
    track: css`
        display: flex;
        width: 100%;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background: #f5f5f5;
            box-shadow: 1px 1px 1px 0 rgba(0 0 0 / 17%) inset;
            height: 12px;
            width: 100%;
            border-radius: 34px;
            transform: translateY(-50%);
        }
    `,
    thumb: css`
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background: ${colors.coral3};
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

        &[data-focus-visible] {
            outline: none;
            box-shadow: 0 0 0 5px rgb(239 107 107 / 16%);
        }
    `
};

export const PriceSlider = ({
    value,
    max = 4,
    min = 0,
    onChange
}: {
    value?: [number, number];
    max?: number;
    min?: number;
    onChange?: (value: [number, number]) => void;
}) => {
    const thumbLabels = ['Minimum price', 'Maximum price'];

    const renderOutput = () => {
        const ticks = Array.from({ length: max + 1 }, (_, i) => (
            <span key={i + 1}>
                {'€'.repeat(i + 1)}
                <span className={cx(styles.outputLabelSubtle)}>{'€'.repeat(max - i)}</span>
            </span>
        ));

        return (
            <SliderOutput className={cx(styles.output)}>
                {({ state }) => {
                    return state.values.map((item, i) => {
                        const left = (item / max) * 100;

                        return (
                            <span
                                className={cx(styles.outputLabel)}
                                style={{
                                    left: `${left}%`
                                }}
                                key={i}
                            >
                                {ticks[item]}
                            </span>
                        );
                    });
                }}
            </SliderOutput>
        );
    };

    const handleChange = (newValue: number | number[]) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        const [newMin, newMax] = newValue;

        // Ensure there's a minimum distance of between the sliders
        if (Math.abs(newMax - newMin) < 1) {
            return;
        }

        onChange && onChange([newMin, newMax]);
    };

    return (
        <Slider
            className={cx(styles.slider)}
            value={value}
            minValue={min}
            maxValue={max}
            onChange={handleChange}
            step={1}
        >
            <Label className={cx(styles.label)}>
                <Trans>Price</Trans>
            </Label>

            <SliderTrack className={cx(styles.track)}>
                {({ state }) =>
                    state.values.map((_, i) => (
                        <SliderThumb className={cx(styles.thumb)} key={i} index={i} aria-label={thumbLabels?.[i]} />
                    ))
                }
            </SliderTrack>

            {renderOutput()}
        </Slider>
    );
};

import { useClickoutSide } from '@common/hooks/use-click-outside';
import { useIsTouchDevice } from '@common/hooks/use-is-touch-device';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { RefObject, useState } from 'react';
import { AriaButtonOptions, useButton } from 'react-aria';
import {
    Tooltip as DefaultTooltip,
    TooltipProps as DefaultTooltipProps,
    TooltipTrigger as DefaultTooltipTrigger,
    OverlayArrow,
    TooltipTriggerComponentProps
} from 'react-aria-components';

const styles = {
    tooltip: css`
        position: relative;
        background: #fff;
        padding: 8px 16px;
        border-radius: 5px;
        filter: drop-shadow(0 2px 4px rgb(0 0 0 / 15%));
        font-size: 14px;
        color: ${colors.text1};
        transform: translate3d(0, 0, 0);

        .arrow {
            fill: ${colors.white};
            display: block;
        }

        &[data-placement='top'] {
            margin-bottom: 8px;
        }

        &[data-placement='bottom'] {
            margin-top: 8px;

            .arrow {
                transform: rotate(180deg);
            }
        }

        &[data-placement='right'] {
            margin-left: 8px;

            .arrow {
                transform: rotate(90deg) translateY(-4px);
            }
        }

        &[data-placement='left'] {
            margin-right: 8px;

            .arrow {
                transform: rotate(-90deg) translateY(-4px);
            }
        }
    `,
    tooltipButton: css`
        outline: none;
    `
};

interface TooltipProps extends Omit<DefaultTooltipProps, 'children'> {
    children: React.ReactNode;
    className?: string;
}

export const Tooltip = ({ children, className, ...props }: TooltipProps) => {
    return (
        <DefaultTooltip {...props} className={cx(styles.tooltip, className)}>
            <OverlayArrow>
                <svg className="arrow" width="12" height="5" viewBox="0 0 12 5" fill="none">
                    <path d="M0 0L4.58579 4.58579C5.36683 5.36683 6.63316 5.36684 7.41421 4.58579L12 0" />
                </svg>
            </OverlayArrow>
            {children}
        </DefaultTooltip>
    );
};

const TooltipTriggerButton = (
    props: AriaButtonOptions<'span'> & { children: React.ReactNode; onClickOutside?: () => void }
) => {
    const ref = useClickoutSide(
        () => {
            props.onClickOutside && props.onClickOutside();
        },
        { active: true }
    ) as RefObject<HTMLSpanElement>;

    const { buttonProps } = useButton(
        {
            ...props,
            elementType: 'span'
        },
        ref
    );

    const { children } = props;

    return (
        <span {...buttonProps} className={styles.tooltipButton} ref={ref}>
            {children}
        </span>
    );
};

export const TooltipTrigger = ({ children, ...props }: TooltipTriggerComponentProps) => {
    const [isOpen, setOpen] = useState(false);

    const isTouchDevice = useIsTouchDevice();

    if (isTouchDevice) {
        return (
            <DefaultTooltipTrigger {...props} isOpen={isOpen}>
                <TooltipTriggerButton
                    onPress={() => {
                        setOpen(!isOpen);
                    }}
                    onClickOutside={() => {
                        setOpen(false);
                    }}
                >
                    {children}
                </TooltipTriggerButton>
            </DefaultTooltipTrigger>
        );
    }

    return (
        <DefaultTooltipTrigger {...props} delay={350}>
            <TooltipTriggerButton>{children}</TooltipTriggerButton>
        </DefaultTooltipTrigger>
    );
};

import { WidgetConfigurator } from '@components/configurator/configurator-types';
import { createRoot } from 'react-dom/client';

import { ConfiguratorModularWidget } from './components/configurator/widgets/configurator-modular';

declare global {
    interface Window {
        configuratorInit: () => void;
        __VITRA_EMITTER: any;
        widgets: any[];
        config: {
            features: string[];
        };
    }
}

let hasInit = false;

window.configuratorInit = () => {
    const allowedWidgets = new Set(['vitra--configure']);
    const widgets = window.widgets ?? [];
    const featureFlags = new Set(window.config.features ?? []);

    /**
     * Prepare/cleanup the SSR rendered HTML to contain a blank div which will be used to render the React component.
     * We need to keep the first <a> tag which is used as an anchor for navigation breadcrumbs!
     */
    const stripeServerHtml = (element: Element) => {
        let foundFirstATag = false;
        const children = [...element.children];

        for (const child of children) {
            if (child.tagName === 'A' && !foundFirstATag) {
                foundFirstATag = true;
            } else {
                child.remove();
            }
        }

        const newDiv = document.createElement('div');
        element.append(newDiv);
        return newDiv;
    };

    /**
     * The feature flag "FrontendV2Enabled" is set within the router-go service.
     */
    if (hasInit || widgets.length === 0 || !featureFlags.has('FrontendV2Enabled')) {
        return;
    }
    hasInit = true;
    const io = new IntersectionObserver((entries) => {
        const intersecting = entries.filter((e) => e.isIntersecting);
        for (const element of intersecting) {
            const elementId = element.target.getAttribute('id');
            const elementType = (element.target as HTMLElement).dataset.type;
            const elementData = widgets.find((w) => w.id === elementId) || {};

            switch (elementType) {
                case 'vitra--configure':
                    import('./components/configurator/widgets/configurator-standard').then(
                        ({ ConfiguratorStandardWidget }) => {
                            const cleanedEl = stripeServerHtml(element.target);
                            const root = createRoot(cleanedEl);
                            root.render(<ConfiguratorStandardWidget {...elementData} />);
                        }
                    );

                    break;
            }
            io.unobserve(element.target);
        }
    });

    for (const widget of widgets) {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const element = document.getElementById(widget.id);
        if (element) {
            const elementType = element.dataset.type;
            const elementId = element.getAttribute('id');
            const elementData = widgets.find((w) => w.id === elementId) || {};
            const hasModularConfigurator = (elementData as WidgetConfigurator)?.options?.hasModularConfigurator;

            if (allowedWidgets.has(elementType ?? '')) {
                // Instant load modular configurator
                if (hasModularConfigurator) {
                    const cleanedEl = stripeServerHtml(element);
                    const root = createRoot(cleanedEl);
                    root.render(<ConfiguratorModularWidget {...elementData} />);
                } else {
                    // The other widgets are lazy loaded
                    io.observe(element);
                }
            }
        }
    }
};

window.configuratorInit();

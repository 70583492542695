import { useMedia } from '@common/hooks/use-media';
import { css, cx } from '@linaria/core';
import { motion } from 'framer-motion';
import React from 'react';

import { PANEL_WIDTH } from './constants';

export const styles = {
    panelSurface: css`
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0 0 0 / 10%);
    `,
    panelSurfaceFlex: css`
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        /* @todo: test min height min-height: 235px; */

        /* Used for contaner queries of children */
        container-type: inline-size;
        container-name: surface-container;
    `,
    panelSurfaceRounded: css`
        border-radius: 5px;
    `
};
export type PanelSurfaceSize = 'base' | 'lg' | 'xl';

interface PanelSurfaceProps {
    className?: string;
    children: React.ReactNode;
    rounded?: boolean;
    size?: PanelSurfaceSize;
}

const getPanelWidth = (size: PanelSurfaceSize) => {
    return PANEL_WIDTH[size.toLocaleUpperCase() as 'BASE' | 'LG' | 'XL'];
};

export const PanelSurface = React.forwardRef<HTMLDivElement, PanelSurfaceProps>((props, ref) => {
    const { className, children, size = 'base', rounded = true } = props;
    const { md } = useMedia();
    const panelWidth = getPanelWidth(size);

    const resizeMotionProps = md ? { initial: { maxWidth: panelWidth }, animate: { maxWidth: panelWidth } } : {};

    return (
        <motion.div
            {...resizeMotionProps}
            ref={ref}
            className={cx(
                'panel-surface',
                styles.panelSurface,
                styles.panelSurfaceFlex,
                rounded && styles.panelSurfaceRounded,
                className
            )}
            style={{
                width: md ? panelWidth : '100%'
            }}
        >
            {children}
        </motion.div>
    );
});

PanelSurface.displayName = 'PanelSurface';

import { useMedia } from '@common/hooks/use-media';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import {
    BffConfiguratorModularProductWithNode,
    getLayerPushFromConfiguration,
    useModularConfigurator
} from '@components/configurator/hooks';

export const LayerModularCategory = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const currentCategory = currentLayer?.replace('category-detail-', '');
    const categoriesWithProducts = useModularConfigurator((state) => state.categoriesWithProducts);
    const categoryData = categoriesWithProducts.find((category) => category.id === currentCategory);
    const setSelectedProductSlot = useModularConfigurator((state) => state.setSelectedProductSlot);

    const { md } = useMedia();

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    const onProductClick = (product: BffConfiguratorModularProductWithNode) => {
        const nextLayer = getLayerPushFromConfiguration(product);

        if (!nextLayer) {
            return;
        }

        setSelectedProductSlot({ product: product.node });
    };
    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {categoryData?.products.map((product, ix) => {
                const title = `${categoryData?.name} ${ix + 1}`;
                return (
                    <ButtonTile.Item direction={tileDirection} key={`${product.sku}-${ix}`}>
                        <ButtonTile.Surface direction={tileDirection} onClick={() => onProductClick(product)}>
                            <ButtonTile.Image
                                size={thumbnailSize}
                                direction={tileDirection}
                                image={product.image as ButtonTileImageImage}
                            />
                            <ButtonTile.Text direction={tileDirection} title={title} subtitle={product.name} />
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                );
            })}
        </ButtonTile.List>
    );

    if (md) {
        return (
            <>
                <Panel.Navigation kind="back" onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{categoryData?.name}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );
    }

    return (
        <>
            <Panel.Navigation kind="back" onPress={popLayer} appearance="mobile" title={categoryData?.name as string} />
            <Panel.ScrollArea direction={'horizontal'}>{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

import { useMedia } from '@common/hooks/use-media';
import { Tab, Tabs } from '@common/primitives';
import { Badge } from '@common/primitives/badge';
import { media } from '@common/styles/media';
import { Layer, Panel, ShareMenu } from '@components/configurator';
import { PANEL_HEIGHT, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { ConfiguratorTab, useModularConfigurator, useShoppingList, useViewer } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { Trans } from '@lingui/macro';

const styles = {
    tabHeader: css`
        display: flex;
        align-items: flex-start;
    `,
    tabHeaderButton: css`
        margin-top: 13px;
        display: flex;
    `,
    tabList: css`
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;

        .tab-list {
            ${media.md} {
                width: 352px;
                max-width: 100%;
            }
        }
    `,
    tabPanelSaved: css`
        .panel-tab-panel-content {
            justify-content: flex-start;
        }
    `,
    tabPanelBuilder: css`
        min-height: ${PANEL_HEIGHT - TAB_HEIGHT_MOBILE}px;
    `,
    tabSavedBadge: css`
        position: absolute;
        top: -4px;
        right: -4px;
    `
};

export const LayerOverviewModular = () => {
    const onSetTab = useModularConfigurator((state) => state.onSetTab);
    const selectedTab = useModularConfigurator((state) => state.ui?.tab);
    const { md } = useMedia();

    const modularLoadSharedConfiguration = useViewer((state) => state.modularLoadSharedConfiguration);

    const { shoppingList, shoppingListNotification } = useShoppingList((state) => state);

    const shareMenu = selectedTab === 'saved' && (shoppingList?.lineItems || []).length > 0 && (
        <div className={styles.tabHeaderButton}>
            <ShareMenu />
        </div>
    );
    const tabHeader = (
        <div className={styles.tabHeader}>
            {md && shareMenu}

            <div className={styles.tabList}>
                <Panel.TabList aria-label="Menu">
                    <Tab id="presets">
                        <Trans>Presets</Trans>
                    </Tab>
                    <Tab id="builder">
                        <Trans>Builder</Trans>
                    </Tab>
                    <Tab id="saved">
                        <Trans>Saved</Trans>
                        {shoppingListNotification > 0 && (
                            <span className={styles.tabSavedBadge}>
                                <Badge aria-label="New items" />
                            </span>
                        )}
                    </Tab>
                </Panel.TabList>
            </div>
        </div>
    );
    const tabs = (
        <Tabs selectedKey={selectedTab} onSelectionChange={(e) => onSetTab(e.toString() as ConfiguratorTab)}>
            {tabHeader}

            <Panel.TabPanel id="presets">
                <Layer.OverviewModularPresets />
            </Panel.TabPanel>

            <Panel.TabPanel id="builder" className={styles.tabPanelBuilder}>
                <Layer.OverviewModularBuilder />
            </Panel.TabPanel>

            <Panel.TabPanel id="saved" className={styles.tabPanelSaved}>
                <Layer.OverviewModularShoppingList
                    header={shareMenu}
                    footer={tabHeader}
                    onEdit={async (sku) => {
                        await modularLoadSharedConfiguration(sku.split('|')[1]);
                        onSetTab('builder');
                    }}
                    onUpdate={(sku) => {
                        modularLoadSharedConfiguration(sku.split('|')[1]);
                    }}
                />
            </Panel.TabPanel>
        </Tabs>
    );

    if (md) {
        return <Panel.ScrollArea>{tabs}</Panel.ScrollArea>;
    }

    return tabs;
};

import {
    BffConfiguratorConfigurationColor,
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorConfigurationColorMaterial
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry, useConfig } from '@common/hooks/use-config';
import { useMedia } from '@common/hooks/use-media';
import { Close } from '@common/icons';
import { Button, ButtonIcon, Switch } from '@common/primitives';
import { ToastIconSuccess, ToastLink, ToastProvider } from '@common/primitives/toast';
import { media } from '@common/styles/media';
import { addSamplesToCart } from '@common/utils/add-to-cart';
import { Panel } from '@components/configurator/components/panel';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { MaterialComparisonTable } from '@components/material-comparison/components/table';
import { css, cx } from '@linaria/core';
import { plural, t, Trans } from '@lingui/macro';
import { ToastState } from '@react-stately/toast';
import { useState } from 'react';
import { Label, Modal, ModalOverlay, Selection } from 'react-aria-components';

import { LayerConfigureColorOptionsCompare } from './layer-configure-color-options-compare';

const styles = {
    stickyFooter: css`
        position: fixed;
        left: 0;
        padding-top: 24px;
        display: flex;
        gap: 8px;

        ${media.md} {
            position: sticky;
        }
    `,
    overlay: css`
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        background-size: cover;
        background-position: center;
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 2};
    `,
    modal: css`
        height: 100dvh;
        width: 100dvw;

        ${media.md} {
            width: 938px;
            max-width: 100dvw;
            margin: 12px;
            height: calc(100dvh - 24px);
        }
    `,
    scrollArea: css`
        padding: 0;
        position: relative;
    `,
    label: css`
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%;
        letter-spacing: 0.26px;
    `,
    toast: css`
        bottom: 14px;
        position: absolute;
    `
};

interface LayerConfigureColorCompareProps {
    configuration: BffConfiguratorConfigurationColor;
    filteredColors: BffConfiguratorConfigurationColorItem[];
    isCompareOpen: boolean;
    currencyCode: string;
    onReset: () => void;
    onChangeColor: (color: BffConfiguratorConfigurationColorItem) => void;
    setIsCompareModalOpen: (value: boolean) => void;
}

export const LayerConfigureColorCompare = ({
    configuration,
    filteredColors,
    isCompareOpen,
    currencyCode = 'EUR',
    onReset,
    onChangeColor,
    setIsCompareModalOpen
}: LayerConfigureColorCompareProps) => {
    const { md } = useMedia();

    // react-aria-components Selection set
    const [selectedKeys, setSelectedKeys] = useState<Selection>();
    const selectedCompareColors = filteredColors.filter((color) => {
        return selectedKeys && selectedKeys !== 'all' && [...selectedKeys].includes(color.id);
    });

    const [showDifferencesOnly, setShowDifferencesOnly] = useState(false);
    const [mode, setMode] = useState<'compare' | 'order-samples'>('compare');
    const [selectedSamples, setSelectedSamples] = useState<BffConfiguratorConfigurationColorItem[]>([]);
    const [isAddingSamplesToCart, setIsAddingSamplesToCart] = useState(false);

    const shoppingCartUrl = useConfig('cartPageUrl', '/cart');
    const { country, language } = getBffLanguageAndCountry();

    const resetSamples = () => {
        setMode('compare');
        setIsAddingSamplesToCart(false);
        setSelectedSamples([]);
    };

    const handleAddToCart = async (toastState: ToastState<any>) => {
        setIsAddingSamplesToCart(true);

        const response = await addSamplesToCart({
            skus: selectedSamples.map((sample) => sample.sampleArticleNumber as string),
            country,
            language
        });

        if (response.__typename === 'Success') {
            toastState.add(
                <>
                    <ToastIconSuccess />
                    {response.message}
                    <ToastLink href={shoppingCartUrl}>{t`View cart`}</ToastLink>
                </>,
                { timeout: 4500 }
            );
        }

        if (response.__typename === 'Error') {
            toastState.add(response.message, { timeout: 4500 });
        }

        resetSamples();
    };

    const renderFooterOptions = (toastState: ToastState<any>) => {
        const isToastVisible = toastState.visibleToasts.length > 0;

        if (isToastVisible) {
            return null;
        }

        if (mode === 'compare') {
            return (
                <Button
                    variant="coral"
                    onPress={() => {
                        setMode('order-samples');
                    }}
                    isDisabled={isAddingSamplesToCart}
                    isLoading={isAddingSamplesToCart}
                >
                    <Trans>Order Samples</Trans>
                </Button>
            );
        }

        return (
            <>
                <ButtonIcon
                    onPress={() => {
                        setMode('compare');
                    }}
                    aria-label={t`Close`}
                    icon={<Close />}
                    isDisabled={isAddingSamplesToCart}
                />
                {selectedSamples.length > 0 ? (
                    <Button
                        variant="coral"
                        rounded
                        onPress={() => {
                            handleAddToCart(toastState);
                        }}
                        isDisabled={isAddingSamplesToCart}
                        isLoading={isAddingSamplesToCart}
                    >
                        {plural(selectedSamples?.length || 0, {
                            one: 'Add # sample to cart',
                            other: 'Add # samples to cart'
                        })}
                    </Button>
                ) : (
                    <Panel.Hint>
                        <Trans>Add up to 5 samples</Trans>
                    </Panel.Hint>
                )}
            </>
        );
    };

    const handleCloseModal = () => {
        setIsCompareModalOpen(false);
        setMode('compare');
    };

    const table = (
        <>
            <Panel.ScrollArea className={styles.scrollArea}>
                <Panel.Title title={t`Compare Fabrics`}>
                    <Switch isSelected={showDifferencesOnly} onChange={setShowDifferencesOnly}>
                        <Label className={styles.label}>
                            <Trans>only show differences</Trans>
                        </Label>
                    </Switch>
                </Panel.Title>
                <Panel.Navigation kind="close" onPress={() => setIsCompareModalOpen(false)} />
                <MaterialComparisonTable
                    onSelectSample={(color) => {
                        setSelectedSamples((samples) => {
                            const exists = samples.some((sample) => sample.id === color.id);

                            return exists ? samples.filter((sample) => sample.id !== color.id) : [...samples, color];
                        });
                    }}
                    selectedSamples={selectedSamples}
                    mode={mode}
                    onApplyColor={onChangeColor}
                    currencyCode={currencyCode}
                    selectedColors={selectedCompareColors}
                    materials={configuration.materials as BffConfiguratorConfigurationColorMaterial[]}
                    onRemoveColor={(key) =>
                        setSelectedKeys((keys) => {
                            return keys && keys !== 'all' ? new Set([...keys].filter((k) => k !== key)) : keys;
                        })
                    }
                    onClose={handleCloseModal}
                    showDifferencesOnly={showDifferencesOnly}
                />
            </Panel.ScrollArea>
            <Panel.Sticky position="footer">
                <ToastProvider className={styles.toast}>
                    {(toastState) => <div className={styles.stickyFooter}>{renderFooterOptions(toastState)}</div>}
                </ToastProvider>
            </Panel.Sticky>
        </>
    );

    if (isCompareOpen && md) {
        return table;
    }

    const body = (
        <>
            <LayerConfigureColorOptionsCompare
                filteredColors={filteredColors}
                configuration={configuration}
                onSelectionChange={setSelectedKeys}
                selectedKeys={selectedKeys}
            />
            {selectedCompareColors.length > 0 && (
                <Panel.Sticky position="footer" className={styles.stickyFooter}>
                    {selectedCompareColors.length === 1 ? (
                        <Panel.Hint>
                            <Trans>Select more fabrics</Trans>
                        </Panel.Hint>
                    ) : (
                        <Button variant={'coral'} rounded onPress={() => setIsCompareModalOpen(true)}>
                            {plural(selectedCompareColors?.length || 0, {
                                other: 'Compare # fabrics'
                            })}
                        </Button>
                    )}
                </Panel.Sticky>
            )}
        </>
    );

    if (md) {
        return (
            <>
                <Panel.Navigation kind="close" onPress={onReset} />
                <Panel.ScrollArea>
                    <Panel.Title>
                        <Trans>Select Fabrics</Trans>
                    </Panel.Title>
                    {body}
                </Panel.ScrollArea>
            </>
        );
    }

    return (
        <>
            <Panel.Navigation appearance="mobile" kind="back" title={t`Select Fabrics`} onPress={onReset} />
            <Panel.ScrollArea direction="horizontal">{body}</Panel.ScrollArea>

            <ModalOverlay isOpen={isCompareOpen} className={styles.overlay}>
                <Modal className={cx('comparison-modal', styles.modal)} isOpen={isCompareOpen}>
                    <Panel.Surface>{table}</Panel.Surface>
                </Modal>
            </ModalOverlay>
        </>
    );
};

import { BffMarket } from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import clipboardCopy from 'clipboard-copy';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
    EmersyaDragPayload,
    EmersyaDragPositionUpdate,
    EmersyaPayload,
    ModularViewer
} from '../components/viewer/viewer-types';

type EmersyaViewerState = 'loading' | 'loaded' | 'error';
export type ViewerState = EmersyaViewerState; // we add extra values

export type ViewerScreenshot = {
    screenshot: string;
    viewpoint: string;
};

const getViewer = async (configuratorId: string) => {
    const viewers = (window as any).emViewers;

    if (!viewers) {
        throw new Error('Viewer not found');
    }

    const viewer = viewers[configuratorId];

    if (viewer) {
        return viewer;
    } else {
        throw new Error(`Viewer ${configuratorId} not found`);
    }
};

const onViewerLoaded = async (id: string) => {
    // const disableViewerMouseWheel = async () => {
    //     const viewer = await getViewer(id);
    //     viewer?.disableMouseWheel();
    // };

    const setViewerBackgroundColor = async () => {
        const viewer = await getViewer(id);
        await viewer?.setSceneryBackgroundColor({ color: '#f5f5f5' });
    };

    await setViewerBackgroundColor();
    // await disableViewerMouseWheel();
};

const createAndAttachScript = (attributes: Record<string, string>) => {
    const tag = document.createElement('script');
    for (const key of Object.keys(attributes)) {
        tag.setAttribute(key, attributes[key]);
    }
    document.querySelector('head')?.append(tag);
};

type ViewerStore = {
    viewer: ModularViewer | null;
    id: string;
    sku: string;
    viewerState: ViewerState;
    loadingProgress: number;
    loaded: boolean;
    screenshotFallback?: string;
    screenshotCurrentConfiguration?: string;
    screenshots: ViewerScreenshot[];
    screenshotsCache: {
        small: Record<string, ViewerScreenshot[]>; // Used for the panel
        large: Record<string, string>; // Used for recommendations
        fallback: Record<string, string>; // used for the fallback if there is no viewpoint
    };
    hasAr: boolean | null;
    arQrCode: string | null;
    rulersShown: boolean;
    zoomProportion: number | null;
};

type AnyCallbackRecord = Record<string, (...params: any[]) => void>;

type ViewerActions = {
    generateScreenshots: (sku: string) => Promise<void>;
    generateScreenshotsFallback: (sku: string) => Promise<void>;
    generateScreenshotsCurrentConfiguration: () => Promise<void>;
    loadViewer: (events: AnyCallbackRecord, sku: string) => Promise<void>;
    loadModularViewer: (events: AnyCallbackRecord, lastActiveScene?: EmersyaPayload) => Promise<void>;
    updateViewer: (sku: string) => Promise<void>;
    destroyViewer: () => Promise<void>;
    resetCamera: () => Promise<void>;
    setZoomProportion: (value?: number) => Promise<void>;
    startAr: () => Promise<void>;
    checkAr: () => Promise<boolean>;
    recenterViewpoint: (options: any) => Promise<void>;
    setModularConfiguratorFurnitureProductConfiguration: (nodeId: number, configuration: string) => Promise<void>;
    setModularConfiguratorFurnitureProductsConfigurations: (
        products: { localId: number; configuration: string }[]
    ) => Promise<void>;
    modularGetAllProducts: () => Promise<void>;
    modularLoadSharedConfiguration: (emersyaId: string) => Promise<void>;
    stopModularConfiguratorProductDrop: (dragPayload: EmersyaDragPayload) => Promise<void>;
    startModularConfiguratorProductDrop: (dragPayload: EmersyaDragPayload) => Promise<void>;
    updateModularConfiguratorProductDragPosition: (position: EmersyaDragPositionUpdate) => Promise<void>;
    modularCreateSharedConfiguration: (articleNo: string) => Promise<string>;
    setModularConfiguratorScene: (scene: EmersyaPayload) => Promise<void>;
    setProductNodesToGhostRenderingMode: (nodes: { localId: number; renderAsGhost: boolean }[]) => Promise<void>;
    isModularConfiguratorProductRotatable: (options: { localId: number | string }) => Promise<any>;
    toggleProductNodeInstanceTracking: (options: { localId: number | string; value: boolean }) => Promise<void>;
    removeModularConfiguratorProduct: (options: { localId: number | string }) => Promise<any>;
    rotateModularConfiguratorProduct: (options: { localId: number | string }) => Promise<any>;
    toggleRulers: (options?: { size: number }) => Promise<void>;
};
const screenShotSize = 160;
export const useViewer = create<ViewerStore & ViewerActions>()(
    immer((set, get) => ({
        viewer: null,
        id: 'conf',
        viewerState: 'loading',
        loadingProgress: 0,
        loaded: false,
        arQrCode: null,
        screenshots: [],
        screenshotFallback: '',
        screenshotCurrent: '', // Used to display the current configuration in the standard configurator
        sku: '',
        hasAr: null,
        screenshotsCache: {
            fallback: {},
            small: {},
            large: {}
        },
        modularSharedConfigurationId: null,
        rulersShown: false,
        zoomProportion: null,
        setZoomProportion: async (value: number | undefined) => {
            const { viewer } = get();
            if (value === undefined) {
                // Get the current zoom value
                const c = await viewer?.getCamera();
                set((draft) => {
                    draft.zoomProportion = c.zoomProportion;
                });
            } else {
                // Set a zoom value
                viewer?.setZoomProportion({ value, transitionTime: 500 });
                set((draft) => {
                    draft.zoomProportion = value;
                });
            }
        },
        resetCamera: async () => {
            const { viewer } = get();
            viewer?.resetCamera({ transitionTime: 500 });
        },
        generateScreenshots: async (sku) => {
            const { generateScreenshotsFallback, screenshotsCache } = get();

            if (screenshotsCache.small[sku]) {
                set({
                    screenshots: screenshotsCache.small[sku],
                    screenshotFallback: screenshotsCache.fallback[sku]
                });
                return;
            }
            const { viewer } = get();

            viewer
                ?.getScreenshots({
                    width: screenShotSize,
                    height: screenShotSize,
                    transparentBackground: false,
                    viewpoints: ['thumb_*']
                })
                .then((screenshots: any, cameras: any) => {
                    for (const [index] of cameras.entries()) {
                        cameras[index].screenshot = screenshots[index];
                    }
                    set((draft) => {
                        draft.screenshots = cameras;
                        draft.screenshotsCache.small[sku] = cameras;
                    });
                });

            await generateScreenshotsFallback(sku);
        },
        generateScreenshotsFallback: async (sku) => {
            const { viewer } = get();

            const [screenshotFallback] = await viewer?.getScreenshots({
                width: screenShotSize,
                height: screenShotSize,
                transparentBackground: false,
                viewpoints: ['']
            });
            set((draft) => {
                draft.screenshotFallback = screenshotFallback;
                draft.screenshotsCache.fallback[sku] = screenshotFallback;
            });
        },
        generateScreenshotsCurrentConfiguration: async () => {
            const { sku, screenshotsCache } = get();

            if (screenshotsCache.large[sku]) {
                set({ screenshotCurrentConfiguration: screenshotsCache.large[sku] });
                return;
            }

            set((draft) => {
                draft.screenshotCurrentConfiguration = undefined;
            });

            const { viewer } = get();

            const [screenshotCurrentConfiguration] = await viewer?.getScreenshots({
                width: 1024,
                height: 1024,
                transparentBackground: false,
                viewpoints: ['']
            });
            set((draft) => {
                draft.screenshotCurrentConfiguration = screenshotCurrentConfiguration;
                draft.screenshotsCache.large[sku] = screenshotCurrentConfiguration;
            });
        },
        startAr: async () => {
            const { viewer } = get();
            await viewer?.startAr();
        },
        checkAr: async () => {
            try {
                const { viewer } = get();
                const arAvailability = await viewer?.getARAvailability();
                if (arAvailability) {
                    viewer?.disableARDefaultQRCodeStartingScreen();
                }
                return arAvailability;
            } catch (error) {
                console.log('Error checking AR availability', error);
            }
        },
        loadModularViewer: async (events: AnyCallbackRecord, lastActiveScene?: EmersyaPayload) => {
            const { id } = get();
            const { market } = getBffLanguageAndCountry();

            const onStateChangeEvent = async (state: any) => {
                set((draft) => {
                    draft.viewerState = state.viewerState;
                });

                if (state.viewerState === 'loading') {
                    const p = Math.round((state.loadingProgress * 100) / 5) * 5; // Nearest 5
                    if (p !== get().loadingProgress) {
                        set((draft) => {
                            draft.loadingProgress = p;
                        });
                    }
                    set((draft) => {
                        draft.loadingProgress = state.loadingProgress * 100;
                    });
                } else if (state.viewerState === 'loaded') {
                    set((draft) => {
                        draft.loadingProgress = 100;
                        draft.loaded = true;
                    });

                    await onViewerLoaded(id);

                    const viewer = await getViewer(id);

                    if (events.onViewerLoaded) {
                        events.onViewerLoaded(viewer);
                    }

                    // Load nodes
                    if (lastActiveScene) {
                        viewer.setModularConfiguratorScene(lastActiveScene);
                    }
                    viewer.enableMouseWheel(); // Enable mouse wheel zoom
                    viewer.setMinAndMaxZoom({ minZoom: 0.5, maxZoom: 2 });
                    viewer.getAllModularConfiguratorProducts();
                }
            };

            const onArCodeEvent = (d: any) => {
                set((draft) => {
                    draft.arQrCode = d.qrcode;
                });
            };

            const onLoadFrame = async () => {
                const viewer = await getViewer(id);
                set({ viewer });
                // viewer.enableHighFrequencyFeedback();
                viewer.addEventListener('onStateChange', onStateChangeEvent, false);
                viewer.addEventListener('ARQRCodeStartingScreenExpected', onArCodeEvent);
                viewer.startViewer();

                // Attach events
                for (const [eventName, callback] of Object.entries(events)) {
                    viewer.addEventListener(eventName, callback);
                }
            };

            document.addEventListener('emersyaViewerInitialized', onLoadFrame, false);

            const routing = market === BffMarket.Gb || market === BffMarket.Ie ? 'R59J3XN7XX' : '95B7LBXU5E';
            createAndAttachScript({
                src: 'https://cdn.emersya.com/f/emersyaLoader.js',
                routing,
                containerId: id,
                furnitureConfiguratorArEnabled: 'true'
            });
        },
        loadViewer: async (events: AnyCallbackRecord, sku: string) => {
            const { id, generateScreenshots } = get();
            const onStateChangeEvent = async (state: any) => {
                set((draft) => {
                    draft.viewerState = state.viewerState;
                });

                if (state.viewerState === 'loading') {
                    const p = Math.round((state.loadingProgress * 100) / 5) * 5; // Nearest 5
                    if (p !== get().loadingProgress) {
                        set((draft) => {
                            draft.loadingProgress = p;
                        });
                    }
                } else if (state.viewerState === 'loaded') {
                    set((draft) => {
                        draft.loadingProgress = 100;
                        draft.loaded = true;
                    });
                    await onViewerLoaded(id);
                    sku && generateScreenshots(sku);
                }
            };

            const onArCodeEvent = (d: any) => {
                set({ arQrCode: d.qrcode });
            };

            const onLoadFrame = async () => {
                const viewer = await getViewer(id);

                set((draft) => {
                    draft.viewer = viewer;
                });

                viewer.enableHighFrequencyFeedback();
                viewer.enableMouseWheel(); // Enable mouse wheel zoom
                viewer.addEventListener('onStateChange', onStateChangeEvent, false);
                viewer.addEventListener('ARQRCodeStartingScreenExpected', onArCodeEvent);

                // Attach events
                for (const [eventName, callback] of Object.entries(events)) {
                    viewer.addEventListener(eventName, callback);
                }
            };

            document.addEventListener('emersyaViewerInitialized', onLoadFrame, false);

            createAndAttachScript({
                src: 'https://emergatev4.s3-eu-west-1.amazonaws.com/f/emersyaLoader.js',
                furnitureCode: 'GOGC957E2K',
                containerId: id,
                configurationCode: `${id}|${sku}`,
                furnitureConfiguratorArEnabled: 'true'
            });
        },
        destroyViewer: async () => {
            const { id } = get();
            const viewer = await getViewer(id);

            await viewer.destroyViewer();

            set((draft) => {
                // reset viewer to inital state
                draft.viewer = null;
                draft.loadingProgress = 0;
                draft.loaded = false;
                draft.viewerState = 'loading';
                draft.screenshots = [];
            });
        },
        updateViewer: async (sku) => {
            if (sku === get().sku) {
                return;
            }
            set((draft) => {
                draft.sku = sku;
            });

            const { viewer, id, generateScreenshots } = get();
            const configuration = `${id}|${sku}`;

            await viewer?.setOFMLConfiguration({ configuration });
            await generateScreenshots(sku);
        },
        recenterViewpoint: async (options: {
            border: number;
            transitionTime?: number;
            reinitializeZoomLimits?: boolean;
            nodeLocalIds?: string[];
        }) => {
            const { id } = get();
            const viewers = (window as any).emViewers;
            if (!viewers) {
                return;
            }
            const viewer = await getViewer(id);
            viewer
                .recenterViewpoint({
                    ...options,
                    transitionTime: options.transitionTime ?? 500,
                    reinitializeZoomLimits: options.reinitializeZoomLimits ?? true
                })
                .then(async () => {
                    const c = await viewer.getCamera();
                    set((draft) => {
                        draft.zoomProportion = c.zoomProportion;
                    });
                });
        },
        modularLoadSharedConfiguration: async (emersyaId: string) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.loadConfiguration({
                code: emersyaId
            });
        },
        modularCreateSharedConfiguration: async (articleNo: string) => {
            const { id } = get();
            const viewer = await getViewer(id);

            const response = await viewer.saveModularConfiguration({
                screenshot: false,
                shortLink: true
            });
            // current path without query params
            const path = window.location.pathname;
            const domain = window.location.origin;
            const urlToShare = `${domain}${path}?productId=${articleNo}|${response.code}`;
            clipboardCopy(urlToShare);
            return response.code;
        },
        setProductNodesToGhostRenderingMode: async (nodes) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.setProductNodesToGhostRenderingMode({ nodes: nodes });
        },
        setModularConfiguratorFurnitureProductConfiguration: async (localId: number, configuration: string) => {
            const { id } = get();
            const viewer = await getViewer(id);
            const payload = {
                localId,
                configuration
            };
            return viewer.setModularConfiguratorFurnitureProductConfiguration(payload);
        },
        modularGetAllProducts: async () => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.getAllModularConfiguratorProducts();
        },
        startModularConfiguratorProductDrop: async (dragPayload: EmersyaDragPayload) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.startModularConfiguratorProductDrop(dragPayload);
        },
        stopModularConfiguratorProductDrop: async (dragPayload: EmersyaDragPayload) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.stopModularConfiguratorProductDrop(dragPayload);
        },
        updateModularConfiguratorProductDragPosition: async (position: EmersyaDragPositionUpdate) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.updateModularConfiguratorProductDragPosition(position);
        },
        setModularConfiguratorFurnitureProductsConfigurations: async (products) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.setModularConfiguratorFurnitureProductsConfigurations({ values: products });
        },
        setModularConfiguratorScene: async (scene: EmersyaPayload) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.setModularConfiguratorScene({
                ...scene
            });
        },
        isModularConfiguratorProductRotatable: async (options: { localId: number | string }) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.isModularConfiguratorProductRotatable(options);
        },
        toggleProductNodeInstanceTracking: async (options: { localId: number | string; value: boolean }) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.toggleProductNodeInstanceTracking(options);
        },
        removeModularConfiguratorProduct: async (options: { localId: number | string }) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.removeModularConfiguratorProduct(options);
        },
        rotateModularConfiguratorProduct: async (options: { localId: number | string }) => {
            const { id } = get();
            const viewer = await getViewer(id);
            return viewer.rotateModularConfiguratorProduct(options);
        },
        toggleRulers: async (options) => {
            const { market } = getBffLanguageAndCountry();
            const { id, rulersShown } = get();
            const viewer = await getViewer(id);
            if (market == BffMarket.Us) {
                viewer.toggleRulers({
                    value: !rulersShown,
                    settings: {
                        system: 'imperial',
                        fraction: 0.1,
                        decimals: 1,
                        size: options?.size || 18
                    }
                });
            } else {
                viewer.toggleRulers({
                    value: !rulersShown,
                    settings: {
                        system: 'metric',
                        fraction: 0.01,
                        decimals: 0,
                        size: options?.size || 18
                    }
                });
            }
            set((draft) => {
                draft.rulersShown = !rulersShown;
            });
            return;
        }
    }))
);

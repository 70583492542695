import { Tooltip, TooltipTrigger } from '@common/primitives/tooltip';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';

const styles = {
    tileInfoBase: css`
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: ${colors.white};

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
            outline-offset: 3px;
        }

        &[data-pressed] {
            background: ${colors.coral2};
            color: white;
        }
    `,
    tileInfo: css`
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        pointer-events: all;
        z-index: 2;
        right: 7px;
        top: 7px;
    `,
    tileInfoWrapper: css`
        max-width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        pointer-events: none;
    `,
    tileInfoContent: css`
        max-width: 300px;
        text-align: center;
        padding: 20px 18px 25px;
    `
};

interface ButtonTileInfoProps {
    children?: React.ReactNode;
    className?: string;
    size: [number, number];
    onClick?: () => void;
    tooltipText?: string;
}

declare global {
    interface Window {
        isStorybook: boolean;
    }
}

export const ButtonTileInfo = (props: ButtonTileInfoProps) => {
    const { children, className, size, tooltipText } = props;

    const body = children ?? <div className={cx('tile-info-icon', styles.tileInfoBase)}>i</div>;

    const button = tooltipText ? (
        <TooltipTrigger>
            <Tooltip className={cx('tile-info-content', styles.tileInfoContent)}>{tooltipText}</Tooltip>
            {body}
        </TooltipTrigger>
    ) : (
        body
    );

    return (
        <span
            className={cx('tile-info', styles.tileInfoWrapper, className)}
            style={{ width: size[0], height: size[1] }}
        >
            <span className={cx(styles.tileInfo, className)}>{button}</span>
        </span>
    );
};

import { Spinner } from '@common/primitives/spinner/spinner';
import { media } from '@common/styles/media';
import { ProgressBar } from '@components/configurator/components/progress-bar';
import { useViewer } from '@components/configurator/hooks/use-viewer';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { createContext, memo, useMemo, useRef, useState } from 'react';

import { ContextMenu } from '../context-menu';

const styles = {
    viewer: css`
        height: 100%;
        width: 100%;
    `,
    viewerHidden: css`
        visibility: hidden;
    `,
    viewerPlaceholder: css`
        position: absolute;
        inset: 0;
        object-fit: contain;
        opacity: 0.2;
        z-index: 2;
    `,
    viewerPlaceholderOverlay: css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #f5f5f5;
        mix-blend-mode: multiply;
    `,
    viewerProgress: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 31px;
        display: flex;
        z-index: 2;
    `,
    viewerProgressLoading: css`
        margin-bottom: 24px;

        ${media.md} {
            position: absolute;
            bottom: 51px;
            margin-bottom: 0;
        }
    `,
    viewerSpinner: css`
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `
};

const ViewerCanvas = ({ id }: { id: string }) => <div id={id} className={styles.viewer} role="presentation" />;

const ViewerCanvasMemo = memo(ViewerCanvas);

type ViewerContextType = {
    isHovered?: boolean;
};

export const ViewerContext = createContext<ViewerContextType>({
    isHovered: undefined
});

export const Viewer = ({ placeholderUrl, children }: { placeholderUrl?: string; children?: React.ReactNode }) => {
    const [viewerHovered, setViewerHovered] = useState(false);

    const loadingProgress = useViewer((state) => state.loadingProgress);
    const loaded = useViewer((state) => state.loaded);
    const id = useViewer((state) => state.id);
    const viewerRef = useRef<any>(null);
    const contextValue = useMemo(
        () => ({
            isHovered: viewerHovered
        }),
        [viewerHovered]
    );

    return (
        <div
            ref={viewerRef}
            className={styles.viewer}
            onMouseEnter={() => setViewerHovered(true)}
            onMouseLeave={() => setViewerHovered(false)}
        >
            <div className={cx(styles.viewer, !loaded && styles.viewerHidden)}>
                <ViewerCanvasMemo id={id} />
            </div>

            {!loaded && <Spinner className={cx('viewer-spinner', styles.viewerSpinner)} />}

            {loaded ? (
                <>
                    <ContextMenu viewerRef={viewerRef} />
                    <ViewerContext.Provider value={contextValue}>{children}</ViewerContext.Provider>
                </>
            ) : (
                <div className={cx('viewer-progress', styles.viewerProgress, !loaded && styles.viewerProgressLoading)}>
                    <ProgressBar value={loadingProgress} label={t`Loading`} />
                </div>
            )}

            {placeholderUrl && (
                <>
                    <img className={cx(styles.viewer, styles.viewerPlaceholder)} src={placeholderUrl} alt="" />
                    <div className={cx(styles.viewerPlaceholderOverlay)} />
                </>
            )}
        </div>
    );
};

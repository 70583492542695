import { useConfig } from '@common/hooks/use-config';
import { Button } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';

interface ErrorBlockingProps {
    message?: string;
    trace?: string;
    className?: string;
    showReload?: boolean;
    showRevert?: boolean;
    onRevert?: () => void;
}

const styles = {
    error: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
        height: 100%;
    `,
    errorTitle: css`
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
        text-align: center;
        margin-bottom: 8px;
    `,
    errorSubtitle: css`
        font-size: 14px;
        text-align: center;
    `,
    errorButtonContainer: css`
        display: flex;
        gap: 8px;
    `,
    errorSupport: css`
        font-size: 14px;
        text-align: center;
    `,
    errorSupportLink: css`
        color: ${colors.coral2};
        text-decoration: underline;
    `
};

export const ErrorBlocking = (props: ErrorBlockingProps) => {
    const { className, message, showReload, trace, onRevert, showRevert } = props;

    const handleReload = () => {
        // Refresh the page
        window.location.reload();
    };

    const supportUrl = useConfig('contactServiceUrl', '');

    useEffect(() => {
        console.error(message);
    }, [message]);

    return (
        <div className={cx(styles.error, className)}>
            <div>
                <h1 className={styles.errorTitle}>
                    <Trans>Looks like there has been an error</Trans>
                </h1>
                <p className={styles.errorSubtitle}>{trace}</p>
            </div>
            <div className={styles.errorButtonContainer}>
                {showReload && (
                    <Button onPress={handleReload} variant="secondary">
                        <Trans>Reload the page</Trans>
                    </Button>
                )}
                {showRevert && (
                    <Button variant="coral" onPress={onRevert}>
                        <Trans>Undo your last change</Trans>
                    </Button>
                )}
            </div>
            <p className={styles.errorSupport}>
                <Trans>
                    Still having issues?{' '}
                    <a className={styles.errorSupportLink} href={supportUrl}>
                        Contact support
                    </a>
                </Trans>
            </p>
        </div>
    );
};

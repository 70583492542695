import { AddElement } from '@common/icons';
import { colors } from '@common/styles/colors';
import { css } from '@linaria/core';

import { ButtonTile } from '.';

const styles = {
    tileImageAddElement: css`
        border-radius: 100%;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: ${colors.coral2};
        box-shadow: 0 1.744px 3.488px 0 rgba(0 0 0 / 15%);
    `,
    addElementMobile: css`
        background: white;
        height: 82px;
    `
};

interface ButtonTileImageAddElementProps {
    className?: string;
    direction: 'row' | 'column';
}

export const ButtonTileImageAddElement = (props: ButtonTileImageAddElementProps) => {
    // Mobile
    if (props.direction === 'column') {
        return (
            <ButtonTile.Image size={[60, 102]} direction={props.direction} className={styles.addElementMobile}>
                <ButtonTile.ImageOverlay>
                    <div className={styles.tileImageAddElement}>
                        <AddElement />
                    </div>
                </ButtonTile.ImageOverlay>
            </ButtonTile.Image>
        );
    }

    // Desktop
    return (
        <ButtonTile.Image size={[82, 82]} direction={props.direction}>
            <ButtonTile.ImageOverlay>
                <div className={styles.tileImageAddElement}>
                    <AddElement />
                </div>
            </ButtonTile.ImageOverlay>
        </ButtonTile.Image>
    );
};

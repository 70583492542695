import { throttle } from 'lodash';
import { useCallback, useLayoutEffect, useState } from 'react';

function useElementWidth() {
    const [width, setWidth] = useState(0);
    const [elementRef, setElementRef] = useState<HTMLElement | null>(null);

    const updateWidth = useCallback(
        throttle(() => {
            if (elementRef) {
                setWidth(elementRef.offsetWidth);
            }
        }, 100),
        [elementRef]
    );

    useLayoutEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [updateWidth]);

    return { width, setElementRef };
}

export default useElementWidth;

import { colors } from '@common/styles/colors';
import { css } from '@linaria/core';

const styles = {
    tableColumnHeader: css`
        display: flex;
        margin: auto;
        margin-top: 24px;
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%;
        padding-bottom: 3px;
        text-align: left;
    `,
    tableColumnGroup: css`
        display: flex;
        align-items: center;
        gap: 6px;
    `
};

interface TableColumnProps {
    children: React.ReactNode;
}

export const TableColumnHeader = ({ children }: TableColumnProps) => (
    <tr className={styles.tableColumnHeader}>{children}</tr>
);

export const TableColumnGroup = ({ children }: TableColumnProps) => (
    <th className={styles.tableColumnGroup} scope="colgroup" colSpan={4}>
        {children}
    </th>
);

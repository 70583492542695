import { default as CartSprite } from '@common/icons/src/cart-sprite.svg?react';
import { css, cx } from '@linaria/core';
import { forwardRef, LegacyRef } from 'react';

const iconSize = 30;

const styles = {
    wrapper: css`
        --icon-size: ${iconSize}px;

        width: var(--icon-size);
        height: var(--icon-size);
        overflow: hidden;

        &.active,
        &:hover {
            cursor: pointer;

            .sprite {
                @keyframes sprite {
                    100% {
                        transform: translateX(calc(var(--icon-size) * -8));
                    }
                }

                animation: sprite 0.3s steps(8) forwards;
            }
        }
    `,
    sprite: css`
        height: ${iconSize}px;
        width: auto;
    `
};

interface CartIconAnimationProps {
    animationActive?: boolean;
    animationDelay?: number;
    onAnimationEnd?: () => void;
}

export const CartIconAnimation = forwardRef<HTMLDivElement, CartIconAnimationProps>((props, ref) => {
    const { animationActive, animationDelay = 0, onAnimationEnd } = props;

    const handleAnimationEnd = () => {
        onAnimationEnd && onAnimationEnd();
    };

    return (
        <div className={cx(styles.wrapper, animationActive && 'active')}>
            <CartSprite
                style={{ animationDelay: `${animationDelay}s` }}
                className={cx('sprite', styles.sprite)}
                onAnimationEnd={handleAnimationEnd}
                ref={ref as LegacyRef<SVGSVGElement>}
            />
        </div>
    );
});

CartIconAnimation.displayName = 'CartIconAnimation';

import { BffConfiguratorConfiguration, BffConfiguratorConfigurationColor } from '@common/graphql/sdk';
import { useConfig } from '@common/hooks/use-config';
import { useMedia } from '@common/hooks/use-media';
import { media } from '@common/styles/media';
import { AddToCartButton, Layer, Panel } from '@components/configurator';
import { ButtonAddToWishlist } from '@components/configurator/components/button-add-to-wishlist';
import { PanelSurfaceSize } from '@components/configurator/components/panel/panel-surface';
import { ToolbarMobile } from '@components/configurator/components/toolbar';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useConfigurator, useShoppingList, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { AnimatePresence, motion } from 'framer-motion';
import { Modal, ModalOverlay } from 'react-aria-components';

const styles = {
    configuratorPanels: css`
        position: relative;
        margin: 9px 3px 3px;
        width: calc(100dvw - 6px);
        z-index: 3;
        display: flex;
        flex-direction: column;

        ${media.md} {
            align-self: flex-end;
            margin: 0;
            position: absolute;
            flex-direction: row;
            height: 100%;
            width: auto;
        }
    `,
    configuratorPanel: css`
        height: 100dvh;
        border-radius: 0;

        ${media.md} {
            margin: 12px;
            height: auto;
            display: flex;
            position: relative;

            > div {
                overflow: hidden;
            }
        }
    `,
    configuratorLayer: css`
        ${media.md} {
            display: flex;
            height: 100%;
            overflow: hidden;
        }
    `,
    configuratorModal: css`
        position: fixed;
        inset: 0;
        height: 100dvh;
        width: 100dvw;
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};
    `
};

export const ConfiguratorStandardPanels = () => {
    const shareUrl = useConfig('shareUrl', '');

    const layerId = useConfigurator((state) => state.ui.id);
    const filterLayerId = useConfigurator((state) => state.ui.filterLayerId);
    const tab = useConfigurator((state) => state.ui.tab);
    const filters = useConfigurator((state) => state.ui.filters);

    const updateShoppingList = useConfigurator((state) => state.updateShoppingList);
    const createShareLink = useConfigurator((state) => state.createShareLink);
    const data = useConfigurator((state) => state.data);
    const onClearFilter = useConfigurator((state) => state.onClearFilter);
    const onSetFilter = useConfigurator((state) => state.onSetFilter);
    const onToggleFilterLayerById = useConfigurator((state) => state.onToggleFilterLayerById);
    const getFilteredColors = useConfigurator((state) => state.getFilteredColors);
    const { shoppingList } = useShoppingList((state) => state);
    const isCompareOpen = useConfigurator((state) => state.ui.isCompareOpen);

    const visible = useViewer((state) => state.loaded);

    const configuration = data?.configurations?.find((config) => config.id === layerId) as BffConfiguratorConfiguration;

    const filteredColors = getFilteredColors(configuration?.id);

    const { md } = useMedia();

    const renderLayer = () => {
        const variants = {
            initial: { x: '100%' },
            enter: { x: '0%' },
            exit: { x: '-100%' }
        };

        const motionProps = {
            key: layerId,
            transition: { duration: 0.35 },
            variants,
            initial: 'initial',
            animate: 'enter',
            exit: 'initial',
            className: cx('configurator-standard-layer-primary', styles.configuratorLayer)
        };

        if (!layerId) {
            return (
                <motion.div {...motionProps} initial="exit" animate="enter" exit="exit">
                    <Layer.Overview />
                </motion.div>
            );
        }

        return (
            <motion.div {...motionProps}>
                <Layer.Configure id={layerId} />
            </motion.div>
        );
    };

    const renderFilterLayer = () => {
        const filterData = data?.configurations?.find(
            (config) => config.id === filterLayerId
        ) as BffConfiguratorConfigurationColor;

        if (!filterData || isCompareOpen) {
            return null;
        }

        const panel = (
            <motion.div
                key={'secondary'}
                className={cx('configurator-layer', styles.configuratorLayer)}
                initial={{ x: '50%', opacity: 0 }}
                animate={{ x: '0%', opacity: 1 }}
                exit={{ x: '50%', opacity: 0 }}
                transition={{ duration: 0.25 }}
            >
                <div className={styles.configuratorPanel}>
                    <Panel.Surface rounded={md}>
                        <Layer.ConfigureColorFilter
                            resultCount={filteredColors.length}
                            activeFilters={filters}
                            onClearFilter={onClearFilter}
                            onSetFilter={onSetFilter}
                            onClose={() => onToggleFilterLayerById(filterLayerId as string)}
                            colors={filterData.colors || []}
                            materials={filterData.materials || []}
                        />
                    </Panel.Surface>
                </div>
            </motion.div>
        );

        if (md) return <AnimatePresence>{filterLayerId && panel}</AnimatePresence>;

        return (
            <ModalOverlay className={styles.configuratorModal}>
                <Modal>{panel}</Modal>
            </ModalOverlay>
        );
    };

    if (!data) {
        return null;
    }

    if (md) {
        let panelSize: PanelSurfaceSize = 'base';

        if (tab === 'saved' && shoppingList?.lineItems && shoppingList.lineItems.length > 0) {
            panelSize = 'lg';
        }

        if (isCompareOpen) {
            panelSize = 'xl';
        }

        return (
            <div className={styles.configuratorPanels}>
                {renderFilterLayer()}

                <div className={cx(styles.configuratorPanel)}>
                    <Panel.Surface size={panelSize}>
                        <AnimatePresence mode="popLayout" initial={false}>
                            {renderLayer()}
                        </AnimatePresence>

                        {md && tab !== 'saved' && !isCompareOpen && (
                            <Panel.Footer>
                                <ButtonAddToWishlist updateShoppingList={updateShoppingList} isModular={false} />
                                <AddToCartButton sku={data?.sku} />
                            </Panel.Footer>
                        )}
                    </Panel.Surface>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.configuratorPanels}>
                {visible && (
                    <ToolbarMobile
                        onShare={() => createShareLink(shareUrl)}
                        hasResetView={true}
                        hasDimensions={true}
                        onUpdateShoppingList={updateShoppingList}
                    />
                )}

                <AnimatePresence>{filterLayerId && renderFilterLayer()}</AnimatePresence>

                <Panel.Surface>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {renderLayer()}
                    </AnimatePresence>
                </Panel.Surface>
            </div>
        </>
    );
};

import { media } from '@common/styles/media';
import { canUseDOM } from 'exenv';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

interface Breakpoint {
    [index: string]: boolean;
}

export const matchMediaToScreenWidth = (): Breakpoint => {
    // Get the keys from media and turn them into an array
    const breakpoints = Object.keys(media) as Array<keyof typeof media>;
    const results: { [index: string]: boolean } = {};

    if (!canUseDOM) {
        for (const breakpoint of breakpoints) {
            results[breakpoint] = false; // Assuming false if DOM is not available
        }
        return results;
    }

    for (const breakpoint of breakpoints) {
        const query = media[breakpoint]?.replace('@media only screen and ', '');
        const matches = query ? window.matchMedia(query).matches : false;
        results[breakpoint] = matches;
    }

    return results;
};

export const useMedia = (): Breakpoint => {
    const [breakpoints, setBreakpoints] = useState(matchMediaToScreenWidth());

    useEffect(() => {
        if (!canUseDOM) {
            return;
        }

        const supportsOrientationChange = 'onorientationchange' in window;
        const orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';

        const onChange = debounce(() => {
            const breakpointName = matchMediaToScreenWidth();

            setBreakpoints(breakpointName);
        }, 50);

        window.addEventListener(orientationEvent, onChange);

        // Set inital value
        setBreakpoints(matchMediaToScreenWidth());

        return () => {
            window.removeEventListener(orientationEvent, onChange);
        };
    }, []);

    return breakpoints;
};

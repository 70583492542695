import { BffConfiguratorConfigurationColor } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import { useModularConfigurator } from '@components/configurator/hooks';
import { t } from '@lingui/macro';

// Used as an overview to change attributes for Multiple Products
export const LayerModularCategoryAll = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const pushLayer = useModularConfigurator((state) => state.pushLayer);

    // commonConfigurations are the configurations that are shared between all products
    const commonConfigurations = useModularConfigurator((state) => state.activeData)?.commonConfigurations || [];
    const products = useModularConfigurator((state) => state.activeData)?.products || [];
    const colors = useModularConfigurator((state) => state.activeData)?.colors || [];
    const materials = useModularConfigurator((state) => state.activeData)?.materials || [];

    const { md } = useMedia();
    const title = t`Change all Materials`;

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    const activeSkus = products?.map((product) => product.sku) || [];

    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {commonConfigurations.map((configuration) => {
                if (configuration.__typename === 'BffConfiguratorConfigurationColor') {
                    const matchingIds = new Set(
                        products
                            .flatMap((product) =>
                                product.configurations?.find((config) => config.id === configuration.id)
                            )
                            .map((config) => config as BffConfiguratorConfigurationColor)
                            .flatMap((config) => config?.colors || [])
                            .filter((color) => activeSkus.includes(color.sku))
                            .map((color) => color.id)
                    );

                    const matchingColors = colors.filter((color) => matchingIds.has(color.id));
                    if (matchingColors.length === 0) {
                        return null;
                    }
                    const firstMatchingColor = matchingColors[0];
                    const matchingNames = matchingColors
                        .map((color) => {
                            const material = materials.find((m) => m.id === color.material);
                            return [material?.name, color.title].join(': ');
                        })
                        .join(', ');

                    return (
                        <ButtonTile.Item key={configuration.id} direction={tileDirection}>
                            <ButtonTile.Surface
                                direction={tileDirection}
                                onClick={() => {
                                    pushLayer(`change-all-materials-${configuration.id}`);
                                }}
                            >
                                <ButtonTile.Image
                                    size={thumbnailSize}
                                    direction={tileDirection}
                                    image={firstMatchingColor.listingImage as ButtonTileImageImage}
                                />
                                <ButtonTile.Text
                                    direction={tileDirection}
                                    title={configuration.title}
                                    size="large"
                                    subtitle={matchingNames}
                                />
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                }
                if (configuration.__typename === 'BffConfiguratorConfigurationAttributes') {
                    const selected = configuration.values?.find((el) => el.selected);
                    return (
                        <ButtonTile.Item key={configuration.id} direction={tileDirection}>
                            <ButtonTile.Surface
                                direction={tileDirection}
                                onClick={() => {
                                    pushLayer(`change-all-attributes-${configuration.id}`);
                                }}
                            >
                                <ButtonTile.Image
                                    direction={tileDirection}
                                    size={thumbnailSize}
                                    image={selected?.image as ButtonTileImageImage}
                                />
                                <ButtonTile.Text
                                    direction={tileDirection}
                                    title={configuration.title}
                                    size="large"
                                    subtitle={configuration.selected}
                                />
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                }
            })}
        </ButtonTile.List>
    );

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{title}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation kind="back" onPress={popLayer} appearance="mobile" title={title} />
            <Panel.ScrollArea direction="horizontal">{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

import { Spinner } from '@common/primitives/spinner';
import { media } from '@common/styles/media';
import { Recommendations } from '@components/configurator';
import { PANEL_WIDTH, TAB_HEIGHT_MOBILE } from '@components/configurator/components/panel/constants';
import { ConfiguratorRecommendation } from '@components/configurator/components/recommendations/recommendations';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css } from '@linaria/core';
import { t } from '@lingui/macro';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

const styles = {
    presets: css`
        position: absolute;
        height: calc(100dvh - ${TAB_HEIGHT_MOBILE}px);
        width: 100%;
        top: 0;
        background-color: #f5f5f5;
        z-index: 1;
        display: flex;

        ${media.md} {
            height: 100%;
            padding-right: ${PANEL_WIDTH.BASE}px;
            z-index: 2;
            right: 0;
            background: #f5f5f5;
        }
    `,
    spinner: css`
        align-self: center;
        margin: 0 auto;
    `
};

export const ConfiguratorStandardRecommended = () => {
    const data = useConfigurator((state) => state.data);
    const history = useConfigurator((state) => state.history);
    const onSetTab = useConfigurator((state) => state.onSetTab);
    const tab = useConfigurator((state) => state.ui.tab);
    const updateViewer = useViewer((state) => state.updateViewer);
    const viewerLoaded = useViewer((state) => state.loaded);
    const screenshotCurrentConfiguration = useViewer((state) => state.screenshotCurrentConfiguration);
    const generateScreenshotsCurrentConfiguration = useViewer((state) => state.generateScreenshotsCurrentConfiguration);
    const setRecommendationId = useConfigurator((state) => state.setRecommendationId);
    const setRecommendationsStatus = useConfigurator((state) => state.setRecommendationsStatus);
    const presets = useConfigurator((state) => state.presets);

    const updateRemoteConfiguration = useConfigurator((state) => state.updateRemoteConfiguration);
    const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(false);
    const toggleRecommendationLoadingError = useConfigurator((state) => state.toggleRecommendationLoadingError);

    // We need to watch if the viewer is loading and ready.
    // We MUST prevent the user to load a recommendation if the viewer is loading otherwise emersya complains
    const isLoading = useConfigurator((state) => state.ui.isLoading);

    // We must reset the preset if the recommendation tab is mounted
    // otherwise the panel on the right would show the wrong
    // products if switching between the tabs
    useEffect(() => {
        if (tab === 'recommended' && presets.recommendations && presets.recommendations.length > 0) {
            setRecommendationId(presets.recommendations[0].id);
        }
    }, [tab]);

    // Set the current configuration as the first recommendation if we have a history
    // which means that the user already interacted with the configurator
    useEffect(() => {
        const loadCurrentConfigurationImage = async () => {
            await generateScreenshotsCurrentConfiguration();
        };
        if (tab === 'recommended' && history.length > 0) {
            loadCurrentConfigurationImage();
        }
    }, [history, tab]);

    // Load the thumbs of the recommendations in the screenshotscache
    useEffect(() => {
        const loadPanelScreenshots = async () => {
            if (!presets.recommendations) {
                return;
            }
            for (const r of presets.recommendations) {
                await updateViewer(r.id);
            }
            setRecommendationsStatus('loaded');
        };

        if (viewerLoaded && tab === 'recommended' && presets.state !== 'loaded') {
            loadPanelScreenshots();
        }
    }, [viewerLoaded, tab]);

    const handleEdit = useCallback(
        async (recommendation: ConfiguratorRecommendation) => {
            if (recommendation.id === history.at(-1)) {
                onSetTab('builder');
                return;
            }
            try {
                setIsLoadingConfiguration(true);

                //@todo: WE MUST GET THE prices and delivery times for the recommendation
                // Curretly this is doen via the getProductsAndConfiguration BFF API
                await updateRemoteConfiguration(recommendation.id);
                onSetTab('builder');
            } catch {
                toggleRecommendationLoadingError(true, t`Looks like there has been an error`);
            } finally {
                setIsLoadingConfiguration(false);
            }
        },
        [updateRemoteConfiguration, onSetTab]
    );

    const handleSelect = useCallback(
        (recommendation: ConfiguratorRecommendation) => {
            setRecommendationId(recommendation.id);
        },
        [setRecommendationId]
    );

    const currentConfiguration = screenshotCurrentConfiguration && {
        id: history.at(-1) ?? '',
        image: {
            src: screenshotCurrentConfiguration
        },
        configurations: data?.configurations,
        name: t`Current Configuration`,
        price: data?.price,
        delivery: data?.delivery
    };

    const recommendations =
        (presets.recommendations && [
            // Only add current configuration if it exists
            ...(currentConfiguration ? [currentConfiguration] : []),
            // If a recommendation matches the current configuration, do not show it
            ...presets.recommendations.filter((r: any) => r.id !== history.at(-1))
        ]) ||
        [];

    return (
        <AnimatePresence>
            {tab === 'recommended' && (
                <div className={styles.presets}>
                    {(presets.state === 'loading' || isLoading) && <Spinner className={styles.spinner} />}
                    {presets.state === 'loaded' && !isLoading && (
                        <Recommendations
                            isLoadingSharedConfiguration={isLoadingConfiguration}
                            onEdit={handleEdit}
                            onSelect={handleSelect}
                            recommendations={recommendations}
                        />
                    )}
                </div>
            )}
        </AnimatePresence>
    );
};

import { TabPanel } from '@common/primitives';
import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    tabPanel: css`
        height: 100%;
        max-width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
    `,
    tabPanelContent: css`
        max-width: 100%;
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        /*
            WARNING: DONT GIVE 100% HEIGHT, OTHERWISE THE AREA WILL OVERFLOW THE TABS
            height: 100%;
        */
    `
};

interface PanelTabPanelProps {
    id: string;
    className?: string;
    children: React.ReactNode;
}

interface PanelContentProps {
    id?: string;
    className?: string;
    children: React.ReactNode;
}

/**
 * PanelContent is used to vertically center the content of a panel
 * It should only be used if a panel doesn't use PanelTabs.
 * Maybe we should extract tis to a new file
 */
export const PanelContent = (props: PanelContentProps) => {
    const { className, children, id = 'panel-content' } = props;
    return <div className={cx(id, styles.tabPanelContent, className)}>{children}</div>;
};

export const PanelTabPanel = (props: PanelTabPanelProps) => {
    const { className, children, id } = props;

    return (
        <TabPanel id={id} className={cx('panel-tab-panel', styles.tabPanel, className)}>
            <PanelContent id="panel-tab-panel-content" className={styles.tabPanelContent}>
                {children}
            </PanelContent>
        </TabPanel>
    );
};

import { ArrowRight } from '@common/icons';
import { css, cx } from '@linaria/core';

const styles = {
    tileChevron: css`
        padding: 0 0 0 26px;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `
};

export const ButtonTileChevron = () => {
    return <span className={cx('tile-icon', styles.tileChevron)}>{<ArrowRight />}</span>;
};

import { useMedia } from '@common/hooks/use-media';
import { Skeleton } from '@common/primitives/skeleton';
import { media } from '@common/styles/media';
import { BaseWidget } from '@components/base-widget';
import { Header, type WidgetConfigurator } from '@components/configurator';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useConfigurator, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { styles as buttonStyles } from '@primitives/button/button';
import { useEffect, useRef } from 'react';
import { Button as DefaultButton, Modal, ModalOverlay } from 'react-aria-components';

import { ConfiguratorStandardHeader } from './configurator-standard-header';
import { ConfiguratorStandardPanels } from './configurator-standard-panels';
import { ConfiguratorStandardRecommended } from './configurator-standard-recommended';
import { ConfiguratorStandardViewer } from './configurator-standard-viewer';

const styles = {
    configuratorWrapper: css`
        margin-bottom: 3.8rem;
        overflow: hidden;

        > h2 {
            font-weight: 100;
            text-align: center;
            font-size: 1.882rem;
            line-height: 3.8rem;
            padding-bottom: 1.9rem;

            ${media.md} {
                padding-bottom: 52px;
                font-size: 2.942rem;
                line-height: 3.8rem;
            }
        }
    `,
    configuratorWrapperDoubleMargin: css`
        margin-bottom: 7.6rem;
    `,
    configuratorWrapperNoMargin: css`
        margin-bottom: 0;
    `,
    configuratorImage: css`
        width: 100%;
        height: 100%;
        object-fit: contain;
    `,
    configuratorImageContainer: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    configuratorImageTint: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        mix-blend-mode: multiply;
    `,
    configuratorText: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 22px;
        padding-bottom: 70px;
        background: #f5f5f5;
        justify-content: center;

        .header-body {
            align-self: center;
        }
    `,
    configuratorButton: css`
        padding: 14px 21px;
        background-color: #333;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%;
        letter-spacing: 1.96px;
        text-transform: uppercase;

        &[data-pressed] {
            background-color: #666;
        }
    `,
    configuratorModal: css`
        position: fixed;
        inset: 0;
        display: flex;
        background-color: #f5f5f5;
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};
    `,
    configuratorSkeleton: css`
        height: 612px;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        z-index: 1;
        gap: 8px;
    `,
    configuratorSkeletonImage: css`
        margin-bottom: 24px;
    `,
    configuratorContainer: css`
        width: 100dvw;
        height: 100dvh;
        overflow: hidden;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #f5f5f5;

        ${media.md} {
            width: 100%;
            position: relative;
            height: 100dvh;
            max-height: 800px;
            max-width: 1600px;
            margin: auto;
            flex: 1;
        }
    `,
    configuratorContent: css`
        min-height: 612px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f5f5f5;
        padding: 64px 0;

        ${media.md} {
            padding: 0;
            height: 100vh;
            max-height: 800px;
        }
    `
};

type ConfiguratorStandardFullProps = WidgetConfigurator & { sharedProductId?: string };

export const ConfiguratorStandard = (props: ConfiguratorStandardFullProps) => {
    const { md } = useMedia();
    const { id, content, sharedProductId, options } = props;
    const viewerLoaded = useViewer((state) => state.loaded);
    const data = useConfigurator((state) => state.data);
    const startConfiguration = useConfigurator((state) => state.startConfiguration);
    const updateRemoteConfiguration = useConfigurator((state) => state.updateRemoteConfiguration);
    const isModalOpen = useConfigurator((state) => state.ui.isModalOpen);
    const setIsModalOpen = useConfigurator((state) => state.setIsModalOpen);
    const onSetTab = useConfigurator((state) => state.onSetTab);
    const initRecommendations = useConfigurator((state) => state.initRecommendations);

    const storeScrollTop = useRef(0);
    useEffect(() => {
        const body = document.querySelector('body');
        if (isModalOpen) {
            storeScrollTop.current = window ? window.scrollY : 0;
        }
        body!.style.overflow = isModalOpen ? 'hidden' : 'auto';
        body!.style.position = isModalOpen ? 'fixed' : 'initial';
        return () => {
            body!.style.overflow = 'auto';
            body!.style.position = 'initial';
            body!.style.overscrollBehaviorY = 'none';
            if (!isModalOpen || !window) return;
            window!.scrollTo(0, storeScrollTop.current);
        };
    }, [isModalOpen]);

    // Initalizer,
    // * Load presets (prices and delivery)
    // * load shared sku or widget defined sku
    useEffect(() => {
        const loadPresets = async () => {
            if (content) {
                await initRecommendations(content.recommendations);
                if (md) {
                    if (sharedProductId) {
                        onSetTab('builder');
                    } else if (
                        props.content.recommendations &&
                        props.content.recommendations.length > 0 &&
                        !options.skipRecommendationsView
                    ) {
                        onSetTab('recommended');
                    } else {
                        onSetTab('builder');
                    }
                }
            }
        };

        loadPresets();
        const p = sharedProductId || props.content.product.id;
        startConfiguration(p);
    }, [sharedProductId]);

    // Listen to prop changes and update the configurator
    // Mainly used for the storybook
    useEffect(() => {
        const updateConfiguration = async () => {
            await updateRemoteConfiguration(props.content.product.id);
        };
        if (viewerLoaded) {
            updateConfiguration();
        }
    }, [props.content.product.id]);

    const skeleton = (
        <div className={styles.configuratorSkeleton}>
            <Skeleton width={120} height={120} className={styles.configuratorSkeletonImage} />
            <Skeleton width="65%" height={20} />
            <Skeleton width="20%" height={20} />
            <Skeleton width={140} height={45} />
        </div>
    );

    const configuratorElement = (
        <div className={cx('configurator-standard', 'configurator-fullscreen', styles.configuratorContainer)}>
            <ConfiguratorStandardViewer />
            <ConfiguratorStandardHeader />
            <ConfiguratorStandardPanels />
            <ConfiguratorStandardRecommended />
        </div>
    );

    // If it's desktop, render the configurator inline
    if (md) {
        return (
            <BaseWidget key={id}>
                <div
                    className={cx(
                        styles.configuratorWrapper,
                        props.options.margin === 'DoubleBottomMargin' && styles.configuratorWrapperDoubleMargin,
                        props.options.margin === 'NoBottomMargin' && styles.configuratorWrapperNoMargin
                    )}
                >
                    <h2>
                        <Trans>Configure</Trans>
                    </h2>
                    <div className={styles.configuratorContent}>{configuratorElement}</div>
                </div>
            </BaseWidget>
        );
    }

    // If it's mobile render a PDP that triggers a modal
    return (
        <BaseWidget id="configure" key={id}>
            <div
                className={cx(
                    styles.configuratorWrapper,
                    props.options.margin === 'DoubleBottomMargin' && styles.configuratorWrapperDoubleMargin,
                    props.options.margin === 'NoBottomMargin' && styles.configuratorWrapperNoMargin
                )}
            >
                <h2>
                    <Trans>Configure</Trans>
                </h2>
                {data ? (
                    <>
                        <div className={styles.configuratorImageContainer}>
                            <img className={styles.configuratorImage} src={data?.image?.src} alt={''} />
                            <div className={styles.configuratorImageTint} />
                        </div>
                        <div className={styles.configuratorText}>
                            <Header name={data?.name} price={data?.price} shipping={data?.delivery?.deliveryTime} />
                            <DefaultButton
                                className={cx(buttonStyles.reset, styles.configuratorButton)}
                                onPress={() => setIsModalOpen(true)}
                            >
                                <Trans>Configure</Trans>
                            </DefaultButton>
                        </div>
                    </>
                ) : (
                    skeleton
                )}
            </div>
            <ModalOverlay isOpen={isModalOpen}>
                <Modal className={cx('standard-widget-modal', styles.configuratorModal)}>{configuratorElement}</Modal>
            </ModalOverlay>
        </BaseWidget>
    );
};

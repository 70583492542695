import { useIsTouchDevice } from '@common/hooks/use-is-touch-device';
import { colors } from '@common/styles/colors';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities/useSyntheticListeners';
import { css, cx } from '@linaria/core';
import React, { createContext, useContext, useState } from 'react';
import { useFocusRing } from 'react-aria';
import { Button as DefaultButton, ButtonProps as DefaultButtonProps, PressEvent } from 'react-aria-components';

const styles = {
    reset: css`
        all: unset;
        display: inline-flex;
        flex-shrink: 0;
    `,
    interactive: css`
        cursor: pointer;
    `,
    focusVisible: css`
        outline: 1.5px solid ${colors.coral2};
        outline-offset: 3px;
    `,
    tile: css`
        display: flex;
        background: white;
        border-radius: 6px;
        width: 100%;

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
            outline-offset: 3px;
        }

        &.state-selected,
        &[data-pressed] {
            background: #f2f2f2;
            box-shadow: 0 2px 4px 0 rgba(0 0 0 / 22%) inset;
        }
    `,
    tileDirectionColumn: css`
        flex-direction: column;
        max-width: 100%;
    `
};

type DraggableButtonTileSurfaceProps = DraggableAttributes &
    Partial<SyntheticListenerMap> & {
        id: string;
    };

export interface ButtonTileSurfaceProps extends DefaultButtonProps {
    selected?: boolean;
    className?: string;
    children: React.ReactNode;
    onClick?: (event: PressEvent) => void;
    disabled?: boolean;
    direction?: 'row' | 'column';
    immediate?: boolean;
    draggableAttributes?: DraggableButtonTileSurfaceProps;
}

const ButtonTileSurfaceContext = createContext({
    isPressed: false
});

// Custom hook to use the ButtonTileSurfaceContext
export const useButtonTileSurfaceContext = () => useContext(ButtonTileSurfaceContext);

export const ButtonTileSurface = React.forwardRef<HTMLDivElement | HTMLButtonElement, ButtonTileSurfaceProps>(
    (props, ref) => {
        const {
            selected,
            onClick,
            className,
            children,
            direction,
            onHoverChange,
            immediate,
            disabled = false,
            draggableAttributes
        } = props;

        const handleClick = (event: any) => {
            if (onClick) {
                onClick(event);
            }
        };

        const { isFocusVisible, focusProps } = useFocusRing();

        const Element = onClick ? DefaultButton : 'div';

        const isTouchDevice = useIsTouchDevice();

        const [isPressed, setIsPressed] = useState(false);

        return (
            <ButtonTileSurfaceContext.Provider value={{ isPressed }}>
                <Element
                    ref={ref}
                    {...(onClick && immediate && !isTouchDevice ? { onPressStart: handleClick } : {})}
                    {...(onClick && (!immediate || isTouchDevice) ? { onPress: handleClick } : {})}
                    {...(onClick && { onHoverChange: onHoverChange })}
                    {...(!onClick && !disabled && { role: 'button', tabIndex: 0 })}
                    {...(onClick && { ...(focusProps as any) })}
                    {
                        /*
                        draggableAttributes are passed via draggable elements.
                        These have no onClick handler but should be focussable via keyboard
                    */
                        ...((onClick || draggableAttributes?.onKeyDown) && { ...(focusProps as any) })
                    }
                    {...draggableAttributes}
                    {...(onClick &&
                        isTouchDevice && {
                            onPressStart: () => {
                                setIsPressed(true);
                            }
                        })}
                    {...(onClick &&
                        isTouchDevice && {
                            onPressEnd: () => {
                                setIsPressed(false);
                            }
                        })}
                    onTouchStart={(e) => {
                        draggableAttributes?.onTouchStart?.(e);
                        setIsPressed(true);
                    }}
                    onTouchEnd={() => {
                        setIsPressed(false);
                    }}
                    aria-disabled={disabled}
                    className={cx(
                        'tile-surface',
                        `tile-surface--${direction}`,
                        isFocusVisible && !onClick && styles.focusVisible,
                        styles.reset,
                        styles.tile,
                        selected && 'state-selected',
                        className,
                        direction === 'column' && styles.tileDirectionColumn,
                        onClick && styles.interactive
                    )}
                >
                    {children}
                </Element>
            </ButtonTileSurfaceContext.Provider>
        );
    }
);

ButtonTileSurface.displayName = 'ButtonTileSurface';

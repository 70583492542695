import {
    MaterialAbrasionResistance,
    MaterialAreaOfUse,
    MaterialAreaOfUseOffice,
    MaterialDirtResistance,
    MaterialEasyToClean,
    MaterialFastnessToLight,
    MaterialFastnessToRubbing,
    MaterialHairResistance,
    MaterialLowMaintenance,
    MaterialMaterial,
    MaterialMisc,
    MaterialNaturalness,
    MaterialOriginBE,
    MaterialOriginDE,
    MaterialOriginES,
    MaterialOriginEU,
    MaterialOriginGB,
    MaterialOriginHU,
    MaterialOriginIT,
    MaterialOriginNL,
    MaterialOriginUS,
    MaterialPiling,
    MaterialPricingGroup,
    MaterialTemperatureBehaviour,
    MaterialVegan,
    MaterialWeight,
    MaterialWidth
} from '@common/icons';

export const icons = {
    easy_to_clean: <MaterialEasyToClean />,
    low_maintenance: <MaterialLowMaintenance />,
    area_of_use: <MaterialAreaOfUse />,
    area_of_use_office: <MaterialAreaOfUseOffice />,
    material: <MaterialMaterial />,
    misc: <MaterialMisc />,
    width: <MaterialWidth />,
    weight: <MaterialWeight />,
    abrasion_resistance: <MaterialAbrasionResistance />,
    fastness_to_light: <MaterialFastnessToLight />,
    fastness_to_rubbing: <MaterialFastnessToRubbing />,
    pilling: <MaterialPiling />,
    place_of_origin_DE: <MaterialOriginDE />,
    place_of_origin_EU: <MaterialOriginEU />,
    place_of_origin_IT: <MaterialOriginIT />,
    place_of_origin_GB: <MaterialOriginGB />,
    place_of_origin_HU: <MaterialOriginHU />,
    place_of_origin_NL: <MaterialOriginNL />,
    place_of_origin_US: <MaterialOriginUS />,
    place_of_origin_BE: <MaterialOriginBE />,
    place_of_origin_ES: <MaterialOriginES />,
    vegan: <MaterialVegan />,
    vegan2: <MaterialVegan color="#70AF7E" />,
    pricing_group: <MaterialPricingGroup />,
    dirt_resistance: <MaterialDirtResistance />,
    hair_resistance: <MaterialHairResistance />,
    naturalness: <MaterialNaturalness />,
    temperature_behaviour: <MaterialTemperatureBehaviour />
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useHover } from 'react-aria';

const styles = {
    marqueeWrapper: css`
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        outline-color: ${colors.coral2};
        width: 100%;

        > div {
            display: flex;
        }
    `,
    marquee_content: css`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        transform: translateX(0);
        transition: transform 10s linear;
    `,
    marquee_content_animating: css`
        width: auto;
        overflow: visible;
    `,
    marqueq__measure: css`
        position: absolute;
        top: 0;
        visibility: hidden;
        white-space: nowrap;
    `
};

interface MarqueeTextProps {
    children?: React.ReactNode;
    text?: string;
    active?: boolean;
}

export const MarqueeText = (props: MarqueeTextProps) => {
    const PIXELS_PER_SECOND = 100;

    const { text, children, active = false } = props;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const measureRef = useRef<HTMLDivElement>(null);
    const [contentWidth, setContentWidth] = useState(0);
    const [travelDistancePixels, setTravelDistancePixels] = useState(0);

    const calculateX = () => {
        if (contentRef.current && wrapperRef.current && measureRef.current) {
            setContentWidth(measureRef.current.scrollWidth);
            setTravelDistancePixels(wrapperRef.current.clientWidth - measureRef.current.scrollWidth);
        }
    };
    useEffect(() => {
        calculateX();
        addEventListener('resize', () => {
            calculateX();
        });
        return () => {
            removeEventListener('resize', () => {
                calculateX();
            });
        };
    }, [text, children]);

    const [animate, setAnimate] = useState(active);

    useEffect(() => {
        setAnimate(active);
    }, [active]);

    const { hoverProps } = useHover({
        onHoverStart: () => setAnimate(true),
        onHoverEnd: () => setAnimate(false)
    });

    const duration = contentWidth / PIXELS_PER_SECOND;

    const variants = {
        initial: { x: 0 },
        animate: {
            x: [0, travelDistancePixels - 10],
            transition: {
                x: {
                    repeat: Number.POSITIVE_INFINITY,
                    repeatType: 'reverse',
                    duration,
                    ease: 'linear'
                }
            }
        }
    };

    return (
        <div ref={wrapperRef} className={styles.marqueeWrapper} {...hoverProps}>
            <motion.div
                variants={variants}
                initial={{ x: 0 }}
                animate={animate && travelDistancePixels < 0 ? 'animate' : 'initial'}
            >
                <motion.div
                    ref={contentRef}
                    className={cx(
                        styles.marquee_content,
                        animate && travelDistancePixels < 0 && styles.marquee_content_animating
                    )}
                >
                    {text ?? children}
                </motion.div>
            </motion.div>
            <div aria-hidden={true} className={styles.marqueq__measure} ref={measureRef}>
                {text ?? children}
            </div>
        </div>
    );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BffCountry, BffLanguage, BffMarket } from '@common/graphql/sdk';
import { unmarshalSKU } from '@common/utils/sku';
import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export function useConfig<T>(key: keyof FrontendConfigKeys, defaultValue: T): T {
    if (!canUseDOM || process.env.NODE_ENV === 'test') {
        return defaultValue;
    }
    const configValue = get((window as any).config, key);
    if (configValue === undefined && process.env.NODE_ENV !== 'test') {
        console.warn(`Config value for: ${key} is not set, falback to default value: ${defaultValue}`);
        return defaultValue as T;
    }
    return configValue as T;
}

export const generateImageLinkFromSku = (sku: string, transparent = false): string => {
    const staticDomain = useConfig('staticDomain', '');
    const { articleNo, configurationId } = unmarshalSKU(sku);
    const parts = [staticDomain, 'products', articleNo, configurationId].filter(Boolean);
    return parts.join('/') + (transparent ? '.png' : '.jpg');
};

export const isNotProduction = useConfig<string>('env', 'development') !== 'production';
export const getBffLanguageAndCountry = (): {
    language: BffLanguage;
    country: BffCountry;
    market: BffMarket;
    code: string;
    showPrices: boolean;
    includingTax: boolean;
    getCurrencySymbol: (currency: string) => string | null;
} => {
    const code = useConfig('code', 'de-de');
    const countryConfigFromUrl: string = useConfig('country', 'DE');
    const codeParts = code.split('-');
    const showPrices = useConfig('showPrices', false);
    const includingTax = countryConfigFromUrl !== 'US';
    const languageSet: Set<string> = new Set(Object.values(BffLanguage));
    let language: BffLanguage = `${codeParts[0]}`.toLowerCase() as BffLanguage;

    if (!languageSet.has(language)) {
        language = BffLanguage.En;
    }

    const market = `${codeParts[1]}`.toUpperCase() as BffMarket;

    const getCurrencySymbol = (currency: string) => {
        if (currency === 'DKK' || currency === 'CHF' || !currency) {
            return null;
        }

        const parts = new Intl.NumberFormat(code, {
            style: 'currency',
            currency
        }).formatToParts(0);

        const currencySymbol = parts.find((part) => part.type === 'currency')?.value || '';

        return currencySymbol.at(-1) || '€';
    };

    // @todo: check how we do this, we need to import all the shop config

    // const shopConfig = getConfig(codeParts[1] as BffCountry);
    // This overwrites the config with the shop country so we can have multiple fr-fr countries but always show France
    // const country = shopConfig.hasCart ? shopConfig.countryCode : countryConfigFromUrl;
    const country = countryConfigFromUrl;

    return {
        language,
        country: country as BffCountry,
        market,
        code,
        showPrices,
        includingTax,
        getCurrencySymbol
    };
};

export type FrontendConfigKeys = {
    env: string;
    code: string;
    configuratorUrl: string;
    deliveryUrl: string;
    translateUrl: string;
    gatewayMerchantId: string;
    adyenClientKeyV2: string;
    adyenClientKey: string;
    adyenEnv: string;
    cartUrl: string;
    shoppingCartUrl: string;
    userUrl: string;
    showPrices: boolean;
    shareUrl: string;
    loginUrl: string;
    authUrl: string;
    voucherUrl: string;
    contactUrl: string;
    uploadUrl: string;
    newsletterUrl: string;
    shareWishlistUrl: string;
    cartLegacyUrl: string;
    checkoutUrl: string;
    cartPageUrl: string;
    forgotPassword: string;
    wishlistUrl: string;
    accountUrl: string;
    searchApiUrl: string;
    findVitraCityData: string;
    findVitraUrl: string;
    findVitraContactUrl: string;
    productMaterialsUrl: string;
    wishlsitPrintUrl: string;
    wishlistPrintUrl: string;
    checkoutSuccessUrl: string;
    staticDomain: string;
    showWishlist: string;
    bff: string;
    hasUsercentrics: boolean;
    googleAnalyticsId: string;
    site: string;
    isLoggedIn: boolean;
    countriesData: any;
    country: string;
    CFCountry: string;
    googleMapsApiKey: string;
    features: string[];
    contactServiceUrl: string;
    careRepairUrl: string;
    productsOverviewUrl: string;
    searchPageUrl: string;
    cfTurnstileSiteKey: string;
    paqatoCode: string;
    sentryDns: string;
    currentRelease: string;
    professionalsUrl: string;
    registerUrl: string;
    baseUrl: string;
};

import { Spinner } from '@common/primitives/spinner/spinner';
import { css, cx } from '@linaria/core';

const styles = {
    tileImageSpinner: css`
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `
};

interface ButtonTileImageSpinnerProps {
    className?: string;
    color?: string;
}

export const ButtonTileImageSpinner = (props: ButtonTileImageSpinnerProps) => {
    const { color } = props;

    return (
        <span className={cx('tile-image-spinner', styles.tileImageSpinner, props.className)}>
            <Spinner color={color} />
        </span>
    );
};

import { useMedia } from '@common/hooks/use-media';
import useScrollIntoView from '@common/hooks/use-scroll-into-view';
import useSetAndReset from '@common/hooks/use-set-and-reset';
import { Check, Close, MenuHorizontal, ResetViewDesktop, Ruler, Share, Undo, ViewInRoomDesktop } from '@common/icons';
import { Spinner } from '@common/primitives';
import { Button } from '@common/primitives/button';
import { Saved } from '@common/primitives/indicators/saved';
import { colors } from '@common/styles/colors';
import { ButtonAddToWishlist } from '@components/configurator/components/button-add-to-wishlist';
import { ButtonIcon } from '@components/configurator/components/button-icon';
import { useShoppingList, useViewer } from '@components/configurator/hooks';
import { useClickoutSide } from '@hooks/use-click-outside';
import { css, cx } from '@linaria/core';
import { t, Trans } from '@lingui/macro';
import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Toolbar as DefaultToolbar } from 'react-aria-components';

const styles = {
    toolbar: css`
        position: absolute;
        bottom: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 53px;
    `,
    toolbarToggle: css`
        position: absolute;
        top: 0;
        right: 9px;
        z-index: 2;
    `,
    toolbarLeftButton: css`
        position: absolute;
        top: 0;
        left: 9px;
        z-index: 2;
        height: 40px;
        display: flex;
    `,
    toolbarLeftButtonAddToShoppingList: css`
        border-radius: 40px;
        color: black;
        font-size: 13px;
    `,
    toolbarToggleIcon: css`
        width: 40px;
        height: 40px;
        padding: 0;
    `,
    toolbarButtonSpinner: css`
        width: 16.5px;
        height: 16.5px;
        padding: 0;

        span {
            opacity: 1 !important;
            max-width: 7rem !important;
        }
    `,
    toolbarButtonCheckIcon: css`
        color: ${colors.coral2};
    `,
    toolbarList: css`
        position: absolute;
        inset: 0;
        z-index: 1;
        gap: 8px;
        display: flex;
        flex-direction: row-reverse;
        overflow: auto;
        padding-right: 62px;
        padding-left: 9px;
        padding-bottom: 13px;
    `
};
interface ToolbarProps {
    className?: string;
    onUndo?: () => void;
    onShare?: () => Promise<void | string>;
    onUpdateShoppingList?: () => Promise<void>;
    hasUndo?: boolean;
    hasDimensions?: boolean;
    hasResetView?: boolean;
    reRenderIfChanged?: any[];
}

export const ToolbarMobile = ({
    className,
    onUndo,
    onShare,
    hasUndo,
    hasDimensions,
    hasResetView,
    onUpdateShoppingList,
    reRenderIfChanged
}: ToolbarProps) => {
    const shoppingListStatus = useShoppingList((state) => state.status);

    const { startAr, checkAr, resetCamera, toggleRulers } = useViewer();
    const [hasAr, setHasAr] = useState<boolean>(true);

    const [expanded, setExpanded] = useState(false);
    const { md } = useMedia();

    // All Share Button related
    const [shareProcessing, setShareProcessing] = useState(false);
    const [shareSuccess, setAndResetShareSuccess] = useSetAndReset();

    const shareButtonRef = useRef<HTMLButtonElement>(null);
    const scrollIntoView = useScrollIntoView();

    useEffect(() => {
        const check = async () => {
            const ar = await checkAr();
            setHasAr(ar);
        };
        check();
    }, []);

    // We reset the saved success state when the reRenderIfChanged array changes
    // which should be the list of nodes in the viewer
    const [savedSuccess, setSavedSuccess] = useState(false);
    useEffect(() => {
        if (reRenderIfChanged) {
            setSavedSuccess(false);
        }
    }, [reRenderIfChanged]);

    const handleSave = async () => {
        if (onUpdateShoppingList) {
            await onUpdateShoppingList();
            setSavedSuccess(true);
        }
    };

    const toggletoolbar = () => {
        setExpanded(!expanded);
    };

    const onStartAr = async () => {
        startAr();
    };

    const onToggleRuler = async () => {
        await toggleRulers({ size: 16 });
    };

    const onResetView = () => {
        resetCamera();
    };

    const ref = useClickoutSide(
        () => {
            if (expanded) {
                setExpanded(!expanded);
            }
        },
        { active: expanded }
    ) as LegacyRef<HTMLDivElement> | undefined;

    if (md) return null;

    return (
        <DefaultToolbar ref={ref} className={cx('viewer-toolbar-mobile', styles.toolbar, className)}>
            {!hasUndo && onUpdateShoppingList && !expanded && (
                <div className={cx('viewer-toolbar-mobile-shoppinglist', styles.toolbarLeftButton)}>
                    <ButtonAddToWishlist
                        className={styles.toolbarLeftButtonAddToShoppingList}
                        isModular={false}
                        updateShoppingList={onUpdateShoppingList}
                    >
                        <Trans>Save to wishlist</Trans>
                    </ButtonAddToWishlist>
                </div>
            )}

            {hasUndo && !expanded && (
                <div className={cx('viewer-toolbar-mobile-undo', styles.toolbarLeftButton)}>
                    <Button variant="secondary" onPress={onUndo} rounded={true} icon={<Undo />}>
                        <Trans>Undo</Trans>
                    </Button>
                </div>
            )}

            <div className={cx('viewer-toolbar-mobile-toggle', styles.toolbarToggle)}>
                <ButtonIcon
                    aria-label={t`Show more`}
                    onPress={toggletoolbar}
                    className={styles.toolbarToggleIcon}
                    icon={expanded ? <Close /> : <MenuHorizontal />}
                />
            </div>

            {expanded && (
                <div className={styles.toolbarList}>
                    {hasAr && (
                        <Button variant="secondary" rounded={true} onPress={onStartAr} icon={<ViewInRoomDesktop />}>
                            <Trans>View in room</Trans>
                        </Button>
                    )}
                    <Button
                        ref={shareButtonRef}
                        variant="secondary"
                        onPress={async () => {
                            if (onShare) {
                                setShareProcessing(true);
                                await onShare();
                                setShareProcessing(false);
                                setAndResetShareSuccess(true, 5000);
                                setTimeout(() => {
                                    scrollIntoView((ref as any).current, shareButtonRef.current);
                                }, 10);
                            }
                        }}
                        rounded={true}
                        icon={(() => {
                            if (shareProcessing) {
                                return <Spinner className={cx(styles.toolbarButtonSpinner)} />;
                            } else if (shareSuccess) {
                                return <Check className={cx(styles.toolbarButtonCheckIcon)} />; // Replace with the actual success icon component
                            } else {
                                return <Share />;
                            }
                        })()}
                    >
                        {shareSuccess ? <Trans>Copied Share Link to Clipboard</Trans> : <Trans>Share</Trans>}
                    </Button>
                    {hasResetView && (
                        <Button variant="secondary" onPress={onResetView} rounded={true} icon={<ResetViewDesktop />}>
                            <Trans>Reset View</Trans>
                        </Button>
                    )}
                    {hasDimensions && (
                        <Button
                            variant="secondary"
                            onPress={onToggleRuler}
                            rounded={true}
                            icon={<Ruler />}
                        >{t`Dimensions`}</Button>
                    )}
                    {onUpdateShoppingList && (
                        <Button
                            variant="secondary"
                            onPress={handleSave}
                            rounded={true}
                            icon={
                                shoppingListStatus === 'loading' ? (
                                    <Spinner className={cx(styles.toolbarButtonSpinner)} />
                                ) : (
                                    <Saved success={savedSuccess} />
                                )
                            }
                        >
                            <Trans>Save</Trans>
                        </Button>
                    )}
                    {/* {hasUndo && (
                    hasShoppingList?: boolean;
                        <Button variant="secondary" onPress={onUndo} rounded={true} icon={<Undo />}>
                            <Trans>Undo</Trans>
                        </Button>
                    )} */}
                </div>
            )}
        </DefaultToolbar>
    );
};

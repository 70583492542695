import { BffPrice } from '@common/graphql/sdk';
import { I18n } from '@lingui/core';

export const formatPrice = (price: BffPrice, i18n: I18n) => {
    return i18n.number(price?.amount as number, {
        style: 'currency',
        currency: price?.currencyCode as string,
        maximumFractionDigits: 0
    });
};

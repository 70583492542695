import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';

type BadgeProps = {
    'aria-label': string;
};

const styles = {
    badge: css`
        display: flex;
        background-color: ${colors.coral2};
        height: 8px;
        width: 8px;
        border-radius: 100%;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    `
};

export const Badge = (props: BadgeProps) => {
    return <span className={cx(styles.badge)} {...props} />;
};

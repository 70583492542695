import { Chat } from '@common/icons';
import { Button } from '@common/primitives/button';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { motion, MotionValue, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { useContext, useEffect, useRef } from 'react';
import { ButtonProps as DefaultButtonProps } from 'react-aria-components';

import { ScrollContext } from '../panel/panel-scroll-area';
import { PADDING_HORIZONTAL } from './constants';

const styles = {
    panelOrderSamples: css`
        width: calc(100% + 2 * ${PADDING_HORIZONTAL});
        margin-left: -${PADDING_HORIZONTAL};
        margin-right: -${PADDING_HORIZONTAL};
        padding-top: 100%;
        position: relative;
        overflow: hidden;
    `,
    panelOrderSamplesCopy: css`
        display: flex;
        flex-direction: column;
        position: absolute;
        padding-top: 48px;
        width: 100%;
        height: 100%;
        top: 0;
        font-size: 22px;
        line-height: 27.5px;
        text-align: center;
    `,
    panelOrderSamplesCopyHeading: css`
        margin-bottom: 24px;
    `,
    panelOrderSamplesCopyButton: css`
        align-self: center;
    `,
    panelOrderSamplesStage: css`
        display: flex;
        position: absolute;
        width: 100%;
        bottom: 138px;

        > span {
            border-radius: 8px;
            margin-right: 0;
            position: absolute;
            top: 0;
            z-index: -1;
            left: 0;
            width: 100%;
            height: 308px;
            display: flex;
            justify-content: center;
            transform-origin: bottom center;

            span {
                transform: rotate(-10deg);
                transform-origin: center;
                width: 162px;
                height: 154px;
                border-radius: 8px;
                display: block;
            }
        }
    `
};

interface PanelOrderSamplesProps extends DefaultButtonProps {
    className?: string;
}

const Sample = ({ rotation, color }: { rotation: MotionValue<number>; color: string }) => (
    <motion.span style={{ rotate: rotation }}>
        <span style={{ backgroundColor: color }}></span>
    </motion.span>
);

export const PanelOrderSamples = ({ className }: PanelOrderSamplesProps) => {
    const ctx = useContext(ScrollContext);
    const ref = useRef<HTMLDivElement>(null);

    const samples = 7;
    const colors = ['#252f4e', '#42575e', '#536739', '#738856', '#405c23', '#979a34', '#bec151', '#c3ab36'];
    const motionValue = useMotionValue(0);

    useEffect(() => {
        if (!ctx.scrollWrapperHeight || !ctx.scrollWrapperScrollHeight || !ctx.scrollTop || !ref.current) {
            return;
        }
        const scrollHeight = ctx.scrollWrapperScrollHeight - ctx.scrollWrapperHeight;
        const scrollPercentage = ctx.scrollTop / scrollHeight; // percentage of scroll from 0 to 1
        const clampedScrollPercentage = Math.max(0, Math.min(1, scrollPercentage > 0.2 ? scrollPercentage : 0));

        motionValue.set(clampedScrollPercentage);
    }, [ctx.scrollTop, ctx.scrollWrapperHeight, ref.current]);

    const motionSpring = useSpring(motionValue ?? 0, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    const additionalRotation = -41; // Offset the rotation of the samples to assure the final position
    const rotationPerSample = 120 / samples; // 120 degrees is the angle we wanna cover with our samples
    const rotations = Array.from({ length: samples }, (_, i) => i * rotationPerSample); // Add an individual offset (fraction of 120) to each sample

    const adjustedRotations = rotations
        .reverse()
        .map((rotation) => useTransform(motionSpring, (value) => 100 - value * rotation + additionalRotation)); // Reverse adjustst the stacking in render

    return (
        <div ref={ref} className={cx('panel-order-samples', styles.panelOrderSamples, className)}>
            <div className={cx('panel-order-samples-copy', styles.panelOrderSamplesCopy)}>
                <h1
                    className={cx('panel-order-samples-copy-heading', styles.panelOrderSamplesCopyHeading)}
                    dangerouslySetInnerHTML={{
                        __html: t`Not sure yet?<br />Let our experts help you`
                    }}
                />
                <Button
                    className={cx('panel-order-samples-copy-button', styles.panelOrderSamplesCopyButton)}
                    variant="coral"
                >
                    <Chat />
                    {t`Start chat`}
                </Button>
            </div>
            <div className={cx('panel-order-samples-stage', styles.panelOrderSamplesStage)}>
                {adjustedRotations.map((rotation, index) => (
                    <Sample key={index} rotation={rotation} color={colors[index]} />
                ))}
            </div>
        </div>
    );
};

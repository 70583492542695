import * as React from "react";
const SvgCompareForAnimation = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 14.487, height: 18.373, ...props }, /* @__PURE__ */ React.createElement("path", { id: "Back", d: "M12.54 15.893c.46.002.902-.17 1.23-.48a1.62 1.62 0 0 0 .517-1.178V4.158a1.62 1.62 0 0 0-.513-1.176 1.78 1.78 0 0 0-1.226-.482L1.947 2.487c-.46-.002-.902.17-1.231.48A1.62 1.62 0 0 0 .2 4.144v10.078c0 .443.186.866.513 1.176s.768.482 1.226.482zM1.94 15.1a.96.96 0 0 1-.658-.257.84.84 0 0 1-.267-.608V4.146c0-.227.095-.446.267-.609a.96.96 0 0 1 .658-.257l10.609-.001c.249 0 .485.094.658.257a.84.84 0 0 1 .267.609v10.09a.84.84 0 0 1-.267.608.96.96 0 0 1-.658.258z", style: {
  stroke: "#000",
  strokeWidth: ".4px"
} }), /* @__PURE__ */ React.createElement("g", { id: "Line" }, /* @__PURE__ */ React.createElement("path", { d: "M6.063.217v17.956h.814V.217z", style: {
  stroke: "#000",
  strokeWidth: ".4px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.079 0H8.96v18.257H7.079z", style: {
  fill: "#fff"
} })));
export default SvgCompareForAnimation;

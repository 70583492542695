import { useMedia } from '@common/hooks/use-media';
import { Tooltip, TooltipTrigger } from '@common/primitives/tooltip';
import { unmarshalSKU } from '@common/utils/sku';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { plural, t } from '@lingui/macro';

const styles = {
    option: css`
        display: flex;
        width: 100%;
    `,
    optionTransparent: css`
        opacity: 0.25;
    `
};

/**
 * This layer renders all the pssoible catrogries like "Platform", "Walls", "Tables" etc.
 * and is the layer which appears first when you click on the "Add element" button.
 * The next pushed layer is the add-element-${categoryId} layer which is used to add a product *
 */
export const LayerModularAddCategory = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const pushLayer = useModularConfigurator((state) => state.pushLayer);
    const categories = useModularConfigurator((state) => state.initialData?.categories);
    const products = useModularConfigurator((state) => state.initialData?.products);
    const emersyaProducts = useModularConfigurator((state) => state.emersyaProducts);

    const getCategoryImage = useModularConfigurator((state) => state.getCategoryImage);

    const { md } = useMedia();
    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [82, 82] : [102, 102];

    const title = t`Add element`;

    const renderCategories = () => (
        <ButtonTile.List direction={listDirection}>
            {categories
                ?.map((category) => {
                    const options = products?.filter((el) => el.category === category.id);

                    const hasAvailableProducts = options?.some((option) => {
                        return emersyaProducts.some((ep) => {
                            const { articleNo } = unmarshalSKU(option.sku);
                            return articleNo === ep.article && ep.available;
                        });
                    });

                    return {
                        ...category,
                        hasAvailableProducts
                    };
                })
                .sort((a, b) => {
                    if (a.hasAvailableProducts && !b.hasAvailableProducts) {
                        return -1;
                    }
                    if (!a.hasAvailableProducts && b.hasAvailableProducts) {
                        return 1;
                    }
                    return 0;
                })
                .map((category) => {
                    const options = products?.filter((el) => el.category === category.id);
                    const { hasAvailableProducts } = category;

                    const title = category.name ?? '';

                    const subtitle = plural(options?.length || 0, {
                        one: 'one option',
                        other: '# options'
                    });

                    const categoryImage = getCategoryImage(category.id) as ButtonTileImageImage;
                    const isDisabled = !hasAvailableProducts;
                    const disabledMessage = t`No space available. Try adding some more elements`;

                    if (md) {
                        return (
                            <ButtonTile.Item key={category.id} direction={tileDirection}>
                                <ButtonTile.Surface
                                    disabled={isDisabled}
                                    direction={tileDirection}
                                    immediate={true}
                                    onClick={
                                        isDisabled
                                            ? undefined
                                            : () => {
                                                  pushLayer(`add-element-${category.id}`);
                                              }
                                    }
                                >
                                    {isDisabled ? (
                                        <TooltipTrigger>
                                            <div className={cx(styles.option, isDisabled && styles.optionTransparent)}>
                                                <ButtonTile.Image
                                                    direction={tileDirection}
                                                    size={thumbnailSize}
                                                    image={categoryImage}
                                                />
                                                <ButtonTile.Text
                                                    direction={tileDirection}
                                                    title={title}
                                                    subtitle={subtitle}
                                                />
                                                {!isDisabled && <ButtonTile.Chevron />}
                                            </div>
                                            <Tooltip>{disabledMessage}</Tooltip>
                                        </TooltipTrigger>
                                    ) : (
                                        <div className={cx(styles.option, isDisabled && styles.optionTransparent)}>
                                            <ButtonTile.Image
                                                direction={tileDirection}
                                                size={thumbnailSize}
                                                image={categoryImage}
                                            />
                                            <ButtonTile.Text
                                                direction={tileDirection}
                                                title={title}
                                                subtitle={subtitle}
                                            />
                                            {!isDisabled && <ButtonTile.Chevron />}
                                        </div>
                                    )}
                                </ButtonTile.Surface>
                            </ButtonTile.Item>
                        );
                    }

                    // If it's disabled on mobile
                    if (isDisabled) {
                        return (
                            <ButtonTile.Item key={category.id} direction={tileDirection}>
                                <TooltipTrigger>
                                    <ButtonTile.Surface direction={tileDirection} className={styles.optionTransparent}>
                                        <ButtonTile.Image
                                            direction={tileDirection}
                                            size={thumbnailSize}
                                            image={categoryImage}
                                        />
                                        <ButtonTile.Text direction={tileDirection} title={title} />
                                    </ButtonTile.Surface>
                                    <Tooltip
                                        style={{
                                            zIndex: CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1
                                        }}
                                    >
                                        {disabledMessage}
                                    </Tooltip>
                                </TooltipTrigger>
                            </ButtonTile.Item>
                        );
                    }

                    // If it's not disabled
                    return (
                        <ButtonTile.Item key={category.id} direction={tileDirection}>
                            <ButtonTile.Surface
                                onClick={() => {
                                    pushLayer(`add-element-${category.id}`);
                                }}
                                direction={tileDirection}
                            >
                                <ButtonTile.Image
                                    direction={tileDirection}
                                    size={thumbnailSize}
                                    image={categoryImage}
                                />
                                <ButtonTile.Text direction={tileDirection} title={title} />
                            </ButtonTile.Surface>
                        </ButtonTile.Item>
                    );
                })}
        </ButtonTile.List>
    );

    if (md) {
        return (
            <>
                <Panel.Navigation kind="back" onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{title}</Panel.Title>
                    {renderCategories()}
                </Panel.ScrollArea>
            </>
        );
    }

    return (
        <>
            <Panel.Navigation kind="back" onPress={popLayer} appearance="mobile" title={title} />
            <Panel.ScrollArea direction="horizontal">{renderCategories()}</Panel.ScrollArea>
        </>
    );
};

import {
    BffConfiguratorConfigurationColorItem,
    ConfiguratorConfigurationItemAttributesFragment
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { useMedia } from '@common/hooks/use-media';
import { Info, Pin } from '@common/icons';
import { ButtonIcon, Tooltip, TooltipTrigger } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { icons } from '@components/configurator/components/material-detail/icons';
import { PANEL_WIDTH } from '@components/configurator/components/panel/constants';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { AttributeKey, AttributeType } from '@components/material-comparison/material-comparison-types';
import translations from '@i18n/variables';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Label } from 'react-aria-components';
import { Fragment } from 'react/jsx-runtime';

import { Meter } from '../meter/meter';
import { TableCell, TableCellIcon, TableCellText } from './table-cell';
import { TableColumnGroup, TableColumnHeader } from './table-column';
import { TableEmptyContent } from './table-empty-content';
import { TableRow } from './table-row';

const styles = {
    row: css`
        .pin-button {
            visibility: hidden;
        }

        &:hover {
            .pin-button {
                visibility: visible;
            }
        }
    `,
    pinButtonContainer: css`
        position: absolute;
        left: 36px;
        transform: translateY(-14px);
    `,
    pinButton: css`
        background-color: rgba(239 107 107 / 12%);
        color: ${colors.coral2};

        &:hover {
            color: ${colors.coral2};

            circle {
                fill: ${colors.coral2};
            }
        }
    `,
    pinButtonPinned: css`
        circle {
            fill: ${colors.coral2};
        }
    `,
    naturalnessValue: css`
        color: #333;
        font-size: 25px;
        font-weight: 500;
        line-height: 122%;
    `,
    naturalnessLabel: css`
        font-size: 12px;
        font-weight: 500;
        line-height: 122%;
    `,
    priceGroup: css`
        text-align: center;
    `,
    priceGroupSymbol: css`
        font-size: 16px;
        line-height: 130%;
        color: ${colors.coral2};
    `,
    priceGroupSymbolEmpty: css`
        color: #e6e6e6;
    `,
    infoButton: css`
        all: unset;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        border: 1px solid #949494;
    `
};

interface TableRowsProps {
    keys: AttributeKey[];
    colors: BffConfiguratorConfigurationColorItem[];
    currencyCode: string;
    pinnedRows: AttributeKey[];
    showDifferencesOnly: boolean;
    showEmpty: boolean;
    onPinRow: (attribute: AttributeKey) => void;
}

export const TableRows = ({
    keys,
    colors,
    currencyCode,
    showDifferencesOnly,
    showEmpty,
    pinnedRows,
    onPinRow
}: TableRowsProps) => {
    const { _ } = useLingui();
    const { getCurrencySymbol } = getBffLanguageAndCountry();
    const { md } = useMedia();

    const tooltipStyle = {
        zIndex: CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 2,
        maxWidth: PANEL_WIDTH.BASE
    };

    const currencySymbol = getCurrencySymbol(currencyCode);

    const renderPrice = (value: number) => {
        if (!currencySymbol) return null;

        const empty = 5 - value;

        return (
            <div className={styles.priceGroup}>
                <span className={styles.priceGroupSymbol}>{currencySymbol.repeat(value)}</span>
                <span className={cx(styles.priceGroupSymbol, styles.priceGroupSymbolEmpty)}>
                    {currencySymbol.repeat(empty)}
                </span>
            </div>
        );
    };

    const renderRows = (keys: AttributeKey[]) => {
        const rows = keys.map((attribute) => {
            // If none of the colours have the attribute, don't render it
            if (!colors.some((color) => color.attributes?.some((attr) => attr.key === attribute))) {
                return null;
            }

            // If all of the attributes are the same, and showDifferencesOnly is true don't render it
            const allAttributesAreEqual = colors.every((color) => {
                const value = color?.attributes?.find(
                    (attr) => attr?.key === attribute
                ) as ConfiguratorConfigurationItemAttributesFragment;

                const firstValue = colors[0]?.attributes?.find(
                    (attr) => attr?.key === attribute
                ) as ConfiguratorConfigurationItemAttributesFragment;

                if (value?.__typename === AttributeType.Meter && firstValue?.__typename === AttributeType.Meter) {
                    return value?.meterValue === firstValue?.meterValue;
                }

                return false;
            });

            if (showDifferencesOnly && allAttributesAreEqual) {
                return null;
            }

            const renderValues = colors.map((color, i) => {
                const value = color?.attributes?.find(
                    (attr) => attr?.key === attribute
                ) as ConfiguratorConfigurationItemAttributesFragment;

                if (!value) {
                    return <TableCell key={color.id} />;
                }

                const isPinned = pinnedRows.includes(attribute);
                const pinnedLabel = isPinned ? t`Unpin row` : t`Pin row`;
                const showPinButton = (md && i === 0 && pinnedRows.length < 4) || isPinned;

                // Only show the pin if it's a meter
                const pinButton = showPinButton ? (
                    <th scope="col" className={styles.pinButtonContainer}>
                        <ButtonIcon
                            className={cx('pin-button', styles.pinButton, isPinned && styles.pinButtonPinned)}
                            aria-label={pinnedLabel}
                            variant="ghost"
                            icon={<Pin height={14} width={14} />}
                            onPress={() => {
                                onPinRow(attribute);
                            }}
                        />
                    </th>
                ) : null;

                // If the attribute is area_of_use
                if (attribute === AttributeKey.AreaOfUse && value?.__typename === AttributeType.Text) {
                    const translationKey = translations[`area_of_use_${value?.textValue}` as keyof typeof translations];
                    const tooltipTranslationKey = null;
                    // const tooltipTranslationKey =  translations[`area_of_use_${value?.textValue}_tooltip` as keyof typeof translations];

                    const body = (
                        <>
                            <TableCellIcon>
                                {value?.textValue === 'residential_use' ? icons.area_of_use : icons.area_of_use_office}
                            </TableCellIcon>

                            <TableCellText>{translationKey ? _(translationKey) : value?.textValue}</TableCellText>
                        </>
                    );

                    return (
                        <TableCell key={color.id}>
                            {tooltipTranslationKey ? (
                                <TooltipTrigger delay={0}>
                                    {body}
                                    <Tooltip style={tooltipStyle}>
                                        <p>{_(tooltipTranslationKey)}</p>
                                    </Tooltip>
                                </TooltipTrigger>
                            ) : (
                                body
                            )}
                        </TableCell>
                    );
                }

                // If naturalness just show the rawValue
                if (attribute === AttributeKey.Naturalness && value?.__typename === AttributeType.Meter) {
                    return (
                        <TableCell key={color.id}>
                            <TableCellText>
                                <span className={styles.naturalnessValue}>{value?.rawValue}</span>
                                <span className={styles.naturalnessLabel}>%</span>
                            </TableCellText>
                        </TableCell>
                    );
                }

                // If the attribute is place_of_origin
                if (attribute === AttributeKey.PlaceOfOrigin && value?.__typename === AttributeType.Text) {
                    const translationKey =
                        translations[`place_of_origin_${value?.textValue}` as keyof typeof translations];

                    return (
                        <TableCell key={color.id}>
                            <TableCellText>
                                {icons[`place_of_origin_${value?.textValue}` as keyof typeof icons]}
                            </TableCellText>

                            <TableCellText>{translationKey ? _(translationKey) : value?.textValue}</TableCellText>
                        </TableCell>
                    );
                }

                // If it's pricing group, return the currency sign based on the value
                if (
                    attribute === AttributeKey.PricingGroup &&
                    value?.__typename === AttributeType.Meter &&
                    currencySymbol
                ) {
                    return (
                        <Fragment key={color.id}>
                            {pinButton}
                            <TableCell>{renderPrice(value?.meterValue)}</TableCell>
                        </Fragment>
                    );
                }

                if (value?.__typename === AttributeType.Meter) {
                    const translationKey =
                        translations[`${attribute}_${value?.meterValue}` as keyof typeof translations];

                    return (
                        <Fragment key={color.id}>
                            {pinButton}
                            <TableCell>
                                {translationKey ? (
                                    <TooltipTrigger delay={0}>
                                        <Meter labelId={attribute} value={value?.meterValue} />
                                        <Tooltip style={tooltipStyle}>
                                            <p>{_(translationKey)}</p>
                                        </Tooltip>
                                    </TooltipTrigger>
                                ) : (
                                    <Meter labelId={attribute} value={value?.meterValue} />
                                )}
                            </TableCell>
                        </Fragment>
                    );
                }

                // If the attribute is vegan
                if (attribute === AttributeKey.Vegan && value?.__typename === AttributeType.Boolean) {
                    const translationKey = translations[`vegan_${value?.booleanValue}` as keyof typeof translations];

                    return (
                        <TableCell key={color.id}>
                            <TableCellIcon>{value?.booleanValue ? icons.vegan2 : icons.misc}</TableCellIcon>

                            <TableCellText>{translationKey ? _(translationKey) : value?.booleanValue}</TableCellText>
                        </TableCell>
                    );
                }

                return <td key={color.id} />;
            });

            const attributeTranslationKey = translations[attribute as keyof typeof translations];
            const tooltipTranslationKey = translations[`${attribute}_tooltip` as keyof typeof translations];

            return (
                <Fragment key={attribute}>
                    <TableColumnHeader>
                        <TableColumnGroup>
                            <Label id={attribute}>
                                {attributeTranslationKey ? _(attributeTranslationKey) : attribute}
                            </Label>
                            {tooltipTranslationKey && (
                                <TooltipTrigger delay={0}>
                                    <Tooltip style={tooltipStyle}>
                                        <p>{tooltipTranslationKey ? _(tooltipTranslationKey) : attribute}</p>
                                    </Tooltip>
                                    <div className={styles.infoButton}>
                                        <Info />
                                    </div>
                                </TooltipTrigger>
                            )}
                        </TableColumnGroup>
                    </TableColumnHeader>

                    <TableRow className={styles.row}>
                        {renderValues}
                        {showEmpty && (
                            <TableCell>
                                <TableEmptyContent />
                            </TableCell>
                        )}
                    </TableRow>
                </Fragment>
            );
        });

        return rows;
    };

    return renderRows(keys);
};

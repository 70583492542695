import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import { Tag, TagProps } from 'react-aria-components';

import { styles as buttonStyles } from '../button/button';

interface PillProps extends TagProps {
    image?: React.ReactNode;
    label: string;
    className?: string;
}

export const styles = {
    reset: buttonStyles.reset,
    pill: css`
        padding: 10px 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%;
        color: ${colors.black};
        border-radius: 70px;
        border: 1.224px solid #e6e6e6;
        background-color: ${colors.white};
        width: fit-content;
        text-align: center;

        &:hover {
            border-color: #cacaca;
            background-color: #f5f5f5;
        }

        &[data-focus-visible] {
            outline: 1px solid ${colors.black};
        }

        &[data-pressed] {
            transform: scale(0.97);
            transition: transform 0.1s;
        }

        &[data-selected] {
            background-color: ${colors.coral2};
            border-color: ${colors.coral2};
            font-weight: 500;
            color: #fff;

            &[data-focus-visible] {
                outline: none;
                box-shadow: 0 0 0 5px rgb(239 107 107 / 16%);
            }
        }
    `,
    pillImage: css`
        width: 58px;
        height: 58px;
        aspect-ratio: 1;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            transition: all 0.1s;
        }

        &[data-focus-visible] {
            outline: 1px solid ${colors.black};
        }

        &[data-selected] {
            background: #f3f3f3;
            box-shadow: 0 1.3px 4px 0 #00000040 inset;

            img {
                height: 46px;
                width: 46px;
            }

            &[data-focus-visible] {
                img {
                    box-shadow: none;
                }
            }
        }
    `
};

export const Pill = (props: PillProps) => {
    const { image, label, className, ...rest } = props;

    if (image) {
        return (
            <Tag className={cx(styles.reset, styles.pillImage, className)} {...rest} aria-label={label}>
                {image}
            </Tag>
        );
    }

    return (
        <Tag className={cx(styles.reset, styles.pill, className)} {...rest}>
            {label}
        </Tag>
    );
};

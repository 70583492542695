import { useCallback } from 'react';

/**
 * This hooks searches for the first scrollable child of a given element and ensures that
 * a given element is visible in the scrollable child.
 */
function useScrollIntoView() {
    const findScrollableChild = (element: HTMLElement | null): HTMLElement | null => {
        if (!element || !element.children || element.children.length === 0) return null;

        const { overflowX, overflowY } = window.getComputedStyle(element);
        if (overflowX === 'auto' || overflowX === 'scroll' || overflowY === 'auto' || overflowY === 'scroll') {
            return element;
        }

        for (let i = 0; i < element.children.length; i++) {
            const scrollableChild = findScrollableChild(element.children[i] as HTMLElement);
            if (scrollableChild) return scrollableChild;
        }

        return null;
    };

    const ensureInView = useCallback((container: HTMLElement | null, element: HTMLElement | null) => {
        if (!container || !element) return;

        const scrollableChild = findScrollableChild(container);
        if (!scrollableChild) {
            console.warn('No scrollable child found');
            return;
        }

        const scrollableRect = scrollableChild.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        // Calculate element position relative to the scrollable child
        const elementLeft = element.offsetLeft - scrollableChild.offsetLeft;
        const elementTop = element.offsetTop - scrollableChild.offsetTop;
        const elementRight = elementLeft + elementRect.width;
        const elementBottom = elementTop + elementRect.height;

        // Calculate the visible area of the scrollable child
        const visibleLeft = scrollableChild.scrollLeft;
        const visibleTop = scrollableChild.scrollTop;
        const visibleRight = visibleLeft + scrollableRect.width;
        const visibleBottom = visibleTop + scrollableRect.height;

        let targetLeft = scrollableChild.scrollLeft;
        let targetTop = scrollableChild.scrollTop;

        // Check horizontal visibility
        if (elementLeft < visibleLeft) {
            targetLeft = elementLeft;
        } else if (elementRight > visibleRight) {
            targetLeft = elementRight - scrollableRect.width;
        }

        // Check vertical visibility
        if (elementTop < visibleTop) {
            targetTop = elementTop;
        } else if (elementBottom > visibleBottom) {
            targetTop = elementBottom - scrollableRect.height;
        }

        // Use scrollTo for smooth scrolling if supported, otherwise set scroll position directly
        if ('scrollBehavior' in document.documentElement.style) {
            scrollableChild.scrollTo({
                left: targetLeft,
                top: targetTop,
                behavior: 'smooth'
            });
        } else {
            scrollableChild.scrollLeft = targetLeft;
            scrollableChild.scrollTop = targetTop;
        }
    }, []);

    return ensureInView;
}

export default useScrollIntoView;

import { client } from '@common/graphql/client';
import {
    BffConfigurator,
    BffConfiguratorConfiguration,
    BffConfiguratorConfigurationColor,
    BffConfiguratorConfigurationColorItem
} from '@common/graphql/sdk';
import { getBffLanguageAndCountry } from '@common/hooks/use-config';
import { post } from '@common/utils/fetch';
import { unmarshalSKU } from '@common/utils/sku';
import { ConfiguratorRecommendation } from '@components/configurator/components/recommendations/recommendations';
import { WidgetConfiguratorContentRecommendation } from '@components/configurator/configurator-types';
import { SearchEngine } from 'clientside-search';
import clipboardCopy from 'clipboard-copy';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ConfiguratorTab, ConfiguratorUI, FilterKey } from './types';
import { useShoppingList } from './use-shoppinglist';
import { useViewer } from './use-viewer';
import { calculateFilterCount, createSearchEngineFromColors, searchForColors } from './utils';

interface ConfiguratorStore {
    articleNo: string | undefined;
    presets: {
        state: 'loading' | 'loaded' | 'error';
        currentRecommendationId?: string;
        recommendations?: ConfiguratorRecommendation[];
    };
    history: string[];
    data: BffConfigurator | undefined;
    ui: ConfiguratorUI;
}

interface ConfiguratorActions {
    /**
     * Boostrap the configurator
     * @param sku sku
     * @returns
     */
    startConfiguration: (sku: string) => Promise<BffConfigurator | undefined>;

    initWidget: ({ articleNo }: { articleNo: string }) => void;

    initRecommendations: (recommendations: WidgetConfiguratorContentRecommendation[]) => void;

    setRecommendationsStatus: (status: 'loading' | 'loaded' | 'error') => void;

    /**
     * Updates and fetches the new configuration
     * @param newSku sku
     * @returns
     */
    updateRemoteConfiguration: (newSku: string) => Promise<BffConfigurator | undefined>;

    /**
     * Clears all filters
     * @returns void
     */
    onClearFilter: () => void;
    /**
     * set's a filter on the current open filter layer
     * @param key FilterKey
     * @param filter Filter Values
     * @returns
     */
    onSetFilter: (key: FilterKey, filter: string | string[] | undefined) => void;
    /**
     * Toggles a color / materials filter layer
     * @param filterLayerId Filter layer Id
     * @returns
     */
    onToggleFilterLayerById: (filterLayerId?: string) => void;

    /**
     * Close the current filter layer, keeps the filter
     * @returns
     */
    onCloseFilter: () => void;

    /**
     * Returns the filtered colors based on the current filters and selected state
     * @returns BffConfiguratorConfigurationColorItem[]
     */
    getFilteredColors: (ofmlKey: string) => BffConfiguratorConfigurationColorItem[];
    /**
     * Retun active filter count for the selected filterId
     * @returns number
     */
    getFilterCount: () => number;

    /**
     * Sets tab in the ui
     * @param tab ConfiguratorTab
     * @returns voic
     */
    onSetTab: (tab: ConfiguratorTab) => void;

    /**
     * set's the layer by id
     * mabye set the filterLayerId if it's a colors
     * @param id
     * @returns
     */
    onToggleById: (id: string) => void;

    /**
     * Open or close the modal
     * @param isOpen boolean
     * @returns
     */
    setIsModalOpen: (isOpen: boolean) => void;

    /**
     * Update the shopping list with the current configuration
     * @returns
     */
    updateShoppingList: () => Promise<void>;

    /**
     * Open or close the compare modal
     */
    setIsCompareModalOpen: (isOpen: boolean) => void;

    /**
     * Sets the current active recommendation in the recommedations view
     */
    setRecommendationId: (emersyaId: string) => void;

    /**
     * Sets the blocking error
     */
    toggleRecommendationLoadingError: (error: boolean, message?: string) => void;

    /**
     * Get the share link
     */
    createShareLink: (shareAPI: string) => Promise<string>;
}
/**
 * Get configurator data from the BFF
 * @param sku vitra SAP SKU
 * @returns
 */
export const getConfiguratorData = async (sku: string) => {
    const { market, language } = getBffLanguageAndCountry();

    const { getConfigurator } = await client.getConfigurator({
        market,
        language,
        skus: [sku]
    });

    // @todo: error handling
    if (getConfigurator?.__typename === 'QueryGetConfiguratorSuccess') {
        const data = getConfigurator.data as [BffConfigurator];
        return data[0];
    } else {
        console.error('Error fetching configurator data');
    }

    return;
};

/**
 * Generates the search engine for colors
 * @param configurations
 * @returns
 */
const generateSearchEngine = (configurations: BffConfiguratorConfiguration[]) => {
    const searchEngineMap: Record<string, SearchEngine> = {};
    configurations.map((configuration) => {
        if (configuration.__typename === 'BffConfiguratorConfigurationColor' && configuration.colors) {
            searchEngineMap[configuration.id] = createSearchEngineFromColors(configuration.colors, 'en');
        }
    });
    return searchEngineMap;
};

export const useConfigurator = create<ConfiguratorStore & ConfiguratorActions>()(
    immer((set, get) => ({
        articleNo: undefined,
        data: undefined,
        ui: {
            id: undefined,
            primaryStack: [],
            secondaryStack: [],
            searchEngineMap: {},
            isCompareOpen: false,
            initialized: false,
            isLoading: true
        },
        presets: {
            state: 'loading',
            currentRecommendationId: undefined,
            recommendations: undefined
        },
        history: [],
        initWidget: (options) => {
            if (options.articleNo) {
                set((state) => {
                    state.articleNo = options.articleNo;
                });
            }
        },
        onClearFilter: () => {
            set((state) => {
                state.ui.filters = undefined;
            });
        },
        onCloseFilter: () => {
            set((state) => {
                state.ui.id = undefined;
                state.ui.filterLayerId = undefined;
            });
        },
        onToggleFilterLayerById: (filterLayerId?: string) => {
            set((state) => {
                state.ui.filterLayerId = state.ui.filterLayerId === filterLayerId ? undefined : filterLayerId;
            });
        },
        onToggleById: (id: string) => {
            set((state) => {
                state.ui.id = id;
            });
        },
        onSetFilter: (key, filter) => {
            set((state) => {
                if (!state.ui.filters) {
                    state.ui.filters = {};
                }
                state.ui.filters[key] = filter as any;
            });
        },
        getFilterCount: (): number => calculateFilterCount(get().ui?.filters),
        getFilteredColors: (ofmlKey: string): BffConfiguratorConfigurationColorItem[] => {
            const { data, ui } = get();
            if (!ui || !ui.id) {
                return [];
            }
            const activeLayerId = ui.id;
            const activeFilterLayer = data?.configurations?.find(
                (config) => config.id === activeLayerId
            ) as BffConfiguratorConfigurationColor;
            const allColors = activeFilterLayer.colors || [];

            if (!ui.filters || !ui.searchEngineMap || !ui.searchEngineMap[ofmlKey]) {
                return allColors;
            }
            return searchForColors(ui.searchEngineMap[ofmlKey], ui.filters);
        },
        onSetTab: (tab) => {
            set((state) => {
                state.ui.tab = tab;
            });
        },
        updateRemoteConfiguration: async (newSku: string) => {
            // if we do a sku change we clear the stack
            const newSkuParts = unmarshalSKU(newSku);
            const currentSku = get().data?.sku;
            const currentSkuParts = currentSku ? unmarshalSKU(currentSku) : { articleNo: '' };

            const canOptimisticallyUpdate = currentSkuParts.articleNo === newSkuParts.articleNo;
            if (canOptimisticallyUpdate) {
                // try to update this optimistically
                const { data } = get();
                const oData = { ...data };
                oData.sku = newSku;
                useViewer.getState().updateViewer(newSku);
                oData.configurations = data?.configurations?.map((config) => {
                    if (config.__typename === 'BffConfiguratorConfigurationAttributes' && config.values) {
                        const values = config.values.map((value) => {
                            return {
                                ...value,
                                selected: value.sku === newSku
                            };
                        });
                        return {
                            ...config,
                            values
                        };
                    }
                    if (config.__typename === 'BffConfiguratorConfigurationColor' && config.colors) {
                        const colors = config.colors.map((color) => {
                            return {
                                ...color,
                                selected: color.sku === newSku
                            };
                        });
                        return {
                            ...config,
                            colors
                        };
                    }
                    return config;
                });

                // optimistically update
                set((state) => {
                    state.data = oData as BffConfigurator;
                });
            }

            set((state) => {
                state.ui.isLoading = true;
                if (!canOptimisticallyUpdate) {
                    state.ui.id = undefined;
                    state.ui.filterLayerId = undefined;
                    state.ui.primaryStack = [];
                    state.ui.secondaryStack = [];
                }
            });

            const newData = await getConfiguratorData(newSku);

            set((state) => {
                // reset the stack on Model change
                state.data = newData;
                state.ui.searchEngineMap = generateSearchEngine(newData?.configurations || []);
                state.history = [...state.history, newSku];
                state.ui.isLoading = false;
            });

            return newData;
        },
        startConfiguration: async (sku: string) => {
            try {
                set((state) => {
                    state.ui.isLoading = true;
                });

                const data = await getConfiguratorData(sku);

                set((state) => {
                    state.data = data as BffConfigurator;
                    state.ui.searchEngineMap = generateSearchEngine(data?.configurations || []);
                    state.ui.isLoading = false;
                });

                return data;
            } catch (error) {
                // @todo: error handling, sentry, logs ?
                console.log(error);
            }
        },
        initRecommendations: async (recommendations: WidgetConfiguratorContentRecommendation[]) => {
            if (recommendations && recommendations.length > 0) {
                const { market, language } = getBffLanguageAndCountry();

                // We remove the old grouping of quickly available etc
                // We add the name to each product (similar to modular configurator)
                let r = recommendations.flatMap((r) => r.products.map((p) => ({ ...p, name: r.name })));

                // Get prics and delivery
                const resp = await client.getConfigurator({
                    market,
                    language,
                    skus: r.map((r) => r.id)
                });

                const { getConfigurator } = resp;

                if (
                    !getConfigurator ||
                    getConfigurator === null ||
                    getConfigurator?.__typename === 'BffProxyError' ||
                    getConfigurator?.__typename === 'Error'
                ) {
                    return r;
                }

                // Apply the prices and delivery
                r = r.map((r, i) => {
                    return {
                        ...r,
                        configurations: getConfigurator.data[i].configurations,
                        price: getConfigurator.data[i].price,
                        delivery: getConfigurator.data[i].delivery
                    };
                });

                set((state) => {
                    state.presets.currentRecommendationId = r[0].id;
                    state.presets.recommendations = r;
                    // state.presets.state = 'loaded';
                });
            }
        },
        setRecommendationsStatus: (status: 'loading' | 'loaded' | 'error') => {
            set((state) => {
                state.presets.state = status;
            });
        },
        setIsModalOpen: (isOpen: boolean) => {
            set((state) => {
                state.ui.isModalOpen = isOpen;
            });
        },
        updateShoppingList: async () => {
            const updateShoppingList = useShoppingList.getState().updateShoppingList;
            const currentSku = get().data?.sku;
            const articleNo = get().articleNo;

            if (!currentSku || !articleNo) {
                return;
            }
            await updateShoppingList([{ sku: currentSku! }], [articleNo]);
        },
        setIsCompareModalOpen: (isOpen: boolean) => {
            set((state) => {
                state.ui.isCompareOpen = isOpen;
            });
        },
        setRecommendationId: (id: string) => {
            set((state) => {
                state.presets.currentRecommendationId = id;
            });
        },
        toggleRecommendationLoadingError: (error: boolean, message?: string) => {
            set((state) => {
                state.ui.blockingError =
                    error && message
                        ? {
                              message: message
                          }
                        : undefined;
            });
        },
        createShareLink: async (shareAPI: string): Promise<string> => {
            const currentSku = get().data?.sku;
            const json = {
                productId: currentSku,
                url: window.location.href
            };
            const { shareURL }: any = await post(shareAPI, json);
            clipboardCopy(shareURL);
            return shareURL;
        }
    }))
);

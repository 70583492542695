import { useCallback, useState } from 'react';

function useSetAndReset(): [boolean, (value: boolean, timeout: number) => void] {
    const [activeWithTimeout, setActiveWithTimeout] = useState<boolean>(false);

    const setAndResetAfter = useCallback((value: boolean, timeout: number) => {
        setActiveWithTimeout(value);

        if (value) {
            const timer = setTimeout(() => {
                setActiveWithTimeout(false);
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, []);

    return [activeWithTimeout, setAndResetAfter];
}

export default useSetAndReset;

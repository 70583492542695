import { ButtonTileChevron } from './button-tile-chevron';
import { ButtonTileImage } from './button-tile-image';
import { ButtonTileImageAddElement } from './button-tile-image-add-element';
import { ButtonTileImageCount } from './button-tile-image-count';
import { ButtonTileImageOverlay } from './button-tile-image-overlay';
import { ButtonTileImageSpinner } from './button-tile-image-spinner';
import { ButtonTileInfo } from './button-tile-info';
import { ButtonTileItem } from './button-tile-item';
import { ButtonTileList } from './button-tile-list';
import { ButtonTileOrderSamples } from './button-tile-order-samples';
import { ButtonTileSurface } from './button-tile-surface';
import { ButtonTileText } from './button-tile-text';

export const ButtonTile = {
    Item: ButtonTileItem,
    List: ButtonTileList,
    Surface: ButtonTileSurface,
    Image: ButtonTileImage,
    ImageCount: ButtonTileImageCount,
    ImageOverlay: ButtonTileImageOverlay,
    ImageAddElement: ButtonTileImageAddElement,
    ImageSpinner: ButtonTileImageSpinner,
    OrderSamples: ButtonTileOrderSamples,
    Info: ButtonTileInfo,
    Text: ButtonTileText,
    Chevron: ButtonTileChevron
};

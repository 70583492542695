import { Save } from '@common/icons';
import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';

const styles = {
    savedSuccess: css`
        color: ${colors.coral2};

        path {
            fill: ${colors.coral2} !important;
        }
    `
};

interface SavedProps {
    success?: boolean;
}
export const Saved = (props: SavedProps) => {
    return <Save className={cx(props.success && styles.savedSuccess)} />;
};

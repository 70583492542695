import { useModularConfigurator } from '@components/configurator/hooks';
import { useEffect } from 'react';

import { Layer } from '..';

export const LayerModularNode = () => {
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const setSelectedProductSlot = useModularConfigurator((state) => state.setSelectedProductSlot);

    useEffect(() => {
        // On unmount, remove the selected product slot
        return () => {
            setSelectedProductSlot(null);
        };
    }, []);

    // Used for platforms — shows a group of nodes, e.g. platform and mattress
    if (currentLayer?.startsWith('change-node-overview-')) {
        return <Layer.ModularCategoryNode />;
    }

    // Change a single node that's type color
    if (currentLayer?.startsWith('change-node-colors-')) {
        return <Layer.ModularColorsChange />;
    }

    // Change a single node that's type attribute
    if (currentLayer?.startsWith('change-node-attribute-')) {
        return <Layer.ModularAttribute />;
    }

    // Indeterminate loading state
    if (currentLayer?.startsWith('change-node-loading-')) {
        return <Layer.ModularLoading />;
    }

    return null;
};

export enum AttributeKey {
    EasyToClean = 'easy_to_clean',
    LowMaintenance = 'low_maintenance',
    DirtResistance = 'dirt_resistance',
    FastnessToLight = 'fastness_to_light',
    Pilling = 'pilling',
    HairResistance = 'hair_resistance',
    FastnessToRubbing = 'fastness_to_rubbing',
    AbrasionResistance = 'abrasion_resistance',
    PricingGroup = 'pricing_group',
    Naturalness = 'naturalness',
    AreaOfUse = 'area_of_use',
    PlaceOfOrigin = 'place_of_origin',
    Vegan = 'vegan',
    Weight = 'weight',
    Width = 'width',
    TemperatureBehaviour = 'temperature_behaviour'
}

export enum AttributeType {
    Array = 'BffConfiguratorConfigurationItemAttributeArray',
    Boolean = 'BffConfiguratorConfigurationItemAttributeBoolean',
    Meter = 'BffConfiguratorConfigurationItemAttributeMeter',
    Number = 'BffConfiguratorConfigurationItemAttributeNumber',
    Text = 'BffConfiguratorConfigurationItemAttributeText'
}

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    containerRow: css`
        flex: 1 0 !important;
    `,
    container: css`
        position: relative;
        display: inline-flex;
        flex: 0;

        /* stylelint-disable */
        .tile-surface:has(.tile-image-circle) {
            border-radius: 80px;
        }
        &:has(.tile-surface[data-focus-visible]) {
            .tile-surface.tile-surface--column:has(.tile-image-circle) {
                outline: none;

                .tile-image {
                    outline: 1.5px solid ${colors.coral2};
                    outline-offset: 0px;
                }
            }
        }

        .tile-surface:not(.tile-surface--row):has(.tile-image-102-102):not(:has(.tile-image-circle)) {
            /* Needed to overflow hide the tile-text */
            width: 102px;
        }

        &:has(.tile-surface.state-selected),
        &:has(.tile-surface[data-pressed]) {
            .tile-info {
                transform: translateY(8px) translateX(-10px);
                transform-origin: center;
            }

            .tile-image {
                transform: scale(0.87);
                transform-origin: center;
                transition: transform 0.1s ease-in-out;
                will-change: transform;
            }

            .tile-icon {
                transform: translateX(-10px);
            }

            .tile-image-empty svg {
                box-shadow: 0 2px 4px 0 rgba(0 0 0 / 22%) inset;
            }
            .tile-surface:has(.tile-image-circle) {
                &.tile-surface--column {
                    box-shadow: none;
                    background: none;

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        width: 100%;
                        padding-top: 100%;
                        border-radius: 80px;
                        box-shadow: 0 2px 4px 0 rgba(0 0 0 / 22%) inset;
                        background: #f2f2f2;
                    }
                }

                .tile-image {
                    transform: scale(calc(82 / 90));
                }
            }
        }
        /* stylelint-enable */
    `
};

interface ButtonTileItemProps {
    children: React.ReactNode;
    direction?: 'column' | 'row';
    className?: string;
}

export const ButtonTileItem = (props: ButtonTileItemProps) => {
    const { children, direction = 'row', className } = props;
    return (
        <div
            className={cx(
                'tile-item',
                `tile-item-${direction}`,
                styles.container,
                direction === 'row' && styles.containerRow,
                className
            )}
        >
            {children}
        </div>
    );
};

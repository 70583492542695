import { media } from '@common/styles/media';
import { ROW_WIDTH } from '@components/material-comparison/constants';
import { css, cx } from '@linaria/core';

const styles = {
    tableCell: css`
        padding: 14px 0;
        position: relative;
        flex: 1;
        text-align: left;
        max-width: 100%;

        ${media.md} {
            width: ${ROW_WIDTH}px;
        }
    `,
    tableCellBordered: css`
        border-right: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;

        &:last-child {
            border-right: none;
        }
    `,
    tableCellText: css`
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 122%;
    `,
    tableCellTextParagraph: css`
        font-size: 14px;
        line-height: 130%;
        text-align: left;
        padding: 22px 0;
        word-break: break-word;

        ${media.md} {
            line-height: 122%;
            font-size: 12px;
        }
    `,
    tableCellTextParagraphEmpty: css`
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 7px;
        padding-top: 22px;

        div {
            align-self: flex-start;
        }
    `,
    tableCellIcon: css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    `
};

interface TableCellProps {
    children?: React.ReactNode;
    className?: string;
    bordered?: boolean;
}
export const TableCell = ({ children, className, bordered = true }: TableCellProps) => (
    <td className={cx(styles.tableCell, bordered && styles.tableCellBordered, className)}>{children}</td>
);

export const TableCellText = ({ children }: TableCellProps) => <p className={styles.tableCellText}>{children}</p>;

export const TableCellTextParagraph = ({ children }: TableCellProps) => (
    <p className={cx(styles.tableCellText, styles.tableCellTextParagraph)}>{children}</p>
);

export const TableCellEmptyParagraph = ({ children }: TableCellProps) => (
    <div className={styles.tableCellTextParagraphEmpty}>{children}</div>
);

export const TableCellIcon = ({ children }: TableCellProps) => <div className={styles.tableCellIcon}>{children}</div>;

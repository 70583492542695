import take from 'lodash/take';

/**
 * Sanatize sku
 * @param sku
 * @returns
 */
export const sanitizeSku = (sku: unknown): string => {
    const filterList = new Set(['material', 'materials', 'conf', 'sub']);
    // we split into parts, SAP has min of 7 numbers per sku

    const parts = `${sku}`.toString().split('|');
    return take(parts, 3)
        .filter((el) => {
            if (filterList.has(el.toLowerCase())) {
                // Remove fragment if it is in the list
                return false;
            }
            if (el.length < 8) {
                // Remove fragment
                return false;
            }
            // Keep the current fragment
            return true;
        })
        .join('|');
};

export const unmarshalSKU = (
    sku: string
): {
    sku: string;
    articleNo: string;
    configurationId: string | null;
} => {
    const skuString = sanitizeSku(sku);
    const parts = skuString.split('|');
    return {
        sku: skuString,
        articleNo: parts[0],
        configurationId: parts[1] || null
    };
};

export const isSameSku = (skuA: unknown, skuB: unknown): boolean => {
    return sanitizeSku(skuA).toLowerCase() === sanitizeSku(skuB).toLowerCase();
};

export const removeArticleNumberFromSku = (sku: string): string => {
    const { articleNo, configurationId } = unmarshalSKU(sku);
    return `${configurationId || ''}`.replaceAll(`${articleNo}.`, '');
};

export const addArticleNumberToSku = (sku: string, articleNumber: string): string => {
    if (sku.includes(articleNumber)) {
        throw new Error('Article number already in SKU');
    }
    const c = sku
        .split(';')
        .map((part) => `${articleNumber}.${part}`)
        .join(';');
    return `${articleNumber}|${c}`;
};

import { css, cx } from '@linaria/core';
import { useSeparator } from 'react-aria';

interface SeparatorProps {
    orientation?: 'horizontal' | 'vertical';
}

const styles = {
    separator: css`
        background: #e6e6e6;
        height: 1px;
        width: 100%;
    `
};

export const Separator = (props: SeparatorProps) => {
    const { orientation = 'horizontal' } = props;
    const { separatorProps } = useSeparator({ orientation });

    return <div {...separatorProps} className={cx(styles.separator)} />;
};

import { css, cx } from '@linaria/core';
import React from 'react';
import { ButtonProps as DefaultButtonProps } from 'react-aria-components';

const styles = {
    tileList: css`
        position: relative;
        display: flex;
    `,
    tileListColumn: css`
        flex-direction: column;
        width: 100%;

        .tile-item {
            margin-bottom: 18px;
        }
    `,
    tileListRow: css`
        height: max-content;

        .tile-item {
            margin-right: 11px;
        }
    `
};

interface ButtonTileListProps extends DefaultButtonProps {
    children: React.ReactNode;
    direction?: 'row' | 'column';
    className?: string;
}

export const ButtonTileList = (props: ButtonTileListProps) => {
    const { children, direction, className } = props;
    return (
        <div
            className={cx(
                'tile-list',
                styles.tileList,
                direction == 'column' && styles.tileListColumn,
                direction == 'row' && styles.tileListRow,
                className
            )}
        >
            {children}
        </div>
    );
};

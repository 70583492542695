import {
    BffConfiguratorConfigurationAttributes,
    BffConfiguratorConfigurationAttributesValue
} from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { unmarshalSKU } from '@common/utils/sku';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { Panel } from '@components/configurator/components/panel';
import { useModularConfigurator, useViewer } from '@components/configurator/hooks';
import React, { useEffect } from 'react';

export const LayerModularAttributeAll = () => {
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const commonAttribute = currentLayer?.replace('change-all-attributes-', '') || '';
    const setModularConfiguratorFurnitureProductsConfigurations = useViewer(
        (state) => state.setModularConfiguratorFurnitureProductsConfigurations
    );
    const allNodes = useModularConfigurator((state) => state.nodes?.allNodes);
    const setIsBlocked = useModularConfigurator((state) => state.setIsBlocked);
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);

    const { md } = useMedia();

    const [selectedValueIds, setSelectedValueIds] = React.useState<Set<string>>(new Set());

    const listDirection = md ? 'column' : 'row';
    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];
    const activeData = useModularConfigurator((state) => state.activeData);

    // commonConfigurations are the configurations that are shared between all products
    const configuration = (activeData?.commonConfigurations || []).find(
        (c) => c.id === commonAttribute
    ) as BffConfiguratorConfigurationAttributes;

    // Since SKUs are diffrent for each product,
    // we use the id of a configuration value to identify it
    const calculateSelectedValueIds = () => {
        return (activeData?.products || [])
            .flatMap((product) => {
                return (product.configurations || []).flatMap((config) => {
                    if (config.__typename === 'BffConfiguratorConfigurationAttributes') {
                        return config?.values || [];
                    }
                    return [];
                });
            })
            .map((value) => value)
            .filter((value) => value.selected)
            .map((value) => value.id);
    };

    useEffect(() => {
        const ids = calculateSelectedValueIds();
        setSelectedValueIds(new Set(ids));
    }, [activeData]);

    if (!configuration) {
        return null;
    }

    // We use the id to find the SKU that can be applied to the product
    const updateConfiguration = async (value: BffConfiguratorConfigurationAttributesValue) => {
        if (!activeData || !activeData.products) {
            return;
        }
        const productsToUpdate: { localId: number; configuration: string }[] = [];
        for (const product of activeData.products) {
            const valueConfiguration = product.configurations?.find(
                (config) => config.__typename === 'BffConfiguratorConfigurationAttributes'
            ) as BffConfiguratorConfigurationAttributes;

            if (!valueConfiguration) {
                continue;
            }

            if (valueConfiguration) {
                const matchingConfig = valueConfiguration.values?.find((item) => item.id === value.id);
                if (!matchingConfig) {
                    continue;
                }
                const { configurationId, articleNo } = unmarshalSKU(product.sku);
                const matchingNodes =
                    allNodes?.filter(
                        (node) => node.furnitureArticle === articleNo && node.furnitureConfiguration === configurationId
                    ) || [];

                for (const node of matchingNodes) {
                    const { configurationId } = unmarshalSKU(matchingConfig.sku);
                    productsToUpdate.push({ localId: node.localId, configuration: configurationId! });
                }
            }
        }
        setIsBlocked(true);
        setSelectedValueIds(new Set([value.id]));
        setModularConfiguratorFurnitureProductsConfigurations(productsToUpdate);
    };

    const renderOptions = () => (
        <ButtonTile.List direction={listDirection}>
            {configuration.values?.map((item) => {
                const isSelected = selectedValueIds.has(item.id);
                return (
                    <ButtonTile.Item key={item.id} direction={tileDirection}>
                        <ButtonTile.Surface
                            direction={tileDirection}
                            onClick={() => updateConfiguration(item)}
                            selected={isSelected}
                        >
                            <ButtonTile.Image
                                size={thumbnailSize}
                                direction={tileDirection}
                                image={item.image as ButtonTileImageImage}
                            />
                            <ButtonTile.Text
                                title={item.title}
                                direction={tileDirection}
                                size="large"
                                subtitle={item.subtitle}
                            />
                        </ButtonTile.Surface>
                    </ButtonTile.Item>
                );
            })}
        </ButtonTile.List>
    );

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" isDisabled={isBlocked} onPress={popLayer} />
                <Panel.ScrollArea>
                    <Panel.Title>{configuration.title}</Panel.Title>
                    {renderOptions()}
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation
                kind="back"
                isDisabled={isBlocked}
                onPress={popLayer}
                appearance="mobile"
                title={configuration.title as string}
            />
            <Panel.ScrollArea direction="horizontal">{renderOptions()}</Panel.ScrollArea>
        </>
    );
};

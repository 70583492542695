import { useMedia } from '@common/hooks/use-media';
import { media } from '@common/styles/media';
import { Layer, Panel } from '@components/configurator';
import { ButtonAddToWishlist } from '@components/configurator/components/button-add-to-wishlist';
import { styles as panelStyles, PanelSurfaceSize } from '@components/configurator/components/panel/panel-surface';
import { ToolbarMobile } from '@components/configurator/components/toolbar';
import { CONFIGURATOR_MOBILE_MODAL_Z_INDEX } from '@components/configurator/configurator-constants';
import { allColorsIds, useModularConfigurator, useShoppingList, useViewer } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';

const styles = {
    configuratorPanels: css`
        position: relative;
        margin: 9px 3px 3px;
        width: calc(100dvw - 6px);
        z-index: 3;
        display: flex;
        flex-direction: column;

        ${media.md} {
            align-self: flex-end;
            margin: 0;
            position: absolute;
            flex-direction: row;
            height: 100%;
            width: auto;
            right: 0;
        }
    `,
    configuratorPanel: css`
        height: 100dvh;
        border-radius: 0;

        ${media.md} {
            margin: 12px;
            height: auto;
            display: flex;
            position: relative;

            > div {
                overflow: hidden;
            }
        }
    `,
    configuratorLayer: css`
        ${media.md} {
            display: flex;
            height: 100%;
            overflow: hidden;
        }
    `,
    configuratorModalSecondaryLayer: css`
        position: fixed;
        inset: 0;
        height: 100dvh;
        width: 100dvw;
        z-index: ${CONFIGURATOR_MOBILE_MODAL_Z_INDEX + 1};
    `
};

export const ConfiguratorModularPanels = () => {
    const nodes = useModularConfigurator((state) => state.nodes);

    const articleNo = useModularConfigurator((state) => state.articleNo);
    const panelAnimation = useModularConfigurator((state) => state.ui.panelAnimation);
    const materials = useModularConfigurator((state) => state.initialData?.materials) || [];
    const getFilteredColors = useModularConfigurator((state) => state.getFilteredColors);

    const modularAddToShoppingList = useModularConfigurator((state) => state.modularAddToShoppingList);

    const onSetFilter = useModularConfigurator((state) => state.onSetFilter);
    const onClearFilter = useModularConfigurator((state) => state.onClearFilter);
    const isCompareOpen = useModularConfigurator((state) => state.ui.isCompareOpen);

    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const secondaryStack = useModularConfigurator((state) => state.ui.secondaryStack);
    const clearSecondaryLayer = useModularConfigurator((state) => state.clearSecondaryLayer);
    const activeFilters = useModularConfigurator((state) => state.ui.filters);
    const tab = useModularConfigurator((state) => state.ui.tab);

    const { shoppingList } = useShoppingList((state) => state);

    const history = useModularConfigurator((state) => state.history);
    const hasHistory = history.length > 1;
    const removeHistory = useModularConfigurator((state) => state.removeHistory);
    const setModularConfiguratorScene = useViewer((state) => state.setModularConfiguratorScene);
    const modularCreateSharedConfiguration = useViewer((state) => state.modularCreateSharedConfiguration);

    const currentLayer = primaryStack.at(-1);
    const currentSecondaryLayer = secondaryStack.at(-1);
    const shouldRenderSecondsLayer = secondaryStack.length > 0;

    const getProductByNodeLocalId = useModularConfigurator((state) => state.getProductByNodeLocalId);
    const nodeAndConfiguration = currentLayer?.replace('change-node-colors-', '') || '';
    const [nodeId] = nodeAndConfiguration.split('-');
    const product = getProductByNodeLocalId(nodeId ?? '');

    const getColorMapForProduct = useModularConfigurator((state) => state.getColorMapForProduct);
    const colorMap = getColorMapForProduct(product);

    const allColors = useModularConfigurator((state) => state.initialData?.colors) || [];
    const filteredColors = getFilteredColors().filter((color) => colorMap.has(color.id));
    const colors = allColors.filter((color) => colorMap.has(color.id));

    const { md } = useMedia();

    const renderPrimaryLayer = () => {
        const variants = {
            initial: panelAnimation === 'fadeOutIn' ? { opacity: 0 } : { x: '100%' },
            enter: panelAnimation === 'fadeOutIn' ? { opacity: 1 } : { x: '0%' },
            exit: panelAnimation === 'fadeOutIn' ? { opacity: 0 } : { x: '-100%' }
        };

        const motionProps = {
            transition: { duration: 0.35 },
            variants,
            initial: panelAnimation === 'enter' ? 'initial' : 'exit',
            animate: 'enter',
            exit: 'exit'
        };

        if (primaryStack.length === 0) {
            return (
                <motion.div
                    className={cx('layer-overview-modular', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key="overview"
                    initial="exit"
                    animate="enter"
                    exit="exit"
                >
                    <Layer.OverviewModular />
                </motion.div>
            );
        }

        if (currentLayer === 'add-element') {
            return (
                <motion.div
                    className={cx('layer-add-category', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularAddCategory />
                </motion.div>
            );
        }

        if (currentLayer?.startsWith('add-element')) {
            return (
                <motion.div
                    className={cx('layer-modular-add-product', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularAddProduct />
                </motion.div>
            );
        }

        if (currentLayer?.startsWith('change-all-materials-')) {
            return (
                <motion.div
                    className={cx('layer-modular-colors-change-all', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularColorsChangeAll />
                </motion.div>
            );
        }

        if (currentLayer?.startsWith('change-all-attributes-')) {
            return (
                <motion.div
                    className={panelStyles.panelSurfaceFlex}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularAttributeAll />
                </motion.div>
            );
        }

        if (currentLayer?.startsWith('category-detail')) {
            return (
                <motion.div
                    className={cx('layer-modular-category', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularCategory />
                </motion.div>
            );
        }

        if (currentLayer?.startsWith('change-all-overview')) {
            return (
                <motion.div
                    className={cx('layer-modular-category-all', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={currentLayer}
                    id={currentLayer}
                >
                    <Layer.ModularCategoryAll />
                </motion.div>
            );
        }

        // Node layers
        if (currentLayer?.startsWith('change-node-')) {
            return (
                <motion.div
                    className={cx('layer-modular-node', panelStyles.panelSurfaceFlex)}
                    {...motionProps}
                    key={'change-node'}
                    id={currentLayer}
                >
                    <Layer.ModularNode />
                </motion.div>
            );
        }

        return (
            <motion.div className={panelStyles.panelSurfaceFlex} {...motionProps} key={currentLayer}>
                {currentLayer} does not exist
            </motion.div>
        );
    };

    const renderSecondaryLayer = () => {
        if (currentSecondaryLayer === allColorsIds) {
            const panel = (
                <motion.div
                    key={currentSecondaryLayer}
                    className={cx('configurator-layer', styles.configuratorLayer)}
                    initial={{ x: '50%', opacity: 0 }}
                    animate={{ x: '0%', opacity: 1 }}
                    exit={{ x: '50%', opacity: 0 }}
                    transition={{ duration: 0.25 }}
                >
                    <div className={styles.configuratorPanel}>
                        <Panel.Surface>
                            <Layer.ConfigureColorFilter
                                key={nodeId}
                                resultCount={filteredColors.length}
                                activeFilters={activeFilters}
                                onClose={clearSecondaryLayer}
                                onClearFilter={onClearFilter}
                                onSetFilter={onSetFilter}
                                colors={colors}
                                materials={materials}
                            />
                        </Panel.Surface>
                    </div>
                </motion.div>
            );

            if (md) return <AnimatePresence>{panel}</AnimatePresence>;

            return (
                <ModalOverlay isOpen={shouldRenderSecondsLayer}>
                    <Modal className={styles.configuratorModalSecondaryLayer} isOpen={shouldRenderSecondsLayer}>
                        {panel}
                    </Modal>
                </ModalOverlay>
            );
        }
    };

    const showBuilderActions =
        tab === 'builder' &&
        currentLayer !== 'change-all-materials' &&
        !currentLayer?.includes('change-node-colors') &&
        nodes &&
        !isCompareOpen;

    // BLock scrolling in the gap between panels
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const blockWheel = (e: WheelEvent) => {
            const target = e.target as HTMLElement;
            if (
                (target.classList.contains('configurator-layer') && e.layerX > 20) ||
                (target.classList.contains('configurator-panels') && e.layerX > 20)
            ) {
                console.log('block');
                e.preventDefault();
            }
        };

        if (blockRef.current) {
            blockRef.current.addEventListener('wheel', blockWheel, { passive: false });
        }

        return () => {
            if (blockRef.current) {
                blockRef.current.removeEventListener('wheel', blockWheel);
            }
        };
    }, [blockRef.current]);

    if (md) {
        let panelSize: PanelSurfaceSize = 'base';

        if (tab === 'saved' && shoppingList?.lineItems && shoppingList.lineItems.length > 0) {
            panelSize = 'lg';
        }

        if (isCompareOpen) {
            panelSize = 'xl';
        }
        return (
            <div className={cx('configurator-panels', styles.configuratorPanels)} ref={blockRef}>
                {shouldRenderSecondsLayer && renderSecondaryLayer()}

                <AnimatePresence mode="popLayout" initial={false}>
                    <div className={styles.configuratorPanel}>
                        <Panel.Surface size={panelSize}>
                            {renderPrimaryLayer()}
                            {showBuilderActions && (
                                <Panel.Footer>
                                    <ButtonAddToWishlist isModular={true} updateShoppingList={modularAddToShoppingList}>
                                        {t`Add to wishlist`}
                                    </ButtonAddToWishlist>
                                </Panel.Footer>
                            )}
                        </Panel.Surface>
                    </div>
                </AnimatePresence>
            </div>
        );
    }

    const onUndo = () => {
        if (!hasHistory) {
            return;
        }

        const lastElement = history.at(-2);
        if (lastElement) {
            removeHistory();
            setModularConfiguratorScene(lastElement);
        }
    };

    return (
        <div className={styles.configuratorPanels}>
            {showBuilderActions && (
                <ToolbarMobile
                    hasDimensions={true}
                    hasResetView={true}
                    hasUndo={hasHistory}
                    onUndo={onUndo}
                    onUpdateShoppingList={modularAddToShoppingList}
                    onShare={() => modularCreateSharedConfiguration(articleNo)}
                    reRenderIfChanged={[nodes]}
                />
            )}
            {shouldRenderSecondsLayer && renderSecondaryLayer()}
            <AnimatePresence mode="popLayout" initial={false}>
                <Panel.Surface>{renderPrimaryLayer()}</Panel.Surface>
            </AnimatePresence>
        </div>
    );
};

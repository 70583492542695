import { styles as ButtonStyles } from '@common/primitives/button/button';
import { css, cx } from '@linaria/core';
import { forwardRef } from 'react';
import { Button as DefaultButton, ButtonProps as DefaultButtonProps } from 'react-aria-components';

const { reset } = ButtonStyles;

const styles = {
    reset,
    base: css`
        aspect-ratio: 1;
    `,
    basic: css`
        background: #fff;
        color: #101010;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

        &:hover {
            filter: drop-shadow(0 1.867px 3.734px rgba(0 0 0 0.24));
        }

        &[data-focus-visible] {
            outline: none;
            background-color: rgb(0 0 0 / 7%);
        }
    `,
    ghost: css`
        background: transparent;
        color: #333;

        &:hover {
            color: #000;
        }

        &[data-focus-visible] {
            outline: none;
            background-color: rgb(0 0 0 / 7%);
        }
    `,
    subtle: css`
        background: transparent;
        color: #949494;

        &:hover {
            color: #000;
        }

        &[data-focus-visible] {
            outline: none;
            background-color: rgb(0 0 0 / 7%);
        }
    `,
    rounded: css`
        border-radius: 100%;
    `,
    xs: css`
        padding: 4px;
    `,
    sm: css`
        padding: 6px;
    `,
    md: css`
        padding: 11px;
    `,
    lg: css`
        padding: 16px;
    `
};

interface ButtonIconProps extends DefaultButtonProps {
    'aria-label': string;
    icon: React.ReactNode;
    variant?: 'basic' | 'ghost' | 'subtle';
    rounded?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    className?: string;
}

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>((props, ref) => {
    const { icon, rounded = true, variant = 'basic', size = 'md', className, ...rest } = props;

    return (
        <DefaultButton
            ref={ref}
            className={cx(
                styles.reset,
                styles.base,
                variant === 'basic' && styles.basic,
                variant === 'ghost' && styles.ghost,
                variant === 'subtle' && styles.subtle,
                size === 'xs' && styles.xs,
                size === 'sm' && styles.sm,
                size === 'md' && styles.md,
                size === 'lg' && styles.lg,
                rounded && styles.rounded,
                className
            )}
            {...rest}
        >
            {icon}
        </DefaultButton>
    );
});

ButtonIcon.displayName = 'ButtonIcon';

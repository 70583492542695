export const PADDING_HORIZONTAL = '22px';

export const PANEL_WIDTH = {
    BASE: 390,
    LG: 640,
    XL: 940
};

export const PANEL_HEIGHT = 235;

export const TAB_HEIGHT_MOBILE = 68;
export const TAB_HEIGHT_DESKTOP = 108;

import { LayerConfigure, LayerConfigureColorFilter } from './layer-configure';
import { LayerModularAddCategory, LayerModularAddProduct } from './layer-modular-add';
import { LayerModularAttribute, LayerModularAttributeAll } from './layer-modular-attribute';
import { LayerModularCategory, LayerModularCategoryAll, LayerModularCategoryNode } from './layer-modular-category';
import { LayerModularColorsChange, LayerModularColorsChangeAll } from './layer-modular-colors';
import { LayerModularLoading } from './layer-modular-loading';
import { LayerModularNode } from './layer-modular-node';
import {
    LayerOverview,
    LayerOverviewConfigure,
    LayerOverviewRecommended,
    LayerOverviewShoppingList
} from './layer-overview';
import {
    LayerOverviewModular,
    LayerOverviewModularBuilder,
    LayerOverviewModularPresets,
    LayerOverviewModularShoppingList,
    LayerSkeleton
} from './layer-overview-modular';

export const Layer = {
    Configure: LayerConfigure,
    ConfigureColorFilter: LayerConfigureColorFilter,
    ModularAddCategory: LayerModularAddCategory,
    ModularAddProduct: LayerModularAddProduct,
    ModularAttribute: LayerModularAttribute,
    ModularCategory: LayerModularCategory,
    ModularCategoryNode: LayerModularCategoryNode,
    ModularColorsChange: LayerModularColorsChange,
    ModularColorsChangeAll: LayerModularColorsChangeAll,
    ModularCategoryAll: LayerModularCategoryAll,
    ModularAttributeAll: LayerModularAttributeAll,
    ModularLoading: LayerModularLoading,
    ModularNode: LayerModularNode,
    Overview: LayerOverview,
    OverviewConfigure: LayerOverviewConfigure,
    OverviewModular: LayerOverviewModular,
    OverviewModularBuilder: LayerOverviewModularBuilder,
    OverviewModularPresets: LayerOverviewModularPresets,
    OverviewModularShoppingList: LayerOverviewModularShoppingList,
    OverviewRecommended: LayerOverviewRecommended,
    OverviewShoppingList: LayerOverviewShoppingList,
    OverviewSkeleton: LayerSkeleton
};

import { css, cx } from '@linaria/core';

const styles = {
    tableRow: css`
        display: flex;
        margin: auto;

        td {
            padding-left: 18px;
            padding-right: 18px;
            margin-top: 2px;

            &:first-of-type {
                padding-left: 0;
            }
        }
    `
};

interface TableRowProps {
    children: React.ReactNode;
    className?: string;
}

export const TableRow = ({ children, className }: TableRowProps) => (
    <tr className={cx(styles.tableRow, className)}>{children}</tr>
);

import * as React from "react";
const SvgCartSprite = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 180 20", ...props }, /* @__PURE__ */ React.createElement("g", { id: "_0", "data-name": 0 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130", d: "M2.066 5.559h1.39c.256 0 .463.207.463.463v7.875c0 .256.208.463.463.463h8.909c.21 0 .393-.141.448-.344l1.605-6.022a.463.463 0 0 0-.447-.582H6.699", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261", cx: 12.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262", cx: 5.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M0 0h20v20H0z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "_1", "data-name": 1 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-2", d: "M22.066 5.558h1.39c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.448-.344l2.08-7.754a.463.463 0 0 0-.447-.583h-4.196", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-2", cx: 32.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-2", cx: 25.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M20 0h20v20H20z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "_2", "data-name": 2 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-3", d: "M42.048 5.558h1.408c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.448-.344l2.587-9.5a.463.463 0 0 0-.448-.582h-2.721", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-3", cx: 52.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-3", cx: 45.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M40 0h20v20H40z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "_3", "data-name": 3 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-4", d: "M62.068 5.558h1.388c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.448-.344l3.14-11.252a.463.463 0 0 0-.448-.582h-.997", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-4", cx: 72.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-4", cx: 65.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M60 0h20v20H60z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "_4", "data-name": 4 }, /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-5", cx: 92.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-5", cx: 85.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M80 0h20v20H80z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-5", d: "M82.064 5.58h1.39c.256 0 .463.207.463.463l.002 7.854c0 .256.208.463.463.463h8.909c.21 0 .394-.141.448-.344L97.509.5", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} })), /* @__PURE__ */ React.createElement("g", { id: "_5", "data-name": 5 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-6", d: "M102.1 5.558h1.356c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.447-.344l3.14-11.252a.463.463 0 0 0-.447-.582h-.997", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-6", cx: 112.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-6", cx: 105.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M100 0h20v20h-20z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 109.404, cy: 11.181, r: 2, style: {
  fill: "#ee5757"
} })), /* @__PURE__ */ React.createElement("g", { id: "_6", "data-name": 6 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-7", d: "M121.988 5.558h1.468c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.447-.344l2.588-9.5a.463.463 0 0 0-.448-.582h-2.721", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-7", cx: 132.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-7", cx: 125.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M120 0h20v20h-20", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 129.404, cy: 11.181, r: 2, style: {
  fill: "#ee5757"
} })), /* @__PURE__ */ React.createElement("g", { id: "_7", "data-name": 7 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-8", d: "M142.066 5.558h1.39c.256 0 .463.207.463.463v7.876c0 .256.208.463.463.463h8.909c.21 0 .393-.14.448-.344l2.08-7.754a.463.463 0 0 0-.447-.583h-4.196", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-8", cx: 152.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-8", cx: 145.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { cx: 149.404, cy: 11.181, r: 2, style: {
  fill: "#ee5757"
} })), /* @__PURE__ */ React.createElement("g", { id: "_8", "data-name": 8 }, /* @__PURE__ */ React.createElement("path", { id: "Vector_2130-9", d: "M162.066 5.559h1.39c.256 0 .463.207.463.463v7.875c0 .256.208.463.463.463h8.909c.21 0 .393-.141.448-.344l1.605-6.022a.463.463 0 0 0-.447-.582h-8.198", "data-name": "Vector 2130", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1261-9", cx: 172.582, cy: 17.925, r: 1.25, "data-name": "Ellipse 1261", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse_1262-9", cx: 165.168, cy: 17.925, r: 1.25, "data-name": "Ellipse 1262", style: {
  fill: "none",
  stroke: "currentColor",
  strokeMiterlimit: 10
} }), /* @__PURE__ */ React.createElement("path", { d: "M160 0h20v20h-20z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 169.404, cy: 11.181, r: 2, style: {
  fill: "#ee5757"
} })), /* @__PURE__ */ React.createElement("g", { id: "Dots" }, /* @__PURE__ */ React.createElement("circle", { cx: 29.404, cy: 2.455, r: 2, style: {
  fill: "#ee5757"
} }), /* @__PURE__ */ React.createElement("path", { d: "M20 0h20v20H20z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "Ebene_9", "data-name": "Ebene 9" }, /* @__PURE__ */ React.createElement("circle", { cx: 49.404, cy: 5.704, r: 2, style: {
  fill: "#ee5757"
} }), /* @__PURE__ */ React.createElement("path", { d: "M40 0h20v20H40z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "Ebene_10", "data-name": "Ebene 10" }, /* @__PURE__ */ React.createElement("circle", { cx: 69.404, cy: 8.746, r: 2, style: {
  fill: "#ee5757"
} }), /* @__PURE__ */ React.createElement("path", { d: "M60 0h20v20H60z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("g", { id: "Ebene_11", "data-name": "Ebene 11" }, /* @__PURE__ */ React.createElement("circle", { cx: 89.404, cy: 11.181, r: 2, style: {
  fill: "#ee5757"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80 0h20v20H80z", style: {
  fill: "none"
} })));
export default SvgCartSprite;

/**
 * This module provides a utility function for making HTTP POST requests.
 *
 * The `post` function simplifies the process of sending POST requests by:
 * - Automatically setting the 'Content-Type' header to 'application/json' if not provided.
 * - Stringifying the request body.
 * - Handling the response by parsing JSON and throwing an error if the response is not OK.
 *
 * Benefits:
 * - Reduces boilerplate code for making POST requests.
 * - Provides a consistent way to handle errors and responses.
 * - Makes it easier to work with JSON APIs.
 */

export const post = <T>(url: string, params: any, options: any = {}): Promise<T> => {
    options.headers = options.headers || {
        'Content-Type': 'application/json'
    };
    const roptions = {
        method: 'POST',
        body: JSON.stringify(params),
        ...options
    };

    return fetch(url, roptions).then(async (response) => {
        let data;
        try {
            data = await response.json();
        } catch (error) {
            console.log(error);
        }

        if (!response.ok) {
            const err: any = new Error(response.statusText);
            err.data = data;
            err.status = response.status;
            throw err;
        }

        return data as Promise<T>;
    });
};

import { canUseDOM } from 'exenv';
import noop from 'lodash/noop';
import mitt, { Emitter } from 'mitt';
import { useEffect } from 'react';

type VitraEmitter = Emitter<Record<string, unknown>>;

declare const window: Window &
    typeof globalThis & {
        __VITRA_EMITTER: any;
    };

// this is the global event emitter from the renderer
// is used to communicate between different frontends
// eg. breadcrumbs and configurator opens inside the media module
export const emitter: VitraEmitter = canUseDOM && window.__VITRA_EMITTER ? window.__VITRA_EMITTER : mitt();

/**
 * the useEvents hook injects a listener for the given events and a
 * emit function which doesnt require the prefix
 *
 * @param {string} prefix A prefix which scopes the listener and emitter
 * @param {object} events An optional object where every object key represents the name of the event and its value the callback function
 * @return {function} Returns an emit function which may be used tto emit and holds the prefix
 */
type EventsFunc = Record<string, (event: any) => void>;
type EventEmitterCall = (key: string, payload?: any) => void;
export const useEvents = (prefix = 'global', events: EventsFunc = {}): EventEmitterCall => {
    if (!canUseDOM) {
        return noop;
    }

    useEffect(() => {
        // Register Event Listender
        for (const key of Object.keys(events)) {
            emitter.on(`${prefix}:${key}`, (events as any)[key]);
        }

        return () => {
            // Unload Events
            for (const key of Object.keys(events)) {
                emitter.off(`${prefix}:${key}`, (events as any)[key]);
            }
        };
    }, [events, prefix]);

    const emit = (key: string, payload: any) => {
        emitter.emit(`${prefix}:${key}`, payload);
    };
    return emit;
};

import { css, cx } from '@linaria/core';

const styles = {
    tileImageOverlay: css`
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(255 255 255 / 70%);
    `
};

interface ButtonTileImageOverlayProps {
    className?: string;
    children: React.ReactNode;
}

export const ButtonTileImageOverlay = (props: ButtonTileImageOverlayProps) => {
    return <span className={cx('tile-image-overlay', styles.tileImageOverlay, props.className)}>{props.children}</span>;
};

import { useMedia } from '@common/hooks/use-media';
import { MobileHeaderBack } from '@common/icons';
import { Skeleton } from '@common/primitives/skeleton';
import { media } from '@common/styles/media';
import { ButtonIcon, Header } from '@components/configurator';
import { ButtonAddToWishlist } from '@components/configurator/components/button-add-to-wishlist';
import { useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';

const styles = {
    header: css`
        width: 100%;
        max-width: 100%;
        display: flex;
        padding: 12px 10px;
        height: 70px;
        gap: 8px;
        position: relative;
        justify-content: space-between;
        align-items: flex-start;
        z-index: 3;

        header {
            flex: 1;
        }

        ${media.md} {
            width: fit-content;
            padding: 26px 0 0 26px;
        }
    `,
    headerSkeleton: css`
        display: flex;
        flex: 1;
        flex-direction: column;
        z-index: 1;
        gap: 8px;
    `,
    configuratorPresets: css`
        justify-content: flex-start;
    `
};

export const ConfiguratorModularHeader = () => {
    const price = useModularConfigurator((state) => state?.price);
    const delivery = useModularConfigurator((state) => state?.delivery);
    const name = useModularConfigurator((state) => state.initialData?.name);
    const setIsModalOpen = useModularConfigurator((state) => state.setIsModalOpen);
    const nodes = useModularConfigurator((state) => state?.nodes);
    const modularAddToShoppingList = useModularConfigurator((state) => state.modularAddToShoppingList);

    const { md } = useMedia();

    const tab = useModularConfigurator((state) => state.ui.tab);
    const isPresets = tab === 'presets';

    // @todo: Move into header component
    const skeleton = (
        <div className={styles.headerSkeleton}>
            <Skeleton width={200} height={20} />
            <Skeleton width={140} height={20} />
        </div>
    );

    const body = isPresets ? null : (
        <>
            {name ? <Header name={name} price={price} shipping={delivery?.deliveryTime} /> : skeleton}
            {!md && nodes && <ButtonAddToWishlist isModular={true} updateShoppingList={modularAddToShoppingList} />}
        </>
    );

    if (md) {
        return <div className={styles.header}>{body}</div>;
    }

    return (
        <div className={cx(styles.header, isPresets && styles.configuratorPresets)}>
            <ButtonIcon
                aria-label={t`Close`}
                variant="ghost"
                size="md"
                icon={<MobileHeaderBack />}
                onPress={() => setIsModalOpen(false)}
            />
            {body}
        </div>
    );
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';

interface SpinnerProps {
    color?: string;
    className?: string;
    strokeWidth?: number;
    radius?: number;
}

const styles = {
    spinner: css`
        width: 32px;

        @keyframes spin {
            0% {
                stroke-dashoffset: 1;
                transform: rotate(-0.25turn);
            }

            50% {
                stroke-dashoffset: 0;
            }

            100% {
                stroke-dashoffset: -1;
                transform: rotate(0.75turn);
            }
        }

        span {
            display: block;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            width: 100%;
        }

        svg {
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        circle:nth-of-type(2) {
            animation: spin 3s linear infinite;
            stroke-dasharray: 1 1;
            stroke-dashoffset: 1;
            transform-origin: 50% 50%;
            transform: rotate(-0.25turn);
        }
    `
};
export const Spinner = (props: SpinnerProps) => {
    const { color = colors.coral2, className, strokeWidth = 2, radius = 14 } = props;

    return (
        <div className={cx(styles.spinner, className)}>
            <span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={radius * 2}
                    height={radius * 2}
                    viewBox={`0 0 ${radius * 2} ${radius * 2}`}
                    focusable="false"
                >
                    <circle
                        cx={radius}
                        cy={radius}
                        r={radius - strokeWidth}
                        fill="none"
                        stroke="#fff"
                        strokeWidth={strokeWidth}
                        opacity=".55"
                    />
                    <circle
                        pathLength="1"
                        cx={radius}
                        cy={radius}
                        r={radius - strokeWidth}
                        fill="none"
                        stroke={color}
                        strokeWidth={strokeWidth}
                        strokeDasharray="27 57"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </span>
        </div>
    );
};

import { css, cx } from '@linaria/core';
import { motion } from 'framer-motion';

const styles = {
    skeleton: css`
        height: 100%;
        width: 100%;
        background-image: linear-gradient(90deg, #efefef 25%, #f5f5f5 50%, #efefef 75%);
        background-size: 200% 100%;
        background-position: 200% center;
        border-radius: 3px;
    `
};

interface SkeletonProps {
    height: number | string;
    width: number | string;
    className?: string;
}

export const Skeleton = ({ height, width, className }: SkeletonProps) => {
    return (
        <motion.div
            className={cx(styles.skeleton, className)}
            style={{
                height,
                width
            }}
            animate={{ backgroundPosition: '-200% center' }}
            transition={{
                repeat: Number.POSITIVE_INFINITY,
                repeatType: 'loop',
                duration: 2,
                ease: 'linear'
            }}
        />
    );
};

import { colors } from '@common/styles/colors';
import { css, cx } from '@linaria/core';
import React from 'react';

const styles = {
    hint: css`
        color: ${colors.black};
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        text-align: center;
        padding: 12px 22px 14px;
        border-radius: 41px;
        background: white;
        box-shadow: 0 1.867px 3.734px 0 rgba(0 0 0 / 15%);
    `,
    hintColorPrimary: css`
        color: ${colors.white};
        background: ${colors.coral2};
    `
};

type PanelHintProps = {
    children?: React.ReactNode;
    title?: string | React.ReactNode;
    className?: string;
    color?: 'primary' | 'default';
} & ({ children: React.ReactNode } | { title: string | React.ReactNode });

export const PanelHint = ({ title, children, color }: PanelHintProps) => {
    return (
        <span className={cx('panel-hint', styles.hint, color === 'primary' && styles.hintColorPrimary)}>
            {title || children}
        </span>
    );
};

import { Button } from '@common/primitives/button';
import { css, cx } from '@linaria/core';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import React, { useEffect } from 'react';
import { ButtonProps as DefaultButtonProps } from 'react-aria-components';

import { ScrollContext } from '../panel/panel-scroll-area';

const styles = {
    tileOrderSamples: css`
        display: grid;
        place-content: center;
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        > span {
            border-radius: 8px;
            margin-right: 0;
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(120 120 120 / 50%);
        }
    `,

    tileDirectionRow: css`
        margin-right: 18px;
    `,
    tileDirectionColumn: css`
        flex-direction: column;
        max-width: 140px;
    `
};

interface ButtonTileOrderSamplesProps extends DefaultButtonProps {
    className?: string;
    direction: 'row' | 'column';
    size: [number, number];
}

export const ButtonTileOrderSamples = (props: ButtonTileOrderSamplesProps) => {
    const ctx = React.useContext(ScrollContext);
    const ref = React.useRef<HTMLDivElement>(null);
    const { className, direction, size = [80, 80] } = props;

    const motionValue = useMotionValue(0);

    useEffect(() => {
        if (!ctx.scrollWrapperWidth || !ctx.scrollLeft || !ref.current) {
            motionValue.set(0);
            return;
        }
        const scrollPosition =
            (ctx.scrollLeft + ctx.scrollWrapperWidth - ref.current.offsetLeft + /*padding */ 12) /
            ref.current.clientWidth;

        motionValue.set(scrollPosition);
    }, [ctx.scrollLeft, ctx.scrollTop, ctx.scrollWrapperWidth, ctx.scrollWrapperHeight, ref.current]);

    const springValue = useSpring(motionValue ?? 0, {
        stiffness: 500,
        damping: 30,
        restDelta: 0.001
    });

    const offset1 = useTransform(springValue, (value) => 100 - (value - 5)); //
    const offset2 = useTransform(springValue, (value) => 100 - value * 40); //
    const offset3 = useTransform(springValue, (value) => 100 - value * 75); //

    return (
        <span
            ref={ref}
            className={cx(
                'tile-order-materials',
                styles.tileOrderSamples,
                direction === 'column' && styles.tileDirectionColumn,
                direction === 'row' && styles.tileDirectionRow,
                className
            )}
            style={{
                width: size[0],
                height: size[1],
                minWidth: size[0]
            }}
        >
            <Button variant="coral" rounded={true}>
                Order samples
            </Button>
            <motion.span style={{ x: offset1 }} initial={{ x: '100%' }}></motion.span>
            <motion.span style={{ x: offset2 }} initial={{ x: '100%' }}></motion.span>
            <motion.span style={{ x: offset3 }} initial={{ x: '100%' }}></motion.span>
        </span>
    );
};

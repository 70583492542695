import { Back, Close as CloseIcon, Search as SearchIcon } from '@common/icons';
import { ButtonIcon } from '@common/primitives';
import { colors } from '@common/styles/colors';
import { commonStyles } from '@common/styles/styles';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useRef } from 'react';
import { Button, Input, Label, Text, TextField } from 'react-aria-components';

import { SearchInputHint } from './search-input-hint';

const styles = {
    container: css`
        display: flex;
        align-items: center;
        gap: 12px;
    `,
    searchInputWrapper: css`
        background-color: ${colors.white};
        position: relative;
        border-radius: 100px;
        height: 48px;
        box-shadow: 0 1.867px 3.734px 0 rgba(0 0 0 / 12%);
        flex: 1;
    `,
    searchInputField: css`
        height: 100%;
    `,
    searchInputFieldLabel: css`
        position: absolute;
        pointer-events: none;
        left: 48px;
        height: 48px;
        line-height: 48px;
        color: ${colors.grey};
    `,
    searchInputFieldInput: css`
        border-radius: 100px;
        height: 100%;
        width: 100%;
        border: none;
        padding-left: 48px;
        font-size: 15px;
        font-weight: 500;
        line-height: 200%;
        background: transparent;
        -webkit-appearance: none;
        outline-color: ${colors.coral2} !important;

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            outline-color: ${colors.coral2};
        }

        &::placeholder {
            color: white; /* Make it invisible but we still can use the ::placeholder pseudo class above */
        }

        &::-webkit-search-cancel-button {
            display: none;
        }
    `,
    searchInputFieldInputEmpty: css`
        caret-color: transparent;
    `,
    searchInputIconSearch: css`
        position: absolute;
        height: 100%;
        width: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    `,

    searchInputIconClose: css`
        ${commonStyles.buttonReset}
        position: absolute;
        right: 4px;
        top: 4px;
        height: calc(100% - 2 * 4px);
        width: calc(48px - 2 * 4px);
        border-radius: 100%;

        &[data-focused],
        &[data-focus-visible],
        &[data-hovered] {
            outline-color: ${colors.coral2};
        }
    `
};

type SearchInputProps = {
    children?: React.ReactNode;
    onChange?: (value: string) => void;
    onClose?: () => void;
    onClear?: () => void;
    value: string;
    className?: string;
};

export const SearchInput = ({ children, value, onChange, onClear, onClose, className }: SearchInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClear = () => {
        onClear && onClear();

        // Focus the input after clearing
        inputRef.current?.focus();
    };

    return (
        <div className={styles.container}>
            <ButtonIcon aria-label="Close" icon={<Back />} onPress={onClose} />
            <div className={cx(styles.searchInputWrapper, className)}>
                <div className={styles.searchInputIconSearch}>
                    <SearchIcon />
                </div>
                <TextField className={styles.searchInputField} name="search" type="search" inputMode="search">
                    <Input
                        className={cx(styles.searchInputFieldInput, !value && styles.searchInputFieldInputEmpty)}
                        autoComplete="off"
                        autoFocus={true}
                        ref={inputRef}
                        placeholder="Search for..."
                        value={value}
                        onChange={
                            onChange
                                ? (e) => {
                                      onChange(e?.target?.value);
                                  }
                                : undefined
                        }
                    />
                    {!value && (
                        <Label className={styles.searchInputFieldLabel}>
                            <span>
                                <Trans>Search for...</Trans>&nbsp;
                            </span>
                            <SearchInputHint />
                        </Label>
                    )}
                    {children && <Text slot="description">{children}</Text>}
                </TextField>

                <Button className={styles.searchInputIconClose} onPress={handleClear}>
                    <CloseIcon />
                </Button>
            </div>
        </div>
    );
};

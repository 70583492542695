import { css, cx } from '@linaria/core';
import React from 'react';

import { PADDING_HORIZONTAL, PANEL_WIDTH } from './constants';

export const styles = {
    footer: css`
        display: flex;
        padding: ${PADDING_HORIZONTAL};
        gap: 10px;
        margin: 0 auto;

        /*  width: ${PANEL_WIDTH.BASE}px; */

        width: 100%;

        > * {
            /* 1 element should be aligned as 100% */
            flex: 1 1 100%;

            button {
                width: 100%;
            }
        }

        &:has(> :last-child:nth-child(2)) {
            /* 2 elements should be aligned as 50% */
            > * {
                flex: 1 1 50%;

                button {
                    width: 100%;
                }
            }
        }

        @container surface-container (max-width: ${PANEL_WIDTH.BASE}px) {
            width: 100%;
        }
    `
};

interface PanelFooterProps {
    className?: string;
    children: React.ReactNode;
}

export const PanelFooter = (props: PanelFooterProps) => {
    const { className, children } = props;

    return <div className={cx('panel-footer', styles.footer, className)}>{children}</div>;
};

import { useMedia } from '@common/hooks/use-media';
import { Layer, Panel } from '@components/configurator';
import { getLayerPushFromConfiguration, useModularConfigurator } from '@components/configurator/hooks';
import { t } from '@lingui/macro';
import { useEffect } from 'react';

export const LayerModularLoading = () => {
    const primaryStack = useModularConfigurator((state) => state.ui.primaryStack);
    const currentLayer = primaryStack.at(-1);
    const localId = currentLayer?.replace('change-node-loading-', '') || '';
    const isBlocked = useModularConfigurator((state) => state.ui.isBlocked);
    const pushLayer = useModularConfigurator((state) => state.pushLayer);
    const popLayer = useModularConfigurator((state) => state.popLayer);
    const getProductByNodeLocalId = useModularConfigurator((state) => state.getProductByNodeLocalId);
    const clearHistory = useModularConfigurator((state) => state.clearHistory);

    const product = getProductByNodeLocalId(localId ?? '');

    const { md } = useMedia();

    const onClose = () => {
        popLayer();
        clearHistory();
    };

    useEffect(() => {
        if (!isBlocked) {
            const productFromNode = getProductByNodeLocalId(localId);

            const nextLayer = productFromNode ? getLayerPushFromConfiguration(productFromNode) : undefined;
            if (nextLayer) {
                popLayer();
                pushLayer(nextLayer);
            } else {
                onClose();
            }
        }
    }, [isBlocked]);

    const title = product?.name ?? t`Loading`;

    if (md)
        return (
            <>
                <Panel.Navigation kind="back" isDisabled={isBlocked} onPress={onClose} />
                <Panel.ScrollArea>
                    <Panel.Title>{title}</Panel.Title>
                    <Layer.OverviewSkeleton />
                </Panel.ScrollArea>
            </>
        );

    return (
        <>
            <Panel.Navigation kind="back" onPress={onClose} appearance={'mobile'} title="Loading" />
            <Panel.ScrollArea direction={'horizontal'}>
                <Layer.OverviewSkeleton />
            </Panel.ScrollArea>
        </>
    );
};
